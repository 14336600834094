import { gql } from "@apollo/client"

export const upsertEmployeeDetailPageFieldOrderMutation = gql`

    mutation($input: EmployeeDetailPageFieldOrderInput!){
        upsertEmployeeDetailPageFieldOrder(input: $input) {
            message
            response
        }
    }

`; 