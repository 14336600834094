import { Layout, Menu } from "antd";
import logo from '@src/assets/img/wfc-new-logo.png';
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getSharedDocumentById } from "../../util/query/documentShare.query";
import Spinner from "../../components/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";

export const ExternalFilePreview = ()=>{

    const location = useLocation();

    // Use URLSearchParams to parse query string
    const queryParams = new URLSearchParams(location.search);
    const object = queryParams.get('object');


    const {data: fileData, loading} = useQuery(getSharedDocumentById,{
        variables: {
            id: object
        },
        fetchPolicy: 'cache-and-network'
    });

    return(
        <>
            <Layout style={{marginBottom:'50px'}}>
                <Menu mode="horizontal" theme="dark" className='top-menu-layout' triggerSubMenuAction="click" 
                    style={{background:'#2e3f50'}}
                >
                    <Menu.Item>
                        <img src={logo} style={{width:'30px', height:'30px', borderRadius:'4px'}}  className='menu-icon' />
                    </Menu.Item>

                    <Menu.Item className='search' key="search" style={{margin: 'auto', background:"none !important", backgroundColor: 'none !important'}}>
                        File Preview
                    </Menu.Item>

                    <Menu.Item className="menu-item" style={{marginRight:'50px'}} onClick={()=>window.close()}>
                        Exit
                    </Menu.Item>
                </Menu>
            </Layout>

            {loading?
            
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Spinner />
            </div>
            :

            fileData?.getSharedDocumentById?.response?.response.includes(".docx")?
            <div className="file-err">
                <FontAwesomeIcon icon={faCancel} style={{fontSize:'50px'}} /> <br/>
                <div>Oops! Document (.docx) file can not be render here directly. <br/> <small style={{color:'green'}}>Please ask the administrator to upload appropriate file format</small></div>
            </div>

            :
            <iframe 
                
                src={fileData?.getSharedDocumentById?.response?.response}
                title="File Preview"
                allowFullScreen
                allowTransparency
                style={{
                    width: '99.8vw',
                    minHeight: '93vh',
                    margin: 0 ,
                    overflow: 'hidden',
                }}

            />
            
            }

        </>
    );
};