// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userRoleSelection-box{
    border: 1px solid #7fd1de;
    cursor: pointer;
    padding: 12px;
    background-color: #fff;
    font-size: 14px;
    font-weight: bold;
    color: #33475b;
    margin-bottom: 16px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}




.userRoleSelection-box:hover{
    box-shadow: 0 0 0 1px #7fd1de, 0 0 12px 0 rgba(0, 163, 189, 0.3);
}

.userRoleSelection-box-disabled{
    border: 1px solid rgb(176, 193, 212);
    cursor: pointer;
    padding: 12px;
    color: rgb(176, 193, 212) !important;   
    cursor: not-allowed;
    font-size: 14px;
    font-weight: bold;
    color: #33475b;
    margin-bottom: 16px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.userRoleSelection-box-disabled small{
    
    color: rgb(176, 193, 212) !important;
}

.small-text{
    color: #516f90!important;
    margin-top: 10px;
    font-size: 0.6875rem;
    font-weight: normal;
}

.table-footer .small-text{
    margin-top: 0 !important;
    color: #516f90!important;
    font-size: 0.6875rem;
    font-weight: normal;
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/userSetting/component/roleSelectionDrawer/roleSelectionDrawer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;AACb;;;;;AAKA;IACI,gEAAgE;AACpE;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,aAAa;IACb,oCAAoC;IACpC,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,SAAS;AACb;;AAEA;;IAEI,oCAAoC;AACxC;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,wBAAwB;IACxB,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":[".userRoleSelection-box{\n    border: 1px solid #7fd1de;\n    cursor: pointer;\n    padding: 12px;\n    background-color: #fff;\n    font-size: 14px;\n    font-weight: bold;\n    color: #33475b;\n    margin-bottom: 16px;\n    border-radius: 3px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 16px;\n}\n\n\n\n\n.userRoleSelection-box:hover{\n    box-shadow: 0 0 0 1px #7fd1de, 0 0 12px 0 rgba(0, 163, 189, 0.3);\n}\n\n.userRoleSelection-box-disabled{\n    border: 1px solid rgb(176, 193, 212);\n    cursor: pointer;\n    padding: 12px;\n    color: rgb(176, 193, 212) !important;   \n    cursor: not-allowed;\n    font-size: 14px;\n    font-weight: bold;\n    color: #33475b;\n    margin-bottom: 16px;\n    border-radius: 3px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 16px;\n}\n\n.userRoleSelection-box-disabled small{\n    \n    color: rgb(176, 193, 212) !important;\n}\n\n.small-text{\n    color: #516f90!important;\n    margin-top: 10px;\n    font-size: 0.6875rem;\n    font-weight: normal;\n}\n\n.table-footer .small-text{\n    margin-top: 0 !important;\n    color: #516f90!important;\n    font-size: 0.6875rem;\n    font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
