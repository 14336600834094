import axios from 'axios';
import { baseURL } from '@src/config/apollo';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useMutation, useQuery } from "@apollo/client";
import { getReportTemplateById } from "@src/util/query/reportTemplate.query";
import { useEffect, useState } from "react";
import { GenerateNormalFields } from "@src/util/generateFields/generateNormalField";
import { lookupTypes } from '@src/util/types/lookup.types';
import { CreateFormLookUpFields } from '@src/util/generateFields/createFormlookupFields';
import Spinner from "@src/components/spinner";
import { useDispatch } from "react-redux";
import { createScheduleReportMutation } from '@src/util/mutation/scheduleReport.mutation';
import { getScheduleReportByJobIdQuery } from '@src/util/query/scheduleReport.query';
import { updateScheduleReportMutation } from '@src/util/mutation/scheduleReport.mutation';
import { Alert, Dropdown, Form, Input, Modal, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClipboard, faClose, faEllipsisV, faMailForward, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { UpdateScheduleByField } from '@src/util/mutation/schedule.mutation';
import { genericFileUploadSignatureMutation } from '@src/util/mutation/genericFileUpload.mutation';
import { FileFormatTypes } from '@src/util/fileFormatTypes';
import { submitReportByEmailMutation } from '@src/util/mutation/scheduleReport.mutation';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { getScheduleReportBySiteandTemplateIdQuery } from '@src/util/query/scheduleReport.query';
import { WardSecurityMobileReport } from '../../../../reports/mobileReports/wardSecurity/wardSecurityMobileReport';
import { dutyReportToTemplateMapping } from '../../../../util/mobileDutyReportToTemplateMapping/mobileDutyReportToTemplateMapping';



export const EditDutyReportTab = ({templateId, editData, refetch, jobData, refetchScheduleData})=>{
    

    const {data, loading} = useQuery(getReportTemplateById,{
        variables:{
            templateId
        },
        fetchPolicy:'network-only',
        skip: !templateId
    });

    const {data: lastReportData, loading:  lastReportDataLoading,} = useQuery(getScheduleReportBySiteandTemplateIdQuery,{
        variables:{

            siteId: jobData?.site?._id,
            templateId: templateId,

        },
        skip: !templateId || !jobData?.site?._id,
        fetchPolicy:'network-only'
    });

    const {data: reportData, loading: reportDataLoading, refetch: reportDataRefetch} = useQuery(getScheduleReportByJobIdQuery,{
        variables:{
            jobId: editData?.jobId
        },
        skip: !editData?.hasOwnProperty('jobId'),
        fetchPolicy:'network-only'
    });



    const [reportFields, setReportFields] = useState([]);
    const [field, setField] = useState([]);

    useEffect(()=>{
        if(!loading && !lastReportDataLoading){
            if(
                (reportData?.getScheduleReportByJobId?.response && Object.keys(reportData?.getScheduleReportByJobId?.response)?.length>0) ||
                (lastReportData?.getScheduleReportBySiteandTemplateId?.response && Object.keys(lastReportData?.getScheduleReportBySiteandTemplateId?.response)?.length>0)
            ){
                const reportFormData = reportData?.getScheduleReportByJobId?.response?.reportDetail;
                if(reportFormData){
                    setField([...Object.keys(reportFormData)?.map((report)=>{
                        return({
                            name: report,
                            value: reportFormData[report],
                            type: reportFormData[report]?.includes(".")? 
                            FileFormatTypes.includes(reportFormData[report]?.split(".")[1])? 
                            FileFormatTypes?.find((format)=>format==reportFormData[report]?.split(".")[1])
                            : null : null,

                        })
                    })]);    
                }
                // if there is not this job data extract the previouse report if any
                
                else if(lastReportData?.getScheduleReportBySiteandTemplateId?.response?.hasOwnProperty("reportDetail")){
                
                    const reportFormData = lastReportData?.getScheduleReportBySiteandTemplateId?.response?.reportDetail;

                    setField([...Object.keys(reportFormData)?.map((report)=>{
                        return({
                            name: report,
                            value: reportFormData[report],
                            type: null

                        })
                    })]);    
                }

            }else{
                setField([...field, {name:'sitename', value:editData?.sitename}, {name:'siteid', value:editData?._id}]);
            }
        }
    },[reportData?.getScheduleReportByJobId?.response, editData, loading, lastReportDataLoading]);


    useEffect(()=>{
        if(data?.getReportTemplateById?.response?.template?.rawReportFields){
            setReportFields([...data?.getReportTemplateById?.response?.template?.rawReportFields]);
        }
    },[data?.getReportTemplateById?.response?.template?.rawReportFields]);

    
    
    const handelDataValue = ({name, value})=>{
        if(name){
            if(value){
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value}])
                }
            }else{
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value:''
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value:''}])
                }
            }
        }
    };


    const [groupInput, setGroupInput] = useState();
    
    const dispatch = useDispatch();
    const [newScheduleReport, {loading: newScheduleReportLoading}] = useMutation(createScheduleReportMutation)
    const [updateScheduleReport, {loading: updateScheduleReportLoading}] = useMutation(updateScheduleReportMutation)

    const [confirmationModal, setConfirmationModal] = useState(false);

    const [updateScheduleByFieldMutation, {loading: updateScheduleLoading}] = useMutation(UpdateScheduleByField)

    const [genericFileUploadSignature, {loading: genericFileUploadSignatureLoading}] = useMutation(genericFileUploadSignatureMutation);
    const [fileLoading, setFileLoading] = useState(false);

    const handelSubmit = async (isSubmitted=false)=>{
        try{
            setConfirmationModal(false);
            const filesName = field.filter((f)=>f?.value?.size)?.map((file)=>file?.value?.name);

            if (filesName?.length>0){
                setFileLoading(true);

                await field.filter((f)=>f?.value?.size)?.map(async(file)=>{
                    
                    const fileUploadUrlsResponse = await genericFileUploadSignature({
                        variables:{
                            input:{
                                fileName : "dutyreport/"+file?.value?.name,
                                objectType: "dutyreport"
                            }
                        }
                    });
    
                    const fileUploadUrls = fileUploadUrlsResponse?.data?.genericFileUploadSignatureUrl?.response?.url;
                    
                    await axios.put(fileUploadUrls, file?.value,{
                        headers: {
                            'Content-Type': file?.value?.type ,
                        },
                    });


                })


            }


            let fieldData= {};
            field?.map((f)=>{fieldData[f.name]=f?.value?.name? "dutyreport/"+f?.value?.name?.replace(/\s/g,"").toLowerCase(): f.value});
            setFileLoading(false);

            if(reportData?.getScheduleReportByJobId?.response?._id){
                await updateScheduleReport({
                    variables:{
                        input:{
                            _id: reportData?.getScheduleReportByJobId?.response?._id,
                            reportDetail: fieldData,
                            isSubmitted,
                        }
                    }
                });
                dispatch(
                    setNotification({
                        error: false,
                        notificationState: true,
                        message: "Report was updated successfully"
                    })
                );
            }else{

                await newScheduleReport({
                    variables:{
                        input: {
                            jobId: editData?.jobId,
                            areaId: editData?.selectedarea?._id,
                            siteId: editData?._id,
                            templateId: templateId,
                            customerId: editData?.customerDetail?._id,
                            reportDetail: fieldData,
                            isSubmitted
                        }
                    }
                });
                dispatch(
                    setNotification({
                        error: false,
                        notificationState: true,
                        message: "Report was saved successfully"
                    })
                );
            }
            await reportDataRefetch();

            // update report submitted report in schedule 
            if(isSubmitted){
                await updateScheduleByFieldMutation({
                    variables:{
                        input:{
                            _id: editData?.jobId,
                            field: "isReportSubmitted",
                            value: true
                        }
                    }
                });
                await refetch();
            }

        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    notificationState: true,
                    message: err.message
                })
            );
        }
    };

    const [submitReportByEmail, {submitReportByEmailLoading}] = useMutation(submitReportByEmailMutation);

    const handelSubmitReportByEmail = async (scheduleId)=>{
        try{

            
            await submitReportByEmail({
                variables:{
                    scheduleId
                }
            });


            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Report submission marked as submitted by email"
            }));

            await refetchScheduleData();
            await reportDataRefetch();

        }catch(err){

            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error was encountered while marking submission"
            }));

        }
    };

    // navigate(dutyReportToTemplateMapping[templateId]+scheduleId);


    return(
        reportDataLoading || loading || lastReportDataLoading?
            <Spinner/>
        :
        (reportData?.getScheduleReportByJobId?.response?.isSubmitted && !reportData?.getScheduleReportByJobId?.response?.isSubmittedByEmail && 
            dutyReportToTemplateMapping['wardSecurity']?.templateId==templateId
        )?
        
            <div style={{overflow:'auto', height:'78vh', boxShadow:'0 0 15px rgba(0,0,0,.19)'}} className="hide-scrollbar border-radius">
                
                    <WardSecurityMobileReport 
                        jobId={jobData?._id} 
                        copyLink={baseURL+`/schedulereporting/${templateId}/${editData?.jobId}/${jobData?.site?._id}`}
                        
                                                                            
                    />
            </div>
        :
        <div style={{overflow:'auto', height:'78vh', boxShadow:'0 0 15px rgba(0,0,0,.19)'}} className="hide-scrollbar border-radius">
            {/* <h4 style={{margin:'15px 30px', fontSize:'18px'}}>{data?.getReportTemplateById?.response?.template?.templatename.toUpperCase()}</h4> */}
            {
                loading? <Spinner />:
                <div style={{padding:'16px', 
                    position: 'sticky',
                    top: '0rem',
                    background: 'white',
                    zIndex: 9,
                }}>
                    <div className='hr-info-title' style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <span>
                           {data?.getReportTemplateById?.response?.template?.templatename?.toUpperCase()}
                        </span>

                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        label :     
                                                <Popover
                                                    overlayClassName=''
                                                    content={
                                                        <div style={{padding:'20px', display:'flex', flexDirection:'row', gap:'10px', alignItems:'center'}}>
                                                                <Input
                                                                    className='generic-input-control'
                                                                    value={baseURL+`/schedulereporting/${templateId}/${editData?.jobId}/${jobData?.site?._id}`}
                                                                    suffix={
                                                                        <FontAwesomeIcon icon={faCopy} style={{cursor:'pointer'}}
                                                                            onClick={()=> {navigator.clipboard.writeText(baseURL+`/schedulereporting/${templateId}/${editData?.jobId}/${jobData?.site?._id}`); dispatch(setNotification({message: "Copied !", error: false, notificationState: true})) } }
                                                                        />
                                                                    }
                                                                />
                                                                
                                                                <Popover
                                                                    content={"Click to share on whatsapp"}
                                                                    placement='bottom'
                                                                >
                                                                    <svg  
                                                                        onClick={()=>window.open(`https://wa.me/?text=${
                                                                            encodeURIComponent(
                                                                                `Dear officer ,\n\nPlease remember to submit your duty report for ${editData?.sitename} before the end of your shift.\n\nYou can complete the report by following this link:\n${baseURL}/schedulereporting/${templateId}/${editData?.jobId}\n\nIt is mandatory to submit the report to ensure all tasks and incidents are properly documented.\n\nThank you for your attention to this matter.\n\nBest regards,\nIntraguard Security Services\n0333 888 0247`
                                                                            )
                                                                        }`)}
                                                                        fill={"#25D366"}
                                                                        style={{cursor:'pointer'}}
                                                                        width={35}
                                                                        height={35}
                                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>

                                                                </Popover>
                                                                
                                                        </div>
                                                    }
                                                >

                                                    <div 
                                                        style={{padding:'10px', margin:'5px'}}
                                                    > 
                                                        <FontAwesomeIcon icon={faShareAlt} /> 
                                                        &nbsp; Share Link
                                                    </div>
                                                </Popover>,

                                        key:0
                                    },
                                    {
                                        label :     <div 
                                                        style={{padding:'10px', margin:'5px'}}
                                                        onClick={()=>{}} 
                                                    > 
                                                        <FontAwesomeIcon icon={faMailForward} /> 
                                                        &nbsp; Send Reminder?
                                                    </div>,
                                        key:1
                                    },
                                    {
                                        label : <Popover
                                                    placement='right'
                                                    content={"If you have received report by email you can click here to submit report or fill it manual below."}
                                                >
                                                    <div style={submitReportByEmailLoading || reportData?.getScheduleReportByJobId?.response?.isSubmittedByEmail? {cursor:'not-allowed', color: 'rgb(176, 193, 212)', padding:'10px', margin:'5px'}:{padding:'10px', margin:'5px'}} 
                                                        onClick={
                                                            submitReportByEmailLoading || reportData?.getScheduleReportByJobId?.response?.isSubmittedByEmail? null :
                                                            ()=>{const result = confirm("Did you already recevied report on email?"); result? handelSubmitReportByEmail(editData?.jobId) : false}
                                                        } 
                                                    > 
                                                        <FontAwesomeIcon icon={faCheck} /> 
                                                        &nbsp; Report recevied by email?
                                                    </div>
                                                </Popover>,
                                        key:2
                                    }
                                ]
                            }}
                            trigger={['click']}
                        >
                            <FontAwesomeIcon icon={faEllipsisV} style={{cursor:'pointer'}}/>

                        </Dropdown>
                    </div>
                </div>
            }
            {
                reportData?.getScheduleReportByJobId?.response?.isSubmittedByEmail?
                <Alert
                    showIcon
                    type='success'
                    message={"Report was submitted by email"}
                    style={{margin:'0 30px'}}
                />
                :null
            }

            {
            loading || reportDataLoading || submitReportByEmailLoading?
                <div style={{margin:'auto', display:'table'}}>
                    <Spinner/>
                </div>
            
            :
            genericFileUploadSignatureLoading || fileLoading || updateScheduleReportLoading || newScheduleReportLoading?
                <div style={{display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center'}}>
                    <div>
                        <Spinner/>
                    </div>
                    <div className="text">
                        {
                            genericFileUploadSignatureLoading || fileLoading? "Files are uploading" :
                            (updateScheduleReportLoading || newScheduleReportLoading) && "Please wait we are saving details"
                        }
                    </div>
                </div>

            :
                reportFields.map((section, index) => {
                    const sectionKey = Object?.keys(section)[0];
                    const sectionItems = section[sectionKey] || [];
                    const groupName = sectionItems[0]?.groupName || "";
                    return(
                        sectionItems?.length>0?
                        
                        <div style={{marginBottom:'2%', margin:'15px 30px'}} className="box-shadow border-radius">
                            
                            <div className='hr-info-title' style={{marginBottom:'16px'}}>
                                {groupName}
                            </div>
                            
                            <div className='report-section'>
                                {
                                    sectionItems.map((prop, itemIndex) => {
                                        const label = prop?.label;
                                        const name = prop?.label.toLowerCase().replace(/\s/g,"");
                                        const fieldType = prop?.fieldType;
                                        const newprop = prop;                                    
                                        const {value, type} = field?.find((f)=>f.name==name) || {value: "", type: null};
                                        

                                        if(reportData?.getScheduleReportByJobId?.response?.isSubmitted){
                                            
                                            return(
                                                <Form.Item style={{width:'49%'}} >
                                                    <label style={{fontSize:'16px'}}>{label}</label>
                                                    {type && FileFormatTypes?.includes(type) ?
                                                    <div className='reportImgWrapper'>
                                                        <img 
                                                        src={reportData?.getScheduleReportByJobId?.response?.filesURL[name]}  
                                                        onClick={()=>window.open(reportData?.getScheduleReportByJobId?.response?.filesURL[name],"_blank")} 
                                                        />
                                                    </div>
                                                    :
                                                    <div className="text" style={{fontStyle:'italic', padding:'10px 5px', background:'rgb(245, 248, 250)'}}>{value?.length>0? value : "---"}</div>
                                                    }
                                                </Form.Item>
                                            )
                                        }else{
                                            return(
                                                lookupTypes.includes(fieldType)?
                                                
                                                <div  style={{width:'49%'}}>
                                                    <CreateFormLookUpFields
                                                        employee={false}
                                                        setGroupInput={setGroupInput}
                                                        tags={[]}
                                                        setTags={()=>{}}
                                                        property={{propertyDetail:prop}}
                                                        value={value}
                                                        name={name}
                                                        handelDataValue={handelDataValue}
                                                        checkMandatoryField={()=>{}}
                                                        setBtn={()=>{}}
                                                        disabled={true}
                                                    />
                                                </div>

                                                :
                                                <div  style={{width:'49%'}} >

                                                    
                                                    {
                                                        type && FileFormatTypes?.includes(type) ?
                                                        <div style={{marginTop:'16px'}}>
                                                            <label>{label}</label>
                                                            <div style={{display:'flex', gap:'16px'}}>
                                                                <div className='prev-btn' onClick={()=>window.open(reportData?.getScheduleReportByJobId?.response?.filesURL[name],"_blank")}> View </div> 
                                                                OR
                                                                <div className='prev-btn' onClick={()=>setField(field?.filter((prop)=>prop?.name!==name))}> Replace </div>
                                                            </div>
                                                        </div>

                                                        :
                                                        
                                                        <GenerateNormalFields
                                                            label={label}
                                                            name={name}
                                                            fieldType={fieldType}
                                                            handelDataValue={handelDataValue}
                                                            property={newprop}
                                                            value={value}
                                                            employee={false}
                                                            isReporting={true}
                                                            disabled={true}
                                                        />

                                                    }

                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>

                        </div>
                        :
                        null
                    )
                })
            
            } 
            
            {!reportData?.getScheduleReportByJobId?.response?.isSubmitted  || !jobData?.isReportSubmitted?
                <div 
                    className="drawer-footer border-radius-bottom"
                    style={
                        loading? 
                        {display:'flex', alignItems:'right', justifyContent:'right', margin:'-10px', marginTop:'30px', opacity:0.1, position:'sticky'}
                        :{display:'flex', alignItems:'right', justifyContent:'right', margin:'-10px', marginTop:'30px', position:'sticky'}
                    }

                >

                    <button disabled={newScheduleReportLoading || updateScheduleReportLoading}  className={newScheduleReportLoading || updateScheduleReportLoading? 'disabled-btn drawer-filled-btn':'drawer-filled-btn'} onClick={()=>handelSubmit(false)}>
                        {newScheduleReportLoading || updateScheduleReportLoading? <Spinner/> : "Save"}
                    </button>

                    <button disabled={newScheduleReportLoading || updateScheduleReportLoading}  className={newScheduleReportLoading || updateScheduleReportLoading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={()=>{setConfirmationModal(true)}}>
                        Save & Submit
                    </button>

                    
                </div>
            :<div style={{marginTop:'64px'}}></div>}

            <ConfirmationModal
                visible={confirmationModal}
                close={()=>setConfirmationModal(false)}
                handelSubmit={handelSubmit}
            />
        </div>
    );
};

const ConfirmationModal = ({visible, close, handelSubmit})=>{
    return(
        <Modal    
            visible={visible}
            width={600}
            footer={
                <div 
                    className="drawer-footer"
                >

                    <button className={'drawer-filled-btn'} onClick={()=>{handelSubmit(true)}}>
                        Confirm
                    </button>

                    <button disabled={false}  className={'drawer-outlined-btn'} onClick={close}>
                        Cancel
                    </button>

                </div>
            }
            closable={false}
        >
            <div className='modal-header-title'>
                <span style={{letterSpacing:'0.2px'}}> Confirmation </span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div className='modal-body modal-body-padding'>
                <div className="text" style={{textAlign:'center'}}>This action can not be undo. Are you sure to perform this action?</div>
            </div>

        </Modal>
    )
}