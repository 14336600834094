import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const convertToLocalTime = (utcDateFromDB, time, formatted=true) => {

    const bstTime = dayjs.utc(utcDateFromDB).tz("Europe/London", true);
    const gmtTime = dayjs(utcDateFromDB);
    
    if(bstTime.format("HH:mm")==time && formatted){
        
        return bstTime.format("DD/MM/YYYY HH:mm");

    }else if(bstTime.format("HH:mm")==time && !formatted){
        return bstTime;
    }
    else if(gmtTime.format("HH:mm")==time  && formatted){
        return gmtTime.format("DD/MM/YYYY HH:mm");

    }
    else if(gmtTime.format("HH:mm")==time  && !formatted){
        return gmtTime;

    }


}




export const getTimeZoneAdjustment = ()=>{

    // get GMT
    const currentYear = dayjs().format("YYYY");
    const gmtMonth = 10;
    const gmt = getSpecificTimezone(currentYear, gmtMonth);
    // get BST
    const bstMonth = 3;
    const bst = getSpecificTimezone(currentYear, bstMonth);

    return {
        gmt, bst
    }

}



const getSpecificTimezone = (year, month) => {
    let lastDay = dayjs(`${year}-${month}-31`); // Start at October 31st

    while (lastDay.day() !== 0) { // Check if it's Sunday (0 = Sunday)
      lastDay = lastDay.subtract(1, 'day'); // Go back one day
    }
    return lastDay; // Return formatted date
}