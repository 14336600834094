import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { getPayBillReportMutation } from "@src/util/mutation/systemReport.mutation";
import Spinner from "@src/components/spinner";
import { useSelector } from "react-redux";
import { saveAs } from 'file-saver';
import Papa from "papaparse";
import { Button, Dropdown, Menu } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


// to do holiday bill and hour
// if first date is in holiday then first date hour and pay and bill will goes to holiday section and regular would be zero
// vice versa for second date as well
// Bank Holiday 65ff5efdcdef94de7cabc3cb

export const SystemReportEmployeePayDetailView = () => {
    
    const location = useLocation();
    const {filters = {}, filterContent = '', reportDetail = {}} = location?.state || {};
    
    const [reportData, setReportData] = useState([]);
    const navigate = useNavigate();

    const [getPayBillReport, {loading: getPayBillReportLoading}] = useMutation(getPayBillReportMutation);

    const handelPayBillReport = async ()=>{
        try{
            if(filters?.conditions){

                const {data:{getPayBillReport: {response}}} = await getPayBillReport({
                    variables:{
                        filters: {conditions: filters.conditions[0]!=null? filters.conditions: [], date: {from: dayjs(filters?.dates?.from, "DD/MM/YYYY"), to: dayjs(filters?.dates?.to, "DD/MM/YYYY")}}
                    }
                });

                if(response?.length==0){
                    alert("There is no pay/bill calculated in the selected dates");
                    navigate(-1);
                }else{

                    setReportData(response);
                }

            }
        }catch(err){
            console.log(err);
        }
    };

    useEffect(()=>{
        if(filters){

            handelPayBillReport();

        }
    },[filters]);

    const exportToCSV = ()=>{

        const groupedData = groupByMultipleKeys(reportData, ["siteId"]);
        let csvData = [];

        Object.keys(groupedData)?.map((group)=>{
            groupedData[group]?.map((job)=>{
                
                const response = {
                    
                    "Employee Pay Code/Name": job?.employeeDetail?.firstname+" "+(job?.employeeDetail?.lastname || ""),

                    "Regular Pay Hour": convertToHrsMin(parseFloat(job?.payFirstDateHour)+parseFloat(job?.paySecondDateHour)),
                    
                    "Regular Pay": `£ ${parseFloat(job?.paybillDetail?.totalPay).toFixed(2)}`,
                    
                    "Bank Holiday Pay Hour": 
                    job?.isPayFirstDateInBankHoilday && job?.isPaySecondDateInBankHoilday?
                    convertToHrsMin(parseFloat(job?.payFirstDateHour)+parseFloat(job?.paySecondDateHour))
                        :
                    job?.isPayFirstDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.payFirstDateHour))
                        :
                    job?.isPaySecondDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.paySecondDateHour))
                        :
                        "00 hrs 00 m"
                    ,

                    "Holiday Pay": 
                    job?.isPayFirstDateInBankHoilday && job?.isPaySecondDateInBankHoilday?
                        "£ " +(parseFloat(job?.paybillDetail?.totalPay)?.toFixed(2))
                        :
                    job?.isPayFirstDateInBankHoilday?
                        "£ " +(parseFloat(job?.paybillDetail?.firstDatePay))?.toFixed(2)
                        :
                    job?.isPaySecondDateInBankHoilday?
                        "£ " +(parseFloat(job?.paybillDetail?.secondDatePay))?.toFixed(2)
                        :
                        "£ 0.00"
                    ,


                    "Overtime Pay Hour": "00 hrs 00 m",
                    "Overtime Pay": "£ 0.00",

                    "Premium Pay": `£ ${(job?.paybillDetail?.payBillPremium?.length>0 ? (job?.paybillDetail?.payBillPremium[0][0]?.payPremium?.totalPremiumPay).toFixed(2) : 0.00) || "0.00"}`,
                    
                    "Total Pay": `£ ${parseFloat(job?.paybillDetail?.totalPay)?.toFixed(2)}`,
                    

                };

                csvData.push(response);

            });
        });

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const name = reportDetail?.name+" - "+dayjs().format("DD/MM/YYYY");
        saveAs(blob, `${name}.csv`);

    };

    const groupByMultipleKeys = (data, keys) => {
        if (!keys.length) return data; // If no keys are provided, return original data
      
        return data.reduce((acc, item) => {
          let currentLevel = acc;
      
          // Iterate through keys and build nested structure
          keys.forEach((key, index) => {

            let keyValue = "Unknown";

            if(key=="customerId"){
                keyValue = item[key]+"_"+item['customerDetail']['customername'].replaceAll(" ", "-")+"_customer";
            }
            else if(key=="siteGroupId"){
                keyValue = item[key]+"_"+item['sitegroupsDetail']['sitegroupname'].replaceAll(" ", "-")+"_sitegroup";
            }
            else if(key=="siteId"){
                keyValue = item[key]+"_"+item['siteDetail']['sitename'].replaceAll(" ", "-")+"_site";
            }
            else if(key=="assignedEmployee"){
                keyValue = item[key]+"_"+(item['employeeDetail']['firstname']+" "+(item['employeeDetail']['lastname'] || "")).replaceAll(" ", "-")+"_employee";
            }
      
            if (!currentLevel[keyValue]) {
              currentLevel[keyValue] = index === keys.length - 1 ? [] : {}; // Last level stores array, others are objects
            }
      
            // Move to the next nested level
            currentLevel = currentLevel[keyValue];
          });
      
          // Push item to the last nested level
          currentLevel.push(item);
          return acc;
        }, {});
    };

    const [groupedData, setGroupedData] = useState([]);

    const groupAndSum = (data, keys) => {
        if (!keys.length) return data; // If no keys are provided, return original data
    
        return data.reduce((acc, item) => {
            let currentLevel = acc;
    
            // Build dynamic keys for grouping
            keys.forEach((key, index) => {
                let keyValue = "Unknown";
    
                if (key === "customerId") {
                    keyValue = `${item[key]}_${item.customerDetail.customername.replaceAll(" ", "-")}_customer`;
                } else if (key === "siteGroupId") {
                    keyValue = `${item[key]}_${item.sitegroupsDetail.sitegroupname.replaceAll(" ", "-")}_sitegroup`;
                } else if (key === "siteId") {
                    keyValue = `${item[key]}_${item.siteDetail.sitename.replaceAll(" ", "-")}_site`;
                } else if (key === "assignedEmployee") {
                    keyValue = `${item[key]}_${((item.employeeDetail?.firstname) +" "+(item.employeeDetail?.lastname || "")).replaceAll(" ", "-")}_employee`;
                    
                }
    
                // If last level, store an array
                if (!currentLevel[keyValue]) {
                    currentLevel[keyValue] = index === keys.length - 1 ? {  sums: {} } : {};
                }
    
                // Move to the next nested level
                currentLevel = currentLevel[keyValue];
            });
    
    
            // Define properties to sum
            const sumProperties = [
                "regularPayHour", "regularPay", "holidayPayHour", "holidayPay",
                "overtimePayHour", "overtimePay", "payPremium", "totalPay", "unPaidBreak", "unPaidBreakAmount", "totalHours"
            ];
    
            // Initialize sums object if not present
            if (Object.keys(currentLevel.sums)?.length==0) {
                currentLevel.sums = {};
                sumProperties.map(prop => /Hoilday/i.test(prop?.toLowerCase()) ? currentLevel.sums[prop] = false : currentLevel.sums[prop] = 0);
               

            }
            
            // Sum up required fields
            sumProperties.forEach(prop => { 

                const value = 
                prop=="payPremium"?
                    item?.paybillDetail?.payBillPremium[0] && item?.paybillDetail?.payBillPremium[0]?.length>0?
                        parseFloat( item?.paybillDetail?.payBillPremium[0][0]?.payPremium?.totalPremiumPay ) || 0.00
                    :
                    0.00
                :
                
                
                prop=="regularPay"?

                        (!item?.isPayFirstDateInBankHoilday && !item?.isPaySecondDateInBankHoilday)?
                            item?.paybillDetail?.payFirstDateHour>0?
                            ( parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.secondDatePay) + parseFloat(item?.paybillDetail?.unpaidBreakAmount) )

                            :
                            (parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.secondDatePay))

                        : !item?.isPayFirstDateInBankHoilday?
                            item?.paybillDetail?.payFirstDateHour>0?
                                parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.unpaidBreakAmount)
                                :
                                parseFloat(item?.paybillDetail?.firstDatePay)

                        : !item?.isPaySecondDateInBankHoilday?
                        parseFloat(item?.paybillDetail?.secondDatePay)

                        : 0
                :
                prop=="regularPayHour"?

                        (!item?.isPayFirstDateInBankHoilday && !item?.isPaySecondDateInBankHoilday) ?
                            (parseFloat(item?.paybillDetail?.payFirstDateHour)+parseFloat(item?.paybillDetail?.paySecondDateHour))
                        :!item?.isPayFirstDateInBankHoilday?
                            (parseFloat(item?.paybillDetail?.payFirstDateHour))

                        :!item?.isPaySecondDateInBankHoilday?
                            (parseFloat(item?.paybillDetail?.paySecondDateHour))
                        
                        :(0)
                :
                prop=="holidayPayHour"?
                        (item?.isPayFirstDateInBankHoilday && item?.isPaySecondDateInBankHoilday) ?
                            (parseFloat(item?.paybillDetail?.payFirstDateHour)+parseFloat(item?.paybillDetail?.paySecondDateHour))
                        :item?.isPayFirstDateInBankHoilday?
                            (parseFloat(item?.paybillDetail?.payFirstDateHour))

                        :item?.isPaySecondDateInBankHoilday?
                            (parseFloat(item?.paybillDetail?.paySecondDateHour))
                        
                        :(0)

                :
                prop=="holidayPay"?

                        (item?.isPayFirstDateInBankHoilday && item?.isPaySecondDateInBankHoilday)?

                            item?.paybillDetail?.payFirstDateHour>0?
                                ( parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.secondDatePay) + parseFloat(item?.paybillDetail?.unpaidBreakAmount) )

                                :
                                (parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.secondDatePay))

                        : item?.isPayFirstDateInBankHoilday?
                            item?.paybillDetail?.payFirstDateHour>0?
                                parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.unpaidBreakAmount)
                                :
                                parseFloat(item?.paybillDetail?.firstDatePay)


                        : item?.isPaySecondDateInBankHoilday?
                            parseFloat(item?.paybillDetail?.secondDatePay)

                        : 0

                :
                prop=="overtimePayHour"?

                    (item?.paybillDetail?.payOverTimeHour && item?.paybillDetail?.payOverTimeHour>0 )?
                        item?.paybillDetail?.payOverTimeHour
                    :0

                :
                
                prop=="overtimePay"?

                    (item?.paybillDetail?.payOverTimeHour && item?.paybillDetail?.payOverTimeHour>0 )?
                        item?.paybillDetail?.payFirstDateHour==0?
                            parseFloat(item?.paybillDetail?.overTimePay) + parseFloat(item?.paybillDetail?.unpaidBreakAmount)

                        :
                            parseFloat(item?.paybillDetail?.overTimePay)
                    :0

                :

                prop=="unPaidBreak"?
                    !Number.isNaN(parseFloat(item?.unPaidBreakDuration))?
                        parseFloat(item?.unPaidBreakDuration)/60
                    :
                    0
                :

                prop=="unPaidBreakAmount"?
                    
                    !Number.isNaN(parseFloat(item?.unPaidBreakDuration))?
                        parseFloat(item?.paybillDetail?.unpaidBreakAmount)
                    :
                        0

                :

                prop=="totalHours"?
                    !Number.isNaN(parseFloat(item?.unPaidBreakDuration))?
                    ((parseFloat(item?.paybillDetail?.payFirstDateHour)+parseFloat(item?.paybillDetail?.paySecondDateHour)+parseFloat(item?.paybillDetail?.payOverTimeHour))-(parseFloat(item?.unPaidBreakDuration)/60))
                    :
                    (parseFloat(item?.paybillDetail?.payFirstDateHour)+parseFloat(item?.paybillDetail?.paySecondDateHour)+parseFloat(item?.paybillDetail?.payOverTimeHour))
                :
                
                prop=="totalPay"?
                    parseFloat(item?.paybillDetail?.totalPay)
                :
                    0

                currentLevel.sums[prop] += value;
            });

    
            return acc;
        }, {});
    };
    
    const [totalCustomer, setTotalCustomer] = useState({});
    const [totalSiteGroup, setTotalSiteGroup] = useState({});
    const [totalSite, setTotalSite] = useState({});
    const [totalEmployee, setTotalEmployee] = useState({});

    useEffect(()=>{
        if(reportData){
            // ["customerId", "siteGroupId", "siteId"]
            const groupedData = groupByMultipleKeys(reportData, ["customerId", "siteGroupId", "siteId", 'assignedEmployee']);
        
            setGroupedData(groupedData);
            setTotalCustomer( groupAndSum(reportData, ["customerId"]) );
            setTotalSiteGroup( groupAndSum(reportData, ["siteGroupId"]));
            setTotalSite( groupAndSum(reportData, ["siteId"]) );
            setTotalEmployee( groupAndSum(reportData, ["assignedEmployee"]) );

        }
    },[reportData]);

    

    const convertToHrsMin = (decimalHours) => {
        const hours = Math.floor(decimalHours); // Extract hours
        const minutes = Math.round((decimalHours - hours) * 60); // Convert decimal to minutes
    
        // Format hours and minutes with leading zero
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
    
        return `${formattedHours} hrs ${formattedMinutes} m`;
    }
    


    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);
    const exportToPdf = ()=>{

        window.print();

    };


    useEffect(()=>{
        document.title = reportDetail?.name+" "+dayjs().format("DD/MM/YYYY");

        return ()=>{
            document.title = "WFC";
        }
    },[]);


    const menu = (
        <Menu >
          <Menu.Item key="1" onClick={exportToCSV}>Export CSV</Menu.Item>
          <Menu.Item key="2" onClick={exportToPdf} >Print Report</Menu.Item>
                   

        </Menu>
    );

    return (
        <div style={{padding: "15px", zoom: "1.15"}} id="pdfContent">

            <div className="reportsHeaderCss">

                <div style={{ display: "flex", justifyContent: "space-between"}}>
                   <span style={{textTransform:'capitalize', fontWeight: "bold", fontSize: "20px",}}>{reportDetail?.name}</span>
                   <div id="pdfexportBtn">

                        <Dropdown overlay={menu}>
                            <Button style={{fontSize:'12px'}}>
                                Exports <CaretDownFilled />
                            </Button>
                        </Dropdown>

                        
                        


                        <div className='close-link' style={{marginRight:'-20px'}} onClick={()=>navigate(-1)}>
                            <span >Close Report</span> <FontAwesomeIcon className='icon' icon={faTimes}/>
                        </div>

                    </div>
                </div>

                <div className="row-flex" style={{gap: "30px", marginBottom: "6px"}}>
                    <div style={{fontWeight: "bold"}}> Report ID : {reportDetail?._id}</div>
                    <div style={{fontWeight: "bold"}}> Run Date : &nbsp;
                        <span className="text-muted" style={{fontWeight: "normal"}}>
                            {dayjs().format("DD/MM/YYYY HH:mm")} by {authenticatedUserDetail?.employeeDetail[0]?.firstname +" "+(authenticatedUserDetail?.employeeDetail[0]?.lastname || "")}
                        </span>
                    </div>
                </div>

                <div className="text-muted pt20">
                    <span>Date Range Report using dates between </span>
                    <span style={{padding: "0px 2px"}}>
                        {dayjs(filters?.dates?.from, "DD/MM/YYYY").format("ddd DD MMM YYYY HH:mm")}
                    </span>
                    and
                    <span style={{padding: "0px 2px"}}>
                        {dayjs(filters?.dates?.to, "DD/MM/YYYY").format("ddd DD MMM YYYY HH:mm")}
                    </span>
                </div>

                <div style={{display: "flex", width: "100%", marginTop: "6px"}}>
                    <div style={{width: "50px"}}>Filters :</div>
                    <div style={{width: "calc(100% - 110px)", paddingLeft: "10px", textAlign:'left', fontStyle:'oblique'}}
                        dangerouslySetInnerHTML={{__html: filterContent}}
                    >  
                      
                    </div>
                </div>

            </div>

            <div className="mt32"></div>


            <table id="payBillPDF" className="payBillMainTable">
                <tbody>
                    <tr className="payBillHeaderCss">
                        <th colspan="2">
                            Employee Pay Code/Name
                        </th>
                        <th>Site Name</th>
                        <th>Pay Start</th>
                        <th>Pay End</th>
                        <th>Regular Hours</th>
                        <th>Regular Pay</th>
                        <th>Bank Holiday Hours</th>
                        <th>Bank Holiday Pay</th>
                        <th>Overtime Hours</th>
                        <th>Overtime Pay</th>
                        <th>Break Hours</th>
                        <th>Break Pay</th>
                        <th>Premium Pay</th>
                        <th>Total Hours</th>
                        <th>Total Pay</th>
                    </tr>
                    
                   {
                    getPayBillReportLoading?
                        <tr>
                            <td colSpan={16} style={{textAlign: 'center'}}><Spinner /></td>
                        </tr>
                    :
                    Object.keys(groupedData)?.map((key)=>{

                        const customerTotal = totalCustomer[key]?.sums;

                        return(
                            <>
                                {
                                    filters?.header?.groupbyCustomer &&
                                    <tr className="customerCss">
                                        <td colspan="1">Customer:</td>
                                        <td colspan="3">{key?.split("_")[1]?.replaceAll("-", " ")}</td>
                                    </tr>
                                }
                                {
                                    // Site group rendering
                                    Object.keys(groupedData[key])?.map((secKey)=>{
                                        const sitegroupTotal = totalSiteGroup[secKey]?.sums;
                                        return(
                                            <>
                                            {
                                                filters?.header?.groupbySitegroup &&
                                                <tr className="siteGroupCss">
                                                    <td colspan="1">Site Group:  </td>
                                                    <td colspan="3">{secKey?.split("_")[1]?.replaceAll("-", " ")}</td>
                                                </tr>
                                            }


                                                {/* Site rendering */}
                                                { Object.keys(groupedData[key][secKey])?.map((tkey)=>{
                                                    const siteTotal = totalSite[tkey]?.sums;
                                                    return(
                                                    <>
                                                    {
                                                        filters?.header?.groupbySite &&

                                                        <tr className="siteCss">
                                                            <td colspan="1">Site: </td>
                                                            <td colspan="3">{tkey?.split("_")[1]?.replaceAll("-", " ")}</td>
                                                        </tr>
                                                    }


                                                    {/* Employee detail */}

                                                        {
                                                            Object.keys(groupedData[key][secKey][tkey])?.map((ekey)=>{
                                                                
                                                                const employeeTotal = totalEmployee[ekey]?.sums;
                                                                

                                                                return(

                                                                    <>
                                                                        {
                                                                        filters?.header?.groupbyEmployee &&
                
                                                                            <tr className="siteCss">
                                                                                <td colspan="1">Employee: </td>
                                                                                <td colspan="3">{ekey?.split("_")[1]?.replaceAll("-", " ")}</td>
                                                                            </tr>
                                                                        }

                                                                        <tr>
                                                                            <td colspan="16">
                                                                                <table className="payBillSitePayTable">
                                                                                    <tbody>
                                                                                    {
                                                                                        groupedData[key][secKey][tkey][ekey]?.map((job)=>{
                                                                                            // payHourTypeId == ""

                                                                                            return(
                                                                                        
                                                                                                <tr className="siteBillCss">
                                                                                                    {/* employee name */}
                                                                                                    <td colspan="2">{job?.employeeDetail?.firstname+" "+(job?.employeeDetail?.lastname || "")}</td>
                                                                                                    
                                                                                                    <td>{job?.siteDetail?.sitename}</td>
                                                                                                    <td>                                                                
                                                                                                        {dayjs(job?.paybillDetail?.paybillDetail?.payDetail?.payStartDateTime)?.format("DD/MM/YYYY HH:mm")} 
                                                                                                    </td>

                                                                                                    <td>
                                                                                                        
                                                                                                        {dayjs(job?.paybillDetail?.paybillDetail?.payDetail?.payEndDateTime)?.format("DD/MM/YYYY HH:mm")} 
                                                                                                    
                                                                                                    </td>
                                                                                                    
                                                                                                    {/* total pay hour */}
                                                                                                    <td> 
                                                                                                        {
                                                                                                            (!job?.isPayFirstDateInBankHoilday && !job?.isPaySecondDateInBankHoilday) ?

                                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.payFirstDateHour)+parseFloat(job?.paybillDetail?.paySecondDateHour))
                                                                                                            :!job?.isPayFirstDateInBankHoilday?
                                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.payFirstDateHour))

                                                                                                            :!job?.isPaySecondDateInBankHoilday?
                                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.paySecondDateHour))
                                                                                                            
                                                                                                            :convertToHrsMin(0)

                                                                                                        }

                                                                                                    </td>
                                                                                                    
                                                                                                    {/* regular pay amount */}
                                                                                                    <td>
                                                                                                        {
                                                                                                            (!job?.isPayFirstDateInBankHoilday && !job?.isPaySecondDateInBankHoilday)?
                                                                                                                
                                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                                "£ "+ ( parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)  + parseFloat(job?.paybillDetail?.unpaidBreakAmount) ).toFixed(2)
                                                                                                                :
                                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)).toFixed(2)


                                                                                                            : !job?.isPayFirstDateInBankHoilday?
                                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.unpaidBreakAmount)).toFixed(2)
                                                                                                                
                                                                                                                :
                                                                                                                "£ "+ parseFloat(job?.paybillDetail?.firstDatePay).toFixed(2)

                                                                                                            : !job?.isPaySecondDateInBankHoilday?
                                                                                                            "£ "+ parseFloat(job?.paybillDetail?.secondDatePay).toFixed(2)

                                                                                                            : "£ 0.00"

                                                                                                        }
                                                                                                    </td>
                                                                                                    
                                                                                                    {/* total pay hour in holidays */}
                                                                                                    <td>
                                                                                                        { 
                                                                                                            job?.isPayFirstDateInBankHoilday && job?.isPaySecondDateInBankHoilday?
                                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.payFirstDateHour)+parseFloat(job?.paybillDetail?.paySecondDateHour))
                                                                                                                :
                                                                                                            job?.isPayFirstDateInBankHoilday?
                                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.payFirstDateHour))
                                                                                                                :
                                                                                                            job?.isPaySecondDateInBankHoilday?
                                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.paySecondDateHour))
                                                                                                                :
                                                                                                                convertToHrsMin(0)
                                                                                                            

                                                                                                        }
                                                                                            
                                                                                                    </td>
                                                                                                    
                                                                                                    {/* total pay in holiday */}
                                                                                                    <td>
                                                                                                        {

                                                                                                            (job?.isPayFirstDateInBankHoilday && job?.isPaySecondDateInBankHoilday)?
                                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                                "£ "+ ( parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)  + parseFloat(job?.paybillDetail?.unpaidBreakAmount) ).toFixed(2)
                                                                                                                :
                                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)).toFixed(2)

                                                                                                            : job?.isPayFirstDateInBankHoilday?
                                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.unpaidBreakAmount)).toFixed(2)
                                                                                                                :
                                                                                                                "£ "+ parseFloat(job?.paybillDetail?.firstDatePay).toFixed(2)


                                                                                                            : job?.isPaySecondDateInBankHoilday?
                                                                                                            "£ "+ parseFloat(job?.paybillDetail?.secondDatePay).toFixed(2)

                                                                                                            : "£ 0.00"

                                                                                                        }
                                                                                                        
                                                                                                    </td>
                                                                                                    
                                                                                                    {/* total pay overtime hour */}
                                                                                                    <td>{
                                                                                        

                                                                                                            job?.paybillDetail?.payOverTimeHour && job?.paybillDetail?.payOverTimeHour>0?
                                                                                                            convertToHrsMin(job?.paybillDetail?.payOverTimeHour)
                                                                                                            :
                                                                                                            convertToHrsMin(0)

                                                                                                    }</td>
                                                                                                    
                                                                                                    {/* total pay overtime amout */}

                                                                                                    <td>
                                                                                                    {
                                                                                
                                                                                                            job?.paybillDetail?.payOverTimeHour && job?.paybillDetail?.payOverTimeHour>0?
                                                                                                                job?.paybillDetail?.payFirstDateHour==0?
                                                                                                                "£ "+(parseFloat(job?.paybillDetail?.overTimePay) + parseFloat(job?.paybillDetail?.unpaidBreakAmount)).toFixed(2)
                                                                                                                :
                                                                                                                "£ "+parseFloat(job?.paybillDetail?.overTimePay).toFixed(2)

                                                                                                            : "£ 0.00"

                                                                                                    }
                                                                                                    </td>

                                                                                                    {/* unpaid break hour*/}

                                                                                                    <td>
                                                                                                        {
                                                                                                            !Number.isNaN(parseFloat(job?.unPaidBreakDuration))?
                                                                                                            <>
                                                                                                                -  { convertToHrsMin(parseFloat(job?.unPaidBreakDuration)/60) }

                                                                                                            
                                                                                                            </>
                                                                                                            :
                                                                                                            convertToHrsMin(0)
                                                                                                        }
                                                                                                    </td>

                                                                                                    {/* unpaid break pay*/}

                                                                                                    <td>
                                                                                                        {
                                                                                                            !Number.isNaN(parseFloat(job?.unPaidBreakDuration))?
                                                                                                            <>
                                                                                                               
                                                                                                                - £ {parseFloat(job?.paybillDetail?.unpaidBreakAmount).toFixed(2)}

                                                                                                            
                                                                                                            </>
                                                                                                            :
                                                                                                            "0.00"
                                                                                                        }
                                                                                                    </td>

                                                                                                    {/* total pay premium hour */}
                                                                                                    <td>
                                                                                                        £ {(job?.paybillDetail?.payBillPremium?.length>0 ? (job?.paybillDetail?.payBillPremium[0][0]?.payPremium?.totalPremiumPay)?.toFixed(2) : 0.00) || "0.00"}
                                                                                                    
                                                                                                    </td>

                                                                                                    {/* total hours */}

                                                                                                    <td>
                                                                                                        {
                                                                                                            !Number.isNaN(parseFloat(job?.unPaidBreakDuration))?
                                                                                                            convertToHrsMin((parseFloat(job?.paybillDetail?.payFirstDateHour)+parseFloat(job?.paybillDetail?.paySecondDateHour)+parseFloat(job?.paybillDetail?.payOverTimeHour))-(parseFloat(job?.unPaidBreakDuration)/60))
                                                                                                            
                                                                                                            :
                                                                                                            convertToHrsMin(parseFloat(job?.paybillDetail?.payFirstDateHour)+parseFloat(job?.paybillDetail?.paySecondDateHour)+parseFloat(job?.paybillDetail?.payOverTimeHour))
                                                                                                            
                                                                                                        }
                                                                                                    </td>


                                                                                                    {/* total pay and bill hour */}
                                                                                                    
                                                                                                    <td>£ {parseFloat(job?.paybillDetail?.totalPay)?.toFixed(2)} </td>
                                                                                                    
                                                                                                </tr>

                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>

                                                                        {
                                                        
                                                                            filters?.header?.groupbyEmployee &&
                                                                            <>
                                                                                <tr style={{height: "0px"}}>
                                                                                    <td colspan="1"></td><td colspan="15" style={{borderBottom: "2px solid black"}}></td>
                                                                                </tr>
                                                                                

                                                                                {/* Employee total */}
                                                                                <tr>

                                                                                    <td colspan="16">
                                                                                        <table className="payBillSiteCardTable">
                                                                                            <thead>
                                                                                                <tr className="titleCss">
                                                                                                    <th></th>
                                                                                                    <th>Employee Totals:</th>
                                                                                                    <th colspan="3"></th>

                                                                                                    <th> Regular Hours</th>
                                                                                                    <th> Regular Pay </th>
                                                                                                    <th> Bank Holiday Hours</th>
                                                                                                    <th> Bank Holiday Pay </th>
                                                                                                    <th> Overtime Hours</th>
                                                                                                    <th> Overtime Pay </th>
                                                                                                    <th> Break Hours</th>
                                                                                                    <th> Break Pay </th>
                                                                                                    <th> Premium Pay </th>
                                                                                                    <th> Total Hours </th>
                                                                                                    <th> Total Pay </th>
                                                                                                </tr>
                                                                                            </thead>

                                                                                            <tbody>
                                                                                                <tr className="row1">
                                                                                                    <td colspan="5"></td>

                                                                                                    <td>
                                                                                                        {convertToHrsMin(parseFloat(employeeTotal?.regularPayHour))} 
                                                                                                    </td>

                                                                                                    <td>
                                                                                                        £ {parseFloat(employeeTotal?.regularPay)?.toFixed(2)}
                                                                                                    </td>

                                                                                                    <td>
                                                                                                        {convertToHrsMin(parseFloat(employeeTotal?.holidayPayHour))} 
                                                                                                    </td>

                                                                                                    <td>
                                                                                                        £ {parseFloat(employeeTotal?.holidayPay)?.toFixed(2)}
                                                                                                    </td>

                                                                                                    <td>
                                                                                                        {convertToHrsMin(parseFloat(employeeTotal?.overtimePayHour))} 
                                                                                                    </td>

                                                                                                    <td>
                                                                                                        £ {(parseFloat(employeeTotal?.overtimePay).toFixed(2))} 
                                                                                                    </td>

                                                                                                    <td> 
                                                                                                        {employeeTotal?.unPaidBreak>0? "- "+ convertToHrsMin(employeeTotal?.unPaidBreak): convertToHrsMin(0)}
                                                                                                    </td>

                                                                                                    <td> 
                                                                                                        {employeeTotal?.unPaidBreakAmount>0? "- £ "+parseFloat(employeeTotal?.unPaidBreakAmount).toFixed(2): "£ 0.00"}
                                                                                                    </td>


                                                                                                    <td>£ {parseFloat(employeeTotal?.payPremium)?.toFixed(2)} </td>

                                                                                                    <td>{convertToHrsMin(employeeTotal?.totalHours)}</td>

                                                                                                    <td>£ {parseFloat(employeeTotal?.totalPay)?.toFixed(2)} </td>

                                                                                                    
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </>

                                                                        }

                                                                    </>
                                                                )

                                                            })
                                                        }

{/* ===========================================  =========================================== */}
                                                        
                                                        {/* group by site */}

{/* ===========================================  =========================================== */}


                                                        {
                                                        
                                                        filters?.header?.groupbySite &&
                                                        <>
                                                            <tr style={{height: "0px"}}>
                                                                <td colspan="1"></td><td colspan="15" style={{borderBottom: "2px solid black"}}></td>
                                                            </tr>
                                                            

                                                            {/* site total */}
                                                            <tr>

                                                                <td colspan="16">
                                                                    <table className="payBillSiteCardTable">
                                                                        <thead>
                                                                            <tr className="titleCss">
                                                                                <th></th>
                                                                                <th>Site Totals:</th>
                                                                                <th colspan="3"></th>
                                                                                <th>Regular Hours</th>
                                                                                <th>Regular Pay </th>
                                                                                <th>Bank Holiday Hours</th>
                                                                                <th>Bank Holiday Pay </th>
                                                                                <th> Overtime Hours</th>
                                                                                <th> Overtime Pay </th>
                                                 
                                                                                <th> Break Hours</th>
                                                                                <th> Break Pay </th>
                                                                                <th> Premium Pay </th>
                                                                                <th> Total Hours </th>
                                                                                <th> Total Pay </th>

                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr className="row1">
                                                                                <td colspan="5"></td>
                                                                                <td>
                                                                                    {convertToHrsMin(parseFloat(siteTotal?.regularPayHour) )} 
                                                                                </td>

                                                                                <td>
                                                                                    £ {parseFloat(siteTotal?.regularPay)?.toFixed(2)}
                                                                                </td>

                                                                                <td>
                                                                                    {convertToHrsMin(parseFloat(siteTotal?.holidayPayHour))} 
                                                                                </td>

                                                                                <td>
                                                                                    £ {parseFloat(siteTotal?.holidayPay)?.toFixed(2)}
                                                                                </td>

                                                                                <td>
                                                                                    {convertToHrsMin(parseFloat(siteTotal?.overtimePayHour))}
                                                                                </td>

                                                                                <td>£ {parseFloat(siteTotal?.overtimePay)?.toFixed(2)} </td>

                                                                                <td> 
                                                                                    {parseFloat(siteTotal?.unPaidBreak)>0? "- "+ convertToHrsMin(siteTotal?.unPaidBreak): convertToHrsMin(0)}
                                                                                </td>
                                                                                
                                                                                <td> 
                                                                                    {parseFloat(siteTotal?.unPaidBreakAmount)>0? "- £ "+parseFloat(siteTotal?.unPaidBreakAmount).toFixed(2): "£ 0.00"}
                                                                                </td>

                                                                                <td>£ {parseFloat(siteTotal?.payPremium)?.toFixed(2)} </td>

                                                                                <td>{convertToHrsMin(siteTotal?.totalHours)}</td>

                                                                                <td>£ {parseFloat(siteTotal?.totalPay)?.toFixed(2)} </td>

                                                                                
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </>

                                                        }

                                                    </>

                                                    )
                                                })}


{/* ===========================================  =========================================== */}
                                                        
                                                        {/* group by Site Group */}

{/* ===========================================  =========================================== */}


                                                {
                                                    filters?.header?.groupbySitegroup &&
                                                    <>
                                                        <tr style={{height: "0px"}}>
                                                            <td colspan="1"></td><td colspan="15" style={{borderBottom: "2px solid black"}}></td>
                                                        </tr>
                                                        

                                                        {/* sitegroup total */}
                                                        <tr>
                                                            <td colspan="16">
                                                                <table className="payBillSiteCardTable">
                                                                    <thead>
                                                                        <tr className="titleCss">
                                                                            <th></th>
                                                                            <th>Site Group Totals:</th>
                                                                            <th colspan="3"></th>
                                                                            <th>Regular Hours</th>
                                                                            <th>Regular Pay</th>
                                                                            <th>Bank Holiday Hours</th>
                                                                            <th>Bank Holiday Pay</th>
                                                                            <th> Overtime Hours</th>
                                                                            <th> Overtime Pay</th>
                                                                            <th> Break Hours</th>
                                                                            <th> Break Pay </th>
                                                                            <th>Premium Pay</th>
                                                                            <th> Total Hours </th>
                                                                            <th>Total Pay</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr className="row1">

                                                                            <td colspan="5"></td>
                                                                            <td>
                                                                                {convertToHrsMin(parseFloat(sitegroupTotal?.regularPayHour) )} 
                                                                            </td>

                                                                            <td>
                                                                                £ {parseFloat(sitegroupTotal?.regularPay)?.toFixed(2)}
                                                                            </td>

                                                                            <td>
                                                                                {convertToHrsMin(parseFloat(sitegroupTotal?.holidayPayHour))}
                                                                            </td>

                                                                            <td>
                                                                                £ {parseFloat(sitegroupTotal?.holidayPay)?.toFixed(2)}
                                                                            </td>

                                                                            <td>
                                                                                {convertToHrsMin(parseFloat(sitegroupTotal?.overtimePayHour))}
                                                                            </td>

                                                                            <td>£ {parseFloat(sitegroupTotal?.overtimePay)?.toFixed(2)} </td>

                                                                            <td> 
                                                                                {parseFloat(sitegroupTotal?.unPaidBreak)>0? "- "+ convertToHrsMin(sitegroupTotal?.unPaidBreak): convertToHrsMin(0)}
                                                                                
                                                                            </td>

                                                                            <td> 
                                                                                {parseFloat(sitegroupTotal?.unPaidBreakAmount)>0? "- £ "+parseFloat(sitegroupTotal?.unPaidBreakAmount).toFixed(2): "£ 0.00"} 

                                                                            </td>

                                                                            <td>£ {parseFloat(sitegroupTotal?.payPremium)?.toFixed(2)} </td>

                                                                            <td>{convertToHrsMin(sitegroupTotal?.totalHours)}</td>
                                                                            <td>£ {parseFloat(sitegroupTotal?.totalPay)?.toFixed(2)} </td>

                                                                            
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </>
                                                }


                                            </>

                                        )
                                    })
                                }


                                {
                                    filters?.header?.groupbyCustomer &&
                                    <>
                                        {/* customer total border */}
                                        <tr style={{height: "0px"}}>
                                            <td colspan="1"></td><td colspan="15" style={{borderBottom: "2px solid black"}}></td>
                                        </tr>
                                        

                                        {/* Customer total */}
                                        <tr>
                                            <td colspan="16">
                                                <table className="payBillSiteCardTable">
                                                    <thead>
                                                        <tr className="titleCss">
                                                            <th></th>
                                                            <th>Customer Totals:</th>
                                                            <th colspan="3"></th>
                                                            <th>Regular Hours</th>
                                                            <th>Regular Pay</th>
                                                            <th>Bank Holiday Hours</th>
                                                            <th>Bank Holiday Pay</th>
                                                            <th> Overtime Hours</th>
                                                            <th> Overtime Pay</th>
                                                            <th> Break Hours</th>
                                                            <th> Break Pay </th>
                                                            <th>Premium Pay</th>
                                                            <th> Total Hours </th>
                                                            <th>Total Pay</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr className="row1">

                                                            <td colspan="5"></td>
                                                            <td>
                                                                {convertToHrsMin(parseFloat(customerTotal?.regularPayHour) )}
                                                            </td>

                                                            <td>
                                                                £ {parseFloat(customerTotal?.regularPay)?.toFixed(2)}
                                                            </td>

                                                            <td>
                                                                {convertToHrsMin(parseFloat(customerTotal?.holidayPayHour))}
                                                            </td>

                                                            <td>£ {parseFloat(customerTotal?.holidayPay)?.toFixed(2)}</td>

                                                            <td>{convertToHrsMin(parseFloat(customerTotal?.overtimePayHour))}</td>

                                                            <td>£ {parseFloat(customerTotal?.overtimePay)?.toFixed(2)} </td>

                                                            <td> 
                                                                {parseFloat(customerTotal?.unPaidBreak)>0? "- "+ convertToHrsMin(customerTotal?.unPaidBreak): convertToHrsMin(0)}
                                                                
                                                            </td>

                                                            <td> 
                                                                
                                                                {parseFloat(customerTotal?.unPaidBreakAmount)>0? "- £ "+parseFloat(customerTotal?.unPaidBreakAmount).toFixed(2): "£ 0.00"}
                                                            </td>

                                                            <td>£ {parseFloat(customerTotal?.payPremium)?.toFixed(2)}  </td>

                                                            <td>{convertToHrsMin(customerTotal?.totalHours)}</td>   
                                                            <td>£ {parseFloat(customerTotal?.totalPay)?.toFixed(2)}  </td>
                                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </>
                                }


                            </>
                        )
                    })
                   }


                
                </tbody>
            </table>

        </div>
    );

};