
import { Radio } from "antd";
import { objectType } from "@src/util/types/object.types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAsia, faHandHoldingDroplet, faHandshakeSimple, faLayerGroup, faStreetView, faUsers } from '@fortawesome/free-solid-svg-icons';


export const PostPrivacy = (
    {activeVisibility, setActiveVisibility, setPrivacyVisibilityModal, privacyVisibilityModal, setPrivacyModal, privacyModal}
)=>{


    return(
        <div className="post-audience">

            {/* privacy header */}

            <div>
                <div className="post-privacy-title">
                    Who can see your post?
                </div>
                <div className="text">
                    Your post will show up in Feeds, on based of your selected audience.
                </div>
            </div>

            {/* body */}
            <div className="privacy-mode-block-wrapper">

                {/* Branches */}
                <div className="privacy-mode-block" onClick={()=>{setActiveVisibility(objectType?.Branch); setPrivacyVisibilityModal(!privacyVisibilityModal); setPrivacyModal(false);}}>

                    {/* privacy icon */}
                    <div className="privacy-mode-icon">
                        <FontAwesomeIcon icon={faGlobeAsia}/>
                    </div>

                    {/* privacy description and selection*/}
                    <div className="privacy-description">

                        {/* privacy description */}
                        <div>
                            <div>Branches</div>
                            <div className="text" style={{marginTop:'1px', marginBottom:0}}>
                                Post will be in selected branches and associated employees feed.
                            </div>
                        </div>

                        {/* privacy selection */}
                        <div className="privacy-selection">
                            <Radio.Group className="advanceFilterRadio" 
                                value={activeVisibility}
                                onChange={(e)=>setActiveVisibility(objectType.Branch)} 
                            > 

                                <Radio value={objectType.Branch} />

                            </Radio.Group>
                        </div>

                    </div>


                </div>

                {/* site groups */}
                <div className="privacy-mode-block" onClick={()=>{setActiveVisibility(objectType?.SiteGroup); setPrivacyVisibilityModal(!privacyVisibilityModal); setPrivacyModal(false); }}>

                    {/* privacy icon */}
                    <div className="privacy-mode-icon">
                        <FontAwesomeIcon icon={faLayerGroup}/>
                    </div>

                    {/* privacy description and selection*/}
                    <div className="privacy-description">

                        {/* privacy description */}
                        <div>
                            <div>Site Groups</div>
                            <div className="text" style={{marginTop:'1px', marginBottom:0}}>
                                Post will be in selected site group and associated employees feed.
                            </div>
                        </div>

                        {/* privacy selection */}
                        <div className="privacy-selection">
                            <Radio.Group className="advanceFilterRadio" 
                                value={activeVisibility}
                                onChange={(e)=>setActiveVisibility(objectType.SiteGroup)}
                            > 

                                <Radio value={objectType?.SiteGroup}  />

                            </Radio.Group>
                        </div>

                    </div>


                </div>

                {/* sites */}
                <div className="privacy-mode-block" onClick={()=>{setActiveVisibility(objectType?.Site);  setPrivacyVisibilityModal(!privacyVisibilityModal);  setPrivacyModal(false);}}>

                    {/* privacy icon */}
                    <div className="privacy-mode-icon">
                        <FontAwesomeIcon icon={faStreetView}/>
                    </div>

                    {/* privacy description and selection*/}
                    <div className="privacy-description">

                        {/* privacy description */}
                        <div>
                            <div>Sites</div>
                            <div className="text" style={{marginTop:'1px', marginBottom:0}}>
                                Post will be in selected sites and associated employees feed.
                            </div>
                        </div>

                        {/* privacy selection */}
                        <div className="privacy-selection">
                            <Radio.Group className="advanceFilterRadio"
                                value={activeVisibility}
                                onChange={(e)=>setActiveVisibility(objectType.Site)}
                            > 

                                <Radio value={objectType?.Site}  />

                            </Radio.Group>
                        </div>

                    </div>


                </div>

                {/* customer */}
                <div className="privacy-mode-block" onClick={()=>{setActiveVisibility(objectType?.Customer);  setPrivacyVisibilityModal(!privacyVisibilityModal);  setPrivacyModal(false);}}>

                    {/* privacy icon */}
                    <div className="privacy-mode-icon">
                        <FontAwesomeIcon icon={faHandshakeSimple}/>
                    </div>

                    {/* privacy description and selection*/}
                    <div className="privacy-description">

                        {/* privacy description */}
                        <div>
                            <div>Customers</div>
                            <div className="text" style={{marginTop:'1px', marginBottom:0}}>
                                Post will be in selected customers and associated employees feed.
                            </div>
                        </div>

                        {/* privacy selection */}
                        <div className="privacy-selection">
                            <Radio.Group className="advanceFilterRadio"
                                value={activeVisibility}
                                onChange={(e)=>setActiveVisibility(objectType.Customer)}
                            > 

                                <Radio value={objectType?.Customer} />
    

                            </Radio.Group>
                        </div>

                    </div>


                </div>

                {/* supplier */}
                <div className="privacy-mode-block" onClick={()=>{setActiveVisibility(objectType?.Agency);  setPrivacyVisibilityModal(!privacyVisibilityModal);  setPrivacyModal(false);}}>

                    {/* privacy icon */}
                    <div className="privacy-mode-icon">
                        <FontAwesomeIcon icon={faHandHoldingDroplet}/>
                    </div>

                    {/* privacy description and selection*/}
                    <div className="privacy-description">

                        {/* privacy description */}
                        <div>
                            <div>Suppliers</div>
                            <div className="text" style={{marginTop:'1px', marginBottom:0}}>
                                Post will be in selected suppliers and associated employees feed.
                            </div>
                        </div>

                        {/* privacy selection */}
                        <div className="privacy-selection">
                            <Radio.Group className="advanceFilterRadio"
                                value={activeVisibility}
                                onChange={(e)=>setActiveVisibility(objectType.Agency)}
                            > 

                                <Radio value={objectType?.Agency}/>

                            </Radio.Group>
                        </div>

                    </div>


                </div>

                {/* System users */}
                <div className="privacy-mode-block" onClick={()=>{setActiveVisibility(objectType?.Employee);  setPrivacyVisibilityModal(!privacyVisibilityModal);  setPrivacyModal(false);}}>

                    {/* privacy icon */}
                    <div className="privacy-mode-icon">
                        <FontAwesomeIcon icon={faUsers}/>
                    </div>

                    {/* privacy description and selection*/}
                    <div className="privacy-description">

                        {/* privacy description */}
                        <div>
                            <div>Employees</div>
                            <div className="text" style={{marginTop:'1px', marginBottom:0}}>
                                Post will be in selected sites and associated employees feed.
                            </div>
                        </div>

                        {/* privacy selection */}
                        <div className="privacy-selection">
                            <Radio.Group className="advanceFilterRadio"
                                value={activeVisibility}
                                onChange={(e)=>setActiveVisibility(objectType.Employee)}
                            > 

                                <Radio value={objectType?.Employee} />

                            </Radio.Group>
                        </div>

                    </div>


                </div>


            </div>

        </div>
    );
}