import dayjs from "dayjs";
import { Table } from "antd";
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import { SiteDutyTaskModal } from "@src/pages/timeline/siteDutyTask.modal";
import { getScheduleCheckCallQuery } from "@src/util/mutation/scheduleChecks.mutation";



export const EditDutyTaskTab = ({scheduleId, siteId})=>{

    const {data:scheduleCheckCall, loading:scheduleCheckCallLoading, refetch} = useQuery(getScheduleCheckCallQuery,{
        variables:{
            siteId,
            scheduleId
        },
        fetchPolicy:'network-only',
        skip: !siteId || !scheduleId
    });
    const [dataSource, setDataSource] = useState([]);

    const columns = [
        {id:1, title:'Title', dataIndex:'callType'},
        {id:1, title:'Label', dataIndex:'callLabel'},
        {id:3, key:3,  title: 'Created At', dataIndex: 'createdAt'},
        {id:4, key:4,  title: 'Expected Time', dataIndex: 'expectedTime'},
        {id:5, key:5,  title: 'Actual Time', dataIndex: 'actualTime'},
        {id:6, key:6,  title: 'Status', dataIndex: 'status'},
        // {id:7, key:7,  title: 'Late', dataIndex: 'late'},
    ];

    
    useEffect(()=>{
        if(scheduleCheckCall?.getScheduleCheckCalls?.response?.length){

            setDataSource(scheduleCheckCall?.getScheduleCheckCalls?.response?.filter((call)=>call?.callType=="Task")?.map((checkCall)=>{
                return({
                    callType: 
                    <span style={{cursor:'pointer'}} onClick={()=>setSiteDutyTaskModal({modal: true, siteTaskId: checkCall?.taskId, call: checkCall})}>
                        {checkCall?.callType}
                    </span>,
                    callLabel: <span style={{textTransform:'capitalize'}}>{checkCall?.label}</span>,
                    createdAt: checkCall?.createdAt? dayjs(checkCall?.createdAt).format("DD/MM/YYYY HH:mm") : "--",
                    expectedTime: checkCall?.requiredAt? dayjs(checkCall?.requiredAt).format("DD/MM/YYYY HH:mm") : "--",
                    actualTime: checkCall?.actualDate? dayjs(checkCall?.actualDate).format("DD/MM/YYYY HH:mm") : "--",
                    status: checkCall?.pending? "Pending" : "Completed",
                    late: checkCall?.late? "Yes" : "No",
                })
            }));
        }
    },[scheduleCheckCall?.getScheduleCheckCalls?.response]);

    const [siteDutyTaskModal, setSiteDutyTaskModal] = useState(false);

    return (
        <div className="box-shadow border-radius">

            <div style={{padding:'16px'}}>
                    <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between'}}>
                        Check Calls
                        {/* <button className="drawer-outlined-btn" style={{padding:'5px 10px'}}> <FontAwesomeIcon icon={faFilePdf}/> Export Pdf</button> */}
                    </div>
            </div>

            <div>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    loading={scheduleCheckCallLoading}
                    locale={{emptyText: 'No task made for this duty'}}
                />
            </div>

            {
                siteDutyTaskModal?.modal &&
                <SiteDutyTaskModal
                    modalDetail={siteDutyTaskModal}
                    close={()=>setSiteDutyTaskModal(false)}
                    refetch={refetch}
                />
            }


        </div>
    );
}