// this function will be call when the fields are not in editable mode
// this will return the single static value when we pass the id of lookup fields
// it can handel only Agency, Branch, Customer, SiteGroup and Site as well

import { getCustomerQuery } from "../query/customer.query";
import { getSiteGroups } from "../query/siteGroup.query";
import { GET_BRANCHES } from "../query/branch.query";
import { getSitesQuery } from "../query/site.query";
import { getAgencies } from "../query/agency.query";
import { GetEmployeeRecord } from "../query/employee.query";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import { objectType } from "@src/util/types/object.types";
import Spinner from "@src/components/spinner";

export const GetLookupStaticValue = ({type, id})=>{
// BranchList    
  const { data: branchData, } = useQuery(GET_BRANCHES ,{
    fetchPolicy: 'cache-and-network',
    variables: {
        input: {
            filters: null
        }
    }
  });


  // customer list if needed to load
  const {data: customerData, loading: customerLoading} = useQuery(getCustomerQuery,{
    variables: {
        input: {
            filters: null
        }
    },
    fetchPolicy:'network-only'
  });
  // site list if needed to load

  // siteGroup list if needed to load
  const {data: siteGroupData, loading: siteGroupLoading} = useQuery(getSiteGroups,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
  });

  // Agency list if needed to load
  
    const {data: agencyData, loading: agencyLoading, refetch} = useQuery(getAgencies,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });



//   site list

    const {data: siteData, loading: siteLoading} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    // employee lookup

    const {data: employeeData, loading: employeeDataLoading} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    const [extractedStaticValue, setExtractedStaticValue] = useState();

    useEffect(()=>{
        if(type && id && branchData?.branches && employeeData?.getEmployee?.response && 
            siteData?.sites && siteGroupData?.sitegroups &&  customerData?.customers &&  agencyData?.agencies){
                if(type===objectType?.Branch?.toLowerCase()){
                    const branch = id?.map((branchId)=> branchData?.branches?.find((branch)=>branch._id==branchId)?.branchname) 
                  
                    setExtractedStaticValue(branch?.join(", "))
                }
                else if(type===objectType.Employee?.toLowerCase()){
                    const employee = employeeData?.getEmployee?.response?.find((employee)=>employee?._id==id);
                    setExtractedStaticValue(employee?.firstname+" "+employee?.lastname);
                }
                else if(type===objectType.Site?.toLowerCase()){
                    const site = siteData?.sites?.find((site)=>site?._id==id);
                    setExtractedStaticValue(site?.sitename);
                }
                else if(type===objectType.SiteGroup?.toLowerCase()){
                    const siteGroup = siteGroupData?.sitegroups?.find((siteGroup)=>siteGroup?._id==id);
                    setExtractedStaticValue(siteGroup?.sitegroupname);
                }
                else if(type===objectType?.Customer?.toLowerCase()){
                    const customer = customerData?.customers?.find((customer)=>customer?._id==id);
                    setExtractedStaticValue(customer?.customername)
                }
                else if(type===objectType?.Agency?.toLowerCase()){
                    const agency = agencyData?.agencies?.find((agency)=>agency?._id==id);
                    setExtractedStaticValue(agency?.suppliername)
                }
            }else{
                setExtractedStaticValue(null)
            }
    },[type,id, branchData?.branches,employeeData?.getEmployee,siteData?.sites,siteGroupData?.sitegroups, customerData?.customers, agencyData?.agencies]);

    
    if(type && id && branchData?.branches && employeeData?.getEmployee && 
        siteData?.sites && siteGroupData?.sitegroups &&  customerData?.customers &&  agencyData?.agencies && extractedStaticValue){
            return extractedStaticValue;
    }else{
        return <Spinner/>
    }

}