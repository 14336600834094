import { gql } from "@apollo/client";

export const getSiteTaskQuery = gql`
query($siteId: String!){
  getSiteTask(siteId: $siteId) {
    message
    response
  }
}
`;

export const getSiteTaskByIdQuery = gql`
query($siteTaskId: String!){
  getSiteTaskById(siteTaskId: $siteTaskId) {
    message
    response
  }
}
`;