import { useState } from "react";
import { Table, Tabs } from "antd"
import TabPane from "antd/es/tabs/TabPane"
import { NewPayBillColumnDrawer } from "./newPayBillColumn.drawer";
import { useMutation, useQuery } from "@apollo/client";
import { getPayandBillColumnQuery } from "@src/util/query/payandbillColumn.query";
import { deletePayandBillColumnMutation } from "@src/util/mutation/payandbillColumn.mutation";
import Spinner from "@src/components/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

export const PayBillColumn = ({themeData})=>{


    const [editColumn, setEditColumn] = useState({});
    const [deletePayandBillColumn, {loading: deletePayandBillColumnLoading}] = useMutation(deletePayandBillColumnMutation);
    
    const lockedColumn = [
       "65ee4f5dee2b8708243143eb", // 'Standard Pay'
        "65ff5f3ecdef94de7cabc3df", // 'Standard Bill'
        "65ee50966f6d12d9e20b21fe", // Bank Holiday Pay
        "65ff5f56cdef94de7cabc3e7" // Bank Holiday Bill
    ];

    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);   

    const columns = [
        {
            title: 'Name',
            dataIndex: 'columnName',
            key:'name',
            width:'33%',
            
      ellipsis:true,
      render: (_, record) => {
        const showActions = hoveredRow === record.key;
        return (          
            <div style={{display:'flex', alignItems:'center', gap:'20px', justifyContent:'space-between'}}>
                
                {record?.columnName}

                { 
                 lockedColumn.includes(record?.key) && authenticatedUserDetail?._id!==process.env.REACT_APP_systemUserId ?


                        <div style={{width:'auto', display:'flex', justifyContent:'flex-end' ,alignItems:'center', columnGap:'10px'}}>
                            <FontAwesomeIcon icon={faLock} style={{color:'lightgrey'}}/>
                        </div>
                    
                    :
                        
                        <div style={showActions? {width:'auto', display:'flex', justifyContent:'flex-start' ,alignItems:'center', columnGap:'10px', visibility:'visible'}: {visibility:'hidden'}}>
                            <button className={"sm-btn"} type="link" onClick={() => { setEditColumn(record); setNewPayLevelModal(true);}}>
                                Edit
                            </button>

                            <button className={"sm-btn"} type="link" onClick={async() => {  await deletePayandBillColumn({variables:{deletePayandBillCoulmnId:record?._id}}); await refetch(); }}>
                                Delete
                            </button>
                            

                        </div>
                
                    
                }

            </div>
        );
      },
        },
        {
            title: 'Category',
            dataIndex: 'columnCategory',
            key: 'columncategory'
        },
        {
            title: 'Column ORDER',
            dataIndex: 'columnOrder',
            key: 'columnOrder'
        }
    ];

    const [newPayLevelModal, setNewPayLevelModal] = useState(false);

    const {data, loading:getPayandBillColumnLoading, refetch} = useQuery(getPayandBillColumnQuery);

    const [hoveredRow, setHoveredRow] = useState("");

    const rowClassName = (record) => {
        return record.key === hoveredRow ? 'hovered-row' : '';
    };
      
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
    };
    


    return(
        <div className='setting-body'>
            <div className='setting-body-inner'>
                <div className="setting-body-inner">
                    <div className="setting-body-title">
                        <div className='setting-body-inner-title'>
                            Pay and Bill Column
                        </div>
                    </div>

                    <div className="text">
                        Pay and Bill columns will be settle in shift type and pay table.
                    </div>


                    {/* body */}

                    <div className="propertyTab"></div>
                    <Tabs defaultActiveKey="1" className="grid-tabs">
                        <TabPane tab={`Pay & Bill Columns`} key="1" >
                            <div>
                                {/* search header */}
                                <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                        <button className="drawer-filled-btn" onClick={()=>{ setNewPayLevelModal(!newPayLevelModal); setEditColumn({}); }}>Add Pay/Bill Column</button>
                                </div>

                                {/* subscription main body cards */}
                                <div className="propertyTab"></div>
                                {
                                    deletePayandBillColumnLoading?
                                    <Spinner />

                                    :
                                    
                                    <Table
                                        columns={columns}
                                        dataSource={data?.getPayandBillColumn?.response?.map((data)=>({key:data?._id, ...data}))}
                                        className="curvedTable"
                                        onRow={(record) => ({
                                            onMouseEnter: () => handleRowMouseEnter(record),
                                            onMouseLeave: () => handleRowMouseLeave(),
                                        })}
                                        rowClassName={rowClassName}
                                    />
                                }

                                {/* add new paylevel drawer */}
                                <NewPayBillColumnDrawer
                                    visible={newPayLevelModal}
                                    close={()=>setNewPayLevelModal(!newPayLevelModal)}
                                    refetch={refetch}
                                    editColumn={editColumn}
                                    setEditColumn={setEditColumn}
                                    
                                />

                            </div>
                        </TabPane>
                    </Tabs>


                </div> 

                             
            </div>
        </div>
        
    )
}