import { gql } from "@apollo/client";

export const fileUploadSignatureURLMutation = gql`
mutation($input: DocumentInput!){
  fileUploadSignatureUrl(input: $input) {
    response
    message
  }
}
`;

export const fileUploadFromFolderSignatureUrl = gql`
mutation($input: DocumentInput!){
  fileUploadFromFolderSignatureUrl(input: $input) {
    message
    response
  }
}
`;

export const newDocumentFolder = gql`
mutation($folderName: String!){
  newDocumentFolder(folderName: $folderName) {
    message
    response
  }
}
`;


export const deleteFilePermanentlyMutation = gql`
mutation($filePath: String!, $id: String!){
  deleteFilePermanently(filePath: $filePath, _id: $id) {
    message
    response
  }
}
`;


export const RenameFolderMutation = gql`
mutation($newName: String!, $folderPath: String!){
  renameFolder(newName: $newName, folderPath: $folderPath) {
    message
    response
  }
}
`;

export const DeleteFolderMutation = gql`
mutation($folderPath: String!){
  deleteFolder(folderPath: $folderPath) {
    message
    response
  }
}
`;