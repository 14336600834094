// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc-wrapper{
    padding: 32px 16px;
    display: flex;
    flex-wrap: wrap;

}


@media (max-width: 768px) {
    .doc-wrapper {
      max-width: 100%;

    }
  }
  
  @media (max-width: 480px) {
    .doc-wrapper {
      max-width: 100%;
    }
  }

.doc-header{
    display: flex;
    gap:10px;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.doc-body{
    padding: 50px 0px;
    width: 100%;
}

.sigCanvas{
    background-color: aliceblue;
}

.doc-body .ant-table-wrapper .ant-table-tbody>tr>td{
    padding: 10px 16px !important;
}

.doc-body .ant-pagination-options{
  display: flex;
  align-items: center;
  height: 35px;
}

.doc-body .ant-select-selection-search-input{
  margin-top: 30px !important;
}

.doc-body .ant-pagination-options-size-changer{
  border-radius: 15px !important;
}

.doc-body .ant-pagination-options-size-changer .anticon {
  margin-top: -0.6875rem !important;
}


.floating-btn{
  animation: floating-btn-ani ease-in 1s infinite;
}

@keyframes floating-btn-ani {
  0%{
    transform: scale(1);
  }
  
  90%{
    transform: scale(1.1);
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/externalSiteLogPage/externalSiteLogPage.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,eAAe;;AAEnB;;;AAGA;IACI;MACE,eAAe;;IAEjB;EACF;;EAEA;IACE;MACE,eAAe;IACjB;EACF;;AAEF;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,iCAAiC;AACnC;;;AAGA;EACE,+CAA+C;AACjD;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;EACvB;AACF","sourcesContent":[".doc-wrapper{\n    padding: 32px 16px;\n    display: flex;\n    flex-wrap: wrap;\n\n}\n\n\n@media (max-width: 768px) {\n    .doc-wrapper {\n      max-width: 100%;\n\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .doc-wrapper {\n      max-width: 100%;\n    }\n  }\n\n.doc-header{\n    display: flex;\n    gap:10px;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.doc-body{\n    padding: 50px 0px;\n    width: 100%;\n}\n\n.sigCanvas{\n    background-color: aliceblue;\n}\n\n.doc-body .ant-table-wrapper .ant-table-tbody>tr>td{\n    padding: 10px 16px !important;\n}\n\n.doc-body .ant-pagination-options{\n  display: flex;\n  align-items: center;\n  height: 35px;\n}\n\n.doc-body .ant-select-selection-search-input{\n  margin-top: 30px !important;\n}\n\n.doc-body .ant-pagination-options-size-changer{\n  border-radius: 15px !important;\n}\n\n.doc-body .ant-pagination-options-size-changer .anticon {\n  margin-top: -0.6875rem !important;\n}\n\n\n.floating-btn{\n  animation: floating-btn-ani ease-in 1s infinite;\n}\n\n@keyframes floating-btn-ani {\n  0%{\n    transform: scale(1);\n  }\n  \n  90%{\n    transform: scale(1.1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
