import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DraggableTab from "@src/components/dragableTab";
import { GridHeader } from "@src/components/tablegrid/header";
import { TableGrid } from "@src/components/tablegrid";
import { useMutation, useQuery } from "@apollo/client";
import { AgencyObjectQuery, createAgencyMutation, getAgencies } from "@src/util/query/agency.query";
import { UpdateBulkAgency } from "@src/util/mutation/agency.mutation";
import { AgencyFormDrawer } from "./agencyFormDrawer";
import { getAgencyViewQuery } from "@src/util/query/agencyView.query";
import { createAgencyViewMutation, updateAgencyViewMutation } from "@src/util/mutation/agencyView.mutation";
import { GetPropertyByGroupQuery } from '@src/util/query/properties.query';
import { GridFilter } from "@src/components/tablegrid/gridFilter/gridFilter";
import { AdvanceFilter } from "@src/components/advanceFilter/advanceFilter";
import { resetAllFilter, resetAdvanceFilter } from "@src/middleware/redux/reducers/quickFilter";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { setEditGridColumn } from '@src/middleware/redux/reducers/properties.reducer';
import { EditColumn } from '@src/components/table/editColumn/editColumn.modal';
import { objectType } from '@src/util/types/object.types';
import { getSingleAgencyViewQuery } from "@src/util/query/agencyView.query";
import { cloneAgencyViewMutation, deleteAgencyViewMutation } from "../../util/mutation/agencyView.mutation";




export const AgencyPage = ()=>{
    
    const {data:agencyObject, loading: agencyObjectLoading, refetch: agencyObjectRefetch} = useQuery(AgencyObjectQuery,{
      fetchPolicy: 'network-only'
    });
 
    
    const [agencySchema, setAgencySchema] = useState();

    useEffect(()=>{
        if(agencyObject && !agencyObjectLoading){
            setAgencySchema(agencyObject?.getAgencyObject?.response);
        }
    },[agencyObject, agencyObjectLoading]);

     // states that we had to define for formDrawer
    const [data, setData] = useState([]);
    const [isBtnEnable, setBtn] = useState(true);
    const [isoverlay, setIsOverlay] = useState(true);
    const [agencyModal, setAgencyModal] = useState(false);

    const dispatch = useDispatch();

    // Add new Employee while form creation
    const [addAgencyMutation, {loading: processloading}] = useMutation(createAgencyMutation);
    const {quickFilter, advanceFilter} = useSelector(state=>state.quickFilterReducer);

    const {data: agencyData, loading: agencyLoading, refetch} = useQuery(getAgencies,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: quickFilter && Object.values(quickFilter)?.length>0 && advanceFilter?.length>0 ? 
                {quickFilter, advanceFilter: [...advanceFilter]} :
                quickFilter && Object.values(quickFilter)?.length>0 ? {quickFilter} : 
                advanceFilter?.length>0 ? {advanceFilter: [...advanceFilter]} : null
            }
        }
    });


    const agencyMutation = async (agency) =>{
        try{
            await addAgencyMutation({variables: {input: agency}});
            await agencyObjectRefetch();
            await refetch();

            dispatch(setNotification({
                notificationState:true, 
                message: "Agency was added successfully",
                error: false,
            }));
            setData([]);
            setBtn(true);
            setIsOverlay(true);

        }catch(err){
            dispatch(setNotification({
                notificationState: true,
                error: true,
                message: err?.message
            }));
        }
    }

    const handelSubmit = async (isCloseAble)=>{
        const suppliername = data?.find((d)=>(Object.keys(d)[0]=="suppliername"));
       
        let metadata = {};
        data?.map(d=>{
          if(Object.keys(d)[0]!=="suppliername"){
            metadata[Object.keys(d)[0]]= Object.values(d)[0]
          }
        });
        
        const agencyData = {
          ...suppliername,
          metadata,
        }
        // handel mutation
        await agencyMutation(agencyData);

        if(isCloseAble){
            setAgencyModal(!agencyModal);
        }

    };

    // dynamic column state for table gird
    const [dynamicColumn, setDynamicColumn] = useState([]);
    const [updateBulkAgency, {loading: updateBulkAgencyLoading}] = useMutation(UpdateBulkAgency);

    const handelBulkUpdateSave = async (property, record)=>{
        try{
            let schemaFields = {};
            
            
              if(property?.field==="agencyname"){
                schemaFields[property?.field] = property?.value;
              }
              else{
                schemaFields['metadata.'+property.field]=property?.value;
              }
            
            await updateBulkAgency({
                variables:{
                    input:{
                        _ids: [...record],
                        properties: {...schemaFields},
                    }
                }
            });
  
            dispatch(setNotification({
                message: "Agencies Updated Successfully",
                notificationState: true,
                error: false
            }));
            await refetch();
            return true;
        }
        catch(err){            
            dispatch(setNotification({
                message: "An error encountered while updating agency"+err.message,
                notificationState: true,
                error: true
            }));
            return false;
        }
    };


    const {data: agencyViewList, loading: agencyViewListLoading, refetch: agencyViewListRefetch} = useQuery(getAgencyViewQuery, {
      fetchPolicy: 'network-only'
    });
    const [updateAgencyView, {updateAgencyViewLoading}] = useMutation(updateAgencyViewMutation);
    const [createAgencyView, {loading: createAgencyViewLoading}] = useMutation(createAgencyViewMutation);
    const [filterModal, setFilterModal] = useState(false);

    const {data: AgencyGroupProperty} = useQuery(GetPropertyByGroupQuery,{
      variables:{
        objectType: "Agency"
      },
      fetchPolicy:'network-only'
    });

    
    const {data: singleAgencyViewData, loading: singleAgencyViewLoading, refetch: singleAgencyViewRefetch} = useQuery(getSingleAgencyViewQuery,{
      variables:{
        id: sessionStorage.getItem("selectedViewId") || ""
      },
      fetchPolicy: 'network-only',
      skip: !sessionStorage.getItem("selectedViewId")
    });


    
    // reset filters on component unmount
    useEffect(()=>{
      return ()=>{
        dispatch(resetAdvanceFilter());
        dispatch(resetAllFilter());
      }
    },[]);

    const {editGridColumn} = useSelector(state => state.propertyReducer);

    const [cloneAgencyView, {loading:cloneAgencyViewLoading}] = useMutation(cloneAgencyViewMutation);
    const [deleteAgencyView, {loading:deleteAgencyViewLoading}] = useMutation(deleteAgencyViewMutation);

    return(
        <React.Fragment>
        {
 
            
            <div className="tablegrid">
              <GridHeader 
                title={"Supplier"} 
                to={"/branch/editform"}
                record={agencyData?.agencies?.length} 
                from={"/user/branch"}      
                createAction={()=>{setAgencyModal(true);}} 
              />
            
              <DraggableTab  
                viewList = {agencyViewList?.agencyView?.response}
                loading = {agencyViewListLoading  || singleAgencyViewLoading || createAgencyViewLoading || updateAgencyViewLoading || cloneAgencyViewLoading || deleteAgencyViewLoading}
                refetch = {agencyViewListRefetch || singleAgencyViewRefetch}
                updateView = {updateAgencyView}
                createView = {createAgencyView}
                createViewLoading = {createAgencyViewLoading}
                objectType={"Agency"}
                cloneView={cloneAgencyView}
                deleteView={deleteAgencyView}
              />

              <GridFilter
                  openAdvanceFilter={()=>setFilterModal(true)}
                  updateView={updateAgencyView}
                  refetch={async()=>{
                    await agencyViewListRefetch(); await refetch();
                  }}
                  viewList = {agencyViewList?.agencyView?.response}

              />

              <AdvanceFilter 
                visible = {filterModal} 
                onClose = {()=>setFilterModal(false)}
                objectData = {agencySchema}
                object={"Agency"}
                groupProperty = {AgencyGroupProperty?.getPropertyByGroup?.data || []}
                updateView={updateAgencyView}
                refetchAll= {async()=>{
                  await agencyViewListRefetch(); await refetch();
                }}
              />
              
                <TableGrid
                    title={"Agency"}
                    data={agencyData?.agencies?.map((agency)=>({...agency, key:agency?._id}))}
                    refetch={refetch}
                    setDynamicColumn={setDynamicColumn}
                    dynamicColumn={dynamicColumn}
                    viewRefetch={()=>{agencyViewListRefetch();}}
                    view={singleAgencyViewData?.getSingleAgencyView?.response?.viewFields}
                    loading={agencyLoading || agencyViewListLoading || singleAgencyViewLoading || updateAgencyViewLoading}
                    objectData={agencySchema}
                    detailpage={"agency-detail/"}
                    handelBulkUpdateSave={handelBulkUpdateSave}

                />
            
            </div>

        }

        <AgencyFormDrawer
            objectData={agencySchema}
            objectLoading={agencyObjectLoading}
            handelSubmit={handelSubmit}
            visible={false || agencyModal} 
            refetch={refetch} 
            setBtn={setBtn}
            data={data}
            setData={setData}
            isBtnEnable={isBtnEnable}
            isoverlay={isoverlay}
            setIsOverlay={setIsOverlay}
            loading={processloading}
            onClose={()=>setAgencyModal(!agencyModal)} 
            to={""}
            from={"/user/agency"}
            title={"Supplier"}
        />
        
        {editGridColumn?
            <EditColumn 
              objectType={objectType.Agency} 
              visible={editGridColumn} 
              onClose={()=>dispatch(setEditGridColumn(false))}
              properties = {agencyObject?.getAgencyObject?.response}
              propertiesRefetch = {agencyObjectRefetch}
              loading = {agencyObjectLoading || agencyViewListLoading}
              disable = {updateAgencyViewLoading}
              refetchView = {singleAgencyViewRefetch}
              view = {singleAgencyViewData?.getSingleAgencyView?.response?.viewFields}
              updateRenderedView = {updateAgencyView}
            />
          : null
        }
       
        </React.Fragment>
    );
}