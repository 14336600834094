import { useQuery } from '@apollo/client';
import './wardSecurity.css';
import swLogo from '@src/assets/img/ward-security.png';
import { getScheduleReportByJobIdQuery } from '@src/util/query/scheduleReport.query';
import { useEffect, useRef, useState } from 'react';
import { jsPDF } from "jspdf";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faShare, faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Button, message } from 'antd';
import dayjs from 'dayjs';
import Spinner from "@src/components/spinner";



export const WardSecurityMobileReport = (job=null)=>{
    const pdfRef = useRef();

    const getBase64FromUrl = async (url) => {
        try {
            const response = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache'
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = () => reject(new Error('Error reading blob as base64'));
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error converting to base64:', error);
            return null;
        }
    };
    

    const [processingPdf, setPdfProcessing] = useState(false);
      

    



    const param = useParams();

    const {data: reportData, loading: reportDataLoading, refetch: reportDataRefetch} = useQuery(getScheduleReportByJobIdQuery,{
        variables:{
            jobId: (param?.jobId || job?.jobId)
        },
        // skip: (!param?.jobId || !job?.jobId),
        fetchPolicy:'network-only'
    });

    const generatePdf = async () => {
        setPdfProcessing(true);
        await reportDataRefetch();
        try {
            const doc = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: "a4",
            });
    
            // Convert main content to PDF
            await doc.html(pdfRef.current, {
                callback: async (pdf) => {
                    // Process images after main content
                    if (report?.filesURL) {
                        let imageIndex = 1;
                        for (const imageUrl of Object.values(report.filesURL)) {
                            const base64Image = await getBase64FromUrl(imageUrl);
                            if (base64Image) {
                                pdf.addPage(); // Start a new page for each image
                                pdf.setFillColor(0, 0, 255); // Light gray
                                pdf.rect(13, 8, 165, 10, "F");
                                
                                pdf.setFontSize(14);
                                pdf.setTextColor(255, 255, 255)
                                pdf.text(`Photo ${imageIndex}`, 15, 15); // Adjust X and Y as needed

                                // X, Y, W, H
                                pdf.addImage(base64Image, 'JPEG', 10, 30, 190, 247); // Adjust size to fit
                                imageIndex++;
                            }
                        }
                    }
    
                    pdf.save(`ward_security_${dayjs().format("DD-MM-YYYY")}.pdf`);
                },
                x: 10,
                y: 10,
                width: 190,
                windowWidth: 800,
                scale: 1,
            });
        } catch (err) {
            console.error("Error generating PDF:", err);
        }
        setPdfProcessing(false)
    };

   
    const [report, setReport] = useState(null);

    const processReportImages = async () => {
        if (!reportData?.getScheduleReportByJobId?.response?.filesURL) return;
        
        try {
          const files = reportData.getScheduleReportByJobId.response.filesURL;
          const processedFiles = { ...files };
          
          // Convert each image URL to base64
          for (const key in files) {
            processedFiles[key] = await getBase64FromUrl(files[key]);
          }
          
          console.log(processedFiles, "ppp");
        } catch (error) {
          console.error('Error processing images:', error);
        }
    };

    useEffect(()=>{
        if(reportData?.getScheduleReportByJobId?.response){

            setReport(reportData?.getScheduleReportByJobId?.response);

            // Then process the images
            // processReportImages();

        }else{
            setReport(null)
        }
    },[reportData?.getScheduleReportByJobId?.response]);


      

    return (
        reportDataLoading?
        <div className='row-flex' style={{justifyContent:'center', alignContent:'center', alignItems:'center'}}>
            <Spinner/>
        </div>
        :
        <div>
            
        
            <div id="pdfexportBtn" style={{display:'flex', justifyContent:'right', padding:'20px',
                // position: 'sticky',
                // top: '50px',
                // background: 'white',
            }}>
                
                    <Button style={{fontSize:'14px'}} onClick={()=>{ navigator.clipboard.writeText(job?.copyLink); message.success("Link copied to share") }}>
                        Share Report &nbsp;<FontAwesomeIcon icon={faShareFromSquare} />
                    </Button>
                    <Button style={{fontSize:'14px'}} onClick={generatePdf}>
                        {processingPdf? 
                            <Spinner/>:
                            <>
                                Download Report &nbsp;<FontAwesomeIcon icon={faDownload} />
                            </>
                        }
                    </Button>
            </div>

            <div className='w-wrapper' ref={pdfRef} style={{width: "210mm", height: "297mm", padding: 20}}>
                
                <div className="w-report-header">
                    
                    <div className='w-report-title'>Planned Job Report</div>
                    
                    <img src={swLogo} alt="" />

                </div>


                <table style={{width:'70%'}}>
                    <tr>
                        <td colSpan={2} className='w-table-header'>Patrol Details</td>
                    </tr>
                    <tr>
                        <td>Title</td>
                        <td> {report?.reportDetail?.title} </td>
                    </tr>
                    <tr>
                        <td>Assigned Team</td>
                        <td> {report?.reportDetail?.assignedteam} </td>
                    </tr>
                    <tr>
                        <td>Assigned User</td>
                        <td> {report?.reportDetail?.assigneduser} </td>
                    </tr>
                    <tr>
                        <td>Location</td>
                        <td> {report?.reportDetail?.location} </td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td> {report?.reportDetail?.address} </td>
                    </tr>
                    <tr>
                        <td>Location No.</td>
                        <td> {report?.reportDetail?.locationno} </td>
                    </tr>
                    <tr>
                        <td>Service</td>
                        <td> {report?.reportDetail?.service} </td>
                    </tr>
                    <tr>
                        <td>Subject</td>
                        <td> {report?.reportDetail?.subject} </td>
                    </tr>
                    <tr>
                        <td>PO Number</td>
                        <td> {report?.reportDetail?.ponumber} </td>
                    </tr>
                </table>

                <table style={{width:'100%'}} className='mt32'>
                    <tr>
                        <th colSpan={3} className='w-table-header'>Patrol History</th>
                    </tr>
                    <tr>
                        <th >Date</th>
                        <th >Status</th>
                        <th >Notes</th>
                    </tr>

                    <tr>
                        <td>{report?.reportDetail?.date}</td>
                        <td> {report?.reportDetail?.status} </td>
                        <td> {report?.reportDetail?.notes} </td>
                    </tr>
                    <tr>
                        <td>On Site</td>
                        <td> {report?.reportDetail?.onsite} </td>
                    </tr>
                    <tr>
                        <td> Off Site </td>
                        <td> {report?.reportDetail?.offsite} </td>
                    </tr>
                    
                </table>


                <table style={{width:'100%'}} className='mt32'>

                    <tr>
                        <th colSpan={2} className='w-table-header'>Triggered Audit Results</th>
                    </tr>

                    <tr>
                        <th >Question</th>
                        <th >Answer</th>
                    </tr>

                    <tr>
                        <td>Confirm the site was secure on arrival ?</td>
                        <td>{report?.reportDetail['confirmthesitewassecureonarrival?']}</td>
                    </tr>

                    <tr>
                        <td>Are there any other people on site ?</td>
                        <td>{report?.reportDetail['arethereanyotherpeopleonsite?']}</td>
                    </tr>

                    <tr>
                        <td>Are there any vehicles on site ?</td>
                        <td>{report?.reportDetail['arethereanyvehiclesonsite?']}</td>
                    </tr>

                    <tr>
                        <td>Can you confirm the environment surrounding the site has not changed</td>
                        <td>{report?.reportDetail?.canyouconfirmtheenvironmentsurroundingthesitehasnotchanged}</td>
                    </tr>

                    <tr>
                        <td>Has the Site/building exterior remained the same ? (e.g. any construction activity ?)</td>
                        <td>{report?.reportDetail['hasthesite/buildingexteriorremainedthesame?(e.g.anyconstructionactivity?)']}</td>
                    </tr>

                    <tr>
                        <td>Is there evidence of fly tipping?</td>
                        <td>{report?.reportDetail['isthereevidenceofflytipping?']}</td>
                    </tr>

                    <tr>
                        <td>Is there any sign of property damage ?</td>
                        <td>{report?.reportDetail['isthereanysignofpropertydamage?']}</td>
                    </tr>

                    <tr>
                        <td>Are all walkways clear from obstruction.</td>
                        <td>{report?.reportDetail['areallwalkwaysclearfromobstruction.']}</td>
                    </tr>

                    <tr>
                        <td>Are all windows secure?</td>
                        <td>{report?.reportDetail['areallwindowssecure?']}</td>
                    </tr>

                    <tr>
                        <td>Are all fire exits secure?</td>
                        <td>{report?.reportDetail['areallfireexitssecure?']}</td>
                    </tr>

                    <tr>
                        <td>Does the report require escalation to the client?</td>
                        <td>{report?.reportDetail['doesthereportrequireescalationtotheclient?']}</td>
                    </tr>

                    <tr>
                        <td>Has any Non Ward/Vinci Signage been added to the Site Hoarding</td>
                        <td>{report?.reportDetail['hasanynonward/vincisignagebeenaddedtothesitehoarding?']}</td>
                    </tr>

                    

                </table>

                <table style={{width:'100%'}} className='mt32'>
                    
                    <tr>
                        <th colSpan={3} className='w-table-header'>Triggered Audit Failures</th>
                    </tr>

                    <tr>
                        <th >Question</th>
                        <th >failure</th>
                        <th >Location</th>
                    </tr>

                    <tr>
                        <td>Are there any vehicles on site ?</td>
                        <td>{report?.reportDetail['arethereanyvehiclesonsite?']}</td>
                    </tr>


                    

                </table>

                <table style={{width:'100%'}} className='mt32'>
                    
                    <tr>
                        <th colSpan={3} className='w-table-header'>Audit Completed By</th>
                    </tr>

                    <tr>
                        <th >Signature</th>
                        <th >Name</th>
                        <th >Response</th>
                    </tr>

                    <tr>
                        <td></td>
                        <td>{report?.reportDetail['name']}</td>
                        <td>{report?.reportDetail['response']}</td>
                    </tr>
    

                </table>
                

            </div>


            <table className='w-wrapper' style={{width: "221mm", height: "297mm", padding: 20, marginTop:'28vh'}}>
                        
                <tr>
                    <th colSpan={3} className='w-table-header'>Photos</th>
                </tr>

                {!reportDataLoading ?
                    reportData?.getScheduleReportByJobId?.response?.filesURL && Object.values(reportData?.getScheduleReportByJobId?.response?.filesURL)?.map((file)=>(

                        <tr >
                            <td style={{width:'200px'}}>
                                <img  src={file} style={{width:'100%'}}/>
                            </td>
                        </tr>

                    ))
                    :
                    null
                }


            </table>




            
        </div>
    );
}