import "./siteDutyTask.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Spinner from "@src/components/spinner";
import { useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSiteTaskByIdQuery } from "@src/util/query/siteTask.query";
import { Checkbox, Drawer, Form, Input, InputNumber, Select } from "antd";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { faChevronDown, faChevronUp, faClose } from "@fortawesome/free-solid-svg-icons";
import { newSiteTaskMutation, updateSiteTaskMutation } from "@src/util/mutation/siteTask.mutation";

export const SiteDutyTaskDrawer = ({visible, close, refetch, editId=""})=>{
    
    const [title, setTitle] = useState("");
    const [timeIntervalType, setTimeIntervalType] = useState(null);
    const [customTimeInterval, setCustomTimeInterval] = useState(null);
    const [timeIntervalUnit, setTimeIntervalUnit] = useState(null);
    const [response, setResponse] = useState(null);
    const [comment, setComment] = useState(null);
    const [attachment, setAttachment] = useState(null);

    const param = useParams();
    const dispatch = useDispatch();
    const [newSiteDutyTask, {loading: siteDutyTaskLoading}] = useMutation(newSiteTaskMutation);
    

    const [updateSiteTask, {loading: updateSiteTaskLoading}] = useMutation(updateSiteTaskMutation);


    const handelSubmit = async (createAnother=false)=>{
        try{

            const siteTaskInput = {
                id: editId?.length>0 ? editId : null,
                siteId: param?.id,
                title, timeIntervalType, customTimeInterval: customTimeInterval?.toString(), timeIntervalUnit, response, comment, attachment
            };
            
            if(editId){
                await updateSiteTask({
                    variables:{
                        input: siteTaskInput
                    }
                })
            }else{
                await newSiteDutyTask({
                    variables:{
                        input: siteTaskInput
                    }
                });
            }
            
            if(!createAnother){
                close();
            }

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Site task was created successfully"
            }));

            await refetch();
            
        }catch(err){
            throw new Error(err.message);
        }
    };

    const {data: dutyTaskToBeEdit, loading: dutyTaskToBeEditLoading} = useQuery(getSiteTaskByIdQuery,{
        variables:{
            siteTaskId: editId
        },
        fetchPolicy: 'network-only',
        skip: editId?.length==0 || !editId 
    });

    useEffect(()=>{
        if(dutyTaskToBeEdit?.getSiteTaskById?.response){
            const taskDetail = dutyTaskToBeEdit?.getSiteTaskById?.response;
            setTitle(taskDetail?.title);
            setComment(taskDetail?.comment? true: false);
            setTimeIntervalType(taskDetail?.timeIntervalType);
            setCustomTimeInterval(taskDetail?.customTimeInterval);
            setTimeIntervalUnit(taskDetail?.timeIntervalUnit);
            setResponse(taskDetail?.response? true: false);
            setAttachment(taskDetail?.attachment? true: false);
        }
    },[dutyTaskToBeEdit?.getSiteTaskById?.response]);



    return (
        <Drawer
            title={"Add Site Duty Task"}
            placement="right"
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={close} className='close-icon'/>}
            open={visible}
            width={600}
            mask={true}
            maskClosable={false}
            maskClassName='filtermask'
            footer={
                <div className='drawer-footer' style={{display:'flex', }}>
                    
                    <button onClick={()=>{handelSubmit(false)}} disabled={siteDutyTaskLoading || updateSiteTaskLoading} className={siteDutyTaskLoading || updateSiteTaskLoading? 'disabled-btn drawer-filled-btn': 'drawer-filled-btn'}>
                    {siteDutyTaskLoading || updateSiteTaskLoading? <Spinner/> : editId?.length>0? 'Update' : 'Create'}
                    </button>
                    {editId?.length>0? null:
                    <button onClick={()=>{handelSubmit(true)}} disabled={siteDutyTaskLoading || updateSiteTaskLoading} className={siteDutyTaskLoading || updateSiteTaskLoading? 'disabled-btn drawer-filled-btn': 'drawer-filled-btn'}>
                    {siteDutyTaskLoading || updateSiteTaskLoading? <Spinner/> :'Create and add another'}
                    </button>
                    }
                    <button disabled={siteDutyTaskLoading || updateSiteTaskLoading} className='drawer-outlined-btn' onClick={close} >Cancel</button>
                    

                </div>
            }
        >
        {
            editId && dutyTaskToBeEditLoading?
                <Spinner />
            :
                <Form>

                    <Form.Item>
                        <label> Task </label>
                        <Input 
                            className="generic-input-control"
                            placeholder="Task title/detail"
                            value={title}
                            onChange={(e)=>setTitle(e.target.value)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <label> Time Interval </label>

                        <div>
                            <Select 
                                className="custom-select"
                                placeholder="Task title/detail"
                                suffixIcon={<div className="dropdowncaret"></div>}
                                onChange={(e)=>setTimeIntervalType(e)}
                                value={timeIntervalType}
                            >
                                <Select.Option value={"duty"}>Once per duty</Select.Option>
                                <Select.Option value={"custom"}>Custom</Select.Option>
                            </Select>
                            {
                                timeIntervalType=="custom" &&
                                <div className="customisedInterval">
                                    <div>Every</div> 
                                    <InputNumber
                                        min={1}
                                        className='generic-input-control'
                                        onChange={(e)=>setCustomTimeInterval(e)}
                                        value={customTimeInterval}
                                        style={{margin: 0, padding: '3px 0'}}                     
                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                        downHandler={<FontAwesomeIcon  style={{color:'#0091ae'}} icon={faChevronDown} />}
                                    />
                                    <Select
                                        className="custom-select"
                                        placeholder="per hour/mintue"
                                        suffixIcon={<div className="dropdowncaret"></div>}
                                        value={timeIntervalUnit}
                                        onChange={(e)=>setTimeIntervalUnit(e)}
                                    >
                                        <Select.Option value={"hour"}>Hour</Select.Option>
                                        <Select.Option value={"mintue"}>Mintue</Select.Option>
                                    </Select>
                                </div>
                            }

                        </div>
                    </Form.Item>

                    <Form.Item>
                        <Checkbox
                            onChange={(e)=>setResponse(e.target.checked)}
                            checked={response}
                        > 
                            Yes/No Response Field
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Checkbox 
                            checked={comment}
                            onChange={(e)=>setComment(e.target.checked)}
                        > 
                            Any Comments
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Checkbox 
                            checked={attachment}
                            onChange={(e)=>setAttachment(e.target.checked)}
                        > 
                            Any Attachments
                        </Checkbox>
                    </Form.Item>

                </Form>        
        }
            
        </Drawer>
    )
}