import React, { useEffect, useState } from 'react';
import { Avatar, Tooltip, Upload } from 'antd';
import { EditOutlined, UploadOutlined, UserOutlined } from '@ant-design/icons';

export const EditableAvatar = ({ src, size, onEdit }) => {

  const [hovered, setHovered] = useState(false);
  const [uploadImg, setUploadImg] = useState(false);


  useEffect(() => {
    let interval = setInterval(() => {
      const file = document.getElementById("fileRef");
      if(file){

        file.addEventListener("cancel", () => {
          setUploadImg(false);
          setHovered(false);
        });
      }
    }, 0);

    return () => clearInterval(interval);
  }, []);

  // console.log(isDialogOpen, "isDialogOpen")


  return (
    <Tooltip title="Update Profile Image">
      <div
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{ position: 'relative', display: 'inline-block' }}
      >
        {src?.length>0?
          <Avatar size={size} src={src} />
        :
          <Avatar size={size}>
            <UserOutlined/>
          </Avatar>
        }

        {(hovered || uploadImg) && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '50%',
              // cursor: 'pointer'
            }}

          >

            <Upload
              name="avatar"
              listType="picture"
              showUploadList={false}
              
              onChange={(e)=>{ 

                console.log(e, "eee");
                if(e){
                  onEdit(e); 
                }
                setHovered(false);
                setUploadImg(false); 
                
              }}
              id={"fileRef"}
            >

              <UploadOutlined 
                onClick={()=>setUploadImg(true)}
                style={{ color: '#fff', fontSize: '20px', cursor: 'pointer' }}
              />

            </Upload>

            

          </div>
        )}
      </div>


    </Tooltip>
  );
};
