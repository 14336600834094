import { useMutation, useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AgencyDetailPageLeftSideBar } from "./component/leftSidebar";
import { SiteGroupPostSection } from "./component/middleSection";
import { getSingleAgency } from "@src/util/query/agency.query";
import { AgencyPayTable } from "../agencypayTable/agencyPayTable";
import { getNote } from "@src/util/query/note.query";
import { objectType } from "@src/util/types/object.types";
import { AgencyNotesTab } from "./agencyNotes.page";
import { AgencyProfileTab } from "./component/agencyProfile.tab";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getAgencyDetailViewFieldQuery } from "@src/util/query/agencyDetailViewField.query";
import { useSelector } from "react-redux";
import { AgencyCustomLeftPane } from "./agencyCustomLeftPane";
import { deleteAgencyCustomViewFieldMutation, updateAgencyCustomViewFieldMutation } from "@src/util/mutation/agencyDetailViewField.mutation";
import { HolderOutlined } from "@ant-design/icons";
import { ShareDocument } from "@src/components/document/shareDocument.component";
import { DocumentComponent } from "@src/components/document/document.component";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const AgencyDetailPage = ()=>{

    const navigate = useNavigate();

    const agencytabs = [
        // {title: 'Post', url:'general'},
        {title: 'Profile', url:'profile'},
        {title: 'Pay Table', url:'paytable'},
        {title: 'Notes', url:'note'},
        {title: 'Documents', url:'document'},
    ];

    
    const param = useParams();
    

    const [activeTab, setActiveTab] = useState(param?.tab);
    useEffect(()=>{
        if(!param?.edit && !param?.tab){
            setActiveTab("profile");
        }
    },[param]);


    // site group details
    const {data: singleAgency, loading: singleAgencyLoading, refetch} = useQuery(getSingleAgency,{fetchPolicy: 'cache-and-network',
        variables: {
            id: param?.id
        }
    });


    const [agency, setAgency] = useState({});

    useEffect(()=>{
        if(!singleAgencyLoading){
            const {suppliername, metadata} = singleAgency?.agency;
            setAgency({suppliername, ...metadata});
        }
    },[singleAgency,singleAgencyLoading]);

    const [isFieldChanged, setIsFieldChanged] = useState(false);
    const [saveUpdate, setSaveUpdate] = useState(false);



    
    const { data:note , loading: noteLoading, refetch: noteRefetch} = useQuery(getNote,{
        variables:{
            createdFor: param?.id,
            objectType: objectType?.Agency
        },
        fetchPolicy:'network-only',
    });

    // edit customise view
    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);

    const {data:agencyDetailViewField, loading: getAgencyViewDataLoading, refetch: agencyDetailViewRefetch} = useQuery(getAgencyDetailViewFieldQuery,{
        variables:{
            userId: userDetail?._id
        },
        fetchPolicy:'network-only'
    });

    const [deleteAgencyCustomViewField, {loading: deleteAgencyCustomViewFieldLoading}] = useMutation(deleteAgencyCustomViewFieldMutation);
    const [updateAgencyCustomViewField, {loading: updateAgencyCustomViewFieldLoading}] = useMutation(updateAgencyCustomViewFieldMutation)

    useEffect(()=>{
        if(agencyDetailViewField?.getAgencyDetailViewField?.response && !getAgencyViewDataLoading){
            setEditableProp(agencyDetailViewField?.getAgencyDetailViewField?.response)
        }
    },[agencyDetailViewField?.getAgencyDetailViewField?.response, getAgencyViewDataLoading]);

    const [editableProp, setEditableProp] = useState([]);

    const onDragEndForLeftCustomise = async (result) => {
        // dropped outside the list
        if (!result.destination) {
        return;
        }
        
        const reorderedArr = reorder(
            editableProp,
            result.source.index,
            result.destination.index
            );
        
        
        setEditableProp(reorderedArr);
        
        await updateAgencyCustomViewField({
            variables:{
                input:{
                   ids: reorderedArr?.map((prop)=> prop?._id)
                }
            }
        });

    };


    return(
        <div>
            {/* site group tabs */}

            <div style={{background: 'rgb(245, 248, 250)', padding: '15px 45px 7px 15px', display:'flex', gap:50, alignItems: 'center', position: 'fixed',  width: '100%', zIndex: '996'}}>
                
                {/* back + user name btn */}
                <div style={{display:'flex', alignItems:'center', gap:'25px', paddingBottom:'8px', width:'50%'}}>

                    <div onClick={()=>navigate("/user/agency")} >
                        <FontAwesomeIcon className='left-chevron-icon' icon={faChevronLeft}/> <span className='text-deco' style={{left: '5%', position: 'relative', fontSize:'14px'}}>Back</span> 
                    </div>

                    <div style={{fontSize:'14px'}}>
                        Supplier
                    </div>
                </div>

                {param?.edit==="edit"?
                    <>
                        <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', width:'100%'}}>
                           <b>Customise Mode</b> 
                        </div>

                        <div style={{width:'50%', textAlign:'right', marginRight: '35px', cursor:'pointer'}}>
                            <FontAwesomeIcon icon={faTimes} 
                                className="icon-bound"
                                style={{boxShadow:'none', fontSize:'15px', borderRadius:'15px', padding:'6px 8px', cursor:'pointer'}}
                                onClick={async ()=>{navigate("/user/agency-detail/"+param?.id); refetch();}}
                            />
                        </div>
                    </>
                :
                    <>
                        {/* navigation tabs */}
                        <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', width:'100%'}}>
                            {agencytabs?.map((tab)=>(
                                <div className={activeTab==tab?.url? 'emp-menubar emp-menubar-active': 'emp-menubar'} onClick={()=>{
                                    navigate("/user/agency-detail/"+param?.id+"/tab/"+tab?.url); setActiveTab(tab.url); 
                                }}>{tab?.title}</div>
                            ))}
                        </div>

                        <div style={{width:'50%'}}></div>
                    </>
                }

            </div>

            {
                param?.edit==="edit"?
                    <div style={{padding:'50px 5px 5px 5px'}}>
                            <div className='grid-layout'>
                                <div className='grid-25'>
                                    <AgencyDetailPageLeftSideBar
                                        agency={agency}
                                        loading={singleAgencyLoading}
                                        setIsFieldChanged={setIsFieldChanged}
                                        saveUpdate={saveUpdate}
                                        setSaveUpdate={setSaveUpdate}
                                        refetch={refetch}
                                        isFieldChanged={isFieldChanged}
                                        showDetails={false}
                                        
                                    />
                                    <DragDropContext onDragEnd={onDragEndForLeftCustomise}>
                                        <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="tab-grp"
                                            style={{
                                                width:'auto',
                                                border: '1px solid #ECEFEC',
                                                borderRadius: '12px',
                                                padding: '14px',
                                                marginTop: '10px',
                                                gap: 0,
                                            }}
                                            >
                                            {editableProp?.map((item, index) => (
                                                <Draggable key={item._id} draggableId={item._id} index={index}
                                                
                                                >
                                                {(provided, snapshot) => (
                                                    <div className="icon-wrapper">
                                                    
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        id={"item-"+index}
                                                        itemRef={"item-"+index}
                                                        key={item?._id}
                                                        // className=
                                                        style={{
                                                        opacity: snapshot.isDragging ? 0.5 : 1,
                                                        ...provided.draggableProps.style
                                                        }}
                                                        className={"holderwrap"}
                                                    >
                                                        <li style={{direction:'ltr', justifyContent:'space-between'}}>
                                                            <span>

                                                                <HolderOutlined className="holder"/>
                                                                {item?.propertyDetail?.label}
                                                            </span>
                                                            <FontAwesomeIcon icon={faTimes}
                                                                onClick={async()=>{ await deleteAgencyCustomViewField({
                                                                    variables:{
                                                                        userId: userDetail?._id,
                                                                        propertyId: item?.propertyDetail?._id,
                                                                        
                                                                    }
                                                                });  await agencyDetailViewRefetch();}}
                                                            />
                                                        </li>
                                                        
                                                    </div>
                                                    </div>

                                                )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                            </div>
                                        )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                                
                                <div className='grid-50'>
                                    
                                    <AgencyCustomLeftPane 
                                        getAgencyViewDataLoading={getAgencyViewDataLoading || deleteAgencyCustomViewFieldLoading} 
                                        editableProp={editableProp} 
                                        agencyDetailViewRefetch={agencyDetailViewRefetch} 
                                    />
                                    
                                </div>

                                <div className='grid-25'>
                                    {/* <DetailPageRightSideBar branch={true} /> */}
                                </div>
                            </div>
                    </div>
                :
                    <div style={{padding:'50px 5px 5px 5px'}}>
                        
                        {
                            activeTab=='profile'?
                            <AgencyProfileTab />

                            :
                            
                            <div  className="grid-layout">
                                <div className="grid-25">
                                    
                                    <AgencyDetailPageLeftSideBar
                                        agency={agency}
                                        loading={singleAgencyLoading}
                                        setIsFieldChanged={setIsFieldChanged}
                                        saveUpdate={saveUpdate}
                                        setSaveUpdate={setSaveUpdate}
                                        refetch={refetch}
                                        isFieldChanged={isFieldChanged}
                                        showDetails={true}
                                        
                                    />
                                </div>
                                
                                {activeTab=='paytable'?

                                    <div className="grid-75">
                                        <AgencyPayTable id={param?.id}/>
                                    </div>
                                :
                                activeTab=='general'?
                                    <div className="grid-75">
                                        <SiteGroupPostSection/>
                                    </div>

                                :
                                activeTab==='note' ?
                                    <div className="grid-75">
                                        <AgencyNotesTab note={note?.getNote?.response} noteLoading={noteLoading} noteRefetch={noteRefetch} />
                                    </div>
                                :
                                activeTab=="document"?
                                
                                    <div className="grid-75">
                                        {
                                            param?.documentId?

                                            <ShareDocument />

                                            :

                                            <DocumentComponent 
                                                singleEntityRefetch={refetch} 
                                                singleEntityRecord={singleAgency?.agency} 
                                                objectType={objectType.Agency}
                                            />
                                        }
                                    </div>

                                :     
                                null
                                }

                            </div>
                        
                        }
                        
                        
                    </div>
            }


            
            {isFieldChanged ?
                <div className='hr-action-footer'>
                    <button disabled={singleAgencyLoading} className={singleAgencyLoading? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>setSaveUpdate(true)}>Save</button>
                    <button disabled={singleAgencyLoading} className={singleAgencyLoading? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} onClick={async()=> await refetch()}>Cancel</button>
                    {
                        <span className='text' style={{margin: 0}}>You've changed field value</span>
                    }
                </div>
            : null}

        </div>
    )
};