import "./document.css";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { Input, Popover, Table, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { faCheckToSlot, faMinus, faStream, faThLarge } from "@fortawesome/free-solid-svg-icons";
import { faFile, faFileAlt, faFilePdf, faFolder, faFolderClosed, faFolderOpen, faImage, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { NewFolder } from "./newFolder";
// import { MoveFolderModal } from "./moveFolder.modal";
import { NewFileDrawer } from "./newFileDrawer";
import { getFoldersFilesQuery } from "@src/util/query/document.query";
import { newDocumentFolder } from "@src/util/mutation/document.mutation";
import { getDocumentDetailByObjectandEntityId } from "@src/util/query/document.query";
import { deleteFilePermanentlyMutation, RenameFolderMutation, DeleteFolderMutation } from "@src/util/mutation/document.mutation";
import { getFileUploadURL } from "@src/util/query/document.query";
import TabPane from "antd/es/tabs/TabPane";
import { useSelector } from "react-redux";



export const DocumentComponent = ({singleEntityRecord, singleEntityRefetch, objectType})=>{

    // get folder and files from S3
    const [getFolderandFiles, {data: foldersFiles, loading: foldersFilesLoading, refetch: refetchFolderFile}] = useLazyQuery(getFoldersFilesQuery);
    
    // get doc name store in db to align with doc config
    const [getDocumentDetailByEntity,{data: documentDetailData, loading: documentDetailDataLoading, refetch: documentDetailRefetech}] = useLazyQuery(getDocumentDetailByObjectandEntityId);

    // new folder mutation
    const [newFolder, {loading: newFolderLoading}] = useMutation(newDocumentFolder);


    useEffect(()=>{
        singleEntityRefetch();
    },[]);
    
    useEffect(()=>{
        if(singleEntityRecord?._id){
            
            getFolderandFiles({
                variables:{
                    foldername: `${objectType.toLowerCase()}/${singleEntityRecord?._id}/`
                },
                skip: !singleEntityRecord?._id,
                fetchPolicy: 'network-only'
            });

            getDocumentDetailByEntity({
                variables: {
                    objectType: objectType,
                    entityId: singleEntityRecord?._id,
                    isArchived: false
                },
                fetchPolicy: 'cache-and-network'
            });

        }
    },[getFolderandFiles, singleEntityRecord]);


    const handelTabChange = (tab) => {
        if(tab==2){
            getDocumentDetailByEntity({
                variables: {
                    objectType: objectType,
                    entityId: singleEntityRecord?._id,
                    isArchived: true
                },
                fetchPolicy: 'cache-and-network'
            });
        }else{

            getDocumentDetailByEntity({
                variables: {
                    objectType: objectType,
                    entityId: singleEntityRecord?._id,
                    isArchived: false
                },
                fetchPolicy: 'cache-and-network'
            });
        }
    }


   
    //=========== Create first time site name folder when its not exist in DB
    const [currentFolder, setCurrentFolder] = useState(null);
    const [dataSource, setDataSource] = useState([]);

    const [renewableDataSource, setRenewableDataSource] = useState([]);
    const [standardDataSource, setStandardDataSource] = useState([]);
    const [archivedDataSource, setArchivedDataSource] = useState([]);

    const [folderToActive, setFolderToActive] = useState(null);

    useEffect(()=>{
        if(folderToActive!==null){
            getFolderandFiles({
                variables:{
                    foldername: folderToActive
                },
                skip: !singleEntityRecord?._id,
                fetchPolicy: 'network-only'
            });
        }
    },[folderToActive]);

    useEffect(()=>{
        if(foldersFiles===undefined && !foldersFilesLoading && folderToActive!==null){
            addSiteFolderIfNotExist();
        }else if((foldersFiles?.getDirectories?.response?.filesFolders?.folders?.length>0 || foldersFiles?.getDirectories?.response?.filesFolders?.files?.length>0) && !foldersFilesLoading && !documentDetailDataLoading){
            const folders = foldersFiles?.getDirectories?.response?.filesFolders?.folders;

            setCurrentFolder(foldersFiles?.getDirectories?.response?.filesFolders?.Prefix);

            const lastFolder =  folders[folders?.length-1]?.Prefix;
            

            setDataSource([
                ...foldersFiles?.getDirectories?.response?.filesFolders?.files
                ?.filter((file)=> documentDetailData?.getDocumentDetailByObjectType?.response?.find((files)=>files?.fileName==objectType+"-"+file?.Key.split(`/${objectType}-`)[1]))
                ?.map((file, index)=>{
                    const isDocumentExist = documentDetailData?.getDocumentDetailByObjectType?.response?.find((files)=>files?.fileName==objectType+"-"+file?.Key.split(`/${objectType}-`)[1]);
                    return (
                        {
                            key: file?.Key,
                            id: isDocumentExist?._id,
                            fakename: <span> 
                                <FontAwesomeIcon icon={faFileAlt} style={{fontSize:'18px', display: 'none'}} /> 
                                <span className="prev-btn" onClick={()=>setFolderToActive(null)}>  
                                { isDocumentExist?.displayname } 
                                </span> 
                            </span> ,
                            size: ((file?.Size/1024)/1024).toFixed(2) + " MB",
                            shares: isDocumentExist?.shares || 0,
                            views: isDocumentExist?.views || 0,
                            uploadedBy: isDocumentExist?.uploadedBy?.firstname+" "+isDocumentExist?.uploadedBy?.lastname,
                            expiryDate: isDocumentExist?.expiryDate? 
                                dayjs(isDocumentExist?.expiryDate, "YYYY-MM-DD")?.isBefore(dayjs())?
                                "Expired"
                                :
                                dayjs(isDocumentExist?.expiryDate, "YYYY-MM-DD").format("DD/MM/YYYY") 
                            : "--",
                            isAnyExpiryDate: isDocumentExist?.expiryDate?.length>0? true : false,
                            isArchived: isDocumentExist?.isArchived ? true : false,
                            uploadedAt: dayjs(file?.LastModified).format("DD/MM/YYYY HH:mm"),
                        }
                    );
                })
            ]);

        }
        else{
            setCurrentFolder(`${objectType?.toLowerCase()}/${singleEntityRecord?._id}/`);
            setDataSource([]);
        }
    }, [foldersFiles, foldersFilesLoading, documentDetailData?.getDocumentDetailByObjectType?.response, documentDetailDataLoading]);

    // add new folder for specific site if not exist
    const addSiteFolderIfNotExist = async ()=>{
 
        await newFolder({
            variables:{
                
                folderName: `${objectType?.toLowerCase()}/${singleEntityRecord?._id}/`
                
            }
        });


        await refetchFolderFile();
    };

    useEffect(()=>{
        if(!documentDetailDataLoading){

            
            if(dataSource?.find((data)=>data?.isArchived==true)){
                setArchivedDataSource(
                    [...dataSource?.filter((data)=>data?.isArchived==true)]
                )
            }else{
                setRenewableDataSource(
                    [...dataSource?.filter((data)=>data?.isAnyExpiryDate==true)]
                );
                setStandardDataSource(
                    [...dataSource?.filter((data)=>data?.isAnyExpiryDate==false)]
                )

            }


        }
    },[dataSource, documentDetailDataLoading]);


    const [ newFolderVisible ,setNewFolderVisible ] = useState(false);
    const [ moveFolderVisible, setMoveFolderVisible ] = useState(false);

    const dispatch = useDispatch();

    const [deleteFilePermanently, {loading: deleteFilePermanentlyLoading}] = useMutation(deleteFilePermanentlyMutation);

    const handelDeleteFiles = async (id, filePath)=>{
        try{
            const isConfirm = window.confirm("Do you really want to delete this file?");
            if(isConfirm){
                await deleteFilePermanently({
                    variables:{
                        filePath,
                        id
                    }
                });

                await getFolderandFiles({
                    variables:{
                        foldername: currentFolder
                    },
                    skip: !currentFolder,
                    fetchPolicy: 'network-only'
                });
                
                await documentDetailRefetech();

                dispatch(
                    setNotification({
                        error: false,
                        notificationState: true,
                        message: "File deleted successfully"
                    })
                );
            }
            return ;
        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    notificationState: true,
                    message: err.message
                })
            )
        }
    };


    // rename folder
    const [renameFolder, setRenameFolder] = useState(false);
    const [renamedFolder, setRenamedFolder] = useState(null);
    const [renameFolderMutation, {loading: renameFolderMutationLoading}] = useMutation(RenameFolderMutation);

    const handelFolderRename = async (key)=>{
        try{
            console.log(key, "folder key", renamedFolder)
            // here key is the prefix that mean it is the full path of the folder including foldername
            await renameFolderMutation({
                variables:{
                    folderPath: key,
                    newName: renamedFolder
                }
            });

            setRenameFolder(null);
            setRenameFolder(false);


            dispatch(setNotification({
                error: false,
                message: "Folder was rename successfully",
                notificationState: true
            }));

            getFolderandFiles({
                variables:{
                    foldername: currentFolder
                },
                skip: !currentFolder,
                fetchPolicy: 'network-only'
            });

        }catch(err){
            dispatch(setNotification({
                error: true,
                message: err?.message,
                notificationState: true
            }));
        }
    };

    const [getFileUrlToDownload,{data: fileDataURL}] = useLazyQuery(getFileUploadURL);

    const downloadFile = async(filename)=>{
        console.log(filename, "filename grid");
        const file = await getFileUrlToDownload({
            variables:{
                filename: filename
            },
            fetchPolicy: 'network-only'
        });

        const link = document.createElement('a');
        link.href = file?.data?.getFileURL?.response;
        link.target = '_blank';
        link.download = filename || 'download'; // Optional: Set a default filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // refetch directives 
    const refetchDirectives = async ()=>{
        
        await getFolderandFiles({
            variables:{
                foldername: currentFolder
            },
            skip: !currentFolder,
            fetchPolicy: 'network-only'
        });

    };

    //================== table meta data for columns and rows control
    const navigate = useNavigate();
    const param = useParams();

    // delete folder logix
    const [deleteFolder, {loading: deleteFolderLoading}] = useMutation(DeleteFolderMutation)
    const handelDeleteFolder = async(key)=>{
        const isConfirmed = window.confirm("Do you really want to delete this folder?");
        if(isConfirmed){
            const isDeletionConfirmed = window.confirm("If this folder is non-empty you will lost all files \nDo you want to proceed?");
            if (isDeletionConfirmed){

                try{
                    // here key is the prefix that mean it is the full path of the folder including foldername
                    await deleteFolder({
                        variables:{
                            folderPath: key,
                        }
                    });
        
                    setRenameFolder(null);
                    setRenameFolder(false);
        
        
                    dispatch(setNotification({
                        error: false,
                        message: "Folder was deleted successfully",
                        notificationState: true
                    }));
        
                    getFolderandFiles({
                        variables:{
                            foldername: currentFolder
                        },
                        skip: !currentFolder,
                        fetchPolicy: 'network-only'
                    });
        
                }catch(err){
                    dispatch(setNotification({
                        error: true,
                        message: err?.message,
                        notificationState: true
                    }));
                }
            }
            return;
        }
        return;

    }
    
    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);

    const columns = [
        {
            title:'Name',
            dataIndex: 'fakename',
            width:'35%',
            render: (_, record)=>{
                const show = (record?.key == hoveredRow) && record?.size!=="--";
                return(

                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        
                        <span
                        onClick={()=>{
                            record?.size=="--"?  
                            null :
                            navigate("/user/"+objectType?.toLowerCase()+"-detail/"+param?.id+"/tab/"+param?.tab+"/folder/"+encodeURIComponent(record?.id))
                        }}
                        
                        >
                        {record?.size=="--" && renameFolder?.id==record?.id?
                            <>
                                <Input 
                                    value={renamedFolder!=null? renamedFolder : record?.foldername}
                                    placeholder={`Folder name (e.g ${objectType?.toLowerCase()} risk assessment 1)`}
                                    onChange={(e)=>setRenamedFolder(e.target.value)}
                                    style={{marginBottom:'5px'}}
                                />
                                <button className={(renamedFolder==null || renamedFolder?.length<1)? "disabled sm-btn" : "sm-btn"} onClick={()=>handelFolderRename(record?.key)} disabled={renamedFolder?.length<1 || renamedFolder==null} >Submit</button> &nbsp;
                                <button className="sm-btn" onClick={()=> { setRenamedFolder(null); setRenameFolder(false); }}>Cancel</button>
                            </>
                            :
                            record.fakename
                        }
                        </span>
                        
                        {
                            record?.key == hoveredRow && record?.size=="--" && renameFolder==false?
                            <div>
                                <button className="sm-btn"  onClick={()=>setRenameFolder({modal: true, id: record?.id})}>Rename</button>&nbsp;
                                <button className="sm-btn" onClick={()=>handelDeleteFolder(record?.key)}>Delete</button>
                            </div>
                        :
                            <div style={show? {visibility:'visible'} : {visibility:'hidden'} }>
                                <button className="sm-btn" onClick={()=>window.open(window.location.origin + "/user/preview?object="+record?.key,  '_blank')}>Preview</button> &nbsp;
                                <button className="sm-btn" onClick={()=>downloadFile(record?.key)}>Download</button> &nbsp;
                                {
                                    process.env.REACT_APP_systemUserId == authenticatedUserDetail?._id?
                                    <button className="sm-btn" onClick={()=>handelDeleteFiles(record?.id, record?.key)}>Delete</button>
                                    :null
                                }
                            </div>
                        }
                        
                        

                    </div>
                )
            }
        },
        {
            title: 'Size',
            dataIndex: 'size',
        },
        {
            title: 'Expiry Date',
            dataIndex: 'expiryDate',
        },
        // {
        //     title: 'Shares',
        //     dataIndex: 'shares',
        // },
        // {
        //     title: 'Views',
        //     dataIndex: 'views',
        // },
        {
            title: 'Uploaded By',
            dataIndex: 'uploadedBy',
        },
        {
            title: 'Uploaded at',
            dataIndex: 'uploadedAt'
        }
    ];

    const [hoveredRow, setHoveredRow] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);



    const rowClassName = (record) => {
      return record.key === hoveredRow ? 'hovered-row' : '';
    };
      
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
        sessionStorage.setItem('hoverItem', record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
        sessionStorage.removeItem('hoverItem');

    };





    const [newFileDrawer, setNewFileDrawer] = useState(false);

    return(
        <div className='hr-section'>
            
            <div style={{paddingRight:'16px'}}>
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Documents</div>
            
                            
                    <button 
                        onClick={()=>setNewFileDrawer(true)}
                        className='middle-note-btn'
                    > 
                        Upload file
                    </button>
                        
                </div>
            </div>

            

            
            <div className="setting-body-inner grid-tabs" style={{paddingTop:15}}>

                    <Tabs onChange={(e)=>handelTabChange(e)}>
                        <TabPane tab={"Renewable Documents"} key={0} >

                            <Table
                                loading={deleteFolderLoading || renameFolderMutationLoading || foldersFilesLoading || documentDetailDataLoading}
                                columns={columns}
                                dataSource={renewableDataSource}
                                locale={{ emptyText: 'No document uploaded yet' }}
                                className='curvedTable'
                                // rowSelection={rowSelection}
                                onRow={(record) => ({
                                onMouseEnter: () => handleRowMouseEnter(record),
                                onMouseLeave: () => handleRowMouseLeave(),
                                })}
                                rowClassName={rowClassName}
                                title={selectedRowKeys?.length>0 ? () => customHeader : null}
                            />

                        </TabPane>

                        <TabPane tab={"Standard Documents"} key={1}>

                            <Table
                                loading={deleteFolderLoading || renameFolderMutationLoading || foldersFilesLoading || documentDetailDataLoading}
                                columns={columns}
                                dataSource={standardDataSource}
                                locale={{ emptyText: 'No document uploaded yet' }}
                                className='curvedTable'
                                // rowSelection={rowSelection}
                                onRow={(record) => ({
                                onMouseEnter: () => handleRowMouseEnter(record),
                                onMouseLeave: () => handleRowMouseLeave(),
                                })}
                                rowClassName={rowClassName}
                                title={selectedRowKeys?.length>0 ? () => customHeader : null}
                            />
                        </TabPane>


                        <TabPane tab={"Archived Documents"} key={2}>


                            <Table
                                loading={deleteFolderLoading || renameFolderMutationLoading || foldersFilesLoading || documentDetailDataLoading}
                                columns={columns}
                                dataSource={archivedDataSource}
                                locale={{ emptyText: 'No document uploaded yet' }}
                                className='curvedTable'
                                // rowSelection={rowSelection}
                                onRow={(record) => ({
                                onMouseEnter: () => handleRowMouseEnter(record),
                                onMouseLeave: () => handleRowMouseLeave(),
                                })}
                                rowClassName={rowClassName}
                                title={selectedRowKeys?.length>0 ? () => customHeader : null}
                            />

                        </TabPane>


                    </Tabs>

                       
                    
                
            </div>


        {
            newFolderVisible &&
            <NewFolder
            visible={newFolderVisible}
            close={()=>setNewFolderVisible(false)}
            refetch={refetchFolderFile}
            folderId = {currentFolder}
            />
        }

        {
            moveFolderVisible &&
            <MoveFolderModal 
                visible={moveFolderVisible}    
                onClose={()=>setMoveFolderVisible(false)}  
                parentId={singleEntityRecord?.metadata?.folderId}    
                // childFolder={childFolder}  
                selectedKeys={selectedRowKeys}
                refetchFolderFile={refetchFolderFile}
                setSelectedRowKeys={setSelectedRowKeys}
            />

        }

        {
            newFileDrawer && 
            <NewFileDrawer 
                visible={newFileDrawer} 
                close={()=>{setNewFileDrawer(false); refetchDirectives()}}
                entityId={singleEntityRecord?._id}
                folder={currentFolder}
                objectType={objectType}
            />
        }


        </div>

    )
}