import { Dropdown, Form, Input, Modal, Spin, Radio, Space } from "antd";
import "./allView.component.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "@src/components/spinner";
import { CaretDownFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { LoadingOutlined } from '@ant-design/icons';
import { setViewRenderFromAllViewPage } from "../../middleware/redux/reducers/allView.reducer";


export const AllView = ({viewList, loading, objectType, close, updateView, refetch, cloneView, deleteView, handelSelectedView})=>{

    const [search, setSearch] = useState(null);
    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);   
    

    const [standardView, setStandardView] = useState([]);
    const [userView, setUserView] = useState([]);
    const [otherView, setOtherView] = useState([]);
    const [actionView, setActionView] = useState(null);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(setViewRenderFromAllViewPage({status: false, viewToRender:{}}))
    },[]);

    const [views, setViews] = useState([]);

    useEffect(()=>{
        if(search?.length==0 || search==null){
            setViews([...viewList]);
        }else{
            setViews(viewList?.filter((view)=>view?.name?.toLowerCase()?.includes(search?.toLowerCase())))
        }
    },[viewList, search]);

    useEffect(()=>{
        
        setStandardView(
            views?.filter((view)=> view?.isStandard)?.map((list, index)=>({key: list._id, label:list.name, id: list._id, ...list}))
        );

        setUserView(
            views?.filter((view)=> view?.createdBy==authenticatedUserDetail?._id && !view?.isStandard)?.map((list, index)=>({key: list._id, label:list.name, id: list._id, ...list}))
        );
        
        setOtherView(
            views?.filter((view)=> view?.createdBy!==authenticatedUserDetail?._id && !view?.isStandard)?.map((list, index)=>({key: list._id, label:list.name, id: list._id, ...list}))
        );

    },[views]);



    const [modalVisible, setModalVisible] = useState(false);

    const handelDelete = async()=>{
        const response = window.confirm("Do you really want to delete this view?");
        if(response){
            await deleteView({
                variables:{
                    id: actionView?._id
                }
            })
            dispatch(
                setNotification({
                    error: false,
                    notificationState: true,
                    message: "View was deleted successfully"
                })
            );
            sessionStorage.removeItem("selectedViewId");
            setModalVisible(false);
            setActionView(null);
            await refetch();

        }else{
            setModalVisible(false);
            setActionView(null);
            return;
        }
    }


    const otherViewItem = [
        {
            key: '1',
            label: <div className="view-menuitem" onClick={()=>{setModalVisible(true); setActionView({...actionView, action:'clone'}); }}>Clone</div>
        },
        {
            key: '2',
            label: <div className="view-menuitem">Export</div>
        }
    ];

    const viewItem = [
        {
            key: '1',
            label: <div className="view-menuitem" onClick={handelDelete}>Delete</div>
        },
        {
            key: '2',
            label: <div className="view-menuitem" onClick={()=>{setModalVisible(true); setActionView({...actionView, action:'sharing'}); }}>Manage Sharing</div>
        },
        {
            key: '3',
            label: <div className="view-menuitem" onClick={()=>{setModalVisible(true); setActionView({...actionView, action:'rename'}); }}>Rename</div>
        },
        {
            key: '4',
            label: <div className="view-menuitem" onClick={()=>{setModalVisible(true);  setActionView({...actionView, action:'clone'}); }}>Clone</div>
        },
        {
            key: '5',
            label: <div className="view-menuitem">Export</div>
        }
    ];





    return(

        <div className="allViewWrapper">

            <div className='allView-header'>
                <h1>
                    {objectType=="Agency"? "Supplier" :objectType} saved views ({views?.length})
                </h1>
                <div className="right-filter-inner">
                    <Input type="search" 
                        style={{width:'350px'}} 
                        className='generic-input-control' 
                        placeholder="Search views"
                        onChange={(e)=>setSearch(e.target.value)}
                        value={search}
                        autoComplete="off"
                        suffix={search? 
                        <FontAwesomeIcon style={{color:'#7c98b6', cursor:'pointer', fontSize: '20px'}} onClick={()=>{setSearch(null);}} icon={faClose}/> : 
                        <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
                    />
                </div>
            </div>
            {
                loading?
                    <Spinner/>
                :
                    <div className="allview-main">
                        {/* back to link page */}
                        <div className="view-section">
                            <span className="link" onClick={close}>
                                <FontAwesomeIcon icon={faChevronLeft}/> Back to {objectType=="Agency"? "Supplier" :objectType}
                            </span>
                        </div>

                        <div className="border-left view-section">
                            <div className="view-heading">Standard</div>

                            {
                                standardView?.map((view)=>(
                                    <div className="view-body">
                                            <div
                                                className="view-detail"
                                                onClick={()=>handelSelectedView(view)}
                                            >
                                                {view?.label}   
                                            </div>
                                            
                                            <Dropdown
                                                menu={{
                                                    items: otherViewItem,
                                                }}
                                                trigger={"click"}
                                                onClick={()=>setActionView(view)}
                                            >
                                                <Space className="link view-option">
                                                    Options
                                                    <CaretDownFilled />
                                                </Space>
                                            </Dropdown>

                                    </div>
                                ))
                            }


                        </div>

                        <div className="border-left view-section">
                            <div className="view-heading">Created By Me</div>

                            {
                                userView?.map((view)=>(
                                    <div className="view-body">
                                            
                                            <div className="view-detail"
                                                onClick={()=>handelSelectedView(view)}
                                            >
                                                <div>{view?.label}</div>
                                                <div className="view-body-subtitle">
                                                    Created By - <b>{view?.createdByDetail?.firstname} {view?.createdByDetail?.lastname}</b> &nbsp;
                                                    Privilege - <b>{view?.visibility?.toUpperCase()} </b> 
                                                </div>
                                            </div>
                                            
                                            <Dropdown
                                                menu={{
                                                    items: viewItem,
                                                }}
                                                trigger={"click"}
                                                onClick={()=>setActionView(view)}
                                            >
                                                <Space className="link view-option">
                                                    Options
                                                    <CaretDownFilled />
                                                </Space>
                                            </Dropdown>

                                    </div>
                                ))
                            }



                        </div>

                        <div className="border-left view-section">
                            <div className="view-heading">Created By Others</div>

                            {
                                otherView?.map((view)=>(
                                    <div className="view-body">
                                            

                                            <div className="view-detail"
                                                onClick={()=>handelSelectedView(view)}
                                            >
                                                <div>{view?.label}</div>
                                                
                                                <div className="view-body-subtitle">
                                                    Created By - <b>{view?.createdByDetail?.firstname} {view?.createdByDetail?.lastname}</b> &nbsp;
                                                    Privilege - <b>{view?.visibility?.toUpperCase()} </b> 
                                                </div>
                                            </div>
                                            
                                            <Dropdown
                                                menu={{
                                                    items: otherViewItem,
                                                }}
                                                trigger={"click"}
                                                onClick={()=>setActionView(view)}
                                            >
                                                <Space className="link view-option">
                                                    Options
                                                    <CaretDownFilled />
                                                </Space>
                                            </Dropdown>

                                    </div>
                                ))
                            }



                        </div>

                    </div>
            }

            <CreateView 
                visible={modalVisible}
                onClose={()=>{setActionView(null); setModalVisible(false);}}
                data={actionView}
                loading={loading}
                updateView={updateView}
                cloneView={cloneView}
                refetch={refetch}
            />

        </div>
    )
}




export const CreateView = ({visible, onClose, loading, updateView, cloneView, data, refetch }) => {

  const [name, setName] = useState("");
  const [access, setAccess] = useState("");
  const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);   

  const dispatch = useDispatch();

  useEffect(()=>{
    if(data?.action=="rename"){
        setName(data?.name);
    }else if(data?.action=="clone"){
        setAccess(data?.visibility);
        setName(data?.name);
    }else if(data?.action=="sharing"){
        setAccess(data?.visibility);
    }
  }, [data]);

  const handelSave = async ()=>{
    try{

        if(data?.action!=="sharing" && data?.action!=="clone"){
            await updateView({
                variables:{
                    input:{
                        _id: data?._id,
                        name
                    }
                }
            });
        }else if(data?.action=="clone"){
            await cloneView({
                variables:{
                    input: {
                        _id: data?._id,
                        name,
                        visibility: access,
                        createdBy: authenticatedUserDetail?._id,
                    }
                }
            });
        }
        else if(data?.action=="sharing"){
            
            await updateView({
                variables:{
                    input:{
                        _id: data?._id,
                        visibility: access
                    }
                }
            });

        }
        onClose();
        dispatch(setNotification({
            error: false,
            notificationState: true,
            message: "Action performed successfully"
        }));
        await refetch();

    }catch(err){

        dispatch(
            setNotification({
                error: true,
                notificationState: true,
                messsage: err.message
            })
        );
        onClose();
        await refetch();
        
    }
  }

  return (
    <Modal
      open={visible}
      width={480}
      footer={
        <div style={{padding:'26px 40px', textAlign:'left', display:'flex', columnGap:'16px', marginTop:'-25px' }}>
            <button  
              onClick={handelSave}
              disabled={false} 
              className={(false) ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
            >
              {loading? <Spin indicator={<LoadingOutlined/>}/> : "Save"}
            </button>
            <button  disabled={false} className={false? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={onClose}>
              Cancel
            </button>
        </div>
      }
      closable={false}
    >
      
      <React.Fragment>
        {/* {contextHolder} */}
        <div className='modal-header-title'>
            <span>{data?.action=="clone"? "Clone" : "Update"} saved view</span>
            <span  onClick={onClose}><FontAwesomeIcon className='close' icon={faClose}/></span>
        </div>
        <div className='modal-body'>
          <form id="branchForm" className='form'>
            
            {
            data?.action!=="sharing" &&
                <Form.Item>
                    <label>Name </label>
                    <Input className={'input-control'} name="viewName" value={name}  onChange={(e)=>setName(e.target.value)}/>
                </Form.Item> 
            }

            {
                (data?.action=="sharing" || data?.action=="clone") &&
                <Form.Item>
                    <label>Shared with</label><br />
                    <Radio.Group onChange={(e)=>setAccess(e.target.value)} value={access} >
                        <Radio value={"private"}>Private</Radio> <br />
                        <Radio value={"team"}>My team</Radio><br />
                        <Radio value={"public"}>Everyone</Radio>
                    </Radio.Group>
                </Form.Item>    
            }
          </form>
        </div>  
      </React.Fragment>  
    </Modal>
  );
};

