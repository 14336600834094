import dayjs from "dayjs";

export function findPayDate(dates) {
    let sameDate;
    if(Object.isFrozen(dates)){
        sameDate = Array.isArray(dates)? [...dates]?.find((date)=> dayjs(date?.effectivedate).isSame(dayjs(), 'day')) : {};

    }else{
        sameDate = Array.isArray(dates)? dates?.find((date)=> dayjs(date?.effectivedate).isSame(dayjs(), 'day')) : {};
    }
    
    if(sameDate && Object.keys(sameDate)?.length>0){
        if(sameDate?.rate){
            return sameDate?.rate+"/h";
        }else {
            return 0;
        }

    }else{

        let isBeforeDate;
        if(Object.isFrozen(dates) && Array.isArray(dates)){
            isBeforeDate = [...dates]?.sort((a,b) => dayjs(b?.effectivedate) - dayjs(a?.effectivedate))?.find((date)=> dayjs(date?.effectivedate).isBefore(dayjs(), 'day') );
        
        }else{
            isBeforeDate = dates?.sort((a,b) => dayjs(b?.effectivedate) - dayjs(a?.effectivedate))?.find((date)=> dayjs(date?.effectivedate).isBefore(dayjs(), 'day') );

        }

        if(isBeforeDate && isBeforeDate?.rate){
            return isBeforeDate?.rate+"/h";
        }else{
            return 0;
        }

    }
   
}