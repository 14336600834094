import { faChevronDown, faChevronUp, faClose, faExternalLinkAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Input, InputNumber, Modal, Popover, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { CaretDownFilled } from '@ant-design/icons';
import Spinner from "@src/components/spinner";
import { useParams } from 'react-router-dom';


export const RateAdjustmentModal = ({visible, close, data, handleSave, moduleId}) => {

    const [rateType, setRateType] = useState(null);
    const [baseRate, setBaseRate] = useState(0);
    const [adjustmentName, setAdjustmentName] = useState(""); 
    const [currentColumn, setCurrentColumn] = useState("");
    const [payLevelId, setPayLevelId] = useState(null);

    useEffect(()=>{
        const {currentColumn, payLevel, isBillOrPay, baseRate, columnName, currentColumnValue} = data;
        
        // set rate type to check either the column belongs from pay category or bill category
        setBaseRate(baseRate);
        setRateType(isBillOrPay);
        setPayLevelId(payLevel?._id);
        setAdjustmentName(columnName);
        setCurrentColumn(currentColumn);

        if(currentColumnValue){
            setRateMultiplier(currentColumnValue?.multiplier);
            if(currentColumnValue?.multiplier=="custom"){
                setCustomRate(parseFloat(currentColumnValue?.rate))
            }
        }

    },[data]);


    const [rateMultiplier, setRateMultiplier] = useState(null);
    const [customRate, setCustomRate] = useState(null);
    const [finalRate, setFinalRate] = useState(null);


    useEffect(()=>{
        if(rateMultiplier=="custom" && customRate>-1){
            const rate = parseFloat(baseRate) + parseFloat(customRate);
            setFinalRate(rate);

        }else if(rateMultiplier!=null && rateMultiplier!="custom"){
            const rate = parseFloat(baseRate) + (parseFloat(baseRate) * (parseFloat(rateMultiplier) / 100 ));
            setFinalRate(rate);
        }else{
            setFinalRate(null);
        }
    },[rateMultiplier, customRate]);

    const param = useParams();

    const save = ()=>{

        const input = {
            [moduleId]: param?.id,
            payLevelId: payLevelId,
            object: {
                key: currentColumn,
                value: {
                    multiplier: rateMultiplier,
                    rate: customRate
                }
            }
        }

        handleSave(input);
        close();
    };



    return (
        
            <Modal
                open={visible}
                width={600}
                footer={
                    <div className='drawer-footer'>
                        <button  
                            disabled={false} 
                            className={false ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                            onClick={save}
                        >
                            {false? <Spinner/>: "Save"}
                        </button>
                        <button  disabled={false} className={false? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={close}>
                            Cancel
                        </button>
                    </div>
                }
                closable={false}
            >

                <React.Fragment>
                    <div className='modal-header-title'>
                        <span> {rateType} Setup </span>
                        <span onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                    </div>


                    <div className='modal-body'>
                        {
                            (finalRate!=null &&  !Number.isNaN(finalRate)) &&
                            <b>{adjustmentName} Will be £ {finalRate.toFixed(2)}</b>
                        }

                        <Form className='paysetup'>
                            <Form.Item>
                                <label>
                                    Current Base Rate
                                </label>
                                <Input 
                                    className='generic-input-control'
                                    value={baseRate}
                                    readOnly
                                />
                            </Form.Item>

                            <Form.Item>
                                <label>
                                Adjust {adjustmentName} Multiplier  &emsp;
                                <Popover
                                        content={
                                            <div>
                                                <div className="text" style={{color:'white'}}>
                                                The Final rate would be the base rate + (multiplier / customer rate)
                                                </div>
                                                <b>Final Rate = Base Rate + (Multiplier OR Custom Rate)</b>
                                            </div>

                                        }
                                >
                                        <FontAwesomeIcon icon={faInfoCircle} style={{fontSize:'18px', cursor:'pointer', }}/>
                                </Popover>
                                </label>

                                <Select 
                                    className='custom-select paysetup-select'
                                    placeholder="Select Multiplier"
                                    suffixIcon={<CaretDownFilled style={{marginTop:'-5px'}}/>}
                                    onChange={(e)=>setRateMultiplier(e)}
                                    value={rateMultiplier}
                                    allowClear
                                >
                                    
                                    <Option value="custom" style={{fontWeight:'bold', color:'#0091ae', cursor:'pointer'}}>
                                        <span>
                                            Custom Rate <FontAwesomeIcon icon={faExternalLinkAlt} /> 
                                        </span>
                                    </Option>

                                    <Option value="50">
                                        Half (50%)
                                    </Option>

                                    <Option value="100">
                                        Double (100%)
                                    </Option>

                                    <Option value="150">
                                        Time and Half (150%)
                                    </Option>

                                </Select>


                            </Form.Item>

                            {
                                rateMultiplier=="custom" &&
                                <Form.Item>
                                    <label>Custom rate</label>
                                    <InputNumber        
                                        value={customRate}           
                                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                        downHandler={<FontAwesomeIcon  style={{color:'#0091ae'}} icon={faChevronDown} />}
                                        className="generic-input-control"
                                        onChange={(e)=>setCustomRate(e)}

                                    />
                                </Form.Item>

                            }

                        </Form>

                    </div>

                </React.Fragment>



            </Modal>

    )
}
