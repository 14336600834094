
import { useMutation, useQuery } from "@apollo/client";
import { faChevronLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CustomerDetailPageLeftSideBar } from "./component/leftSidebar";
import { SiteGroupPostSection } from "./component/middleSection";
import { getSingleCustomerQuery } from "@src/util/query/customer.query";
import { CustomerPayTable } from "../customerpayTable/customerPayTable";
import { EditLeftSideView } from "./editLeftSideView";
import { getCustomerDetailViewFieldQuery } from "@src/util/query/customerDetailViewField.query";
import { useSelector } from "react-redux";
import { updateCustomerDetailViewFieldMutation } from "@src/util/mutation/customerDetailViewField.mutation";
import { HolderOutlined } from "@ant-design/icons";
import { deleteCustomerDetailViewFieldMutation } from "@src/util/mutation/customerDetailViewField.mutation";
import { CustomerNotesTab } from "./customerNotes";
import { getNote } from "@src/util/query/note.query";
import { objectType } from "@src/util/types/object.types";
import { CustomerProfileTab } from "./component/customerProfile.tab";
import { ShareDocument } from "@src/components/document/shareDocument.component";
import { DocumentComponent } from "@src/components/document/document.component";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};


export const CustomerDetailPage = ()=>{

    const navigate = useNavigate();

    const customertabs = [
        // {title:'Post', url:'general'},
        {title:'Profile', url:'profile'},
        {title:'Pay Table', url:'paytable'},
        {title:'Notes', url:'note'},
        {title:'Documents', url:'document'},
    ];

    const param = useParams();
    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);
    const [activeTab, setActiveTab] = useState(param?.tab);

    useEffect(()=>{
        if(!param?.edit && !param?.tab){
            setActiveTab("profile");
        }
    },[param]);


    // site group details
    const {data: singleCustomer, loading: singleCustomerLoading, refetch} = useQuery(getSingleCustomerQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            id: param?.id
        }
    });

    const [customer, setCustomer] = useState({});

    useEffect(()=>{
        if(!singleCustomerLoading && singleCustomer && singleCustomer?.customer){
            const {customername, metadata} = singleCustomer?.customer;
            setCustomer({customername, ...metadata});
        }
    },[singleCustomer,singleCustomerLoading]);

    
    const [isFieldChanged, setIsFieldChanged] = useState(false);
    const [saveUpdate, setSaveUpdate] = useState(false);

    const [editableProp, setEditableProp] = useState([]);

    const {data:customerDetailViewData, loading: getSiteDetailViewDataLoading, refetch: customerDetailViewRefetch} = useQuery(getCustomerDetailViewFieldQuery,{
        variables:{
            userId: userDetail?._id
        }
    });

    
    const [updateCustomerViewField, {loading: updateCustomerViewFieldLoading}] = useMutation(updateCustomerDetailViewFieldMutation);

    const [deleteCustomerViewField, {loading: deleteCustomerDetailViewField}] = useMutation(deleteCustomerDetailViewFieldMutation)

    useEffect(()=>{
        if(customerDetailViewData?.getCustomerDetailViewField?.response){
            setEditableProp(customerDetailViewData?.getCustomerDetailViewField?.response);

        }
    },[customerDetailViewData?.getCustomerDetailViewField?.response]);


    const onDragEndForLeftCustomise = async (result) => {
        // dropped outside the list
        if (!result.destination) {
        return;
        }
        
        const reorderedArr = reorder(
            editableProp,
            result.source.index,
            result.destination.index
            );
        
        
        setEditableProp(reorderedArr);
        
        await updateCustomerViewField({
            variables:{
                input:{
                   ids: reorderedArr?.map((prop)=> prop?._id)
                }
            }
        });

    };



    
    const { data:note , loading: noteLoading, refetch: noteRefetch} = useQuery(getNote,{
        variables:{
            createdFor: param?.id,
            objectType: objectType?.Agency
        },
        fetchPolicy:'network-only',
    });


    return(
        <div>
            {/* site group tabs */}

            <div style={{background: 'rgb(245, 248, 250)', padding: '15px 45px 7px 15px', display:'flex', gap:50, alignItems: 'center', position: 'fixed',  width: '100%', zIndex: '996'}}>
                
                {/* back + user name btn */}
                <div style={{display:'flex', alignItems:'center', gap:'25px', paddingBottom:'8px', width:'50%'}}>

                    <div onClick={()=>navigate("/user/customer")} >
                        <FontAwesomeIcon className='left-chevron-icon' icon={faChevronLeft}/> <span className='text-deco' style={{left: '5%', position: 'relative', fontSize:'14px'}}>Back</span> 
                    </div>
                    <div style={{fontSize:'14px'}}>
                        Customer
                    </div>
                </div>
                
                {param?.edit==="edit"?
                    <>
                        <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', width:'100%'}}>
                           <b>Customise Mode</b> 
                        </div>

                        <div style={{width:'50%', textAlign:'right', marginRight: '35px', cursor:'pointer'}}>
                            <FontAwesomeIcon icon={faTimes} 
                                className="icon-bound"
                                style={{boxShadow:'none', fontSize:'15px', borderRadius:'15px', padding:'6px 8px', cursor:'pointer'}}
                                onClick={async ()=>{ navigate("/user/customer-detail/"+param?.id); await customerDetailViewRefetch(); }}
                            />
                        </div>
                    </>

                    :

                    <>
                        {/* navigation tabs */}
                        <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', width:'100%'}}>
                            {customertabs?.map((tab)=>(
                                <div className={activeTab==tab?.url? 'emp-menubar emp-menubar-active': 'emp-menubar'} onClick={()=>{
                                    navigate("/user/customer-detail/"+param?.id+"/tab/"+tab?.url); setActiveTab(tab.url); 
                                }}>{tab?.title}</div>
                            ))}
                        </div>

                        <div style={{width:'50%'}}></div>
                    </>
                }


            </div>

            {param?.edit==="edit"?
            // {/* edit left side view for data fields */}
                <div style={{padding:'50px 5px 5px 5px'}}>
                        <div className="grid-layout">
                            <div className="grid-25">
                                <CustomerDetailPageLeftSideBar
                                    customer={customer}
                                    loading={singleCustomerLoading}
                                    setIsFieldChanged={setIsFieldChanged}
                                    saveUpdate={saveUpdate}
                                    setSaveUpdate={setSaveUpdate}
                                    refetch={refetch}
                                    isFieldChanged={isFieldChanged}
                                    showDetails={false}
                                />

                                {/* all editable tabs will be here */}
                                <DragDropContext onDragEnd={onDragEndForLeftCustomise}>
                                    <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        className="tab-grp"
                                        style={{
                                            width:'auto',
                                            border: '1px solid #ECEFEC',
                                            borderRadius: '12px',
                                            padding: '14px',
                                            marginTop: '10px',
                                            gap: 0,
                                        }}
                                        >
                                        {editableProp?.map((item, index) => (
                                            <Draggable key={item._id} draggableId={item._id} index={index}
                                            
                                            >
                                            {(provided, snapshot) => (
                                                <div className="icon-wrapper">
                                                
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    id={"item-"+index}
                                                    itemRef={"item-"+index}
                                                    key={item?._id}
                                                    // className=
                                                    style={{
                                                    opacity: snapshot.isDragging ? 0.5 : 1,
                                                    ...provided.draggableProps.style
                                                    }}
                                                    className={"holderwrap"}
                                                >
                                                    <li style={{direction:'ltr', justifyContent:'space-between'}}>
                                                        <span>

                                                            <HolderOutlined className="holder"/>
                                                            {item?.propertyDetail?.label}
                                                        </span>
                                                        {/* <FontAwesomeIcon icon={faTimes}
                                                            onClick={async()=>{ await deleteCustomerViewField({
                                                                variables:{
                                                                    userId: userDetail?._id,
                                                                    propertyId: item?.propertyDetail?._id,
                                                                    
                                                                }
                                                            }); await customerDetailViewRefetch();}}
                                                        /> */}
                                                    </li>
                                                    
                                                </div>
                                                </div>

                                            )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                        </div>
                                    )}
                                    </Droppable>
                                </DragDropContext>

                                {/* Static Tabs in Operation */}
                                
                                

                            </div>
                            <div className='grid-50'>
                                <EditLeftSideView 
                                
                                />
                            </div>
                            
                            {/* right side */}

                            <div className="grid-25">
                                
                            </div>
                        </div>
                </div>
            :
                <div style={{padding:'50px 5px 5px 5px'}}>
                    

                    {
                        activeTab=='profile'?
                        <CustomerProfileTab />
                        :
                    
                        
                        <div  className="grid-layout">
                            <div className="grid-25">
                                <CustomerDetailPageLeftSideBar
                                    customer={customer}
                                    loading={singleCustomerLoading}
                                    setIsFieldChanged={setIsFieldChanged}
                                    saveUpdate={saveUpdate}
                                    setSaveUpdate={setSaveUpdate}
                                    refetch={refetch}
                                    isFieldChanged={isFieldChanged}
                                    showDetails={true}
                                />
                            </div>

                            <div className="grid-75">
                            {activeTab=='general'?
                                <SiteGroupPostSection/>
                                :
                                activeTab=='paytable'?
                                <CustomerPayTable id={param?.id} />
                                :
                                activeTab=='note' ?
                                <CustomerNotesTab note={note?.getNote?.response} noteLoading={noteLoading} noteRefetch={noteRefetch} />
                                :
                                activeTab=="document"?
                                
                                        
                                            param?.documentId?

                                            <ShareDocument />

                                            :

                                            <DocumentComponent 
                                                singleEntityRefetch={refetch} 
                                                singleEntityRecord={singleCustomer?.customer} 
                                                objectType={objectType.Customer}
                                            />
                                        

                                :     
                                null
                            }
                            </div>
                        </div>
                    
                    }
                        
                </div>
            }

            {isFieldChanged ?
                <div className='hr-action-footer'>
                    <button disabled={singleCustomerLoading} className={singleCustomerLoading? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>setSaveUpdate(true)}>Save</button>
                    <button disabled={singleCustomerLoading} className={singleCustomerLoading? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} onClick={async()=> await refetch()}>Cancel</button>
                    {
                        <span className='text' style={{margin: 0}}>You've changed field value</span>
                    }
                </div>
            : null}

        </div>
    )
};