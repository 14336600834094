
export const dutyReportToTemplateMapping = 
    // key is template id and value is report template route id
    {
        "wardSecurity" : {
            templateId: "67868e4a44e2e036f8eb5969",
            route: "/wardreport/"
        }

    };


