
import { ReactComponent as SupportSvg } from '@src/assets/svg/support.svg';
import { ReactComponent as CelebrateSvg } from '@src/assets/svg/celebrate.svg';
import { ReactComponent as LoveSvg } from '@src/assets/svg/love.svg';
import { ReactComponent as LikeSvg } from '@src/assets/svg/like.svg';


export const PostReactionList = {
    'Like': {
        title: 'Like',
        icon: <LikeSvg  width={16} />
    }, 
    'Support':{
        title: 'Support',
        icon: <SupportSvg  width={16} />
    }, 
    'Celebrate':{
        title: 'Celebrate',
        icon: <CelebrateSvg  width={16} />
    }, 
    'Love':{
        title: 'Love',  
        icon: <LoveSvg  width={16} />
    }
};