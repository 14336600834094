import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Modal, Popover, Select, Slider } from 'antd';
import Spinner from "@src/components/spinner";
import { faChevronDown, faChevronUp, faClose, faExternalLinkAlt, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { getSingleEmployeeRecord } from '@src/util/query/employee.query';
import { findPayDate } from '@src/util/findPayDate/findPayDate';
import { CaretDownFilled } from '@ant-design/icons';
import { updateEmployeeMutation } from "@src/util/mutation/employee.mutation";
import { useDispatch } from 'react-redux';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';


export const RateMultiplierModal = ({close, visible, propertyDetail, singleEmployeeRefetch,value=""}) => {
    const params  = useParams();
    const entityId = params?.id;
    const [baseRate, setBaseRate] = useState(0);


    // get single employee Record
    const [getSingleEmployee] = useLazyQuery(getSingleEmployeeRecord);


    const getEntityBaseRate = async (propertyDetail, entityId)=>{
        switch(propertyDetail?.objectType){
            case "Employee":
                const empRes = await getSingleEmployee({
                    variables:{
                        id: entityId
                    }
                });
                if(empRes?.data?.singleEmployee?.response?.metadata?.payrate){
                    let payrates = (empRes?.data?.singleEmployee?.response?.metadata?.payrate);
                    setBaseRate(parseFloat(findPayDate(payrates)))
                }
            
        }
    }

    useEffect(()=>{
        if(propertyDetail && entityId){
            getEntityBaseRate(propertyDetail, entityId);
        }
    },[propertyDetail, entityId]);

    const [rateMultiplier, setRateMultiplier] = useState(null);
    const [customRate, setCustomRate] = useState(null);
    const [finalRate, setFinalRate] = useState(null);

    useEffect(()=>{
        if(rateMultiplier=="custom" && customRate>-1){
            const rate = parseFloat(baseRate) + parseFloat(customRate);
            setFinalRate(rate);

        }else if(rateMultiplier!=null && rateMultiplier!="custom"){
            const rate = parseFloat(baseRate) + (parseFloat(baseRate) * (parseFloat(rateMultiplier) / 100 ));
            setFinalRate(rate);
        }else{
            setFinalRate(null);
        }
    },[rateMultiplier, customRate]);


    const [updateEmployee, {loading: updateEmpLoading}]  = useMutation(updateEmployeeMutation);


    const dispatch = useDispatch();
    const handelpayrate = async()=>{
        try{

            const name = propertyDetail?.label?.replaceAll(" ", "").toLowerCase();
            await updateEmployee({
                variables:{
                    input:{
                        _id: entityId,
                        properties: [{name, value: {
                            multiplier: rateMultiplier,
                            rate: customRate
                        } , metadata:1}]
                    }
                }
            });
            await singleEmployeeRefetch();
            dispatch(setNotification({
                error: false,
                message: "Rate Changed successfully",
                notificationState: true
            }));
            close();

        }catch(err){

            dispatch(setNotification({
                error: true,
                message: "An error encountered while setting up the rate",
                notificationState: true
            }));
            close();

        }
    };

    useEffect(()=>{
        if(value && (typeof value) =="object" && Object.keys(value)?.length>0){
            setRateMultiplier(value?.multiplier);
            if(value?.multiplier=="custom"){
                setCustomRate(value?.rate)
            }
        }
    },[value]);

    return (
        <Modal
            open={visible}
            width={600}
            footer={
                <div className='drawer-footer'>
                    <button  
                        onClick={handelpayrate}
                        disabled={finalRate==null || updateEmpLoading} 
                        className={finalRate==null || updateEmpLoading ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} 
                    >
                        {updateEmpLoading? <Spinner/>: "Save"}
                    </button>
                    <button  disabled={updateEmpLoading} className={updateEmpLoading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={close}>
                        Cancel
                    </button>
                </div>
            }
            closable={false}
        >

            <React.Fragment>
                <div className='modal-header-title'>
                    <span> Pay Setup </span>
                    <span onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>


                <div className='modal-body'>
                    {
                        (finalRate!=null &&  finalRate!="NaN") &&
                        <b>{propertyDetail?.label} Will be £ {finalRate.toFixed(2)}</b>
                    }

                    <Form className='paysetup'>
                        <Form.Item>
                            <label>
                                Current Base Rate
                            </label>
                            <Input 
                                className='generic-input-control'
                                value={baseRate}
                                readOnly
                            />
                        </Form.Item>

                        <Form.Item>
                            <label>
                               Adjust {propertyDetail?.label} Multiplier  &emsp;
                               <Popover
                                    content={
                                        <div>
                                            <div className="text" style={{color:'white'}}>
                                            The Final rate would be the base rate + (multiplier / customer rate)
                                            </div>
                                            <b>Final Rate = Base Rate + (Multiplier OR Custom Rate)</b>
                                        </div>

                                    }
                               >
                                    <FontAwesomeIcon icon={faInfoCircle} style={{fontSize:'18px', cursor:'pointer', }}/>
                               </Popover>
                            </label>

                            <Select 
                                className='custom-select paysetup-select'
                                placeholder="Select Multiplier"
                                suffixIcon={<CaretDownFilled/>}
                                onChange={(e)=>setRateMultiplier(e)}
                                value={rateMultiplier}
                            >
                                
                                <Option value="custom" style={{fontWeight:'bold', color:'#0091ae', cursor:'pointer'}}>
                                    <span>
                                        Custom Rate <FontAwesomeIcon icon={faExternalLinkAlt} /> 
                                    </span>
                                </Option>

                                <Option value="50">
                                    Half (50%)
                                </Option>

                                <Option value="100">
                                    Double (100%)
                                </Option>

                                <Option value="150">
                                    Time and Half (150%)
                                </Option>

                            </Select>


                        </Form.Item>

                        {
                            rateMultiplier=="custom" &&
                            <Form.Item>
                                <label>Custom rate</label>
                                <InputNumber        
                                    value={customRate}           
                                    upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                                    downHandler={<FontAwesomeIcon  style={{color:'#0091ae'}} icon={faChevronDown} />}
                                    className="generic-input-control"
                                    onChange={(e)=>setCustomRate(e)}

                                />
                            </Form.Item>

                        }

                    </Form>

                </div>


            </React.Fragment>


        </Modal>
    );

}


