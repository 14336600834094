import { FormOutlined, MessageOutlined, PhoneOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, DatePicker, Divider, Drawer, Input, Popover, Table, Tabs } from "antd";
import "./employeeDetail.css";
import TabPane from "antd/es/tabs/TabPane";
import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { getScheduleBySiteIdAndEmployeeIdQuery } from "@src/util/query/schedule.query";
import { faArrowsSpin, faArrowUpFromBracket, faBackward, faBackwardFast, faBookJournalWhills, faBookOpen, faCancel, faCaretLeft, faCaretRight, faClone, faClose, faCopy, faEllipsisVertical, faForward, faForwardFast, faLocationDot, faPencil, faPersonArrowUpFromLine, faPersonChalkboard, faPersonCircleCheck, faPersonCirclePlus, faPersonCircleQuestion, faPersonCircleXmark, faPlus, faPlusCircle, faRemove, faSearch, faSearchPlus, faTrashCanArrowUp, faUserPlus,  } from "@fortawesome/free-solid-svg-icons";
import { ScheduleStatusType } from "@src/util/types/scheduleStatus";

export const EmployeeDetailModal = ({employeeScheduleDetailModal, close})=>{

    const {visible, employee, siteId} = employeeScheduleDetailModal;

    return (
        <Drawer
            title={<EmployeeDetailTitle employee={employee} />}
            placement="right"
            className="empDetailScheduleModal"
            closable={true}
            onClose={close}
            closeIcon={<FontAwesomeIcon icon={faClose} onClick={close} className='close-icon'/>}
            open={visible}
            width={700}
            mask={true}
            maskClosable={false}
            maskClassName='filtermask'
            footer={null}
      >

            <>

                <div className='editFieldTabs employeeScheduleDetailTab' >
                    <Tabs defaultActiveKey="1">

                        <TabPane tab={`General`} key="1">


                            <EmployeeScheduleTable employee={employee} siteId={siteId} />


                        </TabPane>

                        <TabPane tab={`Time Approval`} key="2">
                            
                        </TabPane>

                        <TabPane tab={`Declined`} key="3">
                            
                        </TabPane>

                        <TabPane tab={`Others`} key="4">
                            
                        </TabPane>

                    </Tabs>
                </div>

            </>

        </Drawer>
    );
}



const EmployeeDetailTitle = ({employee})=>{
    return(
        <div className="employeeDetailScheduleTitle">


            <div>
                
                {
                employee?.metadata?.imageURL?
                    <Avatar 
                        size={90} 
                        src={employee?.metadata?.imageURL} 
                    />    

                    :
                    <Avatar size={90}> {employee?.firstname?  employee?.firstname[0]+(employee?.lastname? employee?.lastname[0] : "") : ""} </Avatar>    
                }  

            </div>

                {/* employee detail name */}
            <div className="employeeDetailScheduleTitleDetail">

                <div style={{height:'60px'}}>
                    <h3>{employee?.firstname + " " + (employee?.lastname || "")}</h3>
                </div>

                <div className='text-subtitle'>


                    <div className="activity-btn-grp" style={{justifyContent:'center'}}>
                        
                        <Popover
                            content={"Make a phone call"}
                        >
                            <span>
                                <button className=''>
                                    <PhoneOutlined style={{transform:'rotate(-260deg)'}}/>
                                </button>
                                <span className='tiny-text '>Call</span>
                            </span>
                        </Popover>

                        
                        <Popover
                            content={"Start conversation"}
                        >
                            <span>
                                <button className=''>
                                    <MessageOutlined/>
                                </button>
                                <span className='tiny-text '>Chat</span>
                            </span>
                        </Popover>


                        
                        <Popover content={"Follow this"} >
                            <span>
                                <button className=''>
                                    <UserAddOutlined />
                                </button>
                                <span className='tiny-text '>Follow</span>
                            </span>
                        </Popover>
                    </div>

                </div>

            </div>


        </div>
    );
}

const EmployeeScheduleTable = ({employee, siteId})=>{
    
    console.log(employee?._id, "employeeScheduke", siteId);

    const [dateRange, setDateRange] = useState([]);

    const handelDateChange = (date, dateString)=>{
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
        // localStorage.setItem("scheduleDate", JSON.stringify(dates));

    };

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    
    useEffect(()=>{
        const isStoredDateExist = localStorage.getItem("scheduleDate");
        if(isStoredDateExist){
            const storedDate = JSON.parse(isStoredDateExist);
            const startOfWeek = dayjs(storedDate[0]).startOf('week'); // Get start of the week
            const endOfWeek = dayjs(storedDate[0]).endOf('week'); // Get end of the week
            // set start and end date for schedule
            setStartDate(startOfWeek);
            setEndDate(endOfWeek);
            setDateRange([...storedDate?.map((date)=> dayjs(date))]);

        }else{

            const dateString = dayjs();
            const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
            const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
            // set start and end date for schedule
            setStartDate(startOfWeek);
            setEndDate(endOfWeek);

            const dates = [];
            for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
                dates.push(day); // Format dates as YYYY-MM-DD
            };
            setDateRange([...dates]);

        }
    }, []);

    const nextWeek = ()=>{
        const dateString = dayjs(dateRange[0]).add(1, 'week');
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
        // localStorage.setItem("scheduleDate", JSON.stringify(dates));

    };

    const previousWeek = ()=>{
        const dateString = dayjs(dateRange[0]).subtract(1, 'week');
        const startOfWeek = dayjs(dateString).startOf('week'); // Get start of the week
        const endOfWeek = dayjs(dateString).endOf('week'); // Get end of the week
        setStartDate(startOfWeek);
        setEndDate(endOfWeek);
        const dates = [];
        for (let day = startOfWeek; day.isBefore(endOfWeek) || day.isSame(endOfWeek, 'day'); day = day.add(1, 'day')) {
          dates.push(day); // Format dates as YYYY-MM-DD
        };
        setDateRange([...dates]);
        // localStorage.setItem("scheduleDate", JSON.stringify(dates));
    };



    // get schedule of specific employee for specific site for one week
    const {data: employeeSchedule, loading: employeeScheduleLoading} = useQuery(getScheduleBySiteIdAndEmployeeIdQuery,{
        variables:{
            endDate: endDate,
            startDate: startDate,
            siteId: siteId,
            employeeId: employee?._id,
        },
        fetchPolicy: 'network-only',
        skip: (!endDate || !startDate || !siteId || !employee?._id)
    });

    const [employeeScheduleDetail, setEmployeeScheduleDetail] = useState([]);
    const [totalHours, setTotalHours] = useState(0);

    useEffect(()=>{
        if(employeeSchedule?.getScheduleBySiteIdAndEmployeeId?.response?.length>0){
            let totalH=0;

            setEmployeeScheduleDetail(employeeSchedule?.getScheduleBySiteIdAndEmployeeId?.response?.map((job)=>{
                totalH = totalH + (+job?.firstDateHours + (+job?.secondDateHours||0));
                return(
                    {
                        day: 
                            <div>
                                <b>{dayjs(job?.firstDate).format('ddd')}</b>
                                <div className="text">{dayjs(job?.firstDate).format('DD/MM')}</div>
                            </div>,

                        description: <DutyDescription job={job} />,

                        hours:
                            <div>
                                <b>HRS</b>
                                <div className="text">{+job?.firstDateHours + (+job?.secondDateHours||0)}</div>
                            </div>

                    }
                );
            }));

            setTotalHours(totalH);

        }else{

            setEmployeeScheduleDetail([]);
            setTotalHours(0);

        }
    },[employeeSchedule?.getScheduleBySiteIdAndEmployeeId?.response]);


    const columns = [
        {title: '', dataIndex:'day', width:'30%'},
        {title: '', dataIndex:'description', width:'40%'},
        {title: '', dataIndex:'hours', width:'30%', },
    ];

    return(
        <div className="detailModalBox">
            <div className="title mb16" style={{textAlign:'left'}} >
                Schedule Calendar
            </div>


            <div style={{display:'flex', gap:'20px', margin: 'auto', textAlign:'center', width:'100%'}}>

                <div className="form-grp" style={{display:'flex', height:'40px'}}>

                    <div style={{display:'inline-block', position:'relative', fontWeight:'400'}}>
                                    
                        <Input 
                            readOnly
                            disabled
                            className="generic-input-control"
                            style={{cursor:'pointer', height:'30px', textAlign:'center', color:'black', fontWeight:'400'}}
                            value={dateRange?.length>0? dayjs(dateRange[0]).format('DD MMM') +" - "+ dayjs(dateRange[dateRange?.length-1]).format('DD MMM, YYYY') : null}
                        />
                        <div style={{position:'absolute', top:'0', width:'100%', zIndex:'1', opacity:'0'}}>
                            <DatePicker className="generic-input-control" onChange={handelDateChange} />
                        </div>


                    </div>

                    <span className="icon-btn" onClick={previousWeek}>
                        <FontAwesomeIcon icon={faCaretLeft}  style={{fontSize:'18px'}}/> &nbsp;
                    </span>
                    <span className="icon-btn" onClick={nextWeek} >
                        <FontAwesomeIcon icon={faCaretRight} style={{fontSize:'18px'}}/>
                    </span>

                </div>

                <div className="icon-bound" onClick={()=>handelDateChange(null, dayjs())}
                    style={{width:'60px', display:'flex', alignItems:'center', justifyContent:'center'}}
                >
                    Today
                </div>

                <div className="icon-bound" onClick={()=>handelDateChange(null, dayjs())}
                    style={{width:'60px', display:'flex', alignItems:'center', justifyContent:'center'}}
                    title="Total hours in selected week"
                >
                    {totalHours?.toString()?.split(".").join(" : ")}
                </div>

            </div>

            <div>
                <Table 
                    columns={columns}
                    dataSource={employeeScheduleDetail}
                    loading={employeeScheduleLoading}
                    locale={{emptyText:'No schedule found in selected week'}}
                />
            </div>




        </div>
    )
}


const DutyDescription = ({job})=>{
    const [hoveredJob, setHoveredJob] = useState("");
    useEffect(()=>{
        return ()=>{
            setHoveredJob("");
        }
    },[]);

    const [dutyPopoverVisibility, setDutyPopoverVisibility] = useState(null);


    const popoverRef = useRef(null);
    const dutyRef = useRef(null);

    useEffect(() => {
        // Function to handle clicks outside the box
        const handleClickOutside = (event) => {
        
            if (popoverRef?.current && !popoverRef?.current?.contains(event?.target)) {
                // Perform your desired action here
                setDutyPopoverVisibility(null);
            }

        };
    
        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return(
        <div style={{display:'flex', gap:'1px', flexDirection:'column'}}>
            {/* duty time and schedule status */}
            <div className="duty-time">
                {/* shift time */}
                <div className="schedule-time">
                    { 
                        
                        job?.shiftStartTime
                        
                        + "-" + 
    
                        job?.shiftEndTime

                    }
                </div>

                {/* status */}
                <div className={
                    
                    job?.isDutyCancel? 'openDuty schedule-status' :
                    job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.clocking==2 && job?.checkCall[0]?.totalCalls!==0? 
                    // reporting initated here
                    job?.reporting? 
                        (job?.isReportSubmitted==true? "completedDuty schedule-status" :  "pendingDuty schedule-status" ) : 
                        "completedDuty schedule-status" 
                    // reporting terminated here
                        
                    :  job?.checkCall[0]?.totalCalls==0?
                        'openDuty schedule-status'

                    : (job?.checkCall[0]?.totalCalls > job?.checkCall[0]?.fulfilledCalls && job?.checkCall[0]?.fulfilledCalls>0)?
                        'confirmDuty schedule-status'
                    :
                    job?.status==0?'openDuty schedule-status':
                    job?.status==1?'confirmDuty schedule-status':
                    job?.status==2?'unConfirmDuty schedule-status':
                    job?.status==3?'unallocatedDuty schedule-status'
                    : null

                }
                    
                >
                    
                    {
                        job?.isDutyCancel?
                            "Cancelled"
                        :
                        (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.clocking==2 && job?.checkCall[0]?.totalCalls!==0)? 
                        // reporting initated here
                        job?.reporting? 
                            (job?.isReportSubmitted==true? "Completed" :  "Pending" ) 
                        : 
                            "Compeleted" 
                        // reporting terminated here
                            
                        :  job?.checkCall[0]?.totalCalls==0?
                            'Open'

                        : (job?.checkCall[0]?.totalCalls > job?.checkCall[0]?.fulfilledCalls && job?.checkCall[0]?.fulfilledCalls>0 && job?.checkCall[0]?.clocking<2)?

                            'On Duty'

                        :
                            ScheduleStatusType[job?.status]
                    }
                    {/* unconfirmed */}
                </div>
            </div> 

            {/* job type */}
            <div className="schedule-dutyType">{job?.hourTypeDetail[0]?.name}, {job?.dutyTypeDetail[0]?.code}</div> 

            {/* condition to render either agency name or employee or both */}
            {job?.assignedEmployeeDetail?
                
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}} className={"schedule-detail-wrap"}>
                    <div className="schedule-name" style={{paddingBottom:'5px'}}>{
                    job?._id==hoveredJob && (job?.assignedEmployeeDetail.firstname+" "+job?.assignedEmployeeDetail.lastname)?.length>17? 
                        job?.assignedEmployeeDetail.firstname 
                    : 
                        job?.assignedEmployeeDetail.firstname+" "+(job?.assignedEmployeeDetail.lastname || "")
                    }</div>
                    <div className="control-icon">
                        {
                            (job?.isDutyCancel || (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls>0 && (job?.reporting? job?.isReportSubmitted==true? true : false : true)))?
                            null:
                            <div className="controlledIcon" onClick={()=>{ setEditDutyId(job?._id); setDutyModal({visible:true, title:date, site, area}); }} ><FontAwesomeIcon style={{fontSize:'10px'}} icon={faPencil}/></div> 
                        }
                        <Popover
                            placement="bottom"
                            trigger="click"  
                            open={job?._id == dutyPopoverVisibility}  
                            overlayClassName='settingCustomPopover'
                            content={
                                <span>

                                    <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); setScheduleToCopy(job); }}> <FontAwesomeIcon icon={faCopy}/> Copy Duty</div>
                                    <div className="divider"/>
                                {(job?.hasOwnProperty("isPublished") && job.status==3) || (job?.checkCall[0]?.fulfilledCalls>0)? null:
                                        <>

                                            {job.status==0? null:
                                            <>
                                                {
                                                (job?.isPublished && job?.checkCall[0]?.fulfilledCalls>0)? null :
                                                job?.isPublished? 
                                                    !job?.isDutyCancel &&
                                                    <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1);}}>{job?.status==1?<span> <FontAwesomeIcon icon={faPersonCircleQuestion}/> Unconfirm</span>:<span><FontAwesomeIcon icon={faPersonCircleCheck}/> Confirm</span>} </div>
                                                    :
                                                    <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "isPublished", true);}}> <FontAwesomeIcon icon={faArrowUpFromBracket}/> {"Publish"}</div>
                                                }
                                            </>
                                            }
                                            {job?.status==0? <div className="popoverdataitem" onClick={()=>{setDutyPopoverVisibility(null);}}> <FontAwesomeIcon icon={faSearchPlus}/> Schedule Assist</div> : null}
                                        
                                        </>
                                
                                }    

                                    <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); setEditModalData({jobId: job?._id, ...site, selectedarea:area }) }}>
                                        <FontAwesomeIcon icon={faBookJournalWhills} />  Duty Detail 
                                    </div>
                                
                                
                                {
                                    
                                    <>
                                        <Divider
                                            style={{margin:0}}
                                        />
                                    {(job?.scheduletimesDetail?.actualEndDate || job?.isDutyCancel || job?.checkCall[0]?.clocking==2) ? null :
                                        <div className="popoverdataitem" onClick={async ()=>{
                                            if(job?.status=="1"){
                                                setDutyPopoverVisibility(null);
                                                handelDutyCancel(job);

                                            }else{
                                                setDutyPopoverVisibility(null);
                                                await deleteSchedule({
                                                    variables: {
                                                        scheduleId: job?._id
                                                    }
                                                });
                                                await scheduleRefetch();
                                            }
                                        }}>{job?.status=="1"?  <span> <FontAwesomeIcon icon={faCancel}/> Cancel Duty</span> : <span> <FontAwesomeIcon icon={faTrashCanArrowUp}/> Delete Duty</span>}</div>
                                    }
                                    </>
                                }

                                </span>
                            }
                        >
                            {
                                // (job?.isDutyCancel || (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls === job?.checkCall[0]?.totalCalls && (job?.reporting? job?.isReportSubmitted==true? true : false : true)))?
                                // null:
                                <div className="controlledIcon" onClick={()=>setDutyPopoverVisibility(job?._id)} ref={popoverRef}> <FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical} ref={popoverRef} /> </div> 

                            }
                        </Popover>
                    </div> 
                </div>

                // this section would be render when assign to agency
                :job?.assignedAgencyDetail?    
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}} className={"schedule-detail-wrap"}>
                        <div className="schedule-name">
                        {
                            job?._id==hoveredJob && (job?.assignedAgencyDetail?.suppliername)?.length>19? 
                                job?.assignedAgencyDetail?.suppliername.slice(0,16)
                            : 
                            job?.assignedAgencyDetail?.suppliername
                        }
                        </div>

                        {

                            <div className="control-icon">
                                {
                                    (job?.isDutyCancel || (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls === job?.checkCall[0]?.totalCalls && (job?.reporting? job?.isReportSubmitted==true? true : false : true)))?
                                        null:

                                    <div className="controlledIcon" onClick={()=>{setEditDutyId(job?._id); setDutyModal({visible:true, title:date, site, area}); }}  ><FontAwesomeIcon style={{fontSize:'10px'}} icon={faPencil}/></div> 
                                }
                                
                                <Popover
                                    placement="bottom"
                                    trigger={"click"}
                                    open={job?._id == dutyPopoverVisibility}
                                    overlayClassName='settingCustomPopover'
                                    content={
                                        <>

                                        <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); setScheduleToCopy(job); }}><FontAwesomeIcon icon={faCopy}/> Copy Duty </div>
                                        <div className="divider"/>
                                        {(job?.hasOwnProperty("isPublished") && job.status==3) || (job?.scheduletimesDetail &&  job?.scheduletimesDetail?.actualStartDate) ? null:
                                                <>
                                                    {job.status==0 || job.status==2? null:
                                                    <>
                                                        
                                                        {
                                                            job?.isPublished? null :
                                                            <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "isPublished", true);}}> <FontAwesomeIcon icon={faArrowUpFromBracket}/> {"Publish"}</div>
                                                        }
                                                        {
                                                            job?.isDutyCancel? null :
                                                            <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1)}}>{job?.status==1?<span> <FontAwesomeIcon icon={faPersonCircleQuestion}/> Unconfirm</span>:<span><FontAwesomeIcon icon={faPersonCircleCheck}/> Confirm</span>}</div>
                                                        }
                                                    </>
                                                    }
                                                    {job?.status==0? <div className="popoverdataitem" onClick={()=>{setDutyPopoverVisibility(null);}}> <FontAwesomeIcon icon={faSearchPlus}/> Schedule Assist</div> : null}
                                                
                                                </>
                                        
                                        }    
                                            <div className="popoverdataitem" onClick={()=>{setDutyPopoverVisibility(null); setEditModalData({jobId: job?._id, ...site, selectedarea:area })}}>
                                                <FontAwesomeIcon icon={faBookJournalWhills} /> Duty Detail
                                            </div>
                                        
                                        {
                                            (job?.scheduletimesDetail) ? 
                                            <>
                                                <Divider
                                                    style={{margin:0}}
                                                />
                                            {
                                                job?.scheduletimesDetail?.actualEndDate? null :
                                            
                                                <div className="popoverdataitem" onClick={async ()=>{
                                                    if(job?.status=="1"){
                                                        setDutyPopoverVisibility(null);
                                                        handelDutyCancel(job);

                                                    }else{
                                                        setDutyPopoverVisibility(null);
                                                        await deleteSchedule({
                                                            variables: {
                                                                scheduleId: job?._id
                                                            }
                                                        });
                                                        await scheduleRefetch();
                                                    }
                                                }}>{job?.status=="1"? <span> <FontAwesomeIcon icon={faCancel}/> Cancel Duty</span> : <span> <FontAwesomeIcon icon={faTrashCanArrowUp}/> Delete Duty</span>}</div>
                                                
                                            }   
                                            </>
                                            :null
                                        }
                                            
                                            
                                        </>
                                    }
                                >
                                    {
                                        // (job?.isDutyCancel || (job?.checkCall && job?.checkCall[0] && job?.checkCall[0]?.fulfilledCalls === job?.checkCall[0]?.totalCalls && (job?.reporting? job?.isReportSubmitted==true? true : false : true)))?
                                        // null:
                                        <div className="controlledIcon" onClick={()=>setDutyPopoverVisibility(job?._id)} ref={popoverRef}><FontAwesomeIcon style={{fontSize:'12px'}} ref={popoverRef} icon={faEllipsisVertical}/></div> 

                                    }
                                </Popover>
                            </div> 
                        }
                        
                    </div>

                
                :
                    // this section is when duty is open and nott assigned to any one
                
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginTop:'-5px'}}>
                    <div className="schedule-name" style={{visibility:'hidden'}}>xyz</div>
                    <div className="control-icon">
                    <div className="controlledIcon" onClick={()=>{setEditDutyId(job?._id); setDutyModal({visible:true, title:date, site, area}); }} ><FontAwesomeIcon style={{fontSize:'10px'}}   icon={faPencil}/></div> 
                    <Popover
                        placement="bottom"
                        trigger={"click"}
                        open={job?._id == dutyPopoverVisibility}
                        overlayClassName='settingCustomPopover'
                        content={
                            <span>

                                <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); setScheduleToCopy(job); }}> <FontAwesomeIcon icon={faCopy}/> {"Copy Duty"} </div>
                                <div className="divider"/>

                            {(job?.hasOwnProperty("isPublished") && job.status==3) || (job?.scheduletimesDetail &&  job?.scheduletimesDetail?.actualStartDate) ? null:
                                    <>
                                        <>
                                        {
                                            job?.isPublished? null
                                            // <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "status", job?.status==1? 2: 1)}}>{job?.status==1?"Unconfirm":"Confirm"}</div>
                                            
                                            :
                                            <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); handelUpdateSchedule(job?._id, "isPublished", job?.isPublished? false: true); }}> <FontAwesomeIcon icon={faArrowUpFromBracket}/> {job?.isPublished?"Unpublish":"Publish"}</div>
                                        }
                                        </>
                                        
                                        {job?.status==0? <div className="popoverdataitem" onClick={()=>{ setDutyPopoverVisibility(null); }}> <FontAwesomeIcon icon={faSearchPlus}/> Schedule Assist</div> : null}
                                    
                                    </>
                            
                            }    
                                <div className="popoverdataitem" onClick={()=>{  setDutyPopoverVisibility(null);  setEditModalData({jobId: job?._id, ...site, selectedarea:area })}}>
                                <FontAwesomeIcon icon={faBookJournalWhills} /> Duty Detail
                                </div>

                                <Divider
                                    style={{margin:0}}
                                />

                                <div className="popoverdataitem" onClick={async ()=>{
                                        setDutyPopoverVisibility(null); 
                                        await deleteSchedule({
                                            variables: {
                                                scheduleId: job?._id
                                            }
                                        });
                                        await scheduleRefetch();
                                }}> <FontAwesomeIcon icon={faTrashCanArrowUp}/> Delete Duty</div>
                                
                                
                            </span>
                        }
                    >
                        <div className="controlledIcon" onClick={()=>setDutyPopoverVisibility(job?._id)} ref={popoverRef}> <FontAwesomeIcon style={{fontSize:'12px'}}  icon={faEllipsisVertical} ref={popoverRef} /> </div> 
                    </Popover>
                    </div> 
                </div>     
                        
            }  
                              
        </div>
    )
}