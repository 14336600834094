import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Form, Input, Modal } from "antd";
import React from "react";

export const SystemReportSaveCustomisation = ({visible, close}) => {
    return (
        <Modal
            open={visible}
            width={'50%'}
            // className="systemReportModalWrapper"
            closable={false}
            footer={
                <div className='drawer-footer' style={{display:'flex', justifyContent:'space-between'}}>
                    <button className={false?' disabled-btn drawer-filled-btn': 'drawer-outlined-btn'} onClick={close}>Cancel</button>
                    <button className='drawer-filled-btn'>Save</button>
                </div>
            }
        >

            <React.Fragment>
                <div className='modal-header-title'>
                    <span> Save Customisation </span>
                    <span onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>


                <div className='modal-body' style={{height:'50vh'}}>

                    <Form>
                        <Form.Item>
                            <label>Name</label>
                            <Input className="generic-input-control" type='text' placeholder="Name (e.g; Customer's pay bill report)" />
                        </Form.Item>

                        <Form.Item>

                            <Form.Item>
                                <label>Run Report</label>
                            </Form.Item>
                            
                            <Form.Item>
                                <Checkbox> <span style={{fontWeight:500}}>Monthly</span> </Checkbox> <br/>
                            </Form.Item>

                            <Form.Item>
                                <Checkbox> <span style={{fontWeight:500}}> Quarterly </span> </Checkbox> <br/>
                            </Form.Item>
                            
                            <Form.Item>
                                <Checkbox>  <span style={{fontWeight:500}}> Yearly </span> </Checkbox> <br/>
                            </Form.Item>


                        </Form.Item>



                        <Form.Item>

                            <Form.Item>
                                <label>Visibility</label>
                            </Form.Item>
                            
                            <Form.Item>
                                <Checkbox> <span style={{fontWeight:500}}>Private</span> </Checkbox> <br/>
                            </Form.Item>

                            <Form.Item>
                                <Checkbox> <span style={{fontWeight:500}}> Team </span> </Checkbox> <br/>
                            </Form.Item>
                            
                            <Form.Item>
                                <Checkbox>  <span style={{fontWeight:500}}> Everyone </span> </Checkbox> <br/>
                            </Form.Item>

                            
                        </Form.Item>


                    </Form>

                </div>
            </React.Fragment>
            
        </Modal>
    );
}