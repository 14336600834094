
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Popover, Skeleton } from 'antd';
import {faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { PhoneOutlined,  FormOutlined, MessageOutlined, UserAddOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '@src/components/spinner';
import { useSelector } from 'react-redux';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { GenerateFields } from '@src/util/generateFields/generateFields';
import { useDispatch } from 'react-redux';
import { SiteObjectQuery } from '@src/util/query/site.query';
import { getSiteDetailViewQuery } from '@src/util/query/siteDetailView.query';
import { UpdateSingleSiteMutation } from '@src/util/mutation/site.mutation';

export const SiteDetailPageLeftSideBar = ({showDetails, site, loading, setIsFieldChanged, saveUpdate, setSaveUpdate, refetch})=>{
    
    const navigate = useNavigate();
    
    const {data:siteObject, loading: siteObjectLoading, refetch: siteObjectRefetch} = useQuery(SiteObjectQuery);
    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);

    
    const {data:getSiteDetailViewData, loading: getSiteDetailViewDataLoading, refetch: newSiteDetailViewRefetch} = useQuery(getSiteDetailViewQuery,{
        variables:{
            userId: userDetail?._id
        },
        fetchPolicy:'network-only'
    });

    useEffect(()=>{
        newSiteDetailViewRefetch()
    },[]);


    const [siteSchema, setSiteSchema] = useState([]);
    useEffect(()=>{
        if(getSiteDetailViewData?.getSiteDetailViewField?.response){
            setSiteSchema(getSiteDetailViewData?.getSiteDetailViewField?.response?.map((object)=>({
                label: object?.propertyDetail?.label,
                name: object?.propertyDetail?.label?.toLowerCase().replace(/\s/g,""),
                fieldType: object?.propertyDetail?.fieldType,
                property: object,
            })));
        }
    },[getSiteDetailViewData?.getSiteDetailViewField?.response]);

    const [edit, setEdit] = useState(false);

    const [field, setField] = useState([]);

    const handelDataValue = ({name, value})=>{
        if(name){
            if(value){
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value}])
                }
            }else{
                // setField(field?.filter(f=>f.name!==name));
                
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value:''
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value:''}])
                }
            }
        }
    }

    useEffect(()=>{
        if(Object.values(site)?.length>0 && siteSchema?.length>0){
            const localFeed = siteSchema?.map((schema)=>{
                const {name} = schema;
                if (name=="sitegroup"){
                    return({name, value: site['sitegroupId']})
                }              
                else{
                    return {name, value: site[name]}
                }
            });
            setField([...localFeed]);
        }
    },[siteSchema, site]);


    useEffect(()=>{
        
        if(Object.values(site)?.length>0 && siteSchema?.length>0){
            const localFeed = siteSchema?.map((schema, index)=>{
                if(schema?.name=="sitegroup"){
                    return {name: schema?.name, value: site['sitegroupId']}

                }else{

                    return {name: schema?.name, value: site[schema?.name]}
                }
            });

            const isEqual = localFeed.every((local)=>field.find((f)=> {
                if(f?.name==local?.name && ( Array.isArray(f?.value) || Array.isArray(local?.value) )){

                    if(f?.value?.length!=local?.value?.length){
                        return false;
                    }else{
                        return f?.value?.every((value, index)=> value==local?.value[index])
                    }

                }
                else if(f.name==local.name && f.value?.toLowerCase()==local.value?.toLowerCase()){
                    return true;
                }else{
                    return false;
                }
            }));

            setIsFieldChanged(!isEqual);
        }

    },[field]);

    
    useEffect(()=>{
        setIsFieldChanged(false);
        setEdit(false);
    },[loading]);

    const [updateSingleSiteMutation, {loading: updateSingleSiteLoading}] = useMutation(UpdateSingleSiteMutation);
    const dispatch = useDispatch();
    const param = useParams();
    
    const handelUpdateSiteGroup = async()=>{
        try{
            
            let schemaFields = [];

            field?.map((field)=>{
                if(field.name==="sitename" || field.name==="postcode" || field.name==="contractstartdate" ){
                    schemaFields.push(field);
                }
                else if(field.name==="sitegroup"){
                    schemaFields.push({...field, name: 'sitegroupId'});
                }
                else{
                    schemaFields.push({...field, metadata:1})
                }
            });


            await updateSingleSiteMutation({
                variables:{
                    input:{
                        _id: param?.id,
                        properties: schemaFields
                    }
                }
            });
            setEdit(false);
            setSaveUpdate(false);
            await refetch();
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Site  was updated successfully"
            }));
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }   
    }

    useEffect(()=>{
        if(saveUpdate){
            handelUpdateSiteGroup();
        }
    },[saveUpdate])


   
    return(
        <div className='sidebar-wrapper' >
            <div className='leftsidebar'>

                <div className='side-intro'>
                    {!siteObjectLoading && !loading?
                    <>
                     
                        
                        <div className='text-head' style={{width:'100%', textAlign:'center'}}>
                            <div className='text-title' style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%', textAlign:'center'}}
                            >
                                <span style={{width:'100%'}}>
                                    {site?.sitename?.toUpperCase()}
                                </span>
                                <Popover
                                    overlayClassName='saveView'
                                    placement='bottom'
                                    content={
                                        <div className='popover-data'>
                                            <div className="popoverdataitem" onClick={()=>setEdit(!edit)}>
                                               {edit? "Cancel Edit" : "Edit"}
                                            </div>
                                            <div className="popoverdataitem" onClick={()=>navigate("/user/site-detail/"+param?.id+"/edit")}>
                                                Customise View
                                            </div>
                                        </div>
                                    }
                                >
                                    <FontAwesomeIcon style={{cursor:'pointer'}} icon={faEllipsisV}/>
                                </Popover>
                            </div>

                            <div className='text-subtitle'>

                                <div style={{textTransform:'lowercase', fontSize:'1em', marginBottom:'22px', marginTop:'10px'}}>
                                    <FontAwesomeIcon icon={faLocationDot}/> &nbsp; {
                                        site?.address
                                    }
                                </div>   
                            
                                <div className="activity-btn-grp" style={{justifyContent:'center'}}>
                                    
                                    <Popover
                                        content={"Make a phone call"}
                                    >
                                        <span>
                                            <button className='disabled-btn'>
                                                <PhoneOutlined />
                                            </button>
                                            <span className='tiny-text disabled'>Call</span>
                                        </span>
                                    </Popover>

                                    
                                    <Popover
                                        content={"Start conversation"}
                                    >
                                        <span>
                                            <button className='disabled-btn'>
                                                {/* <FontAwesomeIcon icon={faComment} /> */}
                                                <MessageOutlined/>
                                            </button>
                                            <span className='tiny-text disabled'>Chat</span>
                                        </span>
                                    </Popover>


                                    <Popover
                                        content={"Create a note"}
                                    >
                                        <span>

                                            <button>
                                                <FormOutlined icon={faPenToSquare} />
                                            </button>
                                            <span className='tiny-text'>Note</span>
                                        </span>
                                    </Popover>


                                    
                                    <Popover content={"Follow this"} >
                                        <span>
                                            <button className='disabled-btn'>
                                                {/* <FontAwesomeIcon icon={faUserPlus} /> */}
                                                <UserAddOutlined />
                                            </button>
                                            <span className='tiny-text disabled'>Follow</span>
                                        </span>
                                    </Popover>
                                </div>

                            </div>
                        </div>
                    </>
                    : 
                    <div className='skeleton-custom'>

                    <Skeleton.Avatar active size={69} />
                    <Skeleton className='text-head' active/>
                    </div>
                    }
                </div>

                

                
            </div>
            {showDetails? !siteObjectLoading && !loading?
            <>
                {
                    siteSchema?.map((schema)=>{
                        if(schema?.name=="sitename"){
                            return(
                                edit?
                                <GenerateFields
                                    label = {schema?.label}
                                    name = {schema?.name}
                                    fieldType= {schema?.fieldType}
                                    handelDataValue = {handelDataValue}
                                    value={ field?.find((f)=>f.name==schema?.name)?.value }
                                    property ={schema?.property}
                                />
                                :
                                <div className='fieldView'>
                                    <div>
                                        {schema?.label}
                                    </div>
                                    <div>
                                     {site[schema?.name]}
                                    </div>                                
                                </div>

                            )
                        }
                    })
                }

                <div className="btm-border"></div>

                
                {
                    siteSchema?.map((schema)=>{
                        if(schema?.name!=="sitename"){
                            return(
                                    edit?
                                    <GenerateFields
                                        label = {schema?.label}
                                        name = {schema?.name}
                                        fieldType= {schema?.fieldType}
                                        handelDataValue = {handelDataValue}
                                        value={ field?.find((f)=>schema?.name==f.name)?.value }
                                        property={schema?.property}
                                    />
                                    :
                                    <div className='fieldView'>
                                        <div>{schema?.label}</div>
                                        <div style={{textAlign:'right', textWrap:'pretty', paddingLeft:'1px'}}>
                                            {
                                            schema?.fieldType=="multiCheckbox"?
                                                site[schema?.name?.toLowerCase().replace(/\s/g,"")]?.join(", ")
                                            : 
                                            site?.hasOwnProperty(schema?.label?.replaceAll(" ","")?.toLowerCase())  || site['metadata']?.hasOwnProperty(schema?.label?.replaceAll(" ","")?.toLowerCase())? 
                                            site[schema?.label?.replaceAll(" ","")?.toLowerCase()] || site['metadata'][schema?.label?.replaceAll(" ","")?.toLowerCase()] : ""
                                            }
                                        </div>
                                    </div>
            
                            )
                        }
                    })
                }
            </>
            :
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', }}>
                <Spinner/>
            </div>
            
            :null}
               
        </div>
    );
}