// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-wrapper{
    padding: 32px;
    margin: auto;
}

.w-report-title{
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 32px;
}

.w-report-header{
    display: flex;
    width: 100%;
    border-bottom: 4px solid rebeccapurple;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

}

.w-table-header{
    font-size: 18px;
    font-weight: 600;
    color: white;
    text-align: left;
    background-color: #0000ff45;
}

.w-wrapper table, .w-wrapper tr, .w-wrapper th, .w-wrapper td  {
    border: 1px solid grey;
    border-collapse: collapse;
    text-align: left;
    padding: 5px 10px;
}

@media print {
    .exclude-from-pdf {
        display: none !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/reports/mobileReports/wardSecurity/wardSecurity.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,sCAAsC;IACtC,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;;AAEvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".w-wrapper{\n    padding: 32px;\n    margin: auto;\n}\n\n.w-report-title{\n    font-size: 25px;\n    font-weight: 500;\n    padding-bottom: 32px;\n}\n\n.w-report-header{\n    display: flex;\n    width: 100%;\n    border-bottom: 4px solid rebeccapurple;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 32px;\n\n}\n\n.w-table-header{\n    font-size: 18px;\n    font-weight: 600;\n    color: white;\n    text-align: left;\n    background-color: #0000ff45;\n}\n\n.w-wrapper table, .w-wrapper tr, .w-wrapper th, .w-wrapper td  {\n    border: 1px solid grey;\n    border-collapse: collapse;\n    text-align: left;\n    padding: 5px 10px;\n}\n\n@media print {\n    .exclude-from-pdf {\n        display: none !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
