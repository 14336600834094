import './middleSection.css';
import { Avatar, Input, Modal, Popover } from 'antd';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faClose, faEllipsisV, faPoll } from '@fortawesome/free-solid-svg-icons';

import { useSelector } from 'react-redux';
import { PostModal } from '@src/components/postModal/post.modal';

export const DetailPageMiddleSection = ({singleBranchData})=>{
    
    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);
    const { firstname, lastname, metadata:{imageURL}} = authenticatedUserDetail?.employeeDetail[0];

    const [postCollapse, setPostCollapse] = useState(false)
    const [post, setPost] = useState(false);

    return(
        <div className='detailPageTab'>
            
            <div className='post-header'>
                <div className='post-avatar'>
                {imageURL?
                        <Avatar size={60} src={imageURL} />
                    :
                        <Avatar size={60}> {firstname[0]+lastname[0]} </Avatar>
                }
                </div>
                <Input
                    className='generic-input-control postInput'
                    placeholder={"Start a post"}
                    style={{borderRadius:'5px',height:'50px'}}
                    onClick={()=>setPost(!post)}
                />
            </div>

            <div className="post-body">
                <div className="post-body-item">
                    <div className='poster'>
                        <div style={{display:'flex', gap:'20px', alignItems:'center', width:'100%'}}>
                            <div className='post-avatar'>
                            {imageURL?
                                <Avatar size={60} src={imageURL} />
                            :
                                <Avatar size={60}> {firstname[0]+lastname[0]} </Avatar>
                            }
                            </div>

                            <div style={{width:'97%', borderBottom:'1px solid #ECEFEC', paddingBottom:'16px'}}>
                                <div style={{fontWeight:'500',marginBottom:'6px', fontSize:'14px'}}>{firstname+" "+lastname}</div>
                                <div>{dayjs().format("DD-MM-YYYY HH:mm")}</div>
                            </div>
                        </div>

                        <div style={{cursor:'pointer'}}>
                            <Popover
                                overlayClassName='notePopover'
                                placement='right'
                                content={
                                    <div className='popover-data'>
                                        <div className="popoverdataitem">
                                            Pin
                                        </div>
                                        <div className="disabled popoverdataitem">
                                            Delete
                                        </div>
                                    </div>
                                }
                            >
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </Popover>
                        </div>


                    </div>
                    {/* posting body */}
                    <div style={{padding:'20px 0px 20px 80px'}}>
                        <div style={{fontSize:'16px', fontWeight:'500', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                           <span>Post Body</span> 
                           {
                            postCollapse?
                            <FontAwesomeIcon onClick={()=>setPostCollapse(!postCollapse)} style={{cursor:'pointer'}}  icon={faChevronRight} />
                            :
                            <FontAwesomeIcon onClick={()=>setPostCollapse(!postCollapse)} style={{cursor:'pointer'}} icon={faChevronDown} />
                           }
                        </div>
                        {
                            postCollapse? null
                            :
                            <div className="text">
                                Our research objectives encompass the development and training of a robust CNN model capable of accurately detecting tire cracks. Subsequently, we optimize the model for deployment on mobile platforms using TensorFlow Lite, ensuring that our proposed solution meets the demands of real-time, on-the-go inspections. The dissertation aims to contribute to the field by providing an effective and accessible solution that enhances tire safety through advanced deep learning techniques and mobile computing capabilities.
                            </div>
                        }
                    </div>
                </div>
            </div>
            

            <PostModal 
                visible={post}
                close={
                    ()=>setPost(!post)
                }
            />

            
        </div>
    );
}

