import './middleSection.css';
import { Avatar, Image, Input, Popover, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { PostModal } from '@src/components/postModal/post.modal';
import { getTrainedEmployeeByFieldQuery } from "@src/util/query/trainedEmployee.query";
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { getPostQuery } from '@src/util/query/post.query';
import Spinner from "@src/components/spinner";
import { ReactComponent as LikeDisplaySvg } from '@src/assets/svg/displayLike.svg';
import { ReactComponent as CommentSvg } from '@src/assets/svg/commenticon.svg';
import { PostReactionMutation } from '@src/util/mutation/postReaction.mutation';
import { PostReactionList } from '@src/util/postReaction';
import { PostSeenMutation } from '@src/util/mutation/postSeen.mutation';
import InfiniteScroll from "react-infinite-scroll-component";
import { deleteVotePostPollMutation, votePostPollMutation } from '../../../util/mutation/votePostPoll.mutation';
import { PostSeenModal } from '../../../components/postModal/postSeen.modal';
import { PostVotePollResults } from '../../../components/postModal/postVotePollResults';



function displayRelativeTime(date) {
    const now = dayjs();
    const pastDate = dayjs(date);
    return pastDate.from(now); // Returns a human-readable relative time string
}

export const DetailPageMiddleSection = ({singleEmployee})=>{

    const [post, setPost] = useState(false);
    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);  

    const param = useParams();

    const {data:trainedEmployeeData, loading: trainedEmployeeLoading, refetch: refetchTrainedEmployee} = useQuery(getTrainedEmployeeByFieldQuery,{
        variables:{
            field:'employeeId',
            value: param?.id
        },
        skip:!param?.id,
        fetchPolicy:'network-only'
    });

    const  [getpostQuery, {data:postData, loading:postLoading, refetch:refetchPost}] = useLazyQuery(getPostQuery);
    
    // useEffect(()=>{
    //     if(!trainedEmployeeLoading && singleEmployee){

    //         const employeeIds = [singleEmployee?._id];
    //         const branchIds = [...new Set(singleEmployee?.branch)];
    //         const supplierIds = [singleEmployee?.metadata?.supplierId];
    //         const siteIds = [...new Set(trainedEmployeeData?.getTrainedEmployeeByField?.response?.map(item=>item.siteId))];
    //         const siteGroupIds = [...new Set(trainedEmployeeData?.getTrainedEmployeeByField?.response?.map(item=>item.siteDetail[0]?.sitegroupId))];

    //         getPost(employeeIds, branchIds, supplierIds, siteIds, siteGroupIds);
            
            
    //     }
    // },[trainedEmployeeLoading, singleEmployee]);

    useEffect(()=>{
        if(!trainedEmployeeLoading && singleEmployee){
            getPost();
        }
    },[trainedEmployeeLoading, singleEmployee]);


    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    // const getPost = async(employeeIds, branchIds, supplierIds, siteIds, siteGroupIds)=>{
    const getPost = async()=>{
        try{
            const employeeIds = [singleEmployee?._id];
            const branchIds = [...new Set(singleEmployee?.branch)];
            const supplierIds = [singleEmployee?.metadata?.supplierId];
            const siteIds = [...new Set(trainedEmployeeData?.getTrainedEmployeeByField?.response?.map(item=>item.siteId))];
            const siteGroupIds = [...new Set(trainedEmployeeData?.getTrainedEmployeeByField?.response?.map(item=>item.siteDetail[0]?.sitegroupId))];

            await getpostQuery({
                variables:{
                    input:{
                        employeeIds,
                        branchIds,
                        supplierIds,
                        siteIds,
                        siteGroupIds,
                        page,
                        limit:10
                    }
                },
                fetchPolicy:'network-only'
            });
            setPage((prevPage) => prevPage + 1);
        }catch(err){
            console.log(err);
            setHasMore(false);
        }
    };

    const [postSeen, {loading:postSeenLoading}] = useMutation(PostSeenMutation);
    const handelPostSeenMutation = async (postIds)=>{
        await postSeen({
            variables:{
                employeeId: authenticatedUserDetail?.employeeDetail[0]?._id,
                postId: postIds
            }
        });
    }

    const [postList, setPostList] = useState([]);
    useEffect(()=>{
        if(postData?.getPosts?.response && !postLoading){
            setPostList(postData?.getPosts?.response);
            handelPostSeenMutation(postData?.getPosts?.response?.map(post=>post?._id));
        }else{
            setHasMore(false);
        }
    },[postData?.getPosts?.response, postLoading, page]);
    

    const [postReaction, {loading:postReactionLoading}] = useMutation(PostReactionMutation);
    const handelPostReactionMutation = async (postId, reactionType)=>{
        await postReaction({
            variables:{
                input:{
                    postId,
                    reactionType: reactionType.toUpperCase(),
                    employeeId: authenticatedUserDetail?.employeeDetail[0]?._id
                }
            }
        });

        setPostList(postList.map((post)=>{

            if(post?._id===postId){
                if(post?.postReaction){
                    const isPostReactionExist = post?.postReaction.find(reaction=>reaction?.employeeId===authenticatedUserDetail?.employeeDetail[0]?._id);
                    // if user reaction already exist then remove it and update the newer one that he did
                    if(isPostReactionExist){
                        return {
                            ...post,
                            postReaction: [
                                ...post?.postReaction.filter(reaction=>reaction?.employeeId!==authenticatedUserDetail?.employeeDetail[0]?._id),
                                {   reactionType: reactionType, 
                                    employeeId: authenticatedUserDetail?.employeeDetail[0]?._id,
                                    employeeDetail: authenticatedUserDetail?.employeeDetail[0],
                                }
                            ]
                        }   
                    }

                    // if user reaction not exist then add it
                    return {
                        ...post,
                        postReaction:[
                            ...post?.postReaction,
                            {   reactionType: reactionType, 
                                employeeId: authenticatedUserDetail?.employeeDetail[0]?._id,
                                employeeDetail: authenticatedUserDetail?.employeeDetail[0],
                            }
                        ]
                    }

                }else{
                    // if post reaction not exist then add it
                    return {
                        ...post,
                        postReaction:[
                            {   reactionType: reactionType, 
                                employeeId: authenticatedUserDetail?.employeeDetail[0]?._id,
                                employeeDetail: authenticatedUserDetail?.employeeDetail[0],
                            }
                        ]
                    }
                }

            }else{
                return post;
            }

        }));

    };

    const [votePostPoll] = useMutation(votePostPollMutation);
    const handelVotePostPoll = async(postId, selectedOption)=>{

        await votePostPoll({
            variables:{
                input:{
                    postId,
                    selectedOption,
                    employeeId: authenticatedUserDetail?.employeeDetail[0]?._id
                }
            }
        });


        setPostList(postList.map((post)=>{

            if(post?._id===postId){
                if(post?.votePollList){
                    const isPostVoteExist = post?.votePollList.find(vote=>vote?.employeeId===authenticatedUserDetail?.employeeDetail[0]?._id);
                    // if user reaction already exist then remove it and update the newer one that he did
                    if(isPostVoteExist){
                        return {
                            ...post,
                            votePollList: [
                                ...post?.votePollList.filter(vote=>vote?.employeeId!==authenticatedUserDetail?.employeeDetail[0]?._id),
                                {   selectedOption: selectedOption,
                                    postId: postId, 
                                    employeeId: authenticatedUserDetail?.employeeDetail[0]?._id,
                                    employeeDetails: authenticatedUserDetail?.employeeDetail[0],
                                }
                            ]
                        }   
                    }

                    // if user reaction not exist then add it
                    return {
                        ...post,
                        votePollList:[
                            ...post?.votePollList,
                            {   selectedOption: selectedOption, 
                                postId: postId, 
                                employeeId: authenticatedUserDetail?.employeeDetail[0]?._id,
                                employeeDetails: authenticatedUserDetail?.employeeDetail[0],
                            }
                        ]
                    }

                }else{
                    // if post reaction not exist then add it
                    return {
                        ...post,
                        votePollList:[
                            {   selectedOption: selectedOption, 
                                postId: postId,
                                employeeId: authenticatedUserDetail?.employeeDetail[0]?._id,
                                employeeDetails: authenticatedUserDetail?.employeeDetail[0],
                            }
                        ]
                    }
                }

            }else{
                return post;
            }

        }));

    };

    const [deleteVotePostPoll] = useMutation(deleteVotePostPollMutation);

    const handelDeleteVotePostPoll = async(postId)=>{

        await deleteVotePostPoll({
            variables:{
                    postId,
                    employeeId: authenticatedUserDetail?.employeeDetail[0]?._id
                
            }
        });


        setPostList(postList.map((post)=>{

            if(post?._id===postId){
                if(post?.votePollList){
                    const isPostVoteExist = post?.votePollList.find(vote=>vote?.employeeId===authenticatedUserDetail?.employeeDetail[0]?._id);
                    // if user reaction already exist then remove it and update the newer one that he did
                    if(isPostVoteExist){
                        return {
                            ...post,
                            votePollList: [
                                ...post?.votePollList.filter(vote=>vote?.employeeId!==authenticatedUserDetail?.employeeDetail[0]?._id)
                            ]
                        }   
                    }
                    return post;

                }
                return post

            }else{
                return post;
            }

        }));

    };


    const [seenModal, setSeenModal] = useState(false);
    const [seenList, setSeenList] = useState([]);

    const [voteListModal, setVoteListModal] = useState(false);
    const [voteList, setVoteList] = useState([]);



    return(
        <div className='setting-body-inner detailPageTab' style={{padding:0}}>
            
            <div className='post-header'>
                <div className='post-avatar'>
                    {
                        authenticatedUserDetail?.employeeDetail[0]?.metadata?.imageURL?
                        <Avatar size={60} src={authenticatedUserDetail?.employeeDetail[0]?.metadata?.imageURL} />
                        :
                        <Avatar size={60}> <UserOutlined/> </Avatar>
                    }
                </div>
                <Input
                    className='generic-input-control'
                    placeholder={"What's on your mind ?"}
                    style={{borderRadius:'5px',height:'50px'}}
                    onClick={()=>setPost(!post)}
                />
            </div>

            <div className="post-body">
                {
                    ( postLoading || trainedEmployeeLoading ) ?
                        <Spinner/>
                    :
                        <InfiniteScroll
                            dataLength={postList.length}
                            next={getPost}
                            hasMore={hasMore}
                            loader={<Spinner />}
                            endMessage={
                                <p style={{ textAlign: "center", display:'flex', alignItems:'center', justifyContent:'center', padding:'20px 0', color:'gray'}}>
                                     <FontAwesomeIcon icon={faCircleCheck} style={{color:'rgb(0, 189, 165)', fontSize:'36px'}}/> &emsp; Yay! You have seen it all
                                </p>
                            }
                        >

                            {postList?.map((post, index)=>{ 
                                return(

                                    <div className="post-body-item" >
                                        <div className='poster post-padding'>
                                            <div className='mt16' style={{display:'flex', gap:'20px', alignItems:'center', width:'100%'}}>
                                                <div className='post-avatar'>
                                                    {
                                                        post?.createdByDetail?.metadata?.imageURL?
                                                        <Avatar size={60} src={post?.createdByDetail?.metadata?.imageURL} />
                                                        :
                                                        <Avatar size={60}> { post?.createdByDetail?.firstname[0] + (post?.createdByDetail?.lastname[0] || "")} </Avatar>
                                                    }
                                                </div>

                                                <div style={{width:'97%', paddingBottom:'0', paddingTop:'5px'}}>
                                                    <div style={{fontWeight:'500',marginBottom:'6px', fontSize:'14px'}}>{post?.createdByDetail?.firstname+" "+(post?.createdByDetail?.lastname || "")}</div>
                                                    <div>{displayRelativeTime(dayjs(post?.createdAt))}</div>
                                                </div>
                                            </div>

                                            <div style={{cursor:'pointer'}}>
                                                <Popover
                                                    overlayClassName='saveView'
                                                    placement='bottom'
                                                    trigger={['click']}
                                                    content={
                                                        post?.createdByDetail?._id === authenticatedUserDetail?.employeeDetail[0]?._id ?
                                                        <div className='popover-data'>
                                                            <div className="popoverdataitem">
                                                                Pin
                                                            </div>
                                                            <div className="disabled popoverdataitem">
                                                                Delete
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='popover-data'>
                                                            <div className="popoverdataitem">
                                                                Hide
                                                            </div>
                                                            <div className="popoverdataitem">
                                                                Report
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faEllipsisV} />
                                                </Popover>
                                            </div>


                                        </div>

                                        {/* posting body */}
                                        <div >
                                            
                                            <div className='post-padding post-content' style={(post?.color && post?.color!="white") ?
                                                {marginTop:'16px', background:post?.color, minHeight:'35vh', display:'flex', alignItems:'center', justifyContent:'center', color:'white' }
                                                :
                                                {paddingTop:0}} dangerouslySetInnerHTML={{__html:"<span>"+post?.content.trim()+"</span>"}}></div>

                                            {/* render image if */}
                                            {
                                                post?.media ?
                                                <div style={{ maxWidth: "100%", textAlign: "center" }}>
                                                    <Image
                                                        src={post?.media}
                                                        alt={"Image placeholder"}
                                                        placeholder={<Spinner />}
                                                        style={{ maxWidth: "100%", height: "auto" }} // Ensures responsiveness
                                                    />
                                                </div>
                                                :null
                                            }

                                            {/* render poll if it is exist */}
                                            {
                                                post?.pollConfiguration?
                                                <div className="editor-poll">

                                                    <div className="editor-poll-question">
                                                        {post?.pollConfiguration?.pollQuestion}
                                                    </div>

                                                    <div>
                                                        {
                                                        post?.votePollList?.find((vote)=>vote?.employeeId==authenticatedUserDetail?.employeeDetail[0]?._id) 
                                                        // || 
                                                        // post?.pollConfiguration?.pollDuration
                                                        ?

                                                            post?.pollConfiguration?.options?.map((option)=>(

                                                                post?.votePollList?.find((vote)=>vote?.employeeId==authenticatedUserDetail?.employeeDetail[0]?._id)?.selectedOption==option?.id?
                                                                
                                                                <div className='row-flex mb16' style={{width:'100%', gap:'10', alignItems:'center'}}>

                                                                    <div className='votepoll'>

                                                                        <span>
                                                                            {option?.value} 
                                                                        </span>

                                                                        <FontAwesomeIcon icon={faCircleCheck} style={{fontSize:'25px', color:'#56687a'}}/>
                                                                        

                                                                    </div>

                                                                    <b>
                                                                        {(post?.votePollList?.filter((vote)=>vote?.selectedOption==option?.id)?.length/post?.votePollList?.length)*100} %
                                                                    </b>

                                                                </div>

                                                                :
                                                                <div className='row-flex mb16' style={{width:'100%', gap:'10', alignItems:'center'}}>

                                                                    <div className='votepoll'>
                                                                        
                                                                        {option?.value}

                                                                    </div>

                                                                    <b>
                                                                        {(post?.votePollList?.filter((vote)=>vote?.selectedOption==option?.id)?.length/post?.votePollList?.length)*100} %
                                                                    </b>

                                                                </div>

                                                            ))

                                                        :
                                                        post?.pollConfiguration?.options?.map((option)=>(
                                               
                                                            <div className="editor-poll-option cursor" onClick={()=>{handelVotePostPoll(post?._id, option?.id)}}>
                                                                {option?.value}
                                                            </div>
                                                        ))}
                                                    </div>


                                                    <div className="row-flex">
                                                        <b style={{color:'#0091ae'}} className="cursor">{ post?.votePollList?.length } votes</b>
                                                        <span style={{padding:'0 2px', fontSize:'16px'}}>•</span>
                                                        <div>{post?.pollConfiguration?.pollDuration} left</div>
                                                        <span style={{padding:'0 2px', fontSize:'16px'}}>•</span>
                                                        
                                                        {
                                                            (post?.votePollList?.find((vote)=>vote?.employeeId==authenticatedUserDetail?.employeeDetail[0]?._id) && post.createdByDetail?._id!==authenticatedUserDetail?.employeeDetail[0]?._id)?
                                                            <b style={{color:'#0091ae'}} className="cursor" onClick={()=>handelDeleteVotePostPoll(post?._id)}>Undo</b>
                                                            
                                                            :
                                                            post.createdByDetail?._id==authenticatedUserDetail?.employeeDetail[0]?._id?
                                                            <>
                                                                <b style={{color:'#0091ae'}} className="cursor" onClick={()=>handelDeleteVotePostPoll(post?._id)}>Undo</b>
                                                                
                                                                <span style={{padding:'0 2px', fontSize:'16px'}}>•</span>

                                                                <b style={{color:'#0091ae'}} onClick={()=>{ setVoteList(post?.votePollList); setVoteListModal(true);  }} className="cursor">
                                                                    View results
                                                                </b>
                                                            </>
                                                            :
                                                            <b style={{color:'#0091ae'}} className="cursor disabled" >Undo</b>

                                                        }


                                                    </div>

                                                </div>
                                                :null
                                            }

                                            {/* render post interaction by users */}
                                            
                                            <div className='reactionList'>
                                                {/* like, supported by */}
                                                <div style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                                    <div className='userReaction'>
                                                        {
                                                            post?.postReaction?.map((reaction, index)=>(
                                                                PostReactionList[reaction?.reactionType]?.icon
                                                            ))
                                                        }
                                                    </div>    

                                                    <span className='record-subtitle' style={{padding:0, margin: 0}}>
                                                        {   
                                                            (post?.postReaction && post?.postReaction?.length>1) ? 
                                                                post?.postReaction[0]?.employeeDetail?.firstname +` and ${post?.postReaction?.length-1} others` : 
                                                            post?.postReaction && post?.postReaction?.length==0?
                                                            "":
                                                            1
                                                        } 
                                                    </span>

                                                </div>

                                                {/* seen by */}
                                                <div className='row-flex' onClick={()=>{setSeenModal(true); setSeenList(post?.postSeen)}}>

                                                    <Avatar.Group
                                                        maxCount={1}
                                                        maxPopoverTrigger={''}
                                                        maxPopoverVisible={false}
                                                    >
                                                        {
                                                            post?.postSeen?.map((seen)=>(
                                                                <div className='post-avatar'> 
                                                                    {seen?.employeeDetail[0]?.metadata?.imageURL?
                                                                    <Avatar
                                                                        size={30}
                                                                        src={seen?.employeeDetail[0]?.metadata?.imageURL}
                                                                    />
                                                                    :
                                                                    <Avatar 
                                                                    size={50}
                                                                    >
                                                                        <UserOutlined/>
                                                                    </Avatar>}
                                                                </div>

                                                            ))
                                                        }
                                                    </Avatar.Group>
                                                    
                                                </div>
                                            </div>

                                            {/* interaction footer */}
                                            <div>
                                                    <div className='like-comment'>
                                                        
                                                        <Popover
                                                            overlayClassName='likeCommentPopover'
                                                            content={
                                                                <div>
                                                                    {
                                                                        Object.values(PostReactionList)?.map((reaction, index)=>(
                                                                            
                                                                            <>
                                                                                <Tooltip title={reaction?.title} onClick={()=>handelPostReactionMutation(post?._id, reaction?.title)} mouseEnterDelay={0.15}>
                                                                                    {reaction.icon}
                                                                                </Tooltip> &nbsp;
                                                                            </>

                                                                        ))
                                                                    }

                                                                </div>
                                                            }
                                                            trigger={"hover"}
                                                        >

                                                            <div className='like-comment-item' style={{borderBottomLeftRadius:'21px'}}>
                                                                {
                                                                    post?.postReaction?.find(reaction=>reaction?.employeeId===authenticatedUserDetail?.employeeDetail[0]?._id)?
                                                                    PostReactionList[post?.postReaction?.find(reaction=>reaction?.employeeId===authenticatedUserDetail?.employeeDetail[0]?._id)?.reactionType]?.icon
                                                                    :
                                                                    <LikeDisplaySvg style={{width:'20px'}} />
                                                                }
                                                                <span style={{fontSize:'1rem'}}>
                                                                {post?.postReaction?.find(reaction=>reaction?.employeeId===authenticatedUserDetail?.employeeDetail[0]?._id)?.reactionType || "Like"} 
                                                                </span>
                                                            </div>

                                                        </Popover>

                                                        <div className='comment-item disabled' style={{borderBottomRightRadius:'10px'}}>
                                                            <CommentSvg className='post-comment-icon' /> &nbsp;
                                                            <span style={{fontSize:'1rem'}}>Comment</span>
                                                        </div>

                                                    </div>
                                            </div>

                                        </div>
                                    </div>


                                )
                            })}
                            
                        </InfiniteScroll>
                    }
                
            </div>
            
            {
                post &&
                <PostModal 
                    visible={post}
                    close={()=>setPost(false)}
                    refetchPost={refetchPost}
                    closable={false}
                />
            }

            {
                seenModal &&
                <PostSeenModal 
                    visible={seenModal}
                    close={()=>{ setSeenModal(false); setSeenList([]); }}
                    seenList={seenList}
                />
            }
            
            {
                voteListModal &&
                <PostVotePollResults
                    visible={voteListModal}
                    close={()=>{ setVoteListModal(false); setVoteList([]); }}
                    voteDetail={voteList}
                />
            }
            
        </div>
    );
}

