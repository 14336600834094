import { gql } from "@apollo/client";

export const newScheduleTaskMutation = gql`
mutation($input: ScheduleTaskInput!){
  newScheduleTask(input: $input) {
    message
    response
  }
}
`;

export const updateScheduleTaskMutation = gql`
mutation($input: ScheduleTaskInput!){
  updateScheduleTaskById(input: $input) {
    message
    response
  }
}
`;