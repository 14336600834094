import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { getPayandBillColumnQuery } from '@src/util/query/payandbillColumn.query';
import { useMutation, useQuery } from '@apollo/client';
import { getPayLevelQuery } from '@src/util/query/paylevel.query';
import { getAgencyPayTableQuery } from '@src/util/query/agencyPayTable.query';
import { upsertAgencyPayTableMutation } from '@src/util/mutation/agencyPayTable.mutation';
import { PayRateModal } from '@src/components/payrateModal/payrate.modal.component';
import { useDispatch } from 'react-redux';
import { findPayDate } from '@src/util/findPayDate/findPayDate';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { RateAdjustmentModal } from '@src/components/rateAdjustment/rateAdjustment';




export const AgencyPayTable = ({id})=>{


    const [remoteColumns, setRemoteColumns] = useState([]);

    const {data, loading:getPayandBillColumnLoading, refetch} = useQuery(getPayandBillColumnQuery);
    
    useEffect(()=>{
      if(data?.getPayandBillColumn?.response){
        setRemoteColumns([
          {
            title: '',
            dataIndex: 'payLevel',
            width: '20%',
          },
          ...data?.getPayandBillColumn?.response?.map((col)=>(
            {
              title: col?.columnName,
              dataIndex: col?._id,
              editable: true,
              // width:'20%'
            }
          ))
        ]);
      }
    },[data?.getPayandBillColumn?.response]);

    
    const {data: payLevel, loading: payLevelLoading} = useQuery(getPayLevelQuery,{
      fetchPolicy: 'network-only',
    });

    const [dataSource, setDataSource] = useState([]);

    const {data: agencyPayTableData, loading: agencyPayTableLoading, refetch: refetchAgencyPayTable} = useQuery(getAgencyPayTableQuery,{
      variables:{
        agencyId: id
      },
      fetchPolicy:'network-only'
    });

    const [columnToBeEdit, setColumnToBeEdit] = useState(null);
    const [payrateModal, setPayrateModal] = useState(false);

    const handelEditPayRate = (pay, payLevelColData, column, pl)=>{
      setColumnToBeEdit({
        value: pay,
        column,
        metadata: payLevelColData,
        payLevel: pl
      });
      setPayrateModal(true);
    }


    const [upsertAgencyPayTable, {loading: upsertAgencyPayTableLoading}] = useMutation(upsertAgencyPayTableMutation);

    const payBillStandard = ["65ee4f5dee2b8708243143eb", "65ff5f3ecdef94de7cabc3df"];
    const [rateAdjustmentModal, setRateAdjustmentModal] = useState({modal: false, data:{}});

    // pay level is on row and current column is from pay and bill column
    const handelRateAdjustment = (currentColumn, payLevel, isBillOrPay, baseRate, columnName, currentColumnValue)=>{
      setRateAdjustmentModal({
        modal: true,
        data: {
          currentColumn, payLevel, isBillOrPay, baseRate: parseFloat(baseRate), columnName, currentColumnValue
        }
      });
    };

    useEffect(()=>{
      if(!agencyPayTableLoading && !payLevelLoading && !getPayandBillColumnLoading){

        setDataSource(payLevel?.getPayLevel?.response?.map((pl)=>{
          
          const columns = data?.getPayandBillColumn?.response?.map((col)=>(col?._id));
          const resultObject = {};
          
          const payLevelData = agencyPayTableData?.getAgencyPayTable?.response;

          for (let i = 0; i < columns?.length; i ++) {
            const payLevelColData = payLevelData?.find((pld)=>pld?.payLevelId===pl?._id);
          
            // ====================== This is for all other columns Except Standard
            const isBillOrPay =  data?.getPayandBillColumn?.response?.find((payBill)=>payBill?._id== columns[i])?.columnCategory;

            const baseRate = isBillOrPay=="Pay" ? findPayDate(payLevelColData?.payTableMeta[payBillStandard[0]]) :  findPayDate(payLevelColData?.payTableMeta[payBillStandard[1]]);

            const {columnName} = data?.getPayandBillColumn?.response?.find((pay)=>pay?._id==columns[i]);
            // ================= Expect standard logic terminated
 
            
            resultObject[columns[i]] =  <span className="link" 
              onClick={()=>{
                // this will call the func for each column excluding Standard pay and bill
                if(!payBillStandard.includes(columns[i])){
                  handelRateAdjustment(
                    columns[i],
                    pl,
                    isBillOrPay,
                    baseRate,
                    columnName,
                    payLevelColData?.payTableMeta[columns[i]]
                  );
                }else{
                  handelEditPayRate(payLevelColData?.payTableMeta[columns[i]], payLevelColData, columns[i], pl);
                }

              }}
            >
              {
              
              payLevelColData?.payTableMeta?.hasOwnProperty([columns[i]])? 

              // pay and bill detail is stored in an array for multiple effective dates
              Array.isArray(payLevelColData?.payTableMeta[columns[i]])?
                findPayDate(payLevelColData?.payTableMeta[columns[i]]) 
              :
                // this will work for each columns except standard pay and bill if the multiplier is not set
                // then it would be custom and custom rate would be added there.
                  payLevelColData?.payTableMeta[columns[i]]?.multiplier=="custom"?
                    (parseFloat(baseRate) + parseFloat(payLevelColData?.payTableMeta[columns[i]]?.rate)).toFixed(2)+"/h"
                  :
                // if the multiplier defined there except the custom
                  payLevelColData?.payTableMeta[columns[i]]?.multiplier?
                    (parseFloat(baseRate) +( parseFloat(baseRate) * ((payLevelColData?.payTableMeta[columns[i]]?.multiplier)/100) ) ).toFixed(2) + "/h"
                  :0
              
              : 0
              
              }
            </span>


            if(columnToBeEdit && Object.keys(columnToBeEdit)?.length>0 && columnToBeEdit?.column==columns[i] && 
                columnToBeEdit?.payLevel?._id == pl?._id)
            {
              setColumnToBeEdit({
                ...columnToBeEdit,
                value: payLevelColData?.payTableMeta[columns[i]]
              });
            }
          
          }

          return({
            key:pl?._id,
            payLevel: pl?.name,
            ...resultObject,
            payLevelId: pl?._id
          })

        }));


      }
    },[agencyPayTableLoading, payLevelLoading, getPayandBillColumnLoading, payLevel, agencyPayTableData?.getAgencyPayTable?.response, upsertAgencyPayTableLoading]);

    const dispatch = useDispatch();

    const handleSave = async(input) => {
      
      try{
        await upsertAgencyPayTable({
          variables:{
            input
          }
        });
  
        await refetchAgencyPayTable();

      }
      catch(err){

        dispatch(setNotification({
          error: true,
          notificationState: true,
          message: "An error has been occured while adding rates on this site",
        }));

      }


    };


    return(
        <div className="hr-section">
            <div className="setting-body-title">
                <div className='setting-body-inner-title'>
                    Pay Table
                </div>
            </div>

            <div className="text">
                This is the Agenct pay table that will be use if there's no pay set in site group.
            </div>


            <Table
                className='payTable curvedTable'
                rowClassName={() => 'editable-row'}
                dataSource={dataSource}
                columns={remoteColumns}
                loading={agencyPayTableLoading || upsertAgencyPayTableLoading}
            />

        
          {
            payrateModal &&
              <PayRateModal 
                visible={payrateModal}
                close={()=>{
                  setPayrateModal(false);
                  setColumnToBeEdit(null);
                }}
                name={columnToBeEdit?.column}
                rawData={columnToBeEdit}
                label={`${data?.getPayandBillColumn?.response?.find((payBill)=>payBill?._id==columnToBeEdit?.column)?.columnName} rate`}
                handleSave={handleSave}
                moduleId={"agencyId"}
                loading={agencyPayTableLoading || upsertAgencyPayTableLoading}
              />
          }                   

          {
            rateAdjustmentModal?.modal &&
            <RateAdjustmentModal 
              visible={rateAdjustmentModal?.modal}
              close={()=>setRateAdjustmentModal({modal: false, data:{}})}
              data = {rateAdjustmentModal?.data}
              handleSave={handleSave}
              moduleId={"agencyId"}

            />
          }

        </div> 

        
    )
}