import { CaretDownFilled } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, DatePicker, Divider, Form, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";

import { getAgencies } from "@src/util/query/agency.query";
import { GET_BRANCHES } from "@src/util/query/branch.query";
import { getCustomerQuery } from '@src/util/query/customer.query';
import { getSiteGroups } from "@src/util/query/siteGroup.query";
import { getSitesQuery } from "@src/util/query/site.query";
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { getPayLevelQuery } from '@src/util/query/paylevel.query';
import dayjs from "dayjs";
import { SystemReportSaveCustomisation } from "./systemReportSaveCustomisation";
import { useNavigate } from "react-router-dom";
import { setReportFilters } from "../../middleware/redux/reducers/reportFilters.reducer";
import { useDispatch } from "react-redux";


export const SystemReportFilterModal = ({visible, close, reportDetail, reportFilter})=>{

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 3;

    const [filters, setFilters] = useState({
        header: {
            groupbyCustomer: true,
            groupbySitegroup: true,
            groupbySite: true,
            groupbyEmployee: false,
            groupbyBranch: false,
            addressDetail: false
        },
        dates: {
            from: dayjs().subtract(1, 'month').startOf('month'),
            to: dayjs().subtract(1, 'month').endOf('month')
        },
        conditions: {}
    });

    const tableDataTemplate = (id) => ({
        id,
        field: null,
        operator: null,
        value: null,
        join: null
      });
    
    const [tableData, setTableData] = useState([tableDataTemplate(0)]);

    useEffect(()=>{
        if(reportFilter && Object.keys(reportFilter)?.length>0){
            setFilters({...reportFilter, dates: {
                from: dayjs(reportFilter?.dates?.from, "DD/MM/YYYY"),
                to: dayjs(reportFilter?.dates?.to, "DD/MM/YYYY"),
            }});
            setTableData(reportFilter?.rawConditions)
        }
    },[reportFilter]);




    const [customisationModalVisible, setCustomisationModalVisible] = useState(false);

    const navigate = useNavigate();

    const [filterContent, setFilterContent] = useState("");


    const dispatch = useDispatch();


    const handelPayBillReport = async ()=>{
        try{
            const reportId = reportDetail?._id;
            
            dispatch(setReportFilters({
                reportFilters:
                    {filterContent, reportDetail, filters: {...filters, dates: {from: filters?.dates.from?.format("DD/MM/YYYY"), to: filters?.dates.to?.format("DD/MM/YYYY") } }}
                
            }));

            switch(reportId){
                // Employee Pay Detail Report
                case "67aa342154359f4def5c0e5e":
                    navigate('/user/sysreport/employeePayDetail', {state: {filterContent, reportDetail, filters: {...filters, dates: {from: filters?.dates.from?.format("DD/MM/YYYY"), to: filters?.dates.to?.format("DD/MM/YYYY") } }}});
                    break;

                // Pay Bill Report
                case "67aa33cb54359f4def5c0e31":
                    navigate('/user/sysreport/paybill', {state: {filterContent, reportDetail, filters: {...filters, dates: {from: filters?.dates.from?.format("DD/MM/YYYY"), to: filters?.dates.to?.format("DD/MM/YYYY") } }}});
                    break;

                // Employee Pay Summary Report
                case "67aa33ea54359f4def5c0e3f":
                    navigate('/user/sysreport/employeePaySummary', {state: {filterContent, reportDetail, filters: {...filters, dates: {from: filters?.dates.from?.format("DD/MM/YYYY"), to: filters?.dates.to?.format("DD/MM/YYYY") } }}});
                    break;


                // Billed Detailed Report
                case "67aa340154359f4def5c0e4c":
                    navigate('/user/sysreport/billDetailedReport', {state: {filterContent, reportDetail, filters: {...filters, dates: {from: filters?.dates.from?.format("DD/MM/YYYY"), to: filters?.dates.to?.format("DD/MM/YYYY") } }}});
                    break;


                // site duty performance report
                case "67aa340b54359f4def5c0e52":
                    navigate('/user/sysreport/siteSchedule', {state: {filterContent, reportDetail, filters: {...filters, dates: {from: filters?.dates.from?.format("DD/MM/YYYY"), to: filters?.dates.to?.format("DD/MM/YYYY") } }}});
                    break;

            }
                
        }catch(err){
            console.log(err);
        }
    };

    return (
        <Modal
            open={visible}
            width={'50%'}
            className="systemReportModalWrapper"
            closable={false}
            footer={
                <div className='drawer-footer' style={{display:'flex', justifyContent:'space-between'}}>


                    <button  
                        className={false?' disabled-btn drawer-filled-btn': 'drawer-outlined-btn'}
                        disabled={false}
                        style={{display:'flex', justifyContent:'end', alignItems:'end'}}
                        onClick={close}
                    >
                        Cancel
                    </button>
                    
                    <div style={{display:'flex', alignItems:'center', justifyContent:'start', gap:'20px'}}>
                        <button  
                            className={currentStep==1?' disabled-btn drawer-filled-btn': 'drawer-outlined-btn'}
                            disabled={currentStep==1}
                            onClick={()=>setCurrentStep(currentStep-1)}
                        >
                            Back
                        </button>
                        {
                            currentStep==totalSteps &&
                            <button  
                                onClick={()=>setCustomisationModalVisible(true)}
                                className={(currentStep!==totalSteps || filters?.length==0)?' disabled-btn drawer-filled-btn': 'drawer-filled-btn'}
                            >
                                Save Customisation
                            </button>
                        }
                        {
                            currentStep==totalSteps ?
                            <button  
                                onClick={handelPayBillReport}
                                className={(currentStep!==totalSteps || filters?.length==0)?' disabled-btn drawer-filled-btn': 'drawer-filled-btn'}
                            >
                                Run Report
                            </button>
                            :
                            <button  
                                className={currentStep==totalSteps?' disabled-btn drawer-filled-btn': 'drawer-filled-btn'}
                                disabled={currentStep==totalSteps}
                                onClick={()=>setCurrentStep(currentStep+1)}
                            >
                                Next
                            </button>
                        }


                        
                    </div>




                </div>
            }
        >

            <React.Fragment>

                {/* {contextHolder} */}
                <div className='modal-header-title'>
                    <span> Report - {reportDetail?.name} </span>
                    <span onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>

                <div className='modal-body' style={{height:'50vh'}}>
                    {
                        reportDetail?.description &&
                        <div className="text">
                            {reportDetail?.description}.
                            <Divider />
                        </div>
                    }

                    {
                        currentStep == 1?
                            <SystemReportHeader 
                                setFilters={setFilters} 
                                filters={filters} 
                                reportId={reportDetail?._id}
                            />
                        :
                        currentStep == 2?
                            <SystemReportDates 
                                setFilters={setFilters} 
                                filters={filters} 
                            />
                        :
                        currentStep == 3?
                            <SystemReportFilters 
                                setFilters={setFilters} 
                                filters={filters} 
                                setFilterContent={setFilterContent}
                                setTableData={setTableData}
                                tableData={tableData}
                                tableDataTemplate={tableDataTemplate}
                            />

                        :null
                    }
                    

                </div>

                <SystemReportSaveCustomisation 
                    visible={customisationModalVisible}
                    close={()=>setCustomisationModalVisible(false)}
                />
                

            </React.Fragment>


        </Modal>
    )
};


const SystemReportHeader = ({filters, setFilters, reportId})=>{
    const columns = [
        {
            title: 'Display?',
            align: 'center',
            dataIndex: 'display',
            render: (_, record)=>

                <div style={{textAlign:'center'}}>
                    {record?.display}
                </div>
            

        },
        {
            title: 'Header',
            align: 'center',
            dataIndex: 'header',
            render: (_, record)=>
                <div style={{textAlign:'left', fontWeight:'400'}}>
                    {record?.header}
                </div>
            
        },
        {
            title: 'Group By',
            align: 'center',
            dataIndex: 'groupby',
            render: (_, record)=>
                <div style={{textAlign:'left', fontWeight:'400'}}>
                    {record?.groupby}
                </div>
            
        }
    ];


    let dataSource = [
        {
            display: <Checkbox checked={filters?.header?.groupbyCustomer} onChange={(e)=>setFilters({...filters, header:{ ...filters.header, groupbyCustomer: e.target.checked, groupbyEmployee: false}})} ></Checkbox>,
            header: "Customer",
            groupby: "Customer name"
        },

        {
            display: <Checkbox checked={filters?.header?.groupbySitegroup} onChange={(e)=>setFilters({...filters, header:{ ...filters.header, groupbySitegroup: e.target.checked, groupbyEmployee: false}})}></Checkbox>,
            header: "Site Group",
            groupby: "Site Group name"
        },


        {
            display: <Checkbox checked={filters?.header?.groupbySite} onChange={(e)=>setFilters({...filters, header:{ ...filters.header, groupbySite: e.target.checked, groupbyEmployee: false}})}></Checkbox>,
            header: "Site",
            groupby: "Site name"
        }
    ];

    const handelEmployeeCheckbox = (checked)=>{
        if(checked){

            setFilters({...filters, 
                header:{ 
                    ...filters.header, 
                    groupbyCustomer: false, 
                    groupbySite: false, 
                    groupbySitegroup: false , 
                    groupbyEmployee: checked
                }
            });

        }else{
            setFilters({...filters, header:{ ...filters.header, groupbyEmployee: checked}});
        }
    };

    // Employee pay detail report

    useEffect(()=>{
        if(reportId=="67aa342154359f4def5c0e5e" && filters?.header?.groupbyCustomer && filters?.header?.groupbySite && filters?.header?.groupbySitegroup){
            setFilters({
                ...filters,
                header:{
                    ...filters.header,
                    groupbyCustomer: false,
                    groupbySitegroup: false,
                    groupbySite: false,
                    groupbyEmployee: true,
                }

            });
        }
    },[reportId, filters]);


    if(reportId=="67aa342154359f4def5c0e5e"){
        dataSource.push({
            display: <Checkbox checked={filters?.header?.groupbyEmployee} onChange={(e)=>handelEmployeeCheckbox(e.target.checked)}></Checkbox>,
            header: "Employee",
            groupby: "Employee name"
        });
    }

    // Employee Pay hour only report
    if(reportId=="67aa33ea54359f4def5c0e3f"){
        dataSource?.splice(0,2);
        dataSource.push({
            display: <Checkbox checked={filters?.header?.groupbyBranch}  onChange={(e)=>setFilters({...filters, header:{ ...filters.header, groupbyBranch: e.target.checked}})} ></Checkbox>,
            header: "Branch",
            groupby: "Branch name"
        },
        {
            display: <Checkbox checked={filters?.header?.groupbyEmployee} onChange={(e)=>handelEmployeeCheckbox(e.target.checked)}></Checkbox>,
            header: "Employee",
            groupby: "Employee name"
        });
    }

    //  Site duty performance report
    if(reportId=="67aa340154359f4def5c0e4c"){
        dataSource?.splice(0,2);

    }


    // site schedule report
    if(reportId=="67aa340b54359f4def5c0e52"){
        dataSource.push({
            display: <Checkbox checked={filters?.header?.addressDetail}  onChange={(e)=>setFilters({...filters, header:{ ...filters.header, addressDetail: e.target.checked}})} ></Checkbox>,
            header: "Site Address",
            groupby: ""
        })
    }




    return (
        <>
            <h3> Step 1 of 3 - Specify headers, sort columns and options. </h3>
            <div className="text"> Select the visible columns in the report, and the sort order: </div>
            

            <Table 
                columns={columns}
                dataSource={dataSource}
                className="curvedTable"
                pagination={false}
            />
        </>
    )
}

const SystemReportDates = ({filters, setFilters})=>{
    return(
        <>
            <h3> Step 2 of 3 -  Specify the date range of the report. </h3>
            
            <Form>

                <Form.Item>
                    <label>From</label>
                    <DatePicker value={filters?.dates?.from && dayjs(filters?.dates?.from, "DD/MM/YYYY")} format={"DD/MM/YYYY"} onChange={(rawDate, dateString)=>{setFilters({...filters, dates: {...filters.dates, from: rawDate} })}} className="generic-input-control"/>
                </Form.Item>
                <Form.Item>
                    <label>To</label>
                    <DatePicker value={filters?.dates?.to && dayjs(filters?.dates?.to, "DD/MM/YYYY")}  format={"DD/MM/YYYY"}   onChange={(rawDate, dateString)=>{setFilters({...filters, dates: {...filters.dates, to: rawDate} })}}  className="generic-input-control"/>
                </Form.Item>
            </Form>

        </>
    );
}

const SystemReportFilters = ({filters, setFilters, setFilterContent, tableData, setTableData, tableDataTemplate}) => {
    const { Option } = Select;
  
  
  
    const handelAddFilter = () => {
      setTableData([...tableData, tableDataTemplate(tableData.length)]);
    };
  
    const handelRemoveFilter = () => {
      setTableData(tableData.slice(0, tableData.length - 1));
    };

    const [searchKeyword, setSearchKeyword] = useState(null);
    const [moduleToQuery, setModuleToQuery] = useState(null);



    // agency query
    const [getAgency ,{data: agencyData, loading: agencyLoading}] = useLazyQuery(getAgencies, {fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: {advanceFilter: [
                    [{
                        operator: "suppliername", filter: "contain_exactly", filterValue: [searchKeyword]
                    }]
                ]}
            }
        }
    });

   

    // branch query

    const [getBranch ,{ loading, error, data: branchData}] = useLazyQuery(GET_BRANCHES,{
        fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: {advanceFilter: [
                    [{
                        operator: "branchname", filter: "contain_exactly", filterValue: [searchKeyword]
                    }]
                ]}
            }
        },
        skip: (moduleToQuery!=="branch" && searchKeyword?.length>2)
    });

    // customer query

    const [getCustomer ,{data: customerData, loading: customerLoading}] = useLazyQuery(getCustomerQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: {advanceFilter: [
                    [{
                        operator: "customername", filter: "contain_exactly", filterValue: [searchKeyword]
                    }]
                ]}
            }
        }
    });

    // site group query

    const [getSiteGroup, {data: siteGroupData, loading: siteGroupLoading}] = useLazyQuery(getSiteGroups,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: {advanceFilter: [
                    [{
                        operator: "sitegroupname", filter: "contain_exactly", filterValue: [searchKeyword]
                    }]
                ]}
            }
        }
    });

    // site query

    const [getSites, {data: siteData, loading: siteLoading}] = useLazyQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: {advanceFilter: [
                    [{
                        operator: "sitename", filter: "contain_exactly", filterValue: [searchKeyword]
                    }]
                ]}
          }
        }
    });


    // employee query


    const [getEmployee, {data: employeeData, loading: employeeDataLoading, refetch}] = useLazyQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: {advanceFilter: [
                    [{
                        condition: "or", operator: "firstname", filter: "contain_exactly", filterValue: [searchKeyword]
                    }],
                    [{
                        condition: "or", operator: "lastname", filter: "contain_exactly", filterValue: [searchKeyword]
                    }],


                ]}
          }
        },
        fetchPolicy: 'network-only'
    });
    


    // employee pay level
    const [getPayLevel, {data: dutyTypeData, loading: dutyTypeLoading, refetchDutyType}] = useLazyQuery(getPayLevelQuery,{
        fetchPolicy: 'network-only',
    });

    const [values, setValues] = useState([]);
    

    const handelChange = (id, field, value) => {
        if(field=="field"){
            setModuleToQuery(value);
        }

        setTableData((prevTableData) =>
          prevTableData.map((row) =>
            row.id === id ? { ...row, [field]: value } : row
          )
        );

    };



    useEffect(()=>{
        if(moduleToQuery && searchKeyword){
            switch(moduleToQuery){
                case 'agency':
                    getAgency();
                    break;

                case 'branch':
                    getBranch();
                    break;

                case 'customer':
                    getCustomer();
                    break;
                
                case 'sitegroup':
                    getSiteGroup();
                    break;

                case 'site':
                    getSites();
                    break;
                
                case 'employee':
                    getEmployee();
                    break;

                case 'employeeType':
                    getPayLevel();
                    break;

            }
        }
    }, [moduleToQuery, searchKeyword]);

    useEffect(()=>{
        setValues([]);
        if(moduleToQuery=="agency" && agencyData && searchKeyword?.length>0){
            setValues(agencyData?.agencies?.map((agency)=>({ _id: agency?._id, content: agency?.suppliername })));
        }else if(moduleToQuery=="branch" && branchData && searchKeyword?.length>0){
            setValues(branchData?.branches?.map((branch)=>({_id: branch?._id, content: branch?.branchname})));
        }else if(moduleToQuery=="customer" && customerData && searchKeyword?.length>0){
            setValues(customerData?.customers?.map((customer)=>({_id: customer?._id, content: customer?.customername})));
        }else if(moduleToQuery=="sitegroup" && siteGroupData && searchKeyword?.length>0){
            setValues(siteGroupData?.sitegroups?.map((sitegroup)=>({_id: sitegroup?._id, content: sitegroup?.sitegroupname})));
        }else if(moduleToQuery=="site" && siteData && searchKeyword?.length>0){
            setValues(siteData?.sites?.map((site)=>({_id:site?._id, content: site?.sitename})));
        }else if(moduleToQuery=="employee" && employeeData && searchKeyword?.length>0){
            setValues(employeeData?.getEmployee?.response?.map((employee)=>({_id: employee?._id, content: employee?.firstname+" "+(employee?.lastname || "")})));
        }else if(moduleToQuery=="employeeType" && dutyTypeData && searchKeyword?.length>0){
            setValues(dutyTypeData?.getPayLevel?.response?.map((duty)=>({_id: duty?._id, content: duty?.name})));
        }
    }, [agencyData, branchData, customerData, siteGroupData, siteData, employeeData, dutyTypeData, moduleToQuery, searchKeyword])

    let filterContent = "";
    const returnConditionalogic = (data, index)=>{
        let join='';
        if(index==tableData?.length-1){
            join="";
        }else{
            join = data["join"];
        }
        if(data.value?.includes("-")){
            switch(data.field){
                case 'agency':
                    if(data.operator=="="){
                        const value = data.value.split("-");
                        filterContent += " Agency is Equal "+value[1]+" "+join
                        return {
                            agencyId: value[0],
                        }
                    }else if(data.operator=="!="){
                        const value = data.value.split("-");
                        filterContent += " Agency is Not Equal "+value[1]+" "+join
                        return {
                            agencyId: {$ne: value[0]}
                        }
                    }
                    

                case 'branch': 
                    if(data.operator=="="){

                        const value = data.value.split("-");
                        filterContent += " Branch is Equal "+value[1]+" "+join

                        return {
                            branchId: value[0]
                        }
                    }else if(data.operator=="!="){

                        const value = data.value.split("-");
                        filterContent += " Branch is Not Equal "+value[1]+" "+join
                        return {
                            branchId: {$ne: value[0]}
                        }
                    }

                case 'customer':    
                    if(data.operator=="="){

                        const value = data.value.split("-");
                        filterContent += " Customer is Equal "+value[1]+" "+join

                        return {
                            customerId: value[0]
                        }   
                    }else if(data.operator=="!="){

                        const value = data.value.split("-");
                        filterContent += " Customer is Not Equal "+value[1]+" "+join
                        return {
                            customerId: {$ne: value[0]}
                        }
                    }

                case 'sitegroup':   
                    if(data.operator=="="){

                        const value = data.value.split("-");
                        filterContent += " Site Group is Equal "+value[1]+" "+join

                        return {
                            siteGroupId: value[0]
                        }
                    }else if(data.operator=="!="){

                        const value = data.value.split("-");
                        filterContent += " Site Group is Not Equal "+value[1]+" "+join
                        return {
                            siteGroupId: {$ne: value[1]}
                        }

                    }

                case 'site':    
                    if(data.operator=="="){

                        const value = data.value.split("-");
                        filterContent += " Site is Equal "+value[1]+" "+join

                        return {
                            siteId: value[0]
                        }
                    }else if(data.operator=="!="){

                        const value = data.value.split("-");
                        filterContent += " Site is Not Equal "+value[1]+" "+join
                        return {
                            siteId: {$ne: value[0]}
                        }
                    }

                case 'employee':
                    if(data.operator=="="){

                        const value = data.value.split("-");
                        filterContent += " Employee is Equal "+value[1]+" "+join

                        return {
                            assignedEmployee: value[0]
                        }
                    }else if(data.operator=="!="){

                        const value = data.value.split("-");
                        filterContent += " Employee is Not Equal "+value[1]+" "+join
                        return {
                            assignedEmployee: {$ne: value[0]}
                        }
                    }

                case 'employeeType':
                    if(data.operator=="="){

                        const value = data.value.split("-");
                        filterContent += " Employee Type is Equal "+value[1]+" "+join

                        return {
                            dutyTypeId: value[0]
                        }
                    }else if(data.operator=="!="){
                        const value = data.value.split("-");
                        filterContent += " Employee Type is Not Equal "+value[1]+" "+join
                        return {
                            dutyTypeId: {$ne: value[0]}
                        }
                    }
                
            }
        }
    }

    useEffect(()=>{
        const conditions = tableData?.map((data, index)=> returnConditionalogic(data, index));
        setFilters({...filters, conditions: conditions, rawConditions: tableData });
        setFilterContent(filterContent);
    }, [tableData]);


  
    return (
      <>
        <h3>Step 3 of 3 - Apply filters to the report.</h3>
        <div className="text">
          Apply filters to the report. Use the buttons to add or delete rows. &emsp;
          <button
            className="sm-btn"
            style={{ background: "#008000bf", color: "white" }}
            onClick={handelAddFilter}
          >
            Add
          </button>{" "}
          &emsp;
          <button
            className={tableData.length === 1 ? "disabled-btn sm-btn" : "sm-btn"}
            disabled={tableData.length === 1}
            style={{ background: "red", color: "white" }}
            onClick={tableData.length === 1 ? null : handelRemoveFilter}
          >
            Remove
          </button>
        </div>
  
        <table className="systemReportFilterTable">
          <thead>
            <tr>
              <th>Field name</th>
              <th>Operation</th>
              <th>Value</th>
              <th>Join</th>
            </tr>
          </thead>
  
          <tbody>
            {tableData.map((data) => (
              <tr key={data.id}>

                <td>
                  <Select
                    className="custom-select"
                    placeholder={"Select Field"}
                    style={{ width: "100%" }}
                    suffixIcon={<CaretDownFilled style={{ marginTop: "-10px" }} />}
                    value={data.field}
                    onChange={(e) => handelChange(data.id, "field", e)}
                    allowClear

                  >
                    <Option value={"agency"}>Agency</Option>
                    <Option value={"branch"}>Branch</Option>
                    <Option value={"customer"}>Customer</Option>
                    <Option value={"sitegroup"}>Site Group</Option>
                    <Option value={"site"}>Site</Option>
                    <Option value={"employee"}>Employee</Option>
                    <Option value={"employeeType"}>Employee Type</Option>
                  </Select>
                </td>

                <td>
                  <Select
                    className="custom-select"
                    placeholder={"Select Operator"}
                    style={{ width: "100%" }}
                    suffixIcon={<CaretDownFilled style={{ marginTop: "-10px" }} />}
                    value={data.operator}
                    onChange={(e) => handelChange(data.id, "operator", e)}
                    allowClear

                  >
                    <Option value="=">Equal (=)</Option>
                    <Option value="!=">Not Equal (!=)</Option>
                  </Select>
                </td>

                <td>
                  <Select
                    className="custom-select"
                    placeholder={"Select "+ (data.field || "Value")}
                    style={{ width: "100%" }}
                    suffixIcon={<CaretDownFilled style={{ marginTop: "-10px" }} />}
                    value={data.value?.includes("-")?data?.value?.split("-")[1]:data?.value}
                    showSearch
                    optionFilterProp="children"
                    onSearch={(e)=>{ setModuleToQuery(data?.field); setSearchKeyword(e);}}
                    onChange={(e) => handelChange(data.id, "value", e)}
                    allowClear
                  >
                    {
                        values?.map((value)=>(
                            <Option value={value?._id+"-"+value?.content}>{
                                value?.content
                            }</Option>
                        ))
                    }
                  </Select>
                </td>

                <td>
                  <Select
                    className="custom-select"
                    placeholder={"Select Join"}
                    style={{ width: "100%" }}
                    suffixIcon={<CaretDownFilled style={{ marginTop: "-10px" }} />}
                    value={data.join}
                    onChange={(e) => handelChange(data.id, "join", e)}
                    allowClear

                  >
                    <Option value="and">AND</Option>
                    <Option value="or">OR</Option>
                  </Select>
                </td>


              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
};