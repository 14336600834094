import "./post.css";
import { Avatar, Checkbox, message, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import ReactQuill from 'react-quill';
import multi from "@src/assets/img/multi-shade.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClose, faPhotoFilm, faPoll, faGlobeAsia, faSmile, faEdit, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Popover } from "antd";
import { useSelector } from "react-redux";
import { CaretDownFilled } from "@ant-design/icons";
import { PostPrivacy } from "./postPrivacy.component";
import { objectType } from "@src/util/types/object.types";
import { PrivacyModule } from "./privacyModule";
import { CreatePoll } from "./createPoll";
import EmojiPicker from 'emoji-picker-react';
import imageCompression from "browser-image-compression";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useMutation } from "@apollo/client";
import { newPostMutation } from "../../util/mutation/post.mutation";
import { genericFileUploadSignatureMutation } from "../../util/mutation/genericFileUpload.mutation";
import axios from "axios";
import Spinner from "@src/components/spinner";




const initalPrivacyState = {
    branch:{
        includeAll: false,
        include: [],
    },
    supplier:{
        includeAll: false,
        include: [],
    },
    customer:{
        includeAll: false,
        include: [],
    },
    site:{
        includeAll: false,
        include: [],
    },
    sitegroup:{
        includeAll: false,
        include: [],
    },
    employee:{
        includeAll: false,
        include: [],
    },
};

export const PostModal = ({ visible, close, refetchPost=()=>{} })=>{

    const [postContent, setPostContent] = useState("");
    const [bg, setBg] = useState("white");
    const [poll, setPoll] = useState(false);

    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);
    const { firstname, lastname, metadata:{imageURL}} = authenticatedUserDetail?.employeeDetail[0];

    const [privacyModal, setPrivacyModal] = useState(false);
    const [privacyVisibilityModal, setPrivacyVisibilityModal] = useState(false);
    const [activeVisibility, setActiveVisibility] = useState(null);

  

    useEffect(()=>{
        if(activeVisibility!=null){
            setPrivacyModal(false);
            setPrivacyVisibilityModal(true)
        }
    },[activeVisibility]);

    //==============================  Privacy Would be store here
    
    const [privacy, setPrivacy] = useState({...initalPrivacyState});

    //==============================   Privacy Store will be terminated here
    const savePrivacyChanges = ()=>{
        setPrivacyVisibilityModal(false);
        setPrivacy({
            ...initalPrivacyState,
            [activeVisibility?.toLowerCase().replace(/\s/g,"")]: privacy[activeVisibility?.toLowerCase().replace(/\s/g,"")],
        })
    };


    const [isChangeDisabled, setIsChangedDisabled] = useState(true);

    useEffect(()=>{
        if(activeVisibility){

            const isDisabled =  privacy[activeVisibility?.toLowerCase().replace(/\s/g,"")]?.include?.length==0 && privacy[activeVisibility?.toLowerCase().replace(/\s/g,"")]?.includeAll===false;
            
            setIsChangedDisabled(isDisabled);
        
        }
        
    },[privacy, activeVisibility]);


    // poll configuration
    const [isPollChangeDisabled, setIsPollChangedDisabled] = useState(true);
    const [pollConfiguration, setPollConfiguration] = useState(null);
    const [savePoll, setSavePoll] = useState(false);
    // poll configuration terminated here


    // handel media from here

    const [resizedImageBlob, setResizedImageBlob] = useState(null);
    const [media, setMedia] = useState(null);
    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
            
            setMedia(file);
            // Resize the image to 1200x630 pixels while retaining quality
            resizeImage(file);

        }
    };

    // Function to resize image to 1200x630
    const resizeImage = (imageFile) => {
        const img = new Image();
        const objectURL = URL.createObjectURL(imageFile);
        img.src = objectURL;

        img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the target size (1200x630)
        const targetWidth = 1200;
        const targetHeight = 630;

        // Resize based on aspect ratio
        let newWidth, newHeight;
        const aspectRatio = img.width / img.height;

        // Maintain aspect ratio if necessary
        if (img.width / img.height > targetWidth / targetHeight) {
            newWidth = targetWidth;
            newHeight = targetWidth / aspectRatio;
        } else {
            newHeight = targetHeight;
            newWidth = targetHeight * aspectRatio;
        }

        // Resize the canvas and draw the image on it
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        // Convert the canvas to a Blob (to upload or save the image)
        canvas.toBlob((blob) => {
            if (blob) {
            setResizedImageBlob(blob);
            }
        }, "image/jpeg", 0.9); // 90% quality to retain as much quality as possible
        };
    };

    // media terminated here

    const [postSubmitDisabled, setPostSubmitDisabled] = useState(true);
    useEffect(()=>{
        if((pollConfiguration?.hasOwnProperty('options') && pollConfiguration?.options?.every((option)=>option?.value)) || resizedImageBlob || postContent?.length>12){
            setPostSubmitDisabled(false);
        }else{
            setPostSubmitDisabled(true);
        }
        
    });


    const [newPost, {loading: newPostLoading}] = useMutation(newPostMutation);
    // use this mutation if we have to upload the media file
    const [getFileSignature, {loading: getFileSignatureLoading}] = useMutation(genericFileUploadSignatureMutation);

    const [isPostPublishing, setIsPostPublishing] = useState(false);
    const dispatch = useDispatch();

    const sanitizeHtml = (html) => {
        return html.replace(/<p><br><\/p>/g, '').trim(); // Remove extra spaces
    };
      
      

    const handelPost = async ()=>{
        setIsPostPublishing(true);
        try{

            if(media){
                const fileUploadSignature = await getFileSignature({
                    variables:{
                        input: {
                            fileName: "posts/"+media.name,
                            objectType: 'post'
                        }
                    }
                });
                const { data:{ genericFileUploadSignatureUrl :{ response:{url, originalFileName} }} } = fileUploadSignature;
                if(url){

                    await axios.put(url, media,{
                        headers: {
                            'Content-Type': media.type ,
                        },
                    });

                    await newPost({
                        variables:{
                            input:{
                                
                                content: sanitizeHtml(postContent),
                                pollConfiguration: pollConfiguration,
                                visibility: privacy,
                                color: bg,
                                media: originalFileName,
    
                            }
                        }
                    });
                    
                    

                }

            }else{

                await newPost({
                    variables:{
                        input:{
                            
                            content: sanitizeHtml(postContent),
                            pollConfiguration: pollConfiguration,
                            visibility: privacy,
                            color: bg,


                        }
                    }
                });
            }

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Post has been published"
            }));
            close();
            await refetchPost();

        }catch(err){
            
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: err?.message
            }));

        }
        setIsPostPublishing(false);

    };

    return (
        <Modal
            open={visible}
            width={650}
            footer={
                privacyModal?

                    <div className="drawer-footer" style={{justifyContent:'space-between'}}>

                        <div style={{marginTop:'-6px'}}>
                            <Checkbox>Set as default audience.</Checkbox>
                        </div>

                        <div style={{display:'flex', gap:'20px'}}>

                            <button disabled={false} className={false ? 'disabled-btn drawer-filled-btn' : 'drawer-outlined-btn'} onClick={()=>{ setPrivacyModal(false); setActiveVisibility(null); setPrivacyVisibilityModal(false); }}>
                                Cancel
                            </button>

                            <button disabled={!activeVisibility} className={!activeVisibility ? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>{setPrivacyModal(false); setPrivacyVisibilityModal(true);}}>
                                Next
                            </button>

                        </div>

                        
                    </div>
                :
                privacyVisibilityModal?

                    <div className="drawer-footer" style={{justifyContent:'right'}}>


                            <button disabled={false} className={false ? 'disabled-btn drawer-filled-btn' : 'drawer-outlined-btn'} onClick={()=>{setPrivacyModal(false); setActiveVisibility(null); setPrivacyVisibilityModal(false); setSavePoll(false); }}>
                                Cancel
                            </button>

                            <button disabled={isChangeDisabled} className={isChangeDisabled? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={savePrivacyChanges}>
                                Save changes
                            </button>

                        
                    </div>
                :
                poll?

                    <div className="drawer-footer" style={{justifyContent:'right'}}>


                            <button disabled={false} className={false ? 'disabled-btn drawer-filled-btn' : 'drawer-outlined-btn'} onClick={()=>{setPoll(false); setSavePoll(false);}}>
                                Cancel
                            </button>

                            <button disabled={isPollChangeDisabled} className={isPollChangeDisabled? 'disabled-btn drawer-filled-btn' : 'drawer-filled-btn'} onClick={()=>{setSavePoll(true);}}>
                                Save changes
                            </button>

                        
                    </div>

                :
                    null
            }
            closable={false}
            style={{marginTop:'-50px', borderRadius:'16px'}}
        >

{/* ================================================================================= */}

            {/* Header Start from Here */}

{/* ================================================================================= */}


            {
                privacyModal?
                    <div className='modal-header-title post-modal' style={{justifyContent:'left'}}>
                        <span  onClick={()=>{ setPrivacyModal(false); setPrivacyVisibilityModal(false); }}><FontAwesomeIcon className='close' icon={faArrowLeft}/></span>
                        <span style={{width:'90%', textAlign:'center'}}> {"Post audience"} </span>
                    </div>

                :
                privacyVisibilityModal?
                    <div className='modal-header-title post-modal' style={{justifyContent:'left'}}>
                        <span  onClick={()=>{ setPrivacyModal(true); }}><FontAwesomeIcon className='close' icon={faArrowLeft}/></span>
                        <span style={{width:'90%', textAlign:'center'}}> {activeVisibility==="Agency"? "Supplier" :activeVisibility} </span>
                    </div>

                :
                poll?
                    <div className='modal-header-title post-modal'>
                        <span> {"Create poll"} </span>
                        <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                    </div>

                :
                    <div className='modal-header-title post-modal'>
                        <span> {"Create post"} </span>
                        <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                    </div>
            }

{/* ================================================================================= */}

            {/* Header Terminated from Here */}

{/* ================================================================================= */}


            
            <div className="modal-body" style={(privacyModal || privacyVisibilityModal || poll)? {padding:'10px 24px', height:'435px'} : {padding: 0, height:'555px', paddingBottom:'15px'} }>
           
                {

                    // it open first modal to select who to show the post
                    privacyModal?
                        <PostPrivacy 
                            setActiveVisibility={setActiveVisibility}
                            activeVisibility={activeVisibility}
                            setPrivacyVisibilityModal={setPrivacyVisibilityModal}
                            privacyVisibilityModal={privacyVisibilityModal}
                            setPrivacyModal={setPrivacyModal}
                            privacyModal={privacyModal}
                        />
                    :

                    // it open second modal to select specific entity id based on select to who show this post
                    privacyVisibilityModal?

                        <PrivacyModule 
                            visibility = {activeVisibility}
                            privacy = {privacy}
                            setPrivacy = {setPrivacy}
                        />

                    :

                    // it will open when click on to create poll
                    // !pollConfiguration?.options?.every((option)=>option?.value) &&
                     poll?
                        <CreatePoll 
                            setIsPollChangedDisabled={setIsPollChangedDisabled}
                            setPollConfiguration={setPollConfiguration}
                            savePoll={savePoll}
                            close={()=>{setPoll(false); setSavePoll(false);}}
                            pollConfiguration={pollConfiguration}
                        />
                    :

                    <>
                    {/* this is the first and normal view to create the post */}

                        <div className={'poster post-user-info'}>

                            <div style={{display:'flex', gap:'20px', alignItems:'center', width:'100%'}}>
                                <div className='post-avatar'>
                                {imageURL?
                                    <Avatar size={40} src={imageURL} />
                                :
                                    <Avatar size={40}> {firstname[0]+lastname[0]} </Avatar>
                                }
                                </div>

                                <div >
                                    <div className="post-user-meta">{firstname+" "+lastname} </div>
                                    <div className="post-privacy-label" onClick={()=>setPrivacyModal(true)}>
                                        <FontAwesomeIcon icon={faGlobeAsia} className="post-globe-icon"/> 
                                        {activeVisibility===objectType.Agency? "Supplier" : activeVisibility || "Public"}
                                        <CaretDownFilled />
                                    </div>
                                </div>
                            </div>



                        </div>  
                        
                        <div className={
                            (pollConfiguration?.options?.every((option)=>option?.value))? 'poll-in-editor postlight' :
                            bg=="white"?
                            'postlight':' postdark mt16'
                        }
                        >

                            <ReactQuill
                                placeholder={"What do you want to talk about?"}
                                onChange={(e)=>setPostContent(e)}
                                value={postContent}
                                style={{color:bg=="white"?'black' :'white', fontWeight:'normal', fontSize:'30px', background: bg}}
                            />

                            {/* poll look like show in editor */}
                            {
                                pollConfiguration?.hasOwnProperty('options') && pollConfiguration?.options?.every((option)=>option?.value) ?
                                <div className="editor-poll">
                                    <div className="editor-poll-question">
                                        {pollConfiguration?.pollQuestion}
                                        <div className="row-flex">
                                            <FontAwesomeIcon icon={faPen} onClick={()=>setPoll(true)} className="close-icon cursor" style={{background:'rgb(0 0 0/.75)', fontSize:'12px'}}/>
                                            <FontAwesomeIcon icon={faTimes} onClick={()=>setPollConfiguration(null)} className="close-icon cursor" style={{background:'rgb(0 0 0/.75)'}}/>
                                        </div>
                                    </div>

                                    <div>
                                        {pollConfiguration?.options?.map((option)=>(
                                            <div className="editor-poll-option">{option?.value}</div>
                                        ))}
                                    </div>


                                    <div className="row-flex">
                                        <b style={{color:'#0091ae'}} className="cursor">0 votes</b>
                                        <span style={{padding:'0 2px', fontSize:'16px'}}>•</span>
                                        <div>{pollConfiguration?.pollDuration} left</div>
                                        <span style={{padding:'0 2px', fontSize:'16px'}}>•</span>
                                        <b style={{color:'#0091ae'}} className="cursor">View results</b>


                                    </div>
                                </div>
                                :null
                            }
                            
                            <div>

                        </div>

{/* =================================================================== */}
                            
                            {/* Post footer */}

{/* =================================================================== */}
                        
                            <div className="post-extra-option">
                                
                                <div style={{display:'flex', gap:'20px', alignItems:'center'}}>
                                    
                                    <Popover
                                        
                                        overlayClassName='notePopover'
                                        style={{width:'fit-content'}}
                                        content={
                                            <div style={{display:'flex', gap:'20px', alignItems:'center', padding:'10px 20px'}}>
                                                <div onClick={()=>setBg("white")} style={{background:'white', height:'29px', width:'29px', border:'1px solid lightgray'}} className='post-color'></div>
                                                <div onClick={()=>setBg("#33363D")}  style={{background:'#33363D'}} className='post-color'></div>
                                                <div onClick={()=>setBg("#0077b6")}  style={{background:'#0077b6'}} className='post-color'></div>
                                                <div onClick={()=>setBg("#7600bc")}  style={{background:'#7600bc'}} className='post-color'></div>
                                                <div onClick={()=>setBg("#8B0000")}  style={{background:'#8B0000'}} className='post-color'></div>
                                                <div onClick={()=>setBg("#6FC276")}  style={{background:'#6FC276'}} className='post-color'></div>
                                                <div onClick={()=>setBg("#FFDA29")}  style={{background:'#FFDA29'}} className='post-color'></div>
                                            </div>
                                        }
                                    >
                                        <div className='post-option' style={{height:'38px', width:'38px'}}>
                                            <img src={multi} style={{width:'100%'}} />
                                        </div>
                                    </Popover>
                                    
                                    {
                                        pollConfiguration?.hasOwnProperty('options') && pollConfiguration?.options?.every((option)=>option?.value) || resizedImageBlob?
                                        null
                                        :
                                        <>
                                            <div className='post-option' onClick={()=>setPoll(true)}>
                                                <FontAwesomeIcon icon={faPoll} style={{fontSize:'20px'}}/>    Poll
                                            </div>

                                            <div className='post-option' onClick={() => document.getElementById("fileInput").click()} >
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    onChange={handleFileChange}
                                                    id="fileInput"
                                                />
                                                <FontAwesomeIcon icon={faPhotoFilm} style={{fontSize:'20px'}}/>    Media
                                            </div>
                                        </>
                                    }
                                    {resizedImageBlob &&
                                        <div style={{width:'50px', borderRadius:'16px', position:'relative'}}>
                                            <img
                                                src={URL.createObjectURL(resizedImageBlob)}
                                                alt="Resized"
                                                className="cursor"
                                                style={{ width: "100%", borderRadius:'4px'}}
                                                onClick={()=>window.open(URL.createObjectURL(resizedImageBlob))}
                                            />
                                            <FontAwesomeIcon icon={faTimes} onClick={()=>{setResizedImageBlob(null)}} className="post-close-icon"/>
                                    </div>
                                    }



                                </div>
                                
                                
                                <div className='post-option'>
                                    <Popover
                                        content={
                                            <EmojiPicker />
                                        }
                                        trigger={"click"}
                                    >
                                        <FontAwesomeIcon icon={faSmile} style={{fontSize:'20px'}}/>
                                    </Popover>
                                </div>


                            </div>

                            <button 
                                className={
                                    postSubmitDisabled ?
                                    'drawer-outlined-btn post-btn disabled-btn':
                                    'drawer-outlined-btn post-btn'
                                }
                                style={{margin:'0 16px'}}
                                onClick={handelPost}
                                disabled={isPostPublishing || postSubmitDisabled}
                            >
                                {isPostPublishing?
                                <>
                                    <Spinner />  Publishing...
                                </>
                                : "Post"}
                            </button>

{/* =================================================================== */}
                            
                            {/* Post footer terminated */}

{/* =================================================================== */}
                            
                        </div>   
                    </>


                }



            </div>

        </Modal>
    )
}