import { gql } from "@apollo/client";

export const votePostPollMutation = gql`
    mutation($input: PostPollInput!){
        votePostPoll(input: $input) {
            message
            response
        }
    }
`;

export const deleteVotePostPollMutation = gql`
mutation($employeeId: String!, $postId: String!){
  deleteVotePostPoll(employeeId: $employeeId, postId: $postId) {
    message
    response
  }
}
`;