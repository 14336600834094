import { Radio, Select } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faCalendar, faChevronLeft, faClose, faExternalLink, faSearch } from '@fortawesome/free-solid-svg-icons';
import { CaretDownFilled } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { getCustomerQuery } from '@src/util/query/customer.query';
import { GET_BRANCHES } from "@src/util/query/branch.query";
import { useEffect, useState } from "react";
import { getSiteGroups } from "@src/util/query/siteGroup.query";
import { getAgencies } from "@src/util/query/agency.query";
import { getSitesQuery } from "@src/util/query/site.query";
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import "./advanceFilter.css";



export const PlainFilter = ({
    handelFilter, clearFilter, 
    handelOption, setFilterValueSearch, 
    filtervalues, filterValueSearch, handelChange, 
    setFilterValues, selectedFilter, singleFilter,
    setFilterEnable, upsertFilter
})=>{
    return(
        <>
        <div className='back-btn' onClick={clearFilter}> <FontAwesomeIcon style={{fontSize: '8px'}} icon={faChevronLeft}/> <span>Back</span> </div>
        <div className="h5">{selectedFilter?.label}</div>
        <div className="text">All filters are in the account's time zone</div>
        <Radio.Group onChange={(e)=>handelFilter(e)} className='advanceFilterRadio'>
            <Radio value={"contain_exactly"}>contains exactly</Radio>
            {singleFilter=="contain_exactly" ? <TagString
                selectedFilter={selectedFilter}
                handelOption={handelOption} handelChange={handelChange} setFilterValues={setFilterValues}
                setFilterValueSearch={setFilterValueSearch} filtervalues={filtervalues} filterValueSearch={filterValueSearch}
            /> : null}
            <Radio value={"not_contain"}>doesn't contain exactly</Radio>
            {singleFilter=="not_contain" ? <TagString 
                selectedFilter={selectedFilter}
                handelOption={handelOption} handelChange={handelChange} setFilterValues={setFilterValues}
                setFilterValueSearch={setFilterValueSearch} filtervalues={filtervalues} filterValueSearch={filterValueSearch}/> : null}
            <Radio value={"is_known"}>is known</Radio>
            <Radio value={"is_unknown"}>is unknown</Radio>
        </Radio.Group>
        <button className='filter-btn' style={{marginTop:'10%'}} onClick={()=>{
            upsertFilter({

                operator: selectedFilter.label, 
                filter:singleFilter, 
                filterValue: filtervalues,
                propId: selectedFilter.id

            });
            setFilterEnable(false);
            setFilterValues([]);
            clearFilter();
        }}>
          Apply filter
        </button>
        </>
    )
}

const TagString = ({selectedFilter, handelOption, handelChange, setFilterValues, setFilterValueSearch, filtervalues, filterValueSearch})=>{
    
    
    const {data: customerData, loading: customerLoading} = useQuery(getCustomerQuery,{
        variables: {
            input: {
                filters: null
            }
        },
        fetchPolicy:'network-only'
      });
      
      const { data: branchData, } = useQuery(GET_BRANCHES ,{
        fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
      });

      const {data: siteGroupData, loading: siteGroupLoading} = useQuery(getSiteGroups,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
      });

        // Agency list if needed to load

      const {data: agencyData, loading: agencyLoading, refetch} = useQuery(getAgencies,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
      });

      //   site list

      const {data: siteData, loading: siteLoading} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
          variables: {
              input: {
                  filters: null
              }
          }
      });

      const {data: employeeData, loading: employeeDataLoading,} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        },
        fetchPolicy: 'network-only'
     });

      const [localGroup, setLocalGroup] = useState(branchData?.branches||[]);
      useEffect(()=>{
        if(branchData?.branches){
          setLocalGroup(branchData?.branches)
        }
      }, [branchData?.branches]);


    return(
        <div style={{marginBottom:'1%'}} className="advanceFilterSelect">
            {
                selectedFilter?.type=="branch"?  
                
                <Select
                    showSearch
                    optionFilterProp="children"
                    className="custom-select "
                    placeholder="Branch..."
                    style={{width:'100%',marginTop:"1%", marginBottom:"2%",}} 
                    value={filtervalues}
                    suffixIcon={<CaretDownFilled style={{color:'#0091ae'}} />}
                    onChange={(e)=>setFilterValues([e])}
                >
                    {
                        localGroup?.length && localGroup?.map((gl)=>(
                            <Option key={gl._id} value={gl._id}>{gl.branchname}</Option>
                        ))
                    }
                </Select>
                
                :
                selectedFilter?.type=="customer"?  
                
                <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Customer..."
                    style={{width:'100%',marginTop:"1%", marginBottom:"2%"}} 
                    value={filtervalues}
                    suffixIcon={<CaretDownFilled style={{color:'#0091ae'}} />}
                    onChange={(e)=>setFilterValues([e])}
                >
                    {customerData?.customers?.map((option)=>(<Option value={option._id} label={option?.customername}> {option?.customername} </Option>))}

                </Select>
                :
                selectedFilter?.type=="sitegroup"?  
                
                <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Site group..."
                    style={{width:'100%',marginTop:"1%", marginBottom:"2%"}} 
                    value={filtervalues}
                    suffixIcon={<CaretDownFilled style={{color:'#0091ae'}} />}
                    onChange={(e)=>setFilterValues([e])}
                >
                    {siteGroupData?.sitegroups?.map((option)=>(<Option value={option._id} label={option?.sitegroupname}> {option?.sitegroupname} </Option>))}

                </Select>
                :
                selectedFilter?.type=="site"?  
                
                <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Site..."
                    style={{width:'100%',marginTop:"1%", marginBottom:"2%"}} 
                    value={filtervalues}
                    suffixIcon={<CaretDownFilled style={{color:'#0091ae'}} />}
                    onChange={(e)=>setFilterValues([e])}
                >
                    {siteData?.sites?.map((option)=>(<Option value={option._id} label={option?.sitename}> {option?.sitename} </Option>))}
                    
                </Select>
                :
                selectedFilter?.type=="agency"?  
                
                <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Agency..."
                    style={{width:'100%',marginTop:"1%", marginBottom:"2%"}} 
                    value={filtervalues}
                    suffixIcon={<CaretDownFilled style={{color:'#0091ae'}} />}
                    onChange={(e)=>setFilterValues([e])}
                >
                    {agencyData?.agencies?.map((option)=>(<Option value={option._id} label={option?.suppliername}> {option?.suppliername} </Option>))}
                    
                </Select>
                :
                selectedFilter?.type=="employee"?  
                
                <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Employee..."
                    style={{width:'100%',marginTop:"1%", marginBottom:"2%"}} 
                    value={filtervalues}
                    suffixIcon={<CaretDownFilled style={{color:'#0091ae'}} />}
                    onChange={(e)=>setFilterValues([e])}
                >
                    {employeeData?.getEmployee?.response?.map((option)=>(<Option value={option._id} label={option?.firstname}> {option?.firstname} {option?.lastname} </Option>))}
                    
                </Select>

                :selectedFilter?.prop?.options?
                    <Select
                        className='custom-select ' 
                        style={{width:'100%',marginTop:"1%", marginBottom:"2%"}} 
                        value={filtervalues}
                        suffixIcon={<CaretDownFilled style={{color:'#0091ae'}} />}
                        onChange={(e)=>setFilterValues([e])}
                    >
                        {selectedFilter?.prop?.options?.map((option)=>(
                            <Option value={option?.value}>{option?.key}</Option>
                        ))}
                    </Select>
                :
                <>
                    <Select 
                        mode='tags' 
                        className='custom-select ' 
                        style={{width:'100%',marginTop:"1%", marginBottom:"2%"}} 
                        value={filtervalues}
                        onSearch={(e)=>setFilterValueSearch(e)}
                        onInputKeyDown={(e)=>{if(e.key==="Enter"){setFilterValues([...filtervalues, filterValueSearch]);setFilterValueSearch("")}}}
                        open={false}
                        suffixIcon={<CaretDownFilled style={{color:'#0091ae'}} />}
                        onChange={handelChange}
                    />
                    {
                        filterValueSearch ?
                        <div className='createOption text'  style={{marginBottom:'0',marginTop:'-0.6%', color: ""}}>
                            <span onClick={handelOption}> Create option as "{filterValueSearch}"  </span><FontAwesomeIcon icon={faClose} onClick={()=>setFilterValueSearch(null)}/>
                        </div> : null
                    }
                </>
            }
        </div>
    );
}