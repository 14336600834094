import './assets/default.css';
import 'react-resizable/css/styles.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-quill/dist/quill.snow.css';
import "./app.css";
import {BrowserRouter, Route, Routes, Navigate, useLocation, useNavigate} from 'react-router-dom';
import { Login } from "./pages/login";
import { DefaultLayout } from "./layout/defaultLayout";
import { Employee } from "./pages/employee";
import { Branch } from "./pages/branch";
import { EditForm } from "./pages/editBranchForm/editForm.page";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import { FormView } from './pages/formView/formView';
import { BranchDetailPage } from './pages/branchDetailPage/branchDetailPage';
import { AllProperties } from './pages/allProperties/allProperties.page';
import { BranchAllPropHistory } from './pages/branchAllPropHistory/branchAllPropHistory.page';
import { SiteGroup } from './pages/sitegroup/sitegroup.page';
import { EditEmployeeForm } from './pages/employee/editEmployeeForm/editEmployeeForm';
import { EmployeeDetailPage } from './pages/employeeDetailPage/employeeDetail.page';
// import { EmployeeDetailViewPage } from './pages/employeeDetailPage/employeeDetailView.page';
import { EmployeeAllPropHistory } from './pages/employeeAllPropHistory/employeeAllPropHistory';
import { Password } from './pages/login/password';
import { PrivateRoutes } from './util/routes/private.routes';
import { ApolloProvider, useLazyQuery, useQuery } from '@apollo/client';
import { privateClient, publicClient } from './config/apollo';
import { useErrorBoundary } from './util/errorBoundary/errorboundary';
import { ErrorFallback } from './util/errorFallback/errorFallback';
import { isLoginCheckQuery } from './util/query/user.query';
import { UnAuthroizedAccess } from './pages/unAuthroizedAccess/unAuthroizedAccess.page';
import { Join } from './pages/join/join';
import { useDispatch } from 'react-redux';
import { setNotification } from './middleware/redux/reducers/notification.reducer';
import { UserPerference } from './pages/setting/userPerference/userPerference';
import { SiteGroupDetailPage } from './pages/sitegroup/sitegroupDetailPage/sitegroupDetail.page';
import { CustomerPage } from './pages/customer/customer.page';
import { CustomerDetailPage } from './pages/customer/customerDetailPage/customerDetail.page';
import { AgencyPage } from './pages/agency/agency.page';
import { AgencyDetailPage } from './pages/agency/agencyDetailPage/agencyDetail.page';
import { SitePage } from './pages/site/site.page';
import { SiteDetailPage } from './pages/site/siteDetailPage/siteDetail.page';
import { SchedulePage } from './pages/schedule/schedule.page';
import { TaskPage } from './pages/task/task.page';
// this manage the external logs that is BOC
import { ExternalSiteLog } from './pages/externalSiteLogPage/externalSiteLog.page';
import { FilePreview } from './pages/filePreview/filePreview';
import { ShareDocument } from './pages/shareDocument/shareDocument';
import { ExternalFilePreview } from './pages/externalFilePreview/externalFilePreview';
import { TimelinePage } from './pages/timeline/timeline.page';
import { PublicAccessDutyReport } from './pages/schedule/publicAccessDutyReport/publicAccessDutyReport';
import { PublicAccessDutyReportLanding } from './pages/schedule/publicAccessDutyReport/PublicAccessDutyReportLanding';
import { WardSecurityMobileReport } from './reports/mobileReports/wardSecurity/wardSecurityMobileReport';
import SystemReport from './pages/systemReports/systemReports.page';
import { SystemReportPayBillView } from './pages/systemReports/reportViews/systemReportPayBillView';
import { SystemReportEmployeePayDetailView } from './pages/systemReports/reportViews/systemReportEmployeePayDetail';
import { SystemReportEmployeePaySummaryView } from './pages/systemReports/reportViews/systemReportEmployeePaySummary';
import { SystemReportBillDetailedView } from './pages/systemReports/reportViews/billDetailReport';
import { SystemReportSiteScheduleView } from './pages/systemReports/reportViews/siteScheduleReport';
// HolidayFeed./pages/filePreview/filePreview

const useNetworkStatus = () => {
  const [effectiveType, setEffectiveType] = useState(navigator.connection?.effectiveType || "unknown");

  useEffect(() => {
    const updateNetworkInfo = () => {
      setEffectiveType(navigator.connection.effectiveType);
    };

    navigator.connection?.addEventListener("change", updateNetworkInfo);

    return () => {
      navigator.connection?.removeEventListener("change", updateNetworkInfo);
    };
  }, []);

  return effectiveType; // e.g., '4g', '3g', '2g', 'slow-2g'
};


function App() {



  const dispatch = useDispatch();

  const [isOffline, setIsOffline] = useState(true);

  useEffect(()=>{
    dispatch(setNotification({}));
    setInterval(() => {  
      if (navigator.onLine) {
        setIsOffline(true)
      } else {
        setIsOffline(false);
      }
    }, 100);

  },[]);

  useEffect(() => {
    const checkScreen = () => {

      // const isSmallScreen = window.innerWidth <= 1550;
      const isSmallScreen = window.innerWidth <= 1690;
  
      if (isSmallScreen) {
        document.body.classList.add("mac-small");
      } else {
        document.body.classList.remove("mac-small");
      }
    };
  
    checkScreen(); // Check initially
    window.addEventListener("resize", checkScreen);
  
    return () => window.removeEventListener("resize", checkScreen); // Cleanup
  }, []);
  
  
  const {notificationToast} = useSelector(state => state.notificationReducer);
  const [api, contextHolder] = notification.useNotification();

  useEffect(()=>{
    if(Object.keys(notificationToast).length > 0){
      if(notificationToast?.error){

        api.error({
          message:notificationToast.message,
          placement: notificationToast?.placement || "top",
          className: 'notification-without-close',
        });

      }else{
        api.success({
          message:notificationToast.message,
          placement: notificationToast?.placement || "top",
          className: 'notification-without-close',
        });
      }
      dispatch(setNotification({}));
    }
  },[notificationToast]);

  const hasError = useErrorBoundary();

  useEffect(() => {
    // Check if the browser supports the Notification API
    if (!('Notification' in window)) {
      console.error('This browser does not support notifications.');
      return;
    }

    // Request permission for notifications
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      } else {
        console.error('Notification permission denied.');
      }
    });


  }, []);

  const networkStatus = useNetworkStatus();


  return (
    hasError?
    <ErrorFallback/>
    :
    <>
    {contextHolder}

    {(!isOffline || ["2g", "slow-2g", "3g"].includes(networkStatus)) &&
      <div className='offline'>
        {
          !isOffline? "Your are currently offline. reconnecting ...": 
          ["2g", "slow-2g", "3g"].includes(networkStatus) ? "⚠️ Your internet connection is unstable! You may experience interruptions in real-time updates." :""
        }
        
      </div>
    }

    <BrowserRouter>

      {/* public routes */}
      <ApolloProvider client={publicClient}>
        <Routes>
          {localStorage.getItem('authToken') ? <Route path='/' element={<Navigate to={"/user/employee"} />} /> :
          <Route path='/' element={<Login/>} /> }
          <Route path="/pwd" element={<Password/>} />
          <Route path="/site/:siteId/:siteName/log" element={<ExternalSiteLog/>} />

          {/* schedule report */}
          <Route path='/schedulereporting/:templateId/:scheduleId/:siteId' element={<PublicAccessDutyReportLanding />} />
          <Route path='/schedulereport' element={<PublicAccessDutyReport />} />
          <Route path="/join/:employeeId" element={<Join/>} />
          <Route path="/preview" element={<ExternalFilePreview />} />

            {/* specific customer report design */}
          <Route path='wardreport/:jobId' exact element={<WardSecurityMobileReport/>}/>
          
        </Routes>
      </ApolloProvider>


      {/* private Routes */}
      <ApolloProvider client={privateClient}>

        <Routes>
          {/* Error Fall back route */}
          <Route path="/error" exact element={<UnAuthroizedAccess/>}/>
          
          {/* Error Fall back route terminated*/}


          <Route path='/formview' element={<FormView/>} />
          <Route path='/branch/editform' exact element={<EditForm />} />
          <Route path='/employee/editform' exact element={<EditEmployeeForm />} />

          {/* private routes */}
          <Route path='/user/' element={<PrivateRoutes><DefaultLayout/></PrivateRoutes>}>


            {/* System reports */}
            <Route path='sysreport/:category?' exact element={<SystemReport/>} />
            <Route path='sysreport/paybill' exact element={<SystemReportPayBillView/>} />
            <Route path='sysreport/employeePayDetail' exact element={<SystemReportEmployeePayDetailView/>} />
            <Route path='sysreport/employeePaySummary' exact element={<SystemReportEmployeePaySummaryView/>} />
            <Route path='sysreport/billDetailedReport' exact element={<SystemReportBillDetailedView/>} />
            <Route path='sysreport/siteSchedule' exact element={<SystemReportSiteScheduleView/>} />
            {/* System reports terminated */}

            
            <Route path='perference' exact element={<UserPerference/>}/>
            
            <Route path='schedule' exact element={<SchedulePage/>}/>

            <Route path='preview' exact element={<FilePreview />} />
            <Route path='share/:fileId' exact element={<ShareDocument />} />

            <Route path='branch' exact element={<Branch />}/>
            <Route path='branch-detail/:id' element={<BranchDetailPage/>} />
            <Route path='branch-detail/:id/:edit?' element={<BranchDetailPage/>} />
            <Route path='branch-detail/:id/tab/:tab?' element={<BranchDetailPage/>} />
            <Route path='branch-detail/:id/tab/:tab?/:folderId?' element={<BranchDetailPage />} />

            <Route path='prophistory' element={<BranchAllPropHistory/>} />

            <Route path='employee' element={<Employee/>} />
            <Route path='employee-detail/:id/:edit?' element={<EmployeeDetailPage/>} />
            <Route path='employee-detail/:id/tab/:tab?/:documentId?' element={<EmployeeDetailPage/>} />
            <Route path='employee-detail/:id/tab/:tab?/folder/:documentId?' element={<EmployeeDetailPage/>} />

            {/* <Route path='employee-detail-view/:id' element={<EmployeeDetailViewPage/>} /> */}
            <Route path='employee-prop-history/:id' element={<EmployeeAllPropHistory/>} />
            

            <Route path='allproperties' element={<AllProperties/>} />
            
            {/* site group */}
            <Route path='sitegroup' element={<SiteGroup/>} />
            <Route path='sitegroup-detail/:id/:edit?' element={<SiteGroupDetailPage/>} />
            <Route path='sitegroup-detail/:id/tab/:tab' element={<SiteGroupDetailPage/>} />

            {/* site */}
            <Route path='site' element={<SitePage/>} />
            <Route path='site-detail/:id/:edit?' element={<SiteDetailPage/>} />
            <Route path='site-detail/:id/tab/:tab/:operationTab?' element={<SiteDetailPage/>} />
            <Route path='site-detail/:id/tab/:tab?/folder/:documentId?' element={<SiteDetailPage/>} />


            {/* Customer */}
            <Route path='customer' element={<CustomerPage/>} />
            <Route path='customer-detail/:id' element={<CustomerDetailPage/>} />
            <Route path='customer-detail/:id/:edit?' element={<CustomerDetailPage/>} />
            <Route path='customer-detail/:id/tab/:tab' element={<CustomerDetailPage/>} />
            <Route path='customer-detail/:id/tab/:tab?/folder/:documentId?' element={<CustomerDetailPage/>} />


            {/* agency */}
            <Route path='agency' element={<AgencyPage/>} />
            <Route path='agency-detail/:id/:edit?' element={<AgencyDetailPage/>} />
            <Route path='agency-detail/:id/tab/:tab' element={<AgencyDetailPage/>} />
            <Route path='agency-detail/:id/tab/:tab?/folder/:documentId?' element={<AgencyDetailPage/>} />


            {/* Task */}
            <Route path='task' element={<TaskPage/>} />

            <Route path='timeline' element={<TimelinePage/>} />


            {/* all views shared page */}
            {/* <Route */}

             
          </Route>

          {/* setting navigation layout under hte main navigation*/}
          {/* <Route path={routes.setting} element={<PrivateRoutes><Setting /></PrivateRoutes>}  > */}
            {/* <Route index element={<User/>} />
            <Route path={routes.propertySetting} exact element={<PropertySetting/>} />
            <Route path={routes.docSetting} exact element={<ModuleDocuments/>} />
            <Route path={routes.addUser} element={<User/>} />
            <Route path={routes.userRole} element={<UserRole/>} />
            <Route path={routes.userAccess} element={<UserAccess/>} />
            <Route path={"user/:employeeId"} element={<UserDetailPage/>} />
            <Route path={routes.forms} element={<ModuleForms/>} />

            <Route path={routes.employeeEditForm} exact element={<EditEmployeeForm />} />
            <Route path={routes.branchEditForm} exact element={<EditForm />} />
            <Route path={routes.editskill} exact element={<SkillSetting/>} /> */}

            {/* objects */}
            {/* <Route path={routes.module} exact element={<Module/>} /> */}

            {/* finance */}

            {/* <Route path={routes.paybillcol}  element={<PayBillColumn/>} />
            <Route path={routes.paylevel}    element={<PayLevel/>} />
            <Route path={routes.shifttype}   element={<ShiftType/>} />
            <Route path={routes.paytable}    element={<PayTable/>} />
            <Route path={routes.premium}     element={<Premiums/>} />
            <Route path={routes.calculatepaybill}     element={<CalculatePayBill/>} /> */}

            {/* report management */}
            {/* <Route path={routes.reportField}   element={ <ReportDataFieldSetting/> }  />
            <Route path={routes.reportTemplates} element={<ReportTemplatePage />} />
            <Route path={routes.systemReport} />
            <Route path={routes.scheduleEvent} element={<ScheduleEvents />} /> */}

            {/* billing */}

            {/* <Route path={routes.service} exact element={<PrivateRoutes><BillingServices/></PrivateRoutes>}/>
            <Route path={routes.payment} exact element={<PaymentMethod/>}/>
            <Route path={routes.billingNotification} exact element={<BillingNotification/>}/> */}
            
          {/* </Route> */}
          
        </Routes>

      </ApolloProvider>


    </BrowserRouter>
    </>
  );
}

export default App;
