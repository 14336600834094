import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { getPayBillReportMutation } from "@src/util/mutation/systemReport.mutation";
import Spinner from "@src/components/spinner";
import { useSelector } from "react-redux";
import { saveAs } from 'file-saver';
import Papa from "papaparse";


// to do holiday bill and hour
// if first date is in holiday then first date hour and pay and bill will goes to holiday section and regular would be zero
// vice versa for second date as well
// Bank Holiday 65ff5efdcdef94de7cabc3cb

export const SystemReportSiteScheduleView = () => {
    
    const location = useLocation();
    const {filters = {}, filterContent = '', reportDetail = {}} = location?.state || {};
    
    
    const [reportData, setReportData] = useState([]);
    const navigate = useNavigate();

    const [getPayBillReport, {loading: getPayBillReportLoading}] = useMutation(getPayBillReportMutation);

    const handelPayBillReport = async ()=>{
        try{
            if(filters?.conditions){

                const {data:{getPayBillReport: {response}}} = await getPayBillReport({
                    variables:{
                        filters: {conditions: filters.conditions[0]!=null? filters.conditions: [], date: {from: dayjs(filters?.dates?.from, "DD/MM/YYYY"), to: dayjs(filters?.dates?.to, "DD/MM/YYYY")}}
                    }
                });

                if(response?.length==0){
                    alert("There is no pay/bill calculated in the selected dates");
                    navigate(-1);
                }else{

                    setReportData(response);
                }

            }
        }catch(err){
            console.log(err);
        }
    };

    useEffect(()=>{
        if(filters){

            handelPayBillReport();

        }
    },[filters]);

    const exportToCSV = ()=>{

        const groupedData = groupByMultipleKeys(reportData, ["siteId"]);
        let csvData = [];

        Object.keys(groupedData)?.map((group)=>{
            groupedData[group]?.map((job)=>{
                
                const response = {
                    "Employee Name": job?.employeeDetail?.firstname+" "+(job?.employeeDetail?.lastname || ""),
                    "Day": dayjs(job?.date, "DD/MM/YYYY").format("dddd"),
                    "Schedule Start": dayjs(job?.firstDate).format("DD/MM/YYYY HH:mm"),
                    "Schedule Finish": dayjs(job?.shiftEndDateTime).format("DD/MM/YYYY HH:mm"),
                    "Hours": `${convertToHrsMin( parseFloat(job?.firstDateHours) + (parseFloat(job?.secondDateHours) || 0.00) )}`,
                    "Site": job?.siteDetail?.sitename,
                    "Site Address": job?.siteDetail?.metadata?.address,
                    "Site Postcode": job?.siteDetail?.postcode,
                    "Site Group": job?.sitegroupsDetail?.sitegroupname,
                    "Customer": job?.customerDetail?.customername,

                };

                csvData.push(response);

            });
        });

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const name =  reportDetail?.name+"- "+dayjs().format("DD/MM/YYYY");
        saveAs(blob, `${name}.csv`);

    };

    const groupByMultipleKeys = (data, keys) => {
        if (!keys.length) return data; // If no keys are provided, return original data
      
        return data.reduce((acc, item) => {
          let currentLevel = acc;
      
          // Iterate through keys and build nested structure
          keys.forEach((key, index) => {

            let keyValue = "Unknown";

            if(key=="customerId"){
                keyValue = item[key]+"_"+item['customerDetail']['customername'].replaceAll(" ", "-")+"_customer";
            }
            else if(key=="siteGroupId"){
                keyValue = item[key]+"_"+item['sitegroupsDetail']['sitegroupname'].replaceAll(" ", "-")+"_sitegroup";
            }
            else if(key=="siteId"){
                keyValue = item[key]+"_"+item['siteDetail']['sitename'].replaceAll(" ", "-")+"_site";
            }
      
            if (!currentLevel[keyValue]) {
              currentLevel[keyValue] = index === keys.length - 1 ? [] : {}; // Last level stores array, others are objects
            }
      
            // Move to the next nested level
            currentLevel = currentLevel[keyValue];
          });
      
          // Push item to the last nested level
          currentLevel.push(item);
          return acc;
        }, {});
    };

    const [groupedData, setGroupedData] = useState([]);

    const groupAndSum = (data, keys) => {
        if (!keys.length) return data; // If no keys are provided, return original data
    
        return data.reduce((acc, item) => {
            let currentLevel = acc;
    
            // Build dynamic keys for grouping
            keys.forEach((key, index) => {
                let keyValue = "Unknown";
    
                if (key === "customerId") {
                    keyValue = `${item[key]}_${item.customerDetail.customername.replaceAll(" ", "-")}_customer`;
                } else if (key === "siteGroupId") {
                    keyValue = `${item[key]}_${item.sitegroupsDetail.sitegroupname.replaceAll(" ", "-")}_sitegroup`;
                } else if (key === "siteId") {
                    keyValue = `${item[key]}_${item.siteDetail.sitename.replaceAll(" ", "-")}_site`;
                }
    
                // If last level, store an array
                if (!currentLevel[keyValue]) {
                    currentLevel[keyValue] = index === keys.length - 1 ? {  sums: {} } : {};
                }
    
                // Move to the next nested level
                currentLevel = currentLevel[keyValue];
            });
    
    
            // Define properties to sum
            const sumProperties = [
                "totalHour"
            ];
    
            // Initialize sums object if not present
            if (Object.keys(currentLevel.sums)?.length==0) {
                currentLevel.sums = {};
                sumProperties.map(prop => currentLevel.sums[prop] = 0);
               

            }
            
            // Sum up required fields
            sumProperties.forEach(prop => { 

                let value = 0.00 // Convert to number safely

                if(prop=="totalHour"){
                    value = (parseFloat(item?.firstDateHours) + (parseFloat(item?.secondDateHours) || 0.00))
                }

                currentLevel.sums[prop] += value;

            });

    
            return acc;
        }, {});
    };
    
    const [totalCustomer, setTotalCustomer] = useState({});
    const [totalSiteGroup, setTotalSiteGroup] = useState({});
    const [totalSite, setTotalSite] = useState({});

    useEffect(()=>{
        if(reportData){
            // ["customerId", "siteGroupId", "siteId"]
            const groupedData = groupByMultipleKeys(reportData, ["customerId", "siteGroupId", "siteId"]);
            setGroupedData(groupedData);
            // setTotalCustomer( groupAndSum(reportData, ["customerId"]) );
            // setTotalSiteGroup( groupAndSum(reportData, ["siteGroupId"]));
            setTotalSite( groupAndSum(reportData, ["siteId"]) );

        }
    },[reportData]);

    

    const convertToHrsMin = (decimalHours) => {
        const hours = Math.floor(decimalHours); // Extract hours
        const minutes = Math.round((decimalHours - hours) * 60); // Convert decimal to minutes
    
        // Format hours and minutes with leading zero
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
    
        return `${formattedHours} hrs ${formattedMinutes} m`;
    }
    


    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);

    const exportToPdf = ()=>{
        window.print();
    };

    useEffect(()=>{
        document.title = reportDetail?.name+" "+dayjs().format("DD/MM/YYYY");

        return ()=>{
            document.title = "WFC";
        }
    },[]);

    return (
        <div style={{padding: "15px", zoom: "1.15"}} id="pdfContent" className="siteScheduleReport">

            <div className="reportsHeaderCss" id="pdfContent">

                <div style={{fontWeight: "bold", fontSize: "20px", display: "flex", justifyContent: "space-between"}}>
                   <span style={{textTransform:'capitalize'}}>{reportDetail?.name}</span>
                   <div id="pdfexportBtn">
                        <button onClick={exportToCSV} className="drawer-filled-btn" style={{padding:'10px', fontSize:'12px'}}>Export CSV</button> &emsp;
                        <button onClick={exportToPdf} className="drawer-outlined-btn" style={{padding:'10px', fontSize:'12px'}}>Print Report</button>
                   </div>
                </div>

                <div className="row-flex" style={{gap: "30px", marginBottom: "6px"}}>
                    <div style={{fontWeight: "bold"}}> Report ID : {reportDetail?._id}</div>
                    <div style={{fontWeight: "bold"}}> Run Date : &nbsp;
                        <span className="text-muted" style={{fontWeight: "normal"}}>
                            {dayjs().format("DD/MM/YYYY HH:mm")} by {authenticatedUserDetail?.employeeDetail[0]?.firstname +" "+(authenticatedUserDetail?.employeeDetail[0]?.lastname || "")}
                        </span>
                    </div>
                </div>

                <div className="text-muted pt20">
                    <span>Date Range Report using dates between </span>
                    <span style={{padding: "0px 2px"}}>
                        {dayjs(filters?.dates?.from, "DD/MM/YYYY").format("ddd DD MMM YYYY HH:mm")}
                    </span>
                    and
                    <span style={{padding: "0px 2px"}}>
                        {dayjs(filters?.dates?.to, "DD/MM/YYYY").format("ddd DD MMM YYYY HH:mm")}
                    </span>
                </div>

                <div style={{display: "flex", width: "100%", marginTop: "6px"}}>
                    <div style={{width: "50px"}}>Filters :</div>
                    <div style={{width: "calc(100% - 110px)", paddingLeft: "10px", textAlign:'left', fontStyle:'oblique'}}
                        dangerouslySetInnerHTML={{__html: filterContent}}
                    >  
                      
                    </div>
                </div>

            </div>

            <div className="mt32"></div>


            <table id="payBillPDF" className="payBillMainTable">
                <tbody>
                    <tr className="payBillHeaderCss">
                        <th colspan="2">
                            Employee Name
                        </th>
                        <th>Day</th>
                        <th>Schedule Start</th>
                        <th>Schedule Finish</th>
                        <th>Hours</th>
                    </tr>
                    
                   {
                    getPayBillReportLoading?
                        <tr>
                            <td colSpan={7} style={{textAlign: 'center'}}><Spinner /></td>
                        </tr>
                    :
                    Object.keys(groupedData)?.map((key)=>{


                        return(
                            <>
                                {
                                    filters?.header?.groupbyCustomer &&
                                    <tr className="customerCss">
                                        <td colspan="1">Customer:</td>
                                        <td colspan="3">{key?.split("_")[1]?.replaceAll("-", " ")}</td>
                                    </tr>
                                }
                                {
                                    // Site group rendering
                                    Object.keys(groupedData[key])?.map((secKey)=>{
                                        return(
                                            <>
                                            {
                                                filters?.header?.groupbySitegroup &&
                                                <tr className="siteGroupCss">
                                                    <td colspan="1">Site Group:  </td>
                                                    <td colspan="3">{secKey?.split("_")[1]?.replaceAll("-", " ")}</td>
                                                </tr>
                                            }


                                                {/* Site rendering */}
                                                { Object.keys(groupedData[key][secKey])?.map((tkey)=>{
                                                    const siteTotal = totalSite[tkey]?.sums;
                                                    return(
                                                    <>
                                                    {
                                                        filters?.header?.groupbySite &&

                                                        <tr className="siteCss">
                                                            <td colspan="1">Site: </td>
                                                            <td colspan="3">{tkey?.split("_")[1]?.replaceAll("-", " ")}</td>
                                                        </tr>

                                                    }

                                                    {

                                                        filters?.header?.addressDetail &&
                                                        <>
                                                        <tr className="siteCss">
                                                            <td colspan="1">Address: </td>
                                                            <td colspan="3">{groupedData[key][secKey][tkey][0]?.siteDetail?.metadata?.address}</td>
                                                        </tr>
                                                        <tr className="siteCss">
                                                            <td colspan="1">Post code: </td>
                                                            <td colspan="3">{groupedData[key][secKey][tkey][0]?.siteDetail?.postcode}</td>
                                                        </tr>
                                                        </>
                                                    }
                                                            

                                                        {/* first site record */}
                                                        <tr>
                                                            <td colspan="7">
                                                                <table className="payBillSitePayTable">
                                                                    <tbody>
                                                                    {
                                                                        groupedData[key][secKey][tkey]?.map((job)=>{
                                                                            
                                                                            return(
                                                                        
                                                                                <tr className="siteBillCss">
                                                                                    {/* employee name */}
                                                                                    <td colspan="2">{job?.employeeDetail?.firstname+" "+(job?.employeeDetail?.lastname || "")}</td>
                                                                                    
                                                                                    {/* schedule start day */}
                                                                                    <td> 
                                                                                    
                                                                                        {dayjs(job?.date, "DD/MM/YYYY").format("dddd")}

                                                                                    </td>
                                                                                    
                                                                                    {/* job start date and time */}
                                                                                    <td>
                                                                                        {dayjs(job?.firstDate).format("DD/MM/YYYY HH:mm")}
                                                                                    </td>
                                                                                    
                                                                                    {/* job end date and time */}
                                                                                    <td>
                                                                                        
                                                                                        {dayjs(job?.shiftEndDateTime).format("DD/MM/YYYY HH:mm")}

                                                                                    </td>
                                                                                    
                                                                                    {/* total hours */}
                                                                                    <td>
                                                                                        {
                                                                                            convertToHrsMin(parseFloat(job?.firstDateHours) + (parseFloat(job?.secondDateHours) || 0.00))
                                                                                        }
                                                                                    </td>
                                                                                    
                                                                                    
                                                                                </tr>

                                                                            )
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        {
                                                        
                                                        filters?.header?.groupbySite &&
                                                        <>
                                                            <tr style={{height: "0px"}}>
                                                                <td colspan="2"></td><td colspan="9" style={{borderBottom: "2px solid black"}}></td>
                                                            </tr>
                                                            

                                                            {/* site total */}
                                                            <tr>

                                                                <td colspan="11">
                                                                    <table className="payBillSiteCardTable">
                                                                        <thead>
                                                                            <tr className="titleCss">
                                                                                <th colSpan={3}></th>
                                                                                <th  style={{textAlign:'center'}}>Site Totals:</th>
                                                                                <th>Schedule Hours</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr className="row1">
                                                                                <td colspan="4"></td>

                                                                                <td>
                                                                                    {convertToHrsMin(parseFloat(siteTotal?.totalHour))} 
                                                                                </td>

                                                    
                                                                                
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </>

                                                        }

                                                    </>

                                                    )
                                                })}



                                            </>

                                        )
                                    })
                                }




                            </>
                        )
                    })
                   }


                
                </tbody>
            </table>

        </div>
    );

};