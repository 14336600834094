import { Checkbox, Collapse, Input } from "antd";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client"
import { GROUPLIST } from "@src/util/query/group.query"
import { objectType } from "@src/util/types/object.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { deleteCustomerDetailViewFieldMutation, newCustomerDetailViewFieldMutation } from "@src/util/mutation/customerDetailViewField.mutation";
import { getCustomerDetailViewFieldQuery } from "@src/util/query/customerDetailViewField.query";
import Spinner from "@src/components/spinner";

export const EditLeftSideView = ()=>{

    const {data:customerGrpFields, loading, refetch } = useQuery(GROUPLIST,{
        variables: {
            objectType: objectType?.Customer
        }
    });

    const [items, setItems] = useState([]);

    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);

    const [newCustomerDetailView, {loading: newCustomerDetailViewLoading}] = useMutation(newCustomerDetailViewFieldMutation);
    const [deleteCustomerDetailView, {loading: deleteSiteDetailViewLoading}] = useMutation(deleteCustomerDetailViewFieldMutation);
    const {data:getCustomerDetailViewData, loading: getCustomerDetailViewDataLoading, refetch: newCustomerDetailViewRefetch} = useQuery(getCustomerDetailViewFieldQuery,{
        variables:{
            userId: userDetail?._id
        },
        fetchPolicy:'network-only'
    });


    
    useEffect(()=>{
        if(customerGrpFields?.groupList?.length>0 && !loading && !getCustomerDetailViewDataLoading){
            setItems(customerGrpFields?.groupList?.map((list)=>{
                return(
                    {
                        key: list?.key,
                        label: <span>
                                {list?.name} 
                            </span>,
                        children: list?.propertyList?.map((property)=>(
                            <div className='field-presentation'>
                                
                            <Checkbox 
                                defaultChecked={getCustomerDetailViewData?.getCustomerDetailViewField?.response?.find((siteDetail)=>siteDetail?.propertyId==property?._id)}
                                onChange={async (e)=>{
                                    if(e.target.checked){
                                        await newCustomerDetailView({
                                            variables:{
                                                input:{
                                                    propertyId: property?._id,
                                                }
                                            }
                                        }); newCustomerDetailViewRefetch();
                                        
                                    }else{
                                        await deleteCustomerDetailView({
                                            variables:{
                                                userId: userDetail?._id,
                                                propertyId: property?._id,
                                                
                                            }
                                        }); await newCustomerDetailViewRefetch();
                                    }
                                }}
                            />
                                 
                                <div style={{width: '100%'}}>
                                    
                                    <div className='allpropList-propHead'>
                                        {property?.label} 
                                    </div>
                                    <div className='field-prop-value'>
                                        <span>
                                            {"--"}
                                        </span>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        ))

                    }
                );
            }))
        }
    },[customerGrpFields, getCustomerDetailViewData?.getCustomerDetailViewField?.response, loading, getCustomerDetailViewDataLoading]);

    const handelSearch = (keyword)=>{
        const resultedFields = customerGrpFields?.groupList?.map((props)=>{
            const filteredProperties = props?.propertyList.filter((prop)=> prop.label.toLowerCase().includes(keyword.toLowerCase()) && prop?.isArchive!=true );
            return {
                ...props,
                propertyList: filteredProperties
            }
        });
        setItems(resultedFields?.map((list)=>{
            return(
                {
                    key: list?.key,
                    label: <span>
                            {list?.name} 
                        </span>,
                    children: list?.propertyList?.map((property)=>(
                        <div className='field-presentation'>
                            
                            <Checkbox 
                                checked={getCustomerDetailViewData?.getCustomerDetailViewField?.response?.find((siteDetail)=>siteDetail?.propertyId==property?._id)}
                                onChange={async (e)=>{
                                    if(e.target.checked){
                                        await deleteCustomerDetailView({
                                            variables:{
                                                userId: userDetail?._id,
                                                propertyId: property?._id,
                                                
                                            }
                                        }); await newCustomerDetailViewRefetch();
                                    }else{
                                        await newCustomerDetailView({
                                            variables:{
                                                input:{
                                                    propertyId: property?._id,
                                                }
                                            }
                                        }); newCustomerDetailViewRefetch();
                                    }
                                }}
                            />
                            {/* <FontAwesomeIcon style={getCustomerDetailViewData?.getCustomerDetailViewField?.response?.find((siteDetail)=>siteDetail?.propertyId==property?._id)? {visibility:'visible'} : {visibility:'hidden'}} icon={faCheck}/> */}
                            
                            <div style={{width: '100%'}}>
                                
                                <div className='allpropList-propHead'>
                                    {property?.label} 
                                </div>
                                <div className='field-prop-value'>
                                    <span>
                                        {"--"}
                                    </span>
                                    <span className='field-prop-btn-grp'>
                                        {getCustomerDetailViewData?.getCustomerDetailViewField?.response?.find((customerDetail)=>customerDetail?.propertyId==property?._id)?
                                            <button className='grid-sm-btn'                                                
                                            style={{ padding: "4px 10px" }} 
                                            onClick={async()=>{ await deleteCustomerDetailView({
                                                variables:{
                                                    userId: userDetail?._id,
                                                    propertyId: property?._id,
                                                    
                                                }
                                            }); await newCustomerDetailViewRefetch();}}
                                            >Remove from your view</button>
                                            :
                                            <button className='grid-sm-btn'                                                
                                            style={{ padding: "4px 10px" }} 
                                            onClick={async()=>{ await newCustomerDetailView({
                                                variables:{
                                                    input:{
                                                        propertyId: property?._id,
                                                    }
                                                }
                                            }); newCustomerDetailViewRefetch();}}
                                            >Add to your view</button>
                                        
                                        }
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                    ))

                }
            );
        }))

    }

    return(
        <div className="hr-section" style={{maxHeight:'83vh', overflow:'auto'}}>
            <div className="hr-info">
                <div className="hr-info-title">
                    Customise your view
                </div>
                <div className="text">Manage your view by select the data fields below</div>
            </div>
            <Input type="search" 
                style={{width:'100%'}} 
                className='generic-input-control' 
                placeholder="Search Fields"
                onChange={(e)=>handelSearch(e.target.value)}
                autoComplete="off"
                suffix={
                <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
            />
            {getCustomerDetailViewDataLoading? 
                
                <Spinner/>

                :
                
                <Collapse defaultActiveKey={customerGrpFields?.groupList[0]?.key} items={items} />

            }
        </div>
    )

}