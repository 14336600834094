import "./style.css"
import { faArrowRightLong, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Modal } from "antd";
import { useState, useEffect } from "react";
import { getReportTemplateById } from "@src/util/query/reportTemplate.query";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { GenerateNormalFields } from "@src/util/generateFields/generateNormalField";
import { lookupTypes } from '@src/util/types/lookup.types';
import { CreateFormLookUpFields } from '@src/util/generateFields/createFormlookupFields';
import { getScheduleReportByJobIdQuery } from '@src/util/query/scheduleReport.query';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import Spinner from "@src/components/spinner";
import { useDispatch } from "react-redux";
import { createScheduleReportMutation } from '@src/util/mutation/scheduleReport.mutation';
import { updateScheduleReportMutation } from '@src/util/mutation/scheduleReport.mutation';
import { UpdateScheduleByField } from '@src/util/mutation/schedule.mutation';
import { genericFileUploadSignatureMutation } from '@src/util/mutation/genericFileUpload.mutation';
import { getScheduledJobById } from "@src/util/query/schedule.query";
import axios from 'axios';
import { FileFormatTypes } from '@src/util/fileFormatTypes';
import { getScheduleReportBySiteandTemplateIdQuery } from '@src/util/query/scheduleReport.query';
import { useNavigate } from "react-router-dom";
import { dutyReportToTemplateMapping } from "../../../util/mobileDutyReportToTemplateMapping/mobileDutyReportToTemplateMapping";



export const PublicAccessDutyReport = ()=>{

    const [templateId, setTemplateId] = useState(null);
    const [scheduleId, setScheduleId] = useState(null);
    const [siteId, setSiteId] = useState(null);

    useEffect(()=>{
        if(localStorage.getItem("reportParam")){
            const reportParam = JSON.parse(localStorage.getItem("reportParam"));
            setScheduleId(reportParam?.scheduleId);
            setTemplateId(reportParam?.templateId);
            setSiteId(reportParam?.siteId);
        }
    },[localStorage.getItem("reportParam")]);

    const {data, loading:templateLoading} = useQuery(getReportTemplateById,{
        variables:{
            templateId
        },
        fetchPolicy:'network-only',
        skip: templateId==null
    });
  


    const {data: lastReportData, loading:  lastReportDataLoading,} = useQuery(getScheduleReportBySiteandTemplateIdQuery,{
        variables:{

            siteId: siteId,
            templateId: templateId,

        },
        skip: !templateId || !siteId,
        fetchPolicy:'network-only'
    });


    const {data:scheduleByIdDetail, loading:scheduleDetailLoading} = useQuery(getScheduledJobById,{
        variables: {
            jobId: scheduleId
        },
        skip: scheduleId==null,
        fetchPolicy: 'network-only'
    })

    const [scheduleDetail, setScheduleDetail] = useState(null);
    useEffect(()=>{
        if(scheduleByIdDetail?.getScheduledJobById?.response){
            setScheduleDetail({...scheduleByIdDetail?.getScheduledJobById?.response});
        }
    }, [scheduleByIdDetail?.getScheduledJobById?.response]);


    const [reportFields, setReportFields] = useState([]);
    const [field, setField] = useState([]);

    useEffect(()=>{
        if(data?.getReportTemplateById?.response?.template?.rawReportFields){
            setReportFields([...data?.getReportTemplateById?.response?.template?.rawReportFields]);
        }
    },[data?.getReportTemplateById?.response?.template?.rawReportFields]);

    const handelDataValue = ({name, value})=>{
        if(name){
            if(value){
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value}])
                }
            }else{
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value:''
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value:''}])
                }
            }
        }
    };


    const [isOfficerApproved, setIsOfficerApproved] = useState(localStorage.getItem("isOfficerApproved") || ""); 
    useEffect(()=>{
        localStorage.setItem("isOfficerApproved", isOfficerApproved);

    },[isOfficerApproved]);
    
    const {data: reportData, loading: reportDataLoading, refetch: reportDataRefetch} = useQuery(getScheduleReportByJobIdQuery,{
        variables:{
            jobId: scheduleId
        },
        skip: scheduleId==null,
        fetchPolicy:'network-only'
    });


    useEffect(()=>{
        if(!templateLoading && !scheduleDetailLoading && !reportDataLoading  && !lastReportDataLoading){
            if(reportData?.getScheduleReportByJobId?.response && Object.keys(reportData?.getScheduleReportByJobId?.response)?.length>0 && reportData?.getScheduleReportByJobId?.response?.reportDetail){
                const reportFormData = reportData?.getScheduleReportByJobId?.response?.reportDetail;
                if(reportFormData){
                    setField([...Object.keys(reportFormData)?.map((report)=>{
                        return({
                            name: report,
                            value: reportFormData[report],
                            type: reportFormData[report]?.includes(".")? 
                            FileFormatTypes.includes(reportFormData[report]?.split(".")[1])? 
                            FileFormatTypes?.find((format)=>format==reportFormData[report]?.split(".")[1])
                            : null : null,

                        })
                    })]);    
                }
                // if there is not this job data extract the previouse report if any
                
                else if(lastReportData?.getScheduleReportBySiteandTemplateId?.response?.hasOwnProperty("reportDetail")){
                
                    const reportFormData = lastReportData?.getScheduleReportBySiteandTemplateId?.response?.reportDetail;

                    setField([...Object.keys(reportFormData)?.map((report)=>{
                        return({
                            name: report,
                            value: reportFormData[report],
                            type: null

                        })
                    })]);    
                }

            }else{
                if(scheduleDetail && Object.keys(scheduleDetail)?.length>0 && !scheduleDetailLoading){
                    setField([
                        ...field, 
                        {name:'sitename', value:scheduleDetail?.site?.sitename}, 
                        {name:'siteid', value:scheduleDetail?.siteId},
                        {name:'siteaddress', value: scheduleDetail?.site?.metadata?.address},
                        {name:'sitepostcode', value: scheduleDetail?.site?.postcode},
                    
                    ]);

                }
            }
        }
    },[reportData?.getScheduleReportByJobId?.response, scheduleDetail, templateLoading, scheduleDetailLoading, reportDataLoading, lastReportDataLoading]);

    const dispatch = useDispatch();
    const [newScheduleReport, {loading: newScheduleReportLoading}] = useMutation(createScheduleReportMutation)
    const [updateScheduleReport, {loading: updateScheduleReportLoading}] = useMutation(updateScheduleReportMutation)

    const [confirmationModal, setConfirmationModal] = useState(false);

    const [updateScheduleByFieldMutation, {loading: updateScheduleLoading}] = useMutation(UpdateScheduleByField)

    const [genericFileUploadSignature, {loading: genericFileUploadSignatureLoading}] = useMutation(genericFileUploadSignatureMutation);
    const [fileLoading, setFileLoading] = useState(false);

    const handelSubmit = async (isSubmitted=false)=>{
        try{
            setConfirmationModal(false);
            const filesName = field.filter((f)=>f?.value?.size)?.map((file)=>file?.value?.name);

            if (filesName?.length>0){
                setFileLoading(true);
                
                await field.filter((f)=>f?.value?.size)?.map(async(file)=>{
                    
                    const fileUploadUrlsResponse = await genericFileUploadSignature({
                        variables:{
                            input:{
                                fileName : "dutyreport/"+file?.value?.name,
                                objectType: "dutyreport"
                            }
                        }
                    });
    
                    const fileUploadUrls = fileUploadUrlsResponse?.data?.genericFileUploadSignatureUrl?.response?.url;
                    
                    await axios.put(fileUploadUrls, file?.value,{
                        headers: {
                            'Content-Type': file?.value?.type ,
                        },
                    });


                });

            }


            let fieldData= {};
            field?.map((f)=>{fieldData[f.name]=f?.value?.name? "dutyreport/"+f?.value?.name?.replace(/\s/g,"").toLowerCase(): f.value});
            setFileLoading(false);

            if(reportData?.getScheduleReportByJobId?.response?._id){
                await updateScheduleReport({
                    variables:{
                        input:{
                            _id: reportData?.getScheduleReportByJobId?.response?._id,
                            reportDetail: fieldData,
                            isSubmitted,
                        }
                    }
                });
                dispatch(
                    setNotification({
                        error: false,
                        notificationState: true,
                        message: "Report was updated successfully"
                    })
                );
            }else{

                await newScheduleReport({
                    variables:{
                        input: {
                            jobId: scheduleId,
                            areaId: scheduleDetail?.siteAreaId,
                            siteId: scheduleDetail?.siteId,
                            templateId: templateId,
                            customerId: scheduleDetail?.customerId,
                            reportDetail: fieldData,
                            isSubmitted
                        }
                    }
                });
                dispatch(
                    setNotification({
                        error: false,
                        notificationState: true,
                        message: "Report was saved successfully"
                    })
                );
            }
            await reportDataRefetch();

            // update report submitted report in schedule 
            if(isSubmitted){
                await updateScheduleByFieldMutation({
                    variables:{
                        input:{
                            _id: scheduleId,
                            field: "isReportSubmitted",
                            value: true
                        }
                    }
                });
                localStorage.removeItem("isOfficerApproved");
                localStorage.removeItem("reportParam");
                await reportDataRefetch();
            }

        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    notificationState: true,
                    message: err.message
                })
            );
        }
    };


    const navigate = useNavigate();
    // isSubmittedByEmail
    // isSubmitted
    useEffect(()=>{
        if(reportData?.getScheduleReportByJobId?.response?.isSubmitted && !reportData?.getScheduleReportByJobId?.response?.isSubmittedByEmail){
            if(dutyReportToTemplateMapping['wardSecurity']?.templateId==templateId){
                navigate(dutyReportToTemplateMapping['wardSecurity']?.route+scheduleId);
            }
        }
    },[reportData?.getScheduleReportByJobId?.response]);

    return(
        scheduleId?
        <>

            {
                isOfficerApproved?.length==0 &&
                <OfficerConfirmation setIsOfficerApproved={setIsOfficerApproved} />
            }

                <div className='modal-header-title'>
                    <span>
                        
                        {data?.getReportTemplateById?.response?.template?.templatename?.toUpperCase()}
                        
                    </span>
                </div>
            <div style={{marginBottom:'100px'}} className="scheduleReportMain">
            {/* 67519d5cb3838909ac4db22a */}
                {templateLoading || fileLoading || reportDataLoading || updateScheduleLoading || updateScheduleReportLoading || newScheduleReportLoading?

                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', flexDirection:'columns', gap:'15px'}}>
                        {fileLoading && <div> Files are uploading ... </div>}
                        <Spinner/>
                    </div>

                :
                    reportFields.map((section, index) => {

                        const sectionKey = Object?.keys(section)[0];
                        const sectionItems = section[sectionKey] || [];
                        const groupName = sectionItems[0]?.groupName || "";
                        return(
                            sectionItems?.length>0?
                            
                            <div style={{marginBottom:'2%', margin:'15px'}} className="box-shadow">
                                
                                <div className='hr-info-title' style={{marginBottom:'16px'}}>
                                    {groupName}
                                </div>
                                
                                <div className='report-section'>
                                    {
                                        sectionItems.map((prop, itemIndex) => {
                                            const label = prop?.label;
                                            const name = prop?.label.toLowerCase().replace(/\s/g,"");
                                            const fieldType = prop?.fieldType;
                                            const newprop = prop;                                    
                                            const {value, type} = field?.find((f)=>f.name.toLowerCase().replace(/\s/g,"")==name) || {value: "", type: null};
                                            
                                            if(reportData?.getScheduleReportByJobId?.response?.isSubmitted){
                                                
                                                return(
                                                    <Form.Item className="scheduleReportField" >
                                                        <label style={{fontSize:'16px'}}>{label}</label>
                                                        {type && FileFormatTypes?.includes(type) ?
                                                        <div className='reportImgWrapper'>
                                                            <img 
                                                            src={reportData?.getScheduleReportByJobId?.response?.filesURL[name]}  
                                                            onClick={()=>window.open(reportData?.getScheduleReportByJobId?.response?.filesURL[name],"_blank")} 
                                                            />
                                                        </div>
                                                        :
                                                        <div className="text" style={{fontStyle:'italic', padding:'10px 5px', background:'rgb(245, 248, 250)'}}>{value?.length>0? value : "---"}</div>
                                                        }
                                                    </Form.Item>
                                                )
                                            }else{
                                                return(
                                                    lookupTypes.includes(fieldType)?
                                                    
                                                    <div  className="scheduleReportField">
                                                        <CreateFormLookUpFields
                                                            employee={false}
                                                            setGroupInput={setGroupInput}
                                                            tags={[]}
                                                            setTags={()=>{}}
                                                            property={{propertyDetail:prop}}
                                                            value={value}
                                                            name={name}
                                                            handelDataValue={handelDataValue}
                                                            checkMandatoryField={()=>{}}
                                                            setBtn={()=>{}}
                                                            disabled={true}
                                                        />
                                                    </div>

                                                    :
                                                    <div  className="scheduleReportField" >

                                                        
                                                        {
                                                            type && FileFormatTypes?.includes(type) ?
                                                            <div style={{marginTop:'16px'}}>
                                                                <label>{label}</label>
                                                                <div style={{display:'flex', gap:'16px'}}>
                                                                    <div className='prev-btn' onClick={()=>window.open(reportData?.getScheduleReportByJobId?.response?.filesURL[name],"_blank")}> View </div> 
                                                                    OR
                                                                    <div className='prev-btn' onClick={()=>setField(field?.filter((prop)=>prop?.name!==name))}> Replace </div>
                                                                </div>
                                                            </div>

                                                            :
                                                            
                                                            <GenerateNormalFields
                                                                label={label}
                                                                name={name}
                                                                fieldType={fieldType}
                                                                handelDataValue={handelDataValue}
                                                                property={newprop}
                                                                value={value}
                                                                employee={false}
                                                                isReporting={true}
                                                                disabled={true}
                                                            />

                                                        }

                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>

                            </div>
                            :
                            null
                        )
                    })
                

                    
                }
            </div>

            {
                reportData?.getScheduleReportByJobId?.response?.isSubmitted?
                null
                :
                <div className="hr-action-footer" style={{width:'100%'}}>
                    <button className="drawer-outlined-btn" onClick={()=>handelSubmit(false)}>Save</button>
                    <button className="drawer-filled-btn" onClick={()=>{setConfirmationModal(true)}}>Save & Submit</button>
                </div>
            }

            <ConfirmationModal
                visible={confirmationModal}
                close={()=>setConfirmationModal(false)}
                handelSubmit={handelSubmit}
            />


        </>
        :
        <div style={{ display:'flex', height:'100vh', alignItems:'center', alignContent:'center', textAlign:'center'}}>
            <div style={{display:'table', margin:'auto'}}>You have submitted the report successfully</div> 
        </div>
    )
}

export const OfficerConfirmation = ({setIsOfficerApproved})=>{
    const [officerName, setOfficerName] = useState("");

    return(

        <Modal
                open={true}
                width={'80%'}
                centered
                closable={false}
                footer={null}
            >
            
            <div className='modal-body modal-body-padding' style={{gap:'10px', alignItems:'center'}}>

                <Input
                    placeholder="Officer full name ..."
                    className="generic-input-control"
                    autoFocus
                    value={officerName}
                    onChange={(e)=>setOfficerName(e.target.value)}
                />

                <button className={officerName?.length>3? "publicreportbtn" :"disabled-btn publicreportbtn"} onClick={()=>setIsOfficerApproved(officerName)}><FontAwesomeIcon icon={faArrowRightLong}/></button>
            </div>
                
        </Modal>
    )
}


const ConfirmationModal = ({visible, close, handelSubmit})=>{
    return(
        <Modal    
            visible={visible}
            width={600}
            footer={
                <div 
                    className="drawer-footer"
                >

                    <button className={'drawer-filled-btn'} onClick={()=>{handelSubmit(true)}}>
                        Confirm
                    </button>

                    <button disabled={false}  className={'drawer-outlined-btn'} onClick={close}>
                        Cancel
                    </button>

                </div>
            }
            closable={false}
        >
            <div className='modal-header-title'>
                <span style={{letterSpacing:'0.2px'}}> Confirmation </span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div className='modal-body modal-body-padding'>
                <div className="text" style={{textAlign:'center'}}>This action can not be undo. Are you sure to perform this action?</div>
            </div>

        </Modal>
    )
}