import { Avatar, Checkbox, Input } from "antd";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Spinner from "@src/components/spinner";
import { getSitesQuery } from "@src/util/query/site.query";
import { getAgencies } from "@src/util/query/agency.query";
import { GET_BRANCHES } from "@src/util/query/branch.query";
import { getSiteGroups } from "@src/util/query/siteGroup.query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCustomerQuery } from '@src/util/query/customer.query';
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { faClose, faGlobeAsia, faHandHoldingDroplet, faHandshakeSimple, faLayerGroup, faSearch, faStreetView, faUsers } from "@fortawesome/free-solid-svg-icons";


export const PrivacyModule = ({visibility, privacy, setPrivacy})=>{

    const {data: customerData, loading: customerLoading} = useQuery(getCustomerQuery,{
        variables: {
            input: {
                filters: null
            }
        },
        fetchPolicy:'network-only'
    });
      
    const { data: branchData, loading: branchLoading} = useQuery(GET_BRANCHES ,{
        fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    const {data: siteGroupData, loading: siteGroupLoading} = useQuery(getSiteGroups,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

      // Agency list if needed to load
    const {data: agencyData, loading: agencyLoading, refetch} = useQuery(getAgencies,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

      //   site list
    const {data: siteData, loading: siteLoading} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });


    const {data: employeeData, loading: employeeDataLoading,} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        },
        fetchPolicy: 'network-only'
    });

    const [searchInput, setSearchInput] = useState("");

    const handelSearch = (searchValue)=>{
        setSearchInput(searchValue);
    };



    const objectToSearch = visibility==="Agency"? "supplier" :  visibility?.toLowerCase();

    const [entityToRender, setEntityToRender] = useState([]);
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(()=>{

        if(!customerLoading && !branchLoading && !siteGroupLoading && !agencyLoading && !siteLoading && !employeeDataLoading){

            switch(visibility){
    
                case 'Branch':
                    setEntityToRender([...branchData?.branches?.map((branch)=>({
                        _id: branch?._id,
                        name: branch?.branchname,
                        icon: <div className="privacy-mode-icon">
                                <FontAwesomeIcon icon={faGlobeAsia}/>
                            </div>
                    }))]);
                    break;
    
                case 'Agency':
                    setEntityToRender(agencyData?.agencies?.map((agency)=>({
                        _id: agency?._id,
                        name: agency?.suppliername,
                        icon: <div className="privacy-mode-icon">
                                <FontAwesomeIcon icon={faHandHoldingDroplet}/>
                            </div>
                    })));
                    break;
    
                case 'Customer':
                    setEntityToRender(customerData?.customers?.map((customer)=>({
                        _id: customer?._id,
                        name: customer?.customername,
                        icon: <div className="privacy-mode-icon">
                                <FontAwesomeIcon icon={faHandshakeSimple}/>
                            </div>
                    })));
                    break;
    
                case 'SiteGroup':
                    setEntityToRender(siteGroupData?.sitegroups?.map((sitegroup)=>({
                        _id: sitegroup?._id,
                        name: sitegroup?.sitegroupname,
                        icon: <div className="privacy-mode-icon">
                                <FontAwesomeIcon icon={faLayerGroup}/>
                            </div>
                    })));
                    break;
    
                case 'Site':
                    setEntityToRender(siteData?.sites?.map((site)=>({
                        _id: site?._id,
                        name: site?.sitename,
                        icon: <div className="privacy-mode-icon">
                                <FontAwesomeIcon icon={faStreetView}/>
                            </div>
                    })));
                    break;
    
                case 'Employee':
                    setEntityToRender(employeeData?.getEmployee?.response?.map((employee)=>({
                        _id: employee?._id,
                        name: employee?.firstname +" "+ (employee?.lastname || ""),
                        icon: <div className='post-avatar'>
                            {employee?.metadata?.imageURL?
                                <Avatar size={55} src={employee?.metadata?.imageURL} />
                            :
                                <Avatar size={55}> {employee?.firstname[0]+(employee?.lastname ? employee?.lastname[0] : employee?.firstname[employee?.firstname[0]?.length-1])} </Avatar>
                            }
                        </div>

                    })));
                    break;
    
                default:
                    setEntityToRender([]);
            };

            setLoading(false);
        
        
        }else{
            setLoading(true);
        }
        

        
    },[visibility, customerLoading, branchLoading, siteGroupLoading, agencyLoading, siteLoading, employeeDataLoading]);

    useEffect(()=>{
        if(searchInput?.length==0){
            setData(entityToRender);
        }
    },[entityToRender]);

    useEffect(()=>{
        if(searchInput?.length>0 && entityToRender?.length>0){
            setData(entityToRender?.filter((entity)=> entity.name?.toLowerCase()?.includes(searchInput?.toLowerCase())))
        }else{
            setData(entityToRender);
        }
    },[searchInput]);


    const handelCheck = ({_id})=>{

        // check if exist 
        const isExist = privacy[objectToSearch]?.include?.find((id)=>id==_id);
        if(isExist){

            // filter the include array and remove the passed id and update the privacy state
            const updatedPrivacy = privacy[objectToSearch]?.include?.filter((id)=>id!==_id);

            // update it to unchecked
            setPrivacy({
                ...privacy,
                [objectToSearch]:{
                    ...privacy[objectToSearch],
                    include: [...updatedPrivacy],
                    includeAll: false
                }
            });

        }else{

            // add new id in include array of specific privacy type and marked as checked

            setPrivacy({
                ...privacy,
                [objectToSearch]:{
                    ...privacy[objectToSearch],
                    include: [...privacy[objectToSearch]?.include, _id],
                    includeAll: false
                }
            });
        
        }
    };
    

    const handelSelectAll = (status)=>{
        setPrivacy({
            ...privacy,
            [objectToSearch]:{
                includeAll: status,
                include: []
            }
        });
    }


    return(

        <div className="post-audience">

            {/* privacy header */}

            <div className="post-searchbar">
                <Input type="search" 
                    style={{width:'100%', borderRadius:'50px'}} 
                    className='generic-input-control' 
                    placeholder={"Search for a "+objectToSearch+" or list..."}
                    onChange={(e)=>handelSearch(e.target.value)}
                    value={searchInput}
                    autoComplete="off"
                    prefix={searchInput? 
                    <FontAwesomeIcon style={{color:'#606770', cursor:'pointer', fontSize: '16px', marginRight:'4px'}} onClick={()=>{handelSearch('');}} icon={faClose}/> : 
                    <FontAwesomeIcon style={{color:'#606770', marginRight:'4px', fontSize: '16px'}} icon={faSearch}/> }
                />
            </div>

            {/* body */}
            <div className="privacy-mode-block-wrapper" style={{marginTop:'60px'}}>

                <div className="visibilityTitle mb16">
                    <span>{objectToSearch==="SiteGroup"? "Site Group" : objectToSearch}</span> 
                    {data?.length>0 &&
                    <Checkbox checked={privacy[objectToSearch]?.includeAll}  onChange={(e)=>handelSelectAll(e?.target?.checked)}/>
                    }
                </div>

                

                {loading?

                    <div className="row-flex" style={{justifyContent:'center', alignItems: 'center'}}>
                        <Spinner />
                    </div>

                :
                data?.length>0?
                    data?.map((entity)=>{
                        return (

                            <div className="privacy-mode-block" onClick={()=>handelCheck(entity)}>

                                <div>
                                    {entity?.icon}
                                </div>

                                <div style={{color:'#333', paddingTop:'15px', paddingRight:'10px', fontWeight:500, justifyContent:'space-between', width:'100%', alignItems:'baseline'}} className="row-flex">

                                    <span>
                                        {entity?.name}
                                    </span>

                                    <span>
                                        <Checkbox checked={privacy[objectToSearch]?.include?.find((id)=>id==entity?._id) || privacy[objectToSearch]?.includeAll} onChange={(e)=>handelCheck(entity)}></Checkbox>
                                    </span>

                                </div>


                            </div>

                        )
                    })   
                :
                <div className="row-flex" style={{justifyContent:'center', alignItems: 'center'}}>
                    Oops ! no, {(objectToSearch==="SiteGroup"? "Site Group" : objectToSearch)}  found
                </div>
                }

            </div>

        </div>
    )
}