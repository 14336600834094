import "./employeeDetailPage.css";
import { Popover } from 'antd';
import React, { useEffect, useState } from 'react';
import { DetailPageLeftSideBar } from './leftSideBar/leftSideBar';
import { DetailPageMiddleSection } from './middleSection/middleSection';
import { DetailPageRightSideBar } from './rightSideBar/rightSideBar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { EmployeeObjectQuery, getSingleEmployeeRecord } from '@src/util/query/employee.query';
import { Loader } from '@src/components/loader';
import { updateEmployeeMutation } from '@src/util/mutation/employee.mutation';
import { faChevronLeft, faGears, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HRTab } from "./tabs/hr/hr";
import { CalendarTab } from "./tabs/calendar/calendar.tab";
import { SkillTab } from "./tabs/skill/skill.tab";
import { EmployeeNotesTab } from "./tabs/notes/notes.tab";
import { objectType } from "@src/util/types/object.types";
import { getNote } from "@src/util/query/note.query";
import { EmployeeCustomLeftPane } from "./EmployeeCustomLeftPane";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DeleteEmployeeDetailViewMutation, UpdateEmployeeDetailViewMutation } from "@src/util/mutation/employeeDetailView.mutation";
import { EmployeeDetailViewQuery } from "@src/util/query/employeeDetailView.query";
import { HolderOutlined } from "@ant-design/icons";
import { Task } from "./tabs/task/task";
import { RightSideWidgetModel } from "../../components/rightSideWidgetModel/rightSideWidget.model";
import { SiteCredential } from "./tabs/siteCredential/siteCredential";

import { ShareDocument } from "@src/components/document/shareDocument.component";
import { DocumentComponent } from "@src/components/document/document.component";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const EmployeeDetailPage = ()=>{
    const param = useParams();
    
    const dispatch = useDispatch();

    // get single employee Record
    const {data: singleEmployeeRecord, loading: singleEmployeeLoading, refetch: singleEmployeeRefetch} = useQuery(getSingleEmployeeRecord,{
        variables:{
            id: param?.id
        },
        fetchPolicy: 'network-only'
    });

    const [singleEmployee, setSingleEmployee] = useState([]);
    useEffect(()=>{
        if(!singleEmployeeLoading){
            setSingleEmployee(
                singleEmployeeRecord?.singleEmployee?.response
            );
        }
    },[singleEmployeeRecord]);

    // singleEmployee Record ended

    // Employee Default object
    const {data:employeeSchema, loading: employeeObjectLoading, refetch: employeeObjectRefetch} = useQuery(EmployeeObjectQuery,{fetchPolicy:'network-only'});
    const [employeeObject, setEmployeeObject] = useState([]);
    useEffect(()=>{
        if(!employeeObjectLoading){
            setEmployeeObject(employeeSchema?.getEmployeeObject?.response)  
        }
    },[employeeSchema]);
    // Employee default object terminated


    const [dataFields, setDataFields] = useState([]);
    
    const [refreshProp, setRefreshProp] = useState(false);
    

    const handelInputChange = ({name, value}) => {
        const isExist = dataFields?.find((field)=> field?.name == name);
        const property = employeeObject?.find((prop)=> prop.propertyDetail.label.replaceAll(" ","").toLowerCase() === name)
        // console.log(property, "branchProperties");
        setDataFields(isExist? dataFields?.map((field)=> {
            if(field.name == name){
                return {
                    name, value, propertyId: property?.propertyId,
                }
            }else{
                return field
            }
            
        }): [...dataFields, {name, value, propertyId: property?.propertyId}]);
    };

    const [updateEmployee, {loading, error}]  = useMutation(updateEmployeeMutation);

    const handelUpdateSave = async ()=>{
        try{
            let schemaFields = [];

            dataFields?.map((field)=>{
                if(field.name==="firstname" || field.name==="lastname"  || field.name==="branch"){
                    schemaFields.push(field);
                }
                else{
                    schemaFields.push({...field, metadata:1})
                }
            });

            await updateEmployee({
                variables:{
                    input:{
                        _id: param?.id,
                        properties: schemaFields,
                    }
                }
            });
            dispatch(setNotification({
                message: "Employee Details are Updated Successfully",
                notificationState: true,
                error: false
            }));

            await singleEmployeeRefetch();
            setRefreshProp(false);
            setDataFields([]);
        }
        catch(err){            
            dispatch(setNotification({
                message: "An error encountered while updating employee",
                notificationState: true,
                error: true
            }));
        }
    };

    const navigate = useNavigate();
    const [tabs, setTabs] = useState([
        {title:"Profile", url:"profile", },
        {title:"HR", url:"hr" },
        {title:"Calendar", url:"calendar" },
        {title:"Sites Credentials", url:"sitecredential" },
        {title:"Skills", url:"skills" },
        {title:"Notes", url:"notes" },
        {title:"Task", url:"task" },
        {title:"Documents", url:"document" },
    ]);


    const [activeTab, setActiveTab] = useState(param?.tab);

    useEffect(()=>{
        if(!param?.edit && !param?.tab){
            setActiveTab("hr");
        }else{
            setActiveTab(param?.tab)
        }
    },[param]);



    
    const { data:note , loading: noteLoading, refetch: noteRefetch} = useQuery(getNote,{
        variables:{
            createdFor: param?.id,
            objectType: objectType?.Employee
        },
        fetchPolicy:'network-only',
    });

   

    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);
    
    const [editableProp, setEditableProp] = useState([]);

    const [updateEmployeeDetailView, {loading:updateEmployeeDetailViewLoading}] = useMutation(UpdateEmployeeDetailViewMutation);

    const {data:employeeDetailView, loading: getEmployeeDetailViewDataLoading, refetch: employeeDetailViewRefetch} = useQuery(EmployeeDetailViewQuery,{
        variables:{
            userId: userDetail?._id
        }
    });

    useEffect(()=>{
        if(employeeDetailView?.getEmployeeDetailViewField?.response){
            setEditableProp(employeeDetailView?.getEmployeeDetailViewField?.response)
        }
    },[employeeDetailView?.getEmployeeDetailViewField?.response]);

    
    const onDragEndForLeftCustomise = async (result) => {
        // dropped outside the list
        if (!result.destination) {
        return;
        }
        
        const reorderedArr = reorder(
            editableProp,
            result.source.index,
            result.destination.index
            );
        
        
        setEditableProp(reorderedArr);
        
        await updateEmployeeDetailView({
            variables:{
                input:{
                   ids: reorderedArr?.map((prop)=> prop?._id)
                }
            }
        });

    };

    const [deleteEmployeeDetailView, {loading: deleteSiteDetailViewLoading}] = useMutation(DeleteEmployeeDetailViewMutation);

    // it will pop the model for right side widget customisation
    const [rightSideWidgetModel, setRightSideWidgetModel] = useState(false);

  
    


    return(
        <div>

            {/* employee dashboard main top navbar  */}

            <div style={{background: 'rgb(245, 248, 250)', padding: '15px 15px 7px 15px', display:'flex', gap:50, alignItems: 'center', position: 'fixed',  width: '-webkit-fill-available', zIndex: '996'}}>
                
                {/* back + user name btn */}
                <div style={{display:'flex', alignItems:'center', gap:'25px', paddingBottom:'8px', width:'50%'}}>

                    <div onClick={()=>{navigate(-1); singleEmployeeRefetch(); employeeDetailViewRefetch();}} >
                        <FontAwesomeIcon className='left-chevron-icon' icon={faChevronLeft}/> <span className='text-deco' style={{left: '5%', position: 'relative', fontSize:'14px'}}>Back</span> 
                    </div>
                </div>

                {/* detail page header on edit */}
                {param?.edit==="edit"?
                    <>
                        <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', width:'-webkit-fill-available'}}>
                           <b>Customise Mode</b> 
                        </div>

                        <div style={{width:'50%', textAlign:'right', cursor:'pointer'}}>
                            <FontAwesomeIcon icon={faTimes} 
                                className="icon-bound"
                                style={{boxShadow:'none', fontSize:'15px', borderRadius:'15px', padding:'6px 8px', cursor:'pointer'}}
                                onClick={async ()=>{navigate("/user/employee-detail/"+param?.id);}}
                            />
                        </div>
                    </>
                :
                    <>
                        {/* navigation tabs */}
                        <div style={{display:'flex', alignItems:'center', gap:'50px', justifyContent:'center', margin:'auto', width:'-webkit-fill-available'}}>
                            {tabs?.map((tab)=>(
                                <div className={activeTab==tab.url? 'emp-menubar emp-menubar-active': 'emp-menubar'} onClick={()=> {navigate("/user/employee-detail/"+param?.id+"/tab/"+tab.url); setActiveTab(tab.url)}}>{tab.title}</div>
                            ))}
                        </div>
                        
                        <div style={{width:'50%', textAlign:'right', fontSize: '18px'}}>
                            <Popover
                                overlayClassName="notePopover"
                                trigger={"click"}
                                placement="left"
                                content={
                                <div
                                    className="popover-data"
                                    style={{marginRight:'50px', width: '100%'}}         
                                >
                                    <div className="popoverdataitem popoveritem" onClick={()=>setRightSideWidgetModel(true)}>
                                        Customise Widgets
                                    </div>
                                </div>}
                            >
                                <FontAwesomeIcon className='text-deco' icon={faGears}/> 
                            </Popover>
                        </div>


                    </>

                }



            </div>

            

            {
            singleEmployeeLoading || getEmployeeDetailViewDataLoading || updateEmployeeDetailViewLoading || employeeObjectLoading || noteLoading?
            
            <div style={{display: 'table', margin:'auto', paddingTop:'150px'}}>
                <Loader />
            </div>
            
            :
            param?.edit=="edit"?
            
                <div style={{padding:'50px 5px 5px 5px'}}>
                    <div className="grid-layout">
                        <div className="grid-25">
                            <DetailPageLeftSideBar 
                                employeeObject={employeeObject?.map((object)=> ({_id: object.propertyId, ...object.propertyDetail}))}
                                singleEmployee={singleEmployee}
                                emploading={employeeObjectLoading || singleEmployeeLoading}
                                handelInputChange={handelInputChange}
                                showDetail={false}
                            />
                            <DragDropContext onDragEnd={onDragEndForLeftCustomise}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="tab-grp"
                                    style={{
                                        width:'auto',
                                        border: '1px solid #ECEFEC',
                                        borderRadius: '12px',
                                        padding: '14px',
                                        marginTop: '10px',
                                        gap: 0,
                                    }}
                                    >
                                    {editableProp?.map((item, index) => (
                                        <Draggable key={item._id} draggableId={item._id} index={index}
                                        
                                        >
                                        {(provided, snapshot) => (
                                            <div className="icon-wrapper">
                                            
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                id={"item-"+index}
                                                itemRef={"item-"+index}
                                                key={item?._id}
                                                // className=
                                                style={{
                                                opacity: snapshot.isDragging ? 0.5 : 1,
                                                ...provided.draggableProps.style
                                                }}
                                                className={"holderwrap"}
                                            >
                                                <li style={{direction:'ltr', justifyContent:'space-between'}}>
                                                    <span>

                                                        <HolderOutlined className="holder"/>
                                                        {item?.propertyDetail?.label}
                                                    </span>
                                                    <FontAwesomeIcon icon={faTimes}
                                                        onClick={async()=>{ await deleteEmployeeDetailView({
                                                            variables:{
                                                                userId: userDetail?._id,
                                                                propertyId: item?.propertyDetail?._id,
                                                                
                                                            }
                                                        }); await employeeDetailViewRefetch();}}
                                                    />
                                                </li>
                                                
                                            </div>
                                            </div>

                                        )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        
                        <div className="grid-50">
                            <EmployeeCustomLeftPane/>
                        </div>

                        <div className="grid-25">
                            <DetailPageRightSideBar setActiveTab={setActiveTab} />
                        </div>
                    </div>
                </div>

                :
                
                
                
                <>
                    {/* linkedIn color code background:'#f4f2ee' */}
                    <div style={activeTab=="profile"?{padding:'50px 5px 5px 5px', }:{padding:'50px 5px 5px 5px'}}>

                        {
                            activeTab!=="hr"?
                            <div className="grid-layout">
                                
                                
                                <div className="grid-25">
                                    {singleEmployee?
                                    <DetailPageLeftSideBar 
                                        employeeObject={employeeObject?.map((object)=> ({_id: object.propertyId, ...object.propertyDetail}))}
                                        singleEmployee={singleEmployee}
                                        emploading={employeeObjectLoading || singleEmployeeLoading}
                                        handelInputChange={handelInputChange}
                                        isFromPost={activeTab=="profile"?true:false}
                                    />:
                                    <Loader/>
                                    }
                                </div>
                                
                            
                            {/* ProfileTAB */}
                                {activeTab=="profile" || activeTab=="notes" || activeTab=="task" || activeTab=="sitecredential" ?
                            


                                    activeTab=="profile"?
                                    <div  className="grid-50">

                                        <DetailPageMiddleSection  
                                            singleEmployee={singleEmployee}
                                            
                                        />
                                    </div>
                                    :
                                    activeTab=="notes"?
                                    <div  className="grid-50">
                                        <EmployeeNotesTab 

                                            note={note?.getNote?.response} 
                                            noteLoading={noteLoading} 
                                            noteRefetch={noteRefetch} 
                                        />
                                    </div>
                                    :
                                    activeTab=="task"?
                                    <div  className="grid-50">
                                        <Task employeeId={param?.id} />
                                    </div>
                                    :
                                    activeTab=="sitecredential"?
                                    <div  className="grid-50">
                                        <SiteCredential/>
                                    </div>
                                    :
                                    null
                                    :
                                    null
                                }                   

                                {activeTab=="profile" || activeTab=="notes" || activeTab=="task" || activeTab=="sitecredential"?
                                    <div className="grid-25">
                                    
                                        <DetailPageRightSideBar 
                                            setActiveTab={setActiveTab}
                                        />
                                    </div>
                                    :null
                                }  
                            
                                    
                                {
                                activeTab=="skills" || activeTab=="calendar" || activeTab=="document"?
                                    
                                        
                                    activeTab=="calendar"?
                                        <div className="grid-75">
                                            <CalendarTab/>
                                        </div>
                                        
                                    :
                                    activeTab=="document"?
                                        <div className="grid-75">
                                            {
                                                param?.documentId?

                                                <ShareDocument />

                                                :

                                                <DocumentComponent 
                                                    singleEntityRefetch={singleEmployeeRefetch} 
                                                    singleEntityRecord={singleEmployeeRecord?.singleEmployee?.response} 
                                                    objectType={objectType.Employee}
                                                />
                                            }
                                        </div>
                                    
                                    : 
                                    activeTab=="skills"?
                                        <div className="grid-75">
                                            <SkillTab/>
                                        </div>
                                    :null

                                : null
                                    
                                    
                                
                                            
                                    
                            }
                            </div>
                            :
                            <HRTab
                                employeeObject={employeeObject?.map((object)=> ({_id: object.propertyId, ...object.propertyDetail}))}
                                singleEmployee={singleEmployee}
                                loading={employeeObjectLoading || singleEmployeeLoading}
                                handelInputChange={handelInputChange}
                                setActiveTab={setActiveTab}
                            />
                        }
                    
                        
                    </div>
                </>

                
            }

        <RightSideWidgetModel
            visible={rightSideWidgetModel}
            close = {()=>setRightSideWidgetModel(false)}
        />

        </div>
        
    );
}