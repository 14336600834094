import { useMutation, useQuery } from "@apollo/client";
import { faChevronDown, faChevronUp, faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "@src/components/spinner";
import { Form, Modal, Popover, Input, Checkbox, Tag, InputNumber } from "antd";
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { newDocumentShareMutation } from "@src/util/mutation/documetShare.mutation";
import { baseURL } from "@src/config/apollo";



export const ShareDocumentModal = ({visible, close, documentId, documentSharedDetailRefetch})=>{

    const {data: employeeData, loading: employeeDataLoading, refetch} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: {isArchived: false}
            }
        }
    });

    const [employeeList, setEmployeeList] = useState([]);
    useEffect(()=>{
        if(employeeData?.getEmployee?.response && !employeeDataLoading){
            setEmployeeList(employeeData?.getEmployee?.response);
        }
    },[employeeData?.getEmployee?.response, employeeDataLoading]);

    const [sharedWith, setSharedWith] = useState([]);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const [searchValue, setSearchValues] = useState("");
    const [externalShare, setExternalShare] = useState([]);
    const [shareDuration, setShareDuration] = useState([]);

    useEffect(()=>{
        if(sharedWith?.length>0 && searchValue?.length==0){
            const externalShare = sharedWith?.filter((external)=>emailRegex?.test(external?.email));
            setExternalShare(externalShare);
            setEmployeeList([...externalShare, ...employeeData?.getEmployee?.response]);
        }
    },[sharedWith, searchValue]);

    const dispatch = useDispatch();

    const [newDocumentShare, {loading: newDocumentShareLoading}] = useMutation(newDocumentShareMutation);

    const handelDocumentShare = async()=>{
        try{
            
            const documentShareInput = sharedWith?.map((share)=>({
                sharedWith: share?.email || share?.metadata?.email,
                shareDuration,
                shareUrl: baseURL+"/preview/",
                documentId,
                entityType: 'Employee',
                employeeId: emailRegex?.test(share?._id)? "": share?._id
            }));

            await newDocumentShare({
                variables: {
                    input : {
                        documentShareInput
                    }
                }
            });
            close();

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Document was shared successfully",
            }));

            await documentSharedDetailRefetch();
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error encountered while Sharing this document"
            }))
        }
    }

    return(

        <Modal
            open={visible}
            footer={
            <div 
                style={{
                    padding:'16px 40px', textAlign:'right', marginTop:'-15px',
                    borderTop: '2px solid rgb(245, 248, 250)'
                }}
            >
                <button  
                    className={newDocumentShareLoading || sharedWith?.length==0 || shareDuration<1 ? ' drawer-filled-btn disabled-btn' : 'drawer-filled-btn'} 
                    onClick={handelDocumentShare}
                    disabled={newDocumentShareLoading || sharedWith?.length==0 || shareDuration<1}
                >
                    {newDocumentShareLoading? <Spinner /> : "Save"}
                </button>
            </div>
            }
            closable={false}        
        >
            <div className='modal-header-title'>
                <span>Share Document With Anyone</span>
                <span onClick={()=>{close()}}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>
            
            <div className='modal-body'>

                
                <Form.Item className="suffix-input">
                    <label> Associated with records</label>
                    <Popover
                        placement="bottom"
                        overlayClassName='associationCustomPopover'
                        trigger={"click"}
                        content={
                            <div className="association" style={{flexDirection:'column'}}>

                                    <Input type="search"
                                        id="inputSearch"
                                        name='popoverSearch'
                                        value={searchValue}
                                        style={{ width: '-webkit-fill-available', margin:'8px 8px 16px 8px' }} 
                                        className='generic-input-control' 
                                        placeholder={"Search name or email or add email address for external share"}
                                        autoFocus={true}
                                        autoComplete="off"
                                        onChange={(e)=> {
                                            setSearchValues(e.target.value);
                                            setEmployeeList([...externalShare, ...employeeData?.getEmployee?.response]?.filter((emp)=>
                                                emp?.metadata?.email?.toLowerCase().includes(e.target.value?.toLowerCase()) || 
                                                emp?.firstname?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
                                                emp?.email?.toLowerCase().includes(e.target.value?.toLowerCase())
                                            ))
                                        }}
                                        onPressEnter={(e)=>{ 
                                            if( emailRegex?.test(e.target.value) && employeeData?.getEmployee?.response?.find((emp)=>emp?.metadata?.email.toLowerCase()===e.target.value.toLowerCase())===undefined ){
                                                const isExist = sharedWith?.find((share)=>share?._id?.toLowerCase()==(e.target.value.replace(/\s+/g, '')).toLowerCase());
                                                if(isExist){
                                                    dispatch(setNotification({
                                                        error: true,
                                                        message: "This Document Already Shared with this email",
                                                        notificationState: true
                                                    }));
                                                    setSearchValues("");
                                                }else{
                                                    const es = e.target.value;
                            
                                                    setSharedWith([...sharedWith,
                                                        {firstname: es, lastname:'', email: es, _id: es}
                                                    ]);
                                                    setSearchValues("");
                                                }
                                            }else{
                                                return;
                                            }
                                        }}

                                        suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                    />
                                    <div className="popover-data" style={{overflowY:'scroll', height:'170px', }}>
                                        
                                        {
                                            employeeList?.map((employee)=>(
                                                <div className="popoverdataitem">
                                                    <Checkbox onChange={(e)=>{
                                                        if(e.target.checked){
                                                            setSharedWith([...sharedWith, employee])
                                                        }else{
                                                            setSharedWith(sharedWith?.filter((share)=>share?._id!=employee?._id))
                                                        }
                                                    }}
                                                    checked={sharedWith?.find((share)=>share?._id==employee?._id)}
                                                    > 
                                                        {employee?.firstname +" "+ employee?.lastname}
                                                        {emailRegex?.test(employee?._id) ? <Tag>External</Tag> : null} 
                                                    </Checkbox>
                                                </div>
                                            ))
                                         
                                        }
                                    </div>


                            </div>
                        }


                    >
                        <Input
                            className="generic-input-control"
                            suffix={<span className="caret"></span>}
                            value={`Shared with ${sharedWith?.length} persons`}
                            readOnly={true}
                            style={{cursor:'pointer', fontWeight:'500'}}
                        />
                    </Popover>
                </Form.Item>

                <Form.Item  className="numberInput" style={{margin:'0', width:'100%'}}>
                    <label>Share duration</label>
                    <InputNumber placeholder="Share duration in Mintues" 
                        min={1}
                        onChange={(e)=>setShareDuration(e)}
                        className='generic-input-control'                         
                        upHandler={<FontAwesomeIcon style={{color:'#0091ae'}} icon={faChevronUp} />}
                        downHandler={<FontAwesomeIcon  style={{color:'#0091ae'}} icon={faChevronDown} />}
                    />
                </Form.Item>


            </div>
        </Modal>
    );
};