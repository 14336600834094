const { io } = require('socket.io-client');


// export const graphqlWsEndpoint = "ws://localhost:3600";
export const graphqlWsEndpoint = "https://widedownloader.com";

export const socket = io(graphqlWsEndpoint, {
  path: '/ws/socket.io/',
  transports: ['websocket'],
  reconnection: true,
  reconnectionDelay: 2000,
  reconnectionAttempts: 20,
  timeout: 8000

});

socket.on('connect', () => {
  console.log('Client connected to websocket');
  // Send a message immediately after connection
  socket.emit('message-from-client', { message: 'Hello from WFC Client' });
});


socket.on('disconnect', () => {
    console.log('Client disconnected to websocket');
    // Send a message immediately after connection
    socket.emit('message-from-client', { message: 'Hello from WFC Client' });
  });
// Connect to the server manually
export function connectSocket() {
  if (!socket.connected) {
    socket.connect();
  }
}
