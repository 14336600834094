import { useMutation, useQuery } from "@apollo/client";
import "../tab.css";
import { GROUPLIST } from "@src/util/query/group.query";
import { useEffect, useState } from "react";
import { GenerateFields } from "@src/util/generateFields/generateFields";
import { countries } from "@src/util/datalist/countries";
import { setNotification } from "@src/middleware/redux/reducers/notification.reducer";
import { useDispatch } from "react-redux";
import { moduleTabs } from "@src/util/tabs/employee.tab";
import { singleEmployeeDetailForHRQuery } from "@src/util/query/employeeDetailView.query";
import { useParams } from "react-router-dom";
import { updateEmployeeMutation } from "@src/util/mutation/employee.mutation";
import Spinner from "@src/components/spinner";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HolderOutlined } from "@ant-design/icons";
import { ReorderGroupMutation } from "@src/util/mutation/properties.mutation";
import { DetailPageLeftSideBar } from "../../leftSideBar/leftSideBar";
import { GetLookupStaticValue } from "@src/util/generateFields/lookupStaticValue";
import { lookupTypes } from "@src/util/types/lookup.types";

import {Row, Col} from "antd"
import { faPencil, faTimes } from "@fortawesome/free-solid-svg-icons";
import { DetailPageRightSideBar } from "../../rightSideBar/rightSideBar";
import { findPayDate } from "@src/util/findPayDate/findPayDate";
import dayjs from "dayjs";
import { upsertEmployeeDetailPageFieldOrderMutation } from "@src/util/mutation/employeeDetailPageFieldOrder.mutation";
import { useSelector } from "react-redux";
import { getemployeeDetailPageFieldOrderQuery } from "@src/util/query/employeeDetailPageFieldOrder.query";


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export const HRTab = ({employeeObject, singleEmployee, emploading, handelInputChange, setActiveTab})=>{

    const {data, loading} = useQuery(GROUPLIST ,{
        variables:{
            objectType: 'Employee'
        },
        fetchPolicy:'network-only'
    });

    const param = useParams();
    const [field, setField] = useState([]);

    const {data:employeeDetail, loading:employeeDetailLoading, refetch: singleEmployeeRefetch} = useQuery(singleEmployeeDetailForHRQuery,{
        variables:{
            id:param?.id
        },
        fetchPolicy:'network-only'
    });


    // these are the filtered group in which current tab is included.
    const [filteredGroup, setFilteredGroup] = useState([]);

    // this is the selected group from the left sidebar where the filtered grps are listed
    const [selectedGrp, setSelectedGrp] = useState("");

    // this is the clicked selected grp props that need to be render here
    const [selectedGroupProp, setSelectedGroupProp] = useState([]);

    useEffect(()=>{
        if(data?.groupList){
            const {groupList} = data;
            setFilteredGroup(groupList?.filter((list)=>list?.tabs?.includes(moduleTabs.Employee[0])).sort((a,b)=> a?.order - b?.order))

        }
    },[data]);

    useEffect(()=>{
        if(filteredGroup){
            // setSelectedGrp(filteredGroup[0]?.name);
            setSelectedGrp({name: filteredGroup[0]?.name, _id: filteredGroup[0]?.key});

        }
    },[filteredGroup]);

   
    // get the detailed page field order if user has already set the order
    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);

    const {data: employeeDetailPageFieldOrderData, loading: employeeDetailPageFieldOrderLoading} = useQuery(getemployeeDetailPageFieldOrderQuery, {
        variables:{
            groupId: selectedGrp?._id,
            userId: authenticatedUserDetail?._id
        },
        skip: !selectedGrp || !selectedGrp?._id || !authenticatedUserDetail?._id,
        fetchPolicy:'network-only'
    });



    useEffect(()=>{
        if(selectedGrp && !employeeDetailPageFieldOrderLoading){

            const props = filteredGroup?.find((grp)=> grp.name==selectedGrp?.name);
            const propsToRender = props?.propertyList?.filter((prop)=>prop?.isArchive==false && prop?.isDelete==false)

            const orderedFieldList = employeeDetailPageFieldOrderData?.getEmployeeDetailPageFieldOrder?.response?.fieldList;
            if(orderedFieldList){

                const orderedField = orderedFieldList.map((fieldId)=>{
                    return props?.propertyList?.find((prop)=>prop?._id==fieldId)
                });

                const unOrderedField = props?.propertyList?.filter((prop)=> !orderedFieldList?.includes(prop?._id))


                setSelectedGroupProp([...orderedField, ...unOrderedField]);
            }else{
                setSelectedGroupProp(propsToRender);
            }

        }
    },[selectedGrp, employeeDetailPageFieldOrderData, employeeDetailPageFieldOrderLoading])



    const handelDataValue = ({name, value})=>{
        if(name){
            if(value){
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value}])
                }
            }else{
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value:''
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value:''}])
                }
            }
        }
    }

    const [countryList, setCountryList] = useState();

    useEffect(()=>{
        setCountryList(
            countries?.map((c, i)=>({
                id: i,
                key: c,
                value: c,
                showFormIn: true
            }))
        );
    }, [countries]);

    const dispatch = useDispatch();

    
    const [updateEmployee, {loading: updateEmpLoading, error}]  = useMutation(updateEmployeeMutation);

    const handelUpdateSave = async ()=>{
        try{
            let schemaFields = [];

            field?.map((field)=>{
                if(field.name==="firstname" || field.name==="lastname"  || field.name==="branch"){
                    schemaFields.push(field);
                }else if(field?.name=="employmenttype" && field?.value!=="Agency staff"){
                    
                    schemaFields.push({...field, metadata:1}, {name: 'agency', value: null, metadata:1})

                }
                else{
                    schemaFields.push({...field, metadata:1})
                }
            });

            await updateEmployee({
                variables:{
                    input:{
                        _id: param?.id,
                        properties: schemaFields,
                    }
                }
            });
            setEditable(false);
            dispatch(setNotification({
                message: "Employee Details are Updated Successfully",
                notificationState: true,
                error: false
            }));

            await singleEmployeeRefetch();
            setField([]);
        }
        catch(err){            
            dispatch(setNotification({
                message: "An error encountered while updating employee",
                notificationState: true,
                error: true
            }));
            setField([]);
            setEditable(false);


        }
    };

    // dragable
    
    const [items, setItems] = useState(filteredGroup)
    useEffect(()=>{
        setItems(filteredGroup);
    }, [filteredGroup]);

    const [reorderGroup, {loading:reorderGroupLoading}] = useMutation(ReorderGroupMutation);
    const onDragEnd = async (result) => {
        // dropped outside the list
        if (!result.destination) {
        return;
        }
        
        const reorderedArr = reorder(
            items,
            result.source.index,
            result.destination.index
            )

        setItems(reorderedArr);
        const orderedGroup = {

            order: reorderedArr.map((item, index)=>index),
            ids : reorderedArr.map((item)=>item.key)
        }

        await reorderGroup({
            variables:{
                input: {groups: orderedGroup}
            }
        });
        

    }

    const [editable, setEditable] = useState(false);

    const [upsertEmployeeDetailPageFieldOrder] = useMutation(upsertEmployeeDetailPageFieldOrderMutation);

    const onDragEndField = async (result) => {
        // dropped outside the list
        if (!result.destination) {
        return;
        }
        
        const reorderedArr = reorder(
            selectedGroupProp,
            result.source.index,
            result.destination.index
        );


        setSelectedGroupProp(reorderedArr);


        await upsertEmployeeDetailPageFieldOrder({
            variables:{
                input: {
                    userId: authenticatedUserDetail?._id,
                    groupId: selectedGrp?._id,
                    fieldList: reorderedArr.map((item)=>item._id)
                }
            }
        });
        

    }


    return(
        <>
            <div className="grid-layout">

                    <div className="grid-25">
                        <DetailPageLeftSideBar
                            employeeObject={employeeObject}
                            singleEmployee={singleEmployee}
                            loading={emploading}
                            handelInputChange={handelInputChange}
                            showDetail={false}
                        />
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="tab-grp"
                                style={{
                                    width:'auto',
                                    border: '1px solid #ECEFEC',
                                    borderRadius: '12px',
                                    padding: '14px',
                                    marginTop: '10px',
                                    gap: 0,
                                }}
                                >
                                {items?.map((item, index) => (
                                    <Draggable key={item.key} draggableId={item.key} index={index}
                                    
                                    >
                                    {(provided, snapshot) => (
                                        <div className="icon-wrapper">
                                        
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            id={"item-"+index}
                                            itemRef={"item-"+index}
                                            key={item.key}
                                            // className=
                                            style={{
                                            opacity: snapshot.isDragging ? 0.5 : 1,
                                            marginBottom: '-2%',
                                            ...provided.draggableProps.style
                                            }}
                                            onClick={()=>setSelectedGrp({name: item?.name, _id: item?.key})} 
                                            className={selectedGrp?.name===item?.name? "activeTab holderwrap" : "holderwrap"}
                                        >
                                            <li >
                                                {item.name}
                                                <HolderOutlined className="holder"/>
                                            </li>
                                            
                                        </div>
                                        </div>

                                    )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                </div>
                            )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                    <div className="grid-50">
                        <div className="hr-section">
                            {employeeDetailLoading || loading?
                                    <Spinner />
                                :
                            
                                <div className="hr-info">
                                    <div className="hr-info-title" style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}> 
                                        {selectedGrp?.name} 
                                        {
                                            editable?
                                            <FontAwesomeIcon icon={faTimes} className="icon-bound" 
                                            onClick={()=>setEditable(!editable)}
                                            style={{boxShadow:'none', fontSize:'12px', borderRadius:'15px', padding:'6px 8px', cursor:'pointer'}}
                                            />
                                            :
                                            <FontAwesomeIcon icon={faPencil} className="icon-bound" 
                                                onClick={()=>setEditable(!editable)}
                                                style={{boxShadow:'none', fontSize:'12px', borderRadius:'15px', padding:'6px 8px', cursor:'pointer'}}
                                            />
                                        }
                                    </div>

                                    <div style={{display: 'flex', justifyContent: 'flex-start', width:'100%', flexWrap:'wrap', gap:'1px 100px'}}>
                                         
                                        <DragDropContext onDragEnd={onDragEndField}>
                                            <Droppable droppableId="droppable">
                                                {(provided, snapshot) => ( 
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        style={{
                                                            width:'100%'
                                                        }}
                                                    >
                                                        
                                                        {
                                                            selectedGroupProp?.map((prop, index)=> {
                                                                
                                                                const label = prop?.label;
                                                                const name = prop?.label.toLowerCase().replace(/\s/g,"");
                                                                const fieldType = prop?.fieldType;
                                                                const newprop = name=="nationality"? {...prop, options: countryList} : prop;
                                                                
                                                                const {metadata, ...rest} = employeeDetail?.singleEmployeeDetailForHRTab?.response
                                                                const dataFields = {...metadata, ...rest, agency:metadata?.agency?._id};

                                                                const {value} = field?.find((f)=>f.name==name) ||{value: dataFields[name]} || {value: ""};

                                                                const {value:payRate} = field?.find((f)=>f.name=="payrate") ||{value: dataFields["payrate"]} || {value: ""};

                                                                return (
                                                                    <Draggable key={prop?._id} draggableId={prop?._id} index={index}>
                                                                    {(provided, snapshot) => (
                                                                    
                                                                        <div className="field-ico-wrapper">

                                                                            <HolderOutlined className="holder" style={snapshot.isDragging? {opacity: 0} : {opacity: 1}}/>
                                                                            
                                                                            <div 
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                id={"item-"+index}
                                                                                itemRef={"item-"+index}
                                                                                key={prop?._id}
                                                                                style={{
                                                                                    width:'100%',
                                                                                    // opacity: snapshot.isDragging ? 0.3 : 1,
                                                                                    paddingBottom: '-10px',
                                                                                    ...provided.draggableProps.style

                                                                                }} 
                                                                                className="holderwrap"
                                                                            >

                                                                                {!editable?
                                                                                        label=="Agency" && dataFields.hasOwnProperty("employmenttype") && dataFields["employmenttype"]=="Agency staff"?
                                                                                        <div className='fieldView'>
                                                                                            <div>{label}</div>
                                                                                            <div>
                                                                                                {lookupTypes?.includes(fieldType)?
                                                                                                    value?
                                                                                                    <GetLookupStaticValue
                                                                                                        type={fieldType}
                                                                                                        id={value}
                                                                                                    />
                                                                                                    : ""
                                                                                                    :
                                                                                                    Array.isArray(value)? value?.join(",") : value
                                                                                                }
                                                                                            </div>
                                                                                        </div> : 

                                                                                        label!=="Agency" &&
                                                                                        <div className='fieldView'>
                                                                                            <div>{label}</div>
                                                                                            <div>
                                                                                                {lookupTypes?.includes(fieldType)?
                                                                                                    value?
                                                                                                        <GetLookupStaticValue
                                                                                                            type={fieldType}
                                                                                                            id={value}
                                                                                                        />
                                                                                                    : ""
                                                                                                :
                                                                                                fieldType=="paywidget"?
                                                                                                    value?.length>0 && Array.isArray(value)?
                                                                                                    "£ " +findPayDate(value)
                                                                                                    : ""
                                                                                                :
                                                                                                fieldType=="payadjustmentwidgets"?
                                                                                                    typeof value == "object" ?
                                                                                                        value?.multiplier !=="custom"?
                                                                                                        "£ " +((parseFloat(findPayDate(payRate)) +  ( (parseFloat(value?.multiplier)/100) * parseFloat(findPayDate(payRate)) ) ) || 0 ).toFixed(2) +"/h"

                                                                                                        :
                                                                                                        "£ " +((parseFloat(findPayDate(payRate)) +  parseFloat(value?.rate))||0).toFixed(2) +"/h"

                                                                                                    :""
                                                                                                :
                                                                                                    fieldType=="date"?
                                                                                                    value?.length>0?
                                                                                                        dayjs(value).format("DD/MM/YYYY")
                                                                                                    :''
                                                                                                    :
                                                                                                    Array.isArray(value)? value?.join(",") : value
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                    
                                                                                        
                                                                                    :
                                                                                    label=="Agency" &&  dataFields.hasOwnProperty("employmenttype") && dataFields["employmenttype"]=="Agency staff"?
                                                                                    
                                                                                    <GenerateFields 
                                                                                        label={label}
                                                                                        name={name}
                                                                                        fieldType={fieldType}
                                                                                        handelDataValue={handelDataValue}
                                                                                        property={newprop}
                                                                                        value={value}
                                                                                        employee={true}
                                                                                        singleEmployeeRefetch={singleEmployeeRefetch}
                                                                                    />
                                                                                    :
                                                                                    label!=="Agency" &&
                                                                                    <GenerateFields 
                                                                                        label={label}
                                                                                        name={name}
                                                                                        fieldType={fieldType}
                                                                                        handelDataValue={handelDataValue}
                                                                                        property={newprop}
                                                                                        value={value}
                                                                                        employee={true}
                                                                                        singleEmployeeRefetch={singleEmployeeRefetch}
                                                                                    />
                                                                                    
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    </Draggable>
                                                                );
                                                            })
                                                        }

                                                    </div> 
                                                )}
                                            </Droppable>
                                        </DragDropContext>


                                        
                                    </div>
                                </div>   
                            }                 
                        </div>
                    </div>
                    
                    <div className="grid-25">
                        <DetailPageRightSideBar setActiveTab={setActiveTab} />
                    </div>
                    
                
            </div>


            {/* control btn */}
            {
                field?.length>0?
                    <div className='hr-action-footer'>

                        <button className={updateEmpLoading?'drawer-filled-btn disabled-btn': 'drawer-filled-btn'} onClick={handelUpdateSave} >
                            {updateEmpLoading? <Spinner/> : "Save"}
                        </button>
                        <button onClick={()=>setField([])} className={updateEmpLoading?'drawer-outlined-btn disabled-btn':'drawer-outlined-btn'} >Cancel</button>
                    
                        <span className='text' style={{margin: 0}}>You've changed {field?.length} data fields</span>
                        
                    </div>
                :null
            }
        </>
        
    );
};