import { gql } from "@apollo/client";

export const newSiteTaskMutation = gql`
mutation($input: SiteTaskInput!){
  newSiteTask(input: $input) {
    message
    response
  }
}
`;

export const updateSiteTaskMutation = gql`
mutation($input: SiteTaskInput!){
  updateSiteTask(input: $input) {
    message
    response
  }
}
`;


export const deleteSiteTaskMutation = gql`
mutation($siteTaskId: String!){
  deleteSiteTask(siteTaskId: $siteTaskId) {
    message
    response
  }
}
`;