import './draggableTab.css';
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useRef, useState } from 'react';
import { Tabs, Popover, Input, Button, Dropdown, Menu } from 'antd';
import dragimg from '@src/assets/img/draggable.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faWarning } from '@fortawesome/free-solid-svg-icons';
import { faAdd, faSearch } from '@fortawesome/free-solid-svg-icons';
import { CreateView } from './modal/createView.modal';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { resetAdvanceFilter, resetQuickFilter, setAdvanceFilter, setQuickFilter, setSelectedViewId } from '@src/middleware/redux/reducers/quickFilter';
import { useSelector } from 'react-redux';
import { resetAll } from '@src/middleware/redux/reducers/reset.reducer';
import { setTogglenewCreateView } from '@src/middleware/redux/reducers/newView.reducer';
import { createdDateList } from "@src/util/date";
import { setDefaultUserViewMutation, upsertUserAllViewMutation } from '@src/util/mutation/userAllView.mutation';
import { getUserAllViewQuery } from '@src/util/query/userAllView.query';
import { AllView } from "@src/components/allView/allView.component";



const DraggableTabNode = ({ className, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props['data-node-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleX: 1,
      },
    ),
    transition,
    cursor: 'pointer',
  };
  return React.cloneElement(props.children, {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
  });
};
const DraggableTab = ({viewList, refetch, loading,
  updateView, createViewLoading, createView, addNewViewHide=false, objectType="", openAllView,
  deleteView, cloneView
}) => {

  const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);   
  const [upsertUserViewList, {loading: upsertUserViewListLoading}] = useMutation(upsertUserAllViewMutation); 



  const [items, setItems] = useState([]);

  const popoverRef = useRef();
  const inputRef = useRef();

  const [activeKey, setActiveKey] = useState();
  const [createViewForm, setCreateViewForm] = useState(false);
  const [view, setView] = useState([]);
  const [viewSearch, setViewSearch] = useState();
  const [selectedView, setSelectedView] = useState('');
  const [viewPop, setViewPop] = useState();
  const [createdView, setCreatedView] = useState([]);
  
  const dispatch = useDispatch();

  const [pinView, setPinView] = useState();
  const [allViewModal, setAllViewModal] = useState(false);
  
  const [newView, setNewView] = useState(false);

  // add new view in pinned list
  const handelSelectedView = async(prop)=>{
    dispatch(resetAll());
    const selectedSingleView = viewList?.find((view)=>view._id==prop.id);
    const isExist = items.find((item)=>item.label==prop.label);

    sessionStorage.setItem("selectedViewId", prop?._id)

    if(!isExist){
      if(items?.length<5){
        if(objectType?.length>0 && authenticatedUserDetail?._id){
          await upsertUserViewList({
            variables:{
              input:{
                userId: authenticatedUserDetail?._id,
                objectType,
                viewDetail: [...items, {_id: prop._id}]?.map((item, order)=>({viewId: item?._id?.toString(), order: order.toString(), isPinned: true })),
              }
            }
          });
        }
        setItems([...items, {key: prop.id, label: selectedSingleView?.name, ...selectedSingleView}]);
        setNewView(prop?.id);
        await refetch();
        await refetchUserAllView();
        
      }else{
        setItems([...items.slice(0,5), {key: prop.id, label: selectedSingleView?.name, ...selectedSingleView}])
        setNewView(prop?.id);
      }
    }else{
      setActiveKey(isExist.key.toString());
    }

    if(selectedSingleView?.quickFilter){
      const quickFilter = {}
      
      if(selectedSingleView?.quickFilter?.createdDate && Object.values(selectedSingleView?.quickFilter?.createdDate)?.length>0){
        quickFilter['createdDate']={
          title: selectedSingleView?.quickFilter?.createdDate?.title,
          value: createdDateList?.find((date)=>date?.title==selectedSingleView?.quickFilter?.createdDate?.title)?.value
        }
      }

      if(selectedSingleView?.quickFilter?.updatedDate && Object.keys(selectedSingleView?.quickFilter?.updatedDate)?.length>0){
        quickFilter['updatedDate']={
          title: selectedSingleView?.quickFilter?.updatedDate?.title,
          value: createdDateList?.find((date)=>date?.title==selectedSingleView?.quickFilter?.updatedDate?.title)?.value
        }
      }

      dispatch(setQuickFilter(quickFilter));
    }else{
      dispatch(resetQuickFilter());
    }
    if(selectedSingleView?.advanceFilter && selectedSingleView?.advanceFilter?.length>0){
      dispatch(setAdvanceFilter(selectedSingleView?.advanceFilter));
    }else{
      dispatch(resetAdvanceFilter());
    }

    setAllViewModal(false);
  };
  const {data:userAllViewList, loading:userAllViewListLoading, refetch: refetchUserAllView} = useQuery(getUserAllViewQuery,{
    variables:{
      userId: authenticatedUserDetail?._id,
      objectType
    },
    fetchPolicy:'network-only',
    skip: !authenticatedUserDetail?._id || objectType?.length==0
  })


  // handel to active new view

  useEffect(()=>{
    if(newView){
      setActiveKey((newView).toString());
      setSelectedView((newView).toString())
      setPinView((newView).toString())
      setNewView(false);
    }
  },[newView, items]);
 


  useEffect(()=>{
    refetchUserAllView();
    console.log("refetchUserAllView", loading);
  },[loading]);


  // load all pinned view from user views
  useEffect(()=>{
    if(viewList && userAllViewList && !userAllViewListLoading && !loading){
      
      const orderedViewIds = userAllViewList?.getUserAllViewList?.response?.viewDetail?.map((ov)=>ov?.viewId);
      
      const orderedViews = orderedViewIds?.map((ov)=> viewList?.find((vl)=>vl?._id==ov)) || [];
    

      setItems(
        orderedViews?.map((list, index)=>({...list, key: list?._id, label:list?.name, id: list?._id}))
      );
      
      setView(viewList?.filter((view)=> view?.isStandard)?.map((list, index)=>({...list, key: list._id, label:list.name, id: list._id})));
      
      setCreatedView(
        viewList?.filter((view)=> view?.createdBy==authenticatedUserDetail?._id && !view?.isStandard)?.map((list, index)=>({key: list._id, label:list.name, id: list._id, ...list}))
      );

      if(!sessionStorage.getItem('selectedViewId')){

        sessionStorage.setItem("selectedViewId", orderedViews[0]?._id);
      
      
        // Handel page load first time to active tab

        dispatch(resetAll());
        setActiveKey(orderedViews[0]?._id);
        setPinView(orderedViews[0]?._id);

        if( orderedViews[0]?.quickFilter && Object.values(orderedViews[0]?.quickFilter).length>0){

          const filter = {
            createdDate: orderedViews[0]?.quickFilter?.createdDate && Object.values(orderedViews[0]?.quickFilter?.createdDate)?.length>0?{
              ...orderedViews[0]?.quickFilter?.createdDate,
              value: createdDateList?.find((date)=>date?.title==orderedViews[0]?.quickFilter?.createdDate?.title)?.value
            } : {},
            updatedDate: orderedViews[0]?.quickFilter?.updatedDate && Object.values(orderedViews[0]?.quickFilter?.updatedDate)?.length>0?{
              ...orderedViews[0]?.quickFilter?.updatedDate,
              value: createdDateList?.find((date)=>date?.title==orderedViews[0]?.quickFilter?.updatedDate?.title)?.value
            }:{}
          }
          dispatch(setQuickFilter(filter));
        }else{
          dispatch(resetQuickFilter());
          dispatch(setQuickFilter([]));

        }
        if(orderedViews[0]?.advanceFilter && orderedViews[0]?.advanceFilter?.length>0){
          dispatch(setAdvanceFilter(orderedViews[0]?.advanceFilter));
        }else{
          dispatch(resetAdvanceFilter());
          dispatch(setAdvanceFilter([]));

        }
      }

    }
  },[viewList, userAllViewList?.getUserAllViewList?.response, userAllViewListLoading, loading])



  useEffect(()=>{
    if(sessionStorage.getItem("selectedViewId")){
      setActiveKey(sessionStorage.getItem("selectedViewId"));
    }
  },[sessionStorage.getItem("selectedViewId")]);


  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 100,
    },
  });


  // rearrange the order of pinned views in userViewList based on objectType e.g; Site, Employee, Branch
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setItems((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        const finalItems = arrayMove(prev, activeIndex, overIndex);
        if(objectType?.length>0 && authenticatedUserDetail?._id){
          upsertUserViewList({
            variables:{
              input:{
                userId: authenticatedUserDetail?._id,
                objectType,
                viewDetail: finalItems?.map((item, order)=>({viewId: item?._id.toString(), order: order.toString(), isPinned: true })),
              }
            }
          })
        }
        return arrayMove(prev, activeIndex, overIndex);
      });
      refetchUserAllView();
      refetch();
    }
  };


  useEffect(() => {
    // Function to handle clicks outside the box
    const handleClickOutside = (event) => {
      if(event.target.name==="popoverSearch" || event.target.name==="popoverCheckboxes"){ return; }
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        // Perform your desired action here
        setViewPop(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // on unPin we do remove the view from UserViewList 
  const removeView = async (rmItm) => {
    setItems(items?.filter(item=>item.key!=rmItm.key));
    await refetch();
    if(objectType?.length>0 && authenticatedUserDetail?._id){
      await upsertUserViewList({
        variables:{
          input:{
            userId: authenticatedUserDetail?._id,
            objectType,
            viewDetail: items?.filter(item=>item.key!=rmItm.key)?.map((item, order)=>({viewId: item?._id.toString(), order: order.toString(), isPinned: true })),
          }
        }
      });
      await refetchUserAllView();
    }

    const islastKeyExist = (items?.filter(item=>item.key!=rmItm.key))[0]?.key;
    if(islastKeyExist){
      handelActiveTab(islastKeyExist);
    }

  };


  const handelActiveTab = (e)=>{
    dispatch(resetAll());
    setActiveKey(e);
    setPinView(e.toString());
    const selectedView = items?.find((view)=>view.key==e.toString());
    
    // dispatch(setSelectedViewId(selectedView?._id));
    sessionStorage.setItem("selectedViewId", selectedView?._id);
    
    if(selectedView?.quickFilter && Object.values(selectedView?.quickFilter).length>0){

      const filter = {
        createdDate:{
          ...selectedView?.quickFilter?.createdDate,
          value: createdDateList?.find((date)=>date?.title==selectedView?.quickFilter?.createdDate?.title)?.value
        },
        updatedDate:{
          ...selectedView?.quickFilter?.updatedDate,
          value: createdDateList?.find((date)=>date?.title==selectedView?.quickFilter?.updatedDate?.title)?.value
        }
      }
      dispatch(setQuickFilter(filter));

    }else{
      dispatch(resetQuickFilter());
    }
    if(selectedView?.advanceFilter && selectedView?.advanceFilter?.length>0){
      dispatch(setAdvanceFilter(selectedView?.advanceFilter));
    }else{
      dispatch(resetAdvanceFilter());
    }
  }

  // toggle create view from save as option in save btn
  const {togglenewCreateView} = useSelector(state=>state.newViewReducer);
  

  return (
    <>
      <div
        className='setting-body-inner grid-tabs' style={{
          padding: '25px 48px 0px', 
          display: 'flex', alignItems:'center'
        }}
      >
        <Tabs       
          closeIcon={true}
          onChange={(e)=>handelActiveTab(e)}
          
          renderTabBar={(tabBarProps, DefaultTabBar) => (
            <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
              <SortableContext items={items.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
                <DefaultTabBar {...tabBarProps}>
                  {(node) => (
                    <DraggableTabNode {...node.props} key={node.key}>
                      {node}
                    </DraggableTabNode>
                  )}
                </DefaultTabBar>
              </SortableContext>
            </DndContext>
          )}
          activeKey={activeKey}
        >
          {items?.map((item, index)=>(

            <Tabs.TabPane  tab={

                <div className='dragContent' style={userAllViewListLoading || upsertUserViewListLoading || createViewLoading ? {opacity:0.3}:{opacity:1}}>
                  {items?.length>1?
                  <>
                    {index>4?
                    <Popover
                      content={"You have hit the limit of open views."}
                    >
                      <FontAwesomeIcon icon={faWarning} style={{color: 'orange', margin: '0 10px'}} />
                    </Popover>
                    : 
                    <img src={dragimg} alt="" className='dragimg'/> 
                    }
                    
                    
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}> {item.label} </div> 
                       

                    

                    {
                      addNewViewHide? null :
                      <FontAwesomeIcon 
                      style={{marginLeft:'15px',marginRight:'-15px'}} 
                      className={pinView==item.key?'':'dragimg'} icon={faClose} 
                      onClick={()=>removeView(item)}
                      />
                    }
                  </> : 
                  <>
                    {index>4?
                    <FontAwesomeIcon icon={faWarning} />
                    : 
                    <img src={dragimg} alt="" className='dragimg'/> 
                    }
                    <div style={{width:'100%'}}>{item.label}</div>
                  </>
                  }
                </div>

            } key={item?.key} tabKey={item?.key} />

          ))}
        </Tabs>

        
          <div className='addView' style={addNewViewHide?{paddingTop:'33px'}:{}}> 

            {addNewViewHide? null:
              <>
                <Popover
                    open={viewPop}
                    overlayClassName='settingCustomPopover '
                    afterOpenChange={()=>{inputRef.current.focus();}}
                    style={{ width: 250 }}
                    content={
                        viewPop?
                        <div ref={popoverRef}>
                            <div className="popover-search" ref={popoverRef}>
                                <Input type="search" 
                                    ref={inputRef}
                                    id="inputSearch"
                                    name='popoverSearch'
                                    style={{ width: '-webkit-fill-available', backgroundColor: 'white'  }} 
                                    className='generic-input-control' 
                                    placeholder="Search..."
                                    autoFocus={viewSearch}
                                    autoComplete="off"
                                    value={viewSearch}
                                    onChange={(e)=> {
                                        setView(viewList?.filter((date)=> (date.name)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))
                                        setViewSearch(e.target.value);
                                    }}
                                    suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                />
                            </div>
                            
                            <div style={{display:'flex'}}>

                                <div 
                                    className="popover-data"  
                                    style={{
                                        width: '275px',
                                        overflow: 'auto',
                                        height: '25vh',
                                    }}          
                                    ref={popoverRef}
                                >
                                    <div
                                        style={{
                                            padding: '8px 20px',
                                            color: 'black',
                                            fontWeight: 600,
                                        }}
                                    >Standard ({view?.length})</div>
                                    {view && view?.map((datelist)=>(

                                        <div 
                                            style={{paddingLeft:'40px'}}
                                            className={selectedView==datelist.label? 
                                            "popoverdataitem popoverdataitem-active": "popoverdataitem"} 
                                            onClick={(e)=>{handelSelectedView(datelist);  setViewPop(false)}}>
                                            {datelist?.label}
                                        </div>
                                    ))}
                                </div>
                                {createdView?.length>0?
                                <div      
                                    className="popover-data"  
                                    style={{
                                        width: '275px',
                                        overflow: 'auto',
                                        height: '25vh',
                                    }}          
                                    ref={popoverRef}
                                >
                                    <div
                                        style={{
                                            padding: '8px 20px',
                                            color: 'black',
                                            fontWeight: 600,
                                        }}
                                    >Created by me ({createdView?.length})</div>
                                    {createdView && createdView?.map((datelist)=>(

                                        <div 
                                            style={{paddingLeft:'40px'}}
                                            className={selectedView==datelist.label? "popoverdataitem popoverdataitem-active": "popoverdataitem"} 
                                            onClick={(e)=>{handelSelectedView(datelist); setViewPop(false)}}>
                                            {datelist.label}
                                        </div>
                                    ))}
                                </div>
                                : null
                                }
                            </div>

                            {/* footer */}
                            <div className="addViewfooter" ref={popoverRef} onClick={()=>setCreateViewForm(true)}>
                                <span>Create a new view</span>
                            </div>

                        </div>
                        : null
                        }
                        trigger="click"
                        placement='bottom'
                    >
                      <Button type='text' ref={popoverRef}  onClick={()=>{setViewPop(!viewPop)}}> <FontAwesomeIcon icon={faAdd} color='#7c98b6' /> &nbsp; Add view ({items?.length}/5)</Button>
                </Popover>

                <Button type='text' onClick={()=>setAllViewModal(true)}>All views </Button>
              </>      
            }             

          </div>
        

      </div>
      
      <CreateView
            visible={createViewForm || togglenewCreateView}
            onClose={()=>{setCreateViewForm(false); dispatch(setTogglenewCreateView(false)); handelActiveTab(sessionStorage.getItem("selectedViewId"))}}
            setcreatedView = {setCreatedView}
            createdView={createdView}
            branchViewRefetch={refetch}
            createView= {createView}
            createViewLoading = {createViewLoading}
            selectedView={viewList?.find((view)=>view._id==sessionStorage.getItem("selectedViewId"))}
      />

    {
      allViewModal &&
      <AllView 
        objectType={objectType}
        viewList = {viewList}
        loading = {loading}
        refetch = {refetch}
        close={()=>setAllViewModal(false)}
        updateView={updateView}
        cloneView={cloneView}
        deleteView={deleteView}
        handelSelectedView={handelSelectedView}
      />
    }


    </>
  );
};
export default DraggableTab;