import { Input, Table } from "antd";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { faClose, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SiteDutyTaskDrawer } from "./dutyTask.drawer";
import { useMutation, useQuery } from "@apollo/client";
import { getSiteTaskQuery } from "@src/util/query/siteTask.query";
import { deleteSiteTaskMutation } from "@src/util/mutation/siteTask.mutation";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';



export const SiteDutyTask = ()=>{

    const param = useParams();

    const {data:siteTaskData, loading:siteTaskDataLoading, refetch} = useQuery(getSiteTaskQuery,{
        variables:{
            siteId: param?.id
        },
        fetchPolicy:'network-only'
    });

    const [hoveredRow, setHoveredRow] = useState(null);

    const rowClassName = (record) => {
        return record.key === hoveredRow ? 'hovered-row' : '';
    };
    
    const handleRowMouseEnter = (record) => {
        setHoveredRow(record.key);
        sessionStorage.setItem('hoverItem', record.key);
    };


    const handleRowMouseLeave = () => {
        setHoveredRow(null);
        sessionStorage.removeItem('hoverItem');

    };


    const [dataSource, setDataSource] = useState([]);
    const [search, setSearch] = useState(null);
    const [dutyTaskModal, setDutyTaskModal] = useState(false);

    const [deleteSiteTask, {loading:deleteSiteTaskLoading}] = useMutation(deleteSiteTaskMutation);

    useEffect(()=>{
        if(siteTaskData?.getSiteTask?.response?.length>0){
            setDataSource(siteTaskData?.getSiteTask?.response?.map((siteTask)=>({
                key: siteTask?._id,
                title: siteTask?.title,
                timeInterval: <span>
                    <div style={{textTransform:'capitalize', fontWeight:'500'}}>
                        {siteTask?.timeIntervalType}
                    </div>
                    {siteTask?.customTimeInterval && <span className="text-subtitle" style={{fontStyle:'italic'}}>Each {siteTask?.customTimeInterval}  {siteTask?.timeIntervalUnit}</span>}
                </span> ,
                response: siteTask?.response? "YES" : "NO",
                comment: siteTask?.comment? "YES" : "NO",
                attachment: siteTask?.attachment? "YES" : "NO",
            })));
        }else{
            setDataSource([]);
        }
    },[siteTaskData?.getSiteTask?.response]);

    const [editId, setEditId] = useState(null);

    const  dispatch = useDispatch();
    const handelDeleteSiteTask = async (id)=>{
        const confirmation = confirm("Do you want to delete this site task?");
        if(confirmation){

            await deleteSiteTask({
                variables:{
                    siteTaskId: id
                },
            });

            dispatch(setNotification({
                error: false,
                notificationState: false,
                message: "Site task was deleted successfully"
            }));

            await refetch();

        }
    }

    const columns = [
        {id:0, title: 'Title', dataIndex:'title',
            render:(_, record)=>{
              const showActions = sessionStorage.getItem('hoverItem') == record.key;

                return(
                    <div style={{textTransform:'capitalize', display:'flex', }}>
                        {record?.title} &nbsp;
                        
                        <button className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link" 
                            onClick={()=>{setDutyTaskModal(true); setEditId(record?.key)}}
                        >
                            Edit
                        </button>
                        &nbsp;
                        <button className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link" 
                            onClick={()=>handelDeleteSiteTask(record?.key)}
                        >
                            Delete
                        </button>

                    </div>
                )
            }
        },
        {id:1, title: 'Time Interval', dataIndex:'timeInterval'},
        {id:2, title: 'Any Response', dataIndex:'response'},
        {id:3, title: 'Any Comments', dataIndex:'comment'},
        {id:4, title: 'Any Attachment', dataIndex:'attachment'},
    ];

    return (
        <div className='hr-section'>
            
            <div style={{paddingRight:'16px'}}>
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Site Duty Task</div>
                </div>
            </div>

            {/* duty site task body */}

            <div className="right-filter-inner">
                <Input type="search" 
                    style={{width:'250px'}} 
                    className='generic-input-control' 
                    placeholder="Search duty task"
                    onChange={(e)=>setSearch(e.target.value)}
                    value={search}
                    autoComplete="off"
                    suffix={search? 
                    <FontAwesomeIcon style={{color:'#7c98b6', cursor:'pointer', fontSize: '20px'}} onClick={()=>{setSearch(null);}} icon={faClose}/> : 
                    <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
                />
                <button className='drawer-filled-btn' onClick={()=>setDutyTaskModal(true)} style={{height:'40px'}}> Create Duty Task</button>
            </div>

            <Table
                columns={columns}
                dataSource={dataSource}
                className="grid-curvTable"
                locale={{emptyText:'No duty task found'}}
                loading={siteTaskDataLoading}
                onRow={(record) => ({
                  onMouseEnter: () => handleRowMouseEnter(record),
                  onMouseLeave: () => handleRowMouseLeave(),
                })}
                rowClassName={rowClassName}
            />

            {/* site duty task drawer to create/edit or view detail */}
            {
                dutyTaskModal &&
                <SiteDutyTaskDrawer
                    visible={dutyTaskModal}
                    close={()=>{ setDutyTaskModal(false); setEditId(null); }}
                    refetch={refetch}
                    editId={editId}
                />
            }
        </div>
    )
}