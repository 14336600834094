import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { getPayBillReportMutation } from "@src/util/mutation/systemReport.mutation";
import Spinner from "@src/components/spinner";
import { useSelector } from "react-redux";
import { saveAs } from 'file-saver';
import Papa from "papaparse";
import { Button, Dropdown, Menu } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { holidayHour, regularHour, overTimeHour } from "@src/util/reportVariables";

// to do holiday bill and hour
// if first date is in holiday then first date hour and pay and bill will goes to holiday section and regular would be zero
// vice versa for second date as well
// Bank Holiday 65ff5efdcdef94de7cabc3cb

export const SystemReportPayBillView = () => {
    
    const location = useLocation();
    const {filters = {}, filterContent = '', reportDetail = {}} = location?.state || {};
    
    const [reportData, setReportData] = useState([]);
    const navigate = useNavigate();

    const [getPayBillReport, {loading: getPayBillReportLoading}] = useMutation(getPayBillReportMutation);

    const handelPayBillReport = async ()=>{
        try{
            if(filters?.conditions){

                const {data:{getPayBillReport: {response}}} = await getPayBillReport({
                    variables:{
                        filters: {conditions: filters.conditions[0]!=null? filters.conditions: [], date: {from: dayjs(filters?.dates?.from, "DD/MM/YYYY"), to: dayjs(filters?.dates?.to, "DD/MM/YYYY")}}
                    }
                });

                if(response?.length==0){
                    alert("There is no pay/bill calculated in the selected dates");
                    navigate(-1);
                }else{

                    setReportData(response);
                }

            }
        }catch(err){
            console.log(err);
        }
    };

    useEffect(()=>{
        if(filters){

            handelPayBillReport();

        }
    },[filters]);

    const exportToCSV = ()=>{

        const groupedData = groupByMultipleKeys(reportData, ["siteId"]);
        let csvData = [];

        Object.keys(groupedData)?.map((group)=>{
            groupedData[group]?.map((job)=>{
                
                const response = {
                    "Employee Pay Code/Name": job?.employeeDetail?.firstname+" "+(job?.employeeDetail?.lastname || ""),
                    "Regular Pay Hour": convertToHrsMin(parseFloat(job?.payFirstDateHour)+parseFloat(job?.paySecondDateHour)),
                    "Regular Bill Hour": convertToHrsMin(parseFloat(job?.billFirstDateHour)+parseFloat(job?.billSecondDateHour)),
                    "Regular Pay": `£ ${parseFloat(job?.paybillDetail?.totalPay).toFixed(2)}`,
                    "Regular Bill": `£ ${parseFloat(job?.paybillDetail?.totalBill).toFixed(2)}`,
                    
                    "Bank Holiday Pay Hour": 
                    job?.isPayFirstDateInBankHoilday && job?.isPaySecondDateInBankHoilday?
                    convertToHrsMin(parseFloat(job?.payFirstDateHour)+parseFloat(job?.paySecondDateHour))
                        :
                    job?.isPayFirstDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.payFirstDateHour))
                        :
                    job?.isPaySecondDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.paySecondDateHour))
                        :
                        "00 hrs 00 m"
                    ,

                    "Holiday Bill Hour": 
                    job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.billFirstDateHour)+parseFloat(job?.billSecondDateHour))
                        :
                    job?.isBillFirstDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.billFirstDateHour))
                        :
                    job?.isBillSecondDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.billSecondDateHour))
                        :
                        "00 hrs 00 m"
                    ,

                    "Holiday Pay": 
                    job?.isPayFirstDateInBankHoilday && job?.isPaySecondDateInBankHoilday?
                        "£ " +(parseFloat(job?.paybillDetail?.totalPay)?.toFixed(2))
                        :
                    job?.isPayFirstDateInBankHoilday?
                        "£ " +(parseFloat(job?.paybillDetail?.firstDatePay))?.toFixed(2)
                        :
                    job?.isPaySecondDateInBankHoilday?
                        "£ " +(parseFloat(job?.paybillDetail?.secondDatePay))?.toFixed(2)
                        :
                        "£ 0.00"
                    ,

                    "Holiday Bill": 
                    job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                        "£ " +(parseFloat(job?.paybillDetail?.totalBill)?.toFixed(2))
                        :
                    job?.isBillFirstDateInBankHoilday?
                        "£ " +(parseFloat(job?.paybillDetail?.firstDateBill))?.toFixed(2)
                        :
                    job?.isBillSecondDateInBankHoilday?
                        "£ " +(parseFloat(job?.paybillDetail?.secondDateBill))?.toFixed(2)
                        :
                        "£ 0.00"
                    ,

                    "Overtime Pay Hour": "00 hrs 00 m",
                    "Overtime Bill Hour": "00 hrs 00 m",
                    "Overtime Pay": "£ 0.00",
                    "OverTime Bill": "£ 0.00",

                    "Premium Pay": `£ ${(job?.paybillDetail?.payBillPremium?.length>0 ? (job?.paybillDetail?.payBillPremium[0][0]?.payPremium?.totalPremiumPay).toFixed(2) : 0.00) || "0.00"}`,
                    "Premium Bill": `£ ${(job?.paybillDetail?.payBillPremium?.length>0 ? (job?.paybillDetail?.payBillPremium[0][1]?.payBillPremium?.totalPremiumBill)?.toFixed(2) : 0.00) || "0.00"}`,
                    
                    "Total Pay": `£ ${parseFloat(job?.paybillDetail?.totalPay)?.toFixed(2)}`,
                    
                    "Total Bill": `£ ${parseFloat(job?.paybillDetail?.totalPay)?.toFixed(2)}`,
                    
                    "Profit": `£ ${parseFloat(job?.paybillDetail?.profit)?.toFixed(2)}`,
                    
                    "Profit Percentage": `% ${parseFloat(job?.paybillDetail?.profitPercent)?.toFixed(2)}`,

                };

                csvData.push(response);

            });
        });

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const name = reportDetail?.name+" - "+dayjs().format("DD/MM/YYYY");
        saveAs(blob, `${name}.csv`);

    };

    const groupByMultipleKeys = (data, keys) => {
        if (!keys.length) return data; // If no keys are provided, return original data
      
        return data.reduce((acc, item) => {
          let currentLevel = acc;
      
          // Iterate through keys and build nested structure
          keys.forEach((key, index) => {

            let keyValue = "Unknown";

            if(key=="customerId"){
                keyValue = item[key]+"_"+item['customerDetail']['customername'].replaceAll(" ", "-")+"_customer";
            }
            else if(key=="siteGroupId"){
                keyValue = item[key]+"_"+item['sitegroupsDetail']['sitegroupname'].replaceAll(" ", "-")+"_sitegroup";
            }
            else if(key=="siteId"){
                keyValue = item[key]+"_"+item['siteDetail']['sitename'].replaceAll(" ", "-")+"_site";
            }
      
            if (!currentLevel[keyValue]) {
              currentLevel[keyValue] = index === keys.length - 1 ? [] : {}; // Last level stores array, others are objects
            }
      
            // Move to the next nested level
            currentLevel = currentLevel[keyValue];
          });
      
          // Push item to the last nested level
          currentLevel.push(item);
          return acc;
        }, {});
    };

    const [groupedData, setGroupedData] = useState([]);

    const groupAndSum = (data, keys) => {
        if (!keys.length) return data; // If no keys are provided, return original data
    
        return data.reduce((acc, item) => {
            let currentLevel = acc;
    
            // Build dynamic keys for grouping
            keys.forEach((key, index) => {
                let keyValue = "Unknown";
    
                if (key === "customerId") {
                    keyValue = `${item[key]}_${item.customerDetail.customername.replaceAll(" ", "-")}_customer`;
                } else if (key === "siteGroupId") {
                    keyValue = `${item[key]}_${item.sitegroupsDetail.sitegroupname.replaceAll(" ", "-")}_sitegroup`;
                } else if (key === "siteId") {
                    keyValue = `${item[key]}_${item.siteDetail.sitename.replaceAll(" ", "-")}_site`;
                }
    
                // If last level, store an array
                if (!currentLevel[keyValue]) {
                    currentLevel[keyValue] = index === keys.length - 1 ? {  sums: {} } : {};
                }
    
                // Move to the next nested level
                currentLevel = currentLevel[keyValue];
            });
    
    
            // Define properties to sum
            const sumProperties = [
                "regularPayHour", "regularPay", "holidayPayHour", "holidayPay", "totalPay",
                "regularBillHour", "regularBill", "holidayBillHour", "holidayBill", "totalBill",
                "payPremium", "billPremium",
                "overtimePay", "overtimePayHour",
                "overtimeBillHour", "overtimeBill", "unBillBreak", "unBillBreakAmount", "unPaidBreak", "unPaidBreakAmount"
            ];
    
            // Initialize sums object if not present
            if (Object.keys(currentLevel.sums)?.length==0) {
                currentLevel.sums = {};
                sumProperties.map(prop => /Hoilday/i.test(prop?.toLowerCase()) ? currentLevel.sums[prop] = false : currentLevel.sums[prop] = 0);
               

            }
            
            // Sum up required fields
            sumProperties.forEach(prop => { 

                const value = 
                prop=="unPaidBreak"?
                    !Number.isNaN(parseFloat(item?.unPaidBreakDuration))?
                        parseFloat(item?.unPaidBreakDuration)/60
                    :
                    0
                :
                prop=="unPaidBreakAmount"?
                    
                    !Number.isNaN(parseFloat(item?.unPaidBreakDuration))?
                        parseFloat(item?.paybillDetail?.unpaidBreakAmount)
                    :
                        0

                :

                prop=="unBillBreak"?
                    !Number.isNaN(parseFloat(item?.unBillBreakDuration))?
                        parseFloat(item?.unBillBreakDuration)/60
                    :
                    0

                :

                prop=="unBillBreakAmount"?
                    !Number.isNaN(parseFloat(item?.unBillBreakDuration))?
                        parseFloat(item?.paybillDetail?.unBillBreakAmount)
                    :
                    0
                :

                prop=="overtimePayHour"?

                    (item?.paybillDetail?.payOverTimeHour && item?.paybillDetail?.payOverTimeHour>0 )?
                        item?.paybillDetail?.payOverTimeHour
                    :0

                :
                
                prop=="overtimePay"?
                
                    (item?.paybillDetail?.payOverTimeHour && item?.paybillDetail?.payOverTimeHour>0 )?
                        item?.paybillDetail?.payFirstDateHour==0?
                            parseFloat(item?.paybillDetail?.overTimePay) + parseFloat(item?.paybillDetail?.unpaidBreakAmount)

                        :
                            parseFloat(item?.paybillDetail?.overTimePay)
                    :0

                :

                prop=="overtimeBill"?

                    item?.paybillDetail?.billOverTimeHour && item?.paybillDetail?.billOverTimeHour>0?
                        item?.paybillDetail?.billFirstDateHour==0?
                            (parseFloat(item?.paybillDetail?.overTimeBill) + parseFloat(item?.paybillDetail?.unBillBreakAmount))
                        :
                            parseFloat(item?.paybillDetail?.overTimeBill)
                    : 0.00

                :

                prop=="overtimeBillHour"?
                    item?.paybillDetail?.billOverTimeHour && item?.paybillDetail?.billOverTimeHour>0?
                        parseFloat(item?.paybillDetail?.billOverTimeHour)
                    :0
                :

                prop=="payPremium"?
                    item?.paybillDetail?.payBillPremium[0] && item?.paybillDetail?.payBillPremium[0]?.length>0?
                        parseFloat( item?.paybillDetail?.payBillPremium[0][0]?.payPremium?.totalPremiumPay ) || 0.00
                    :
                    0.00
                :
                prop=="billPremium"?
                    item?.paybillDetail?.payBillPremium[0] && item?.paybillDetail?.payBillPremium[0]?.length>0?
                        parseFloat( item?.paybillDetail?.payBillPremium[0][0]?.billPremium?.totalPremiumBill ) || 0.00
                    :
                    0.00
                :
                
                                
                
                prop=="regularPay"?

                    (!item?.isPayFirstDateInBankHoilday && !item?.isPaySecondDateInBankHoilday)?
                        item?.paybillDetail?.payFirstDateHour>0?
                        ( parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.secondDatePay) + parseFloat(item?.paybillDetail?.unpaidBreakAmount) )

                        :
                        (parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.secondDatePay))

                    : !item?.isPayFirstDateInBankHoilday?
                        item?.paybillDetail?.payFirstDateHour>0?
                            parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.unpaidBreakAmount)
                            :
                            parseFloat(item?.paybillDetail?.firstDatePay)

                    : !item?.isPaySecondDateInBankHoilday?
                    parseFloat(item?.paybillDetail?.secondDatePay)

                    : 0

                :
                prop=="regularPayHour"?

                    (!item?.isPayFirstDateInBankHoilday && !item?.isPaySecondDateInBankHoilday) ?
                        (parseFloat(item?.paybillDetail?.payFirstDateHour)+parseFloat(item?.paybillDetail?.paySecondDateHour))
                    :!item?.isPayFirstDateInBankHoilday?
                        (parseFloat(item?.paybillDetail?.payFirstDateHour))

                    :!item?.isPaySecondDateInBankHoilday?
                        (parseFloat(item?.paybillDetail?.paySecondDateHour))
                    
                    :(0)

                :
                prop=="holidayPayHour"?
                    
                    (item?.isPayFirstDateInBankHoilday && item?.isPaySecondDateInBankHoilday) ?
                        (parseFloat(item?.paybillDetail?.payFirstDateHour)+parseFloat(item?.paybillDetail?.paySecondDateHour))
                    :item?.isPayFirstDateInBankHoilday?
                        (parseFloat(item?.paybillDetail?.payFirstDateHour))

                    :item?.isPaySecondDateInBankHoilday?
                        (parseFloat(item?.paybillDetail?.paySecondDateHour))
                    
                    :(0)

                :
                prop=="holidayPay"?

                    (item?.isPayFirstDateInBankHoilday && item?.isPaySecondDateInBankHoilday)?

                        item?.paybillDetail?.payFirstDateHour>0?
                            ( parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.secondDatePay) + parseFloat(item?.paybillDetail?.unpaidBreakAmount) )

                            :
                            (parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.secondDatePay))

                    : item?.isPayFirstDateInBankHoilday?
                        item?.paybillDetail?.payFirstDateHour>0?
                            parseFloat(item?.paybillDetail?.firstDatePay) + parseFloat(item?.paybillDetail?.unpaidBreakAmount)
                            :
                            parseFloat(item?.paybillDetail?.firstDatePay)


                    : item?.isPaySecondDateInBankHoilday?
                        parseFloat(item?.paybillDetail?.secondDatePay)

                    : 0

                :
                prop=="totalPay"?

                    parseFloat(item?.paybillDetail?.totalPay) || 0
                    
                
                :

                prop=="regularBillHour"?
                    item?.isBillFirstDateInBankHoilday && item?.isBillSecondDateInBankHoilday?
                    0.00

                    : item?.isBillFirstDateInBankHoilday?
                    (parseFloat(item?.paybillDetail?.billSecondDateHour))

                    : item?.isBillSecondDateInBankHoilday?
                    (parseFloat(item?.paybillDetail?.billFirstDateHour))
                    
                    :
                    (parseFloat(item?.paybillDetail?.billFirstDateHour)+parseFloat(item?.paybillDetail?.billSecondDateHour))
            
                
                :
                prop=="regularBill"?
                    
                    item?.isBillFirstDateInBankHoilday && item?.isBillSecondDateInBankHoilday?
                    0
                    :

                    item?.isBillFirstDateInBankHoilday?
                    (parseFloat(item?.paybillDetail?.secondDateBill))
                    
                    :
                    
                    item?.isBillSecondDateInBankHoilday?
                        item?.paybillDetail?.billFirstDateHour>0?
                        ( parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.unBillBreakAmount) )
                        :
                        (parseFloat(item?.paybillDetail?.firstDateBill))

                    :
                    item?.paybillDetail?.billFirstDateHour>0?
                        ( parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.secondDateBill)  + parseFloat(item?.paybillDetail?.unBillBreakAmount) )
                        :
                        (parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.secondDateBill))

                

                :
                prop=="holidayBillHour"?

                    item?.isBillFirstDateInBankHoilday && item?.isBillSecondDateInBankHoilday?
                    (parseFloat(item?.paybillDetail?.billFirstDateHour)+parseFloat(item?.paybillDetail?.billSecondDateHour))
                
                    :
                    item?.isBillFirstDateInBankHoilday?
                        (parseFloat(item?.paybillDetail?.billFirstDateHour))
                    
                    : item?.isBillSecondDateInBankHoilday?
                        (parseFloat(item?.paybillDetail?.billSecondDateHour))
            
                    :
                    0

                :
                prop=="holidayBill"?
                    
                    item?.isBillFirstDateInBankHoilday && item?.isBillSecondDateInBankHoilday?
                        item?.paybillDetail?.billFirstDateHour>0?
                        ( parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.secondDateBill)  + parseFloat(item?.paybillDetail?.unBillBreakAmount) )
                        :
                        (parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.secondDateBill))

                    :

                    item?.isBillFirstDateInBankHoilday?

                        item?.paybillDetail?.billFirstDateHour>0?
                            (parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.unBillBreakAmount))
                            :
                            parseFloat(item?.paybillDetail?.firstDateBill)


                    :

                    item?.isBillSecondDateInBankHoilday?
                        (parseFloat(item?.paybillDetail?.secondDateBill))
                    
                    :
                    0.00

                :

                prop=="totalBill"?
                    parseFloat(item?.paybillDetail?.totalBill)
                :
                
                parseFloat(item.paybillDetail[prop]) || parseFloat(item[prop]) || 0.00; // Convert to number safely
                
                currentLevel.sums[prop] += value;
            });

    
            return acc;
        }, {});
    };
    
    const [totalCustomer, setTotalCustomer] = useState({});
    const [totalSiteGroup, setTotalSiteGroup] = useState({});
    const [totalSite, setTotalSite] = useState({});

    useEffect(()=>{
        if(reportData){
            // ["customerId", "siteGroupId", "siteId"]
            const groupedData = groupByMultipleKeys(reportData, ["customerId", "siteGroupId", "siteId"]);
            setGroupedData(groupedData);
            setTotalCustomer( groupAndSum(reportData, ["customerId"]) );
            setTotalSiteGroup( groupAndSum(reportData, ["siteGroupId"]));
            setTotalSite( groupAndSum(reportData, ["siteId"]) );

        }
    },[reportData]);

    

    const convertToHrsMin = (decimalHours) => {
        const hours = Math.floor(decimalHours); // Extract hours
        const minutes = Math.round((decimalHours - hours) * 60); // Convert decimal to minutes
    
        // Format hours and minutes with leading zero
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
    
        return `${formattedHours} hrs ${formattedMinutes} m`;
    }
    


    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);

    const exportToPdf = ()=>{
        window.print();
    };

    useEffect(()=>{
        document.title = reportDetail?.name+" "+dayjs().format("DD/MM/YYYY");

        return ()=>{
            document.title = "WFC";
        }
    },[]);

    const menu = (
        <Menu>
          <Menu.Item key="1" onClick={exportToCSV}>Export CSV</Menu.Item>
          <Menu.Item key="2" onClick={exportToPdf} >Print Report</Menu.Item>
        </Menu>
    );

    return (
        <div style={{padding: "15px", zoom: "1.15"}} id="pdfContent">

            <div className="reportsHeaderCss" id="pdfContent">

                <div style={{ display: "flex", justifyContent: "space-between"}}>
                   <span style={{fontWeight: "bold", fontSize: "20px", textTransform:'capitalize'}}>{reportDetail?.name}</span>
                   <div id="pdfexportBtn">

                        <Dropdown overlay={menu}>
                            <Button style={{fontSize:'12px'}}>
                                Exports <CaretDownFilled />
                            </Button>
                        </Dropdown>

                        
                        


                        <div className='close-link' style={{marginRight:'-20px'}} onClick={()=>navigate(-1)}>
                            <span >Close Report</span> <FontAwesomeIcon className='icon' icon={faTimes}/>
                        </div>

                    </div>
                </div>

                <div className="row-flex" style={{gap: "30px", marginBottom: "6px"}}>
                    <div style={{fontWeight: "bold"}}> Report ID : {reportDetail?._id}</div>
                    <div style={{fontWeight: "bold"}}> Run Date : &nbsp;
                        <span className="text-muted" style={{fontWeight: "normal"}}>
                            {dayjs().format("DD/MM/YYYY HH:mm")} by {authenticatedUserDetail?.employeeDetail[0]?.firstname +" "+(authenticatedUserDetail?.employeeDetail[0]?.lastname || "")}
                        </span>
                    </div>
                </div>

                <div className="text-muted pt20">
                    <span>Date Range Report using dates between </span>
                    <span style={{padding: "0px 2px"}}>
                        {dayjs(filters?.dates?.from, "DD/MM/YYYY").format("ddd DD MMM YYYY HH:mm")}
                    </span>
                    and
                    <span style={{padding: "0px 2px"}}>
                        {dayjs(filters?.dates?.to, "DD/MM/YYYY").format("ddd DD MMM YYYY HH:mm")}
                    </span>
                </div>

                <div style={{display: "flex", width: "100%", marginTop: "6px"}}>
                    <div style={{width: "50px"}}>Filters :</div>
                    <div style={{width: "calc(100% - 110px)", paddingLeft: "10px", textAlign:'left', fontStyle:'oblique'}}
                        dangerouslySetInnerHTML={{__html: filterContent}}
                    >  
                      
                    </div>
                </div>

            </div>

            <div className="mt32"></div>


            <table id="payBillPDF" className="payBillMainTable">
                <tbody>
                    <tr className="payBillHeaderCss">
                        <th colspan="2">
                            Employee Pay Code/Name
                        </th>
                        <th>Regular Pay/ Bill Hours</th>
                        <th>Regular Pay/ Bill</th>
                        <th>Bank Holiday Pay/ Bill Hours</th>
                        <th>Bank Holiday Pay/ Bill</th>
                        <th>(Additional / Overtime) Pay/ Bill Hours</th>
                        <th>(Additional / Overtime) Pay/ Bill</th>
                        <th>Unpaid / Unbilled Break</th>
                        <th>Premium Pay/ Bill</th>
                        <th>Total Pay/ Bill</th>
                        <th>Profit/ %Profit</th>
                    </tr>
                    
                   {
                    getPayBillReportLoading?
                        <tr>
                            <td colSpan={14} style={{textAlign: 'center'}}><Spinner /></td>
                        </tr>
                    :
                    Object.keys(groupedData)?.map((key)=>{

                        const customerTotal = totalCustomer[key]?.sums;

                        return(
                            <>
                                {
                                    filters?.header?.groupbyCustomer &&
                                    <tr className="customerCss">
                                        <td colspan="1">Customer:</td>
                                        <td colspan="3">{key?.split("_")[1]?.replaceAll("-", " ")}</td>
                                    </tr>
                                }
                                {
                                    // Site group rendering
                                    Object.keys(groupedData[key])?.map((secKey)=>{
                                        const sitegroupTotal = totalSiteGroup[secKey]?.sums;
                                        return(
                                            <>
                                            {
                                                filters?.header?.groupbySitegroup &&
                                                <tr className="siteGroupCss">
                                                    <td colspan="1">Site Group:  </td>
                                                    <td colspan="3">{secKey?.split("_")[1]?.replaceAll("-", " ")}</td>
                                                </tr>
                                            }


                                                {/* Site rendering */}
                                                { Object.keys(groupedData[key][secKey])?.map((tkey)=>{
                                                    const siteTotal = totalSite[tkey]?.sums;
                                                    return(
                                                    <>
                                                    {
                                                        filters?.header?.groupbySite &&

                                                        <tr className="siteCss">
                                                            <td colspan="1">Site: </td>
                                                            <td colspan="3">{tkey?.split("_")[1]?.replaceAll("-", " ")}</td>
                                                        </tr>
                                                    }

                                                        {/* first site record */}
                                                        <tr>
                                                            <td colspan="12">
                                                                <table className="payBillSitePayTable">
                                                                    <tbody>
                                                                    {
                                                                        groupedData[key][secKey][tkey]?.map((job)=>{
                                                                            
                                                                            return(
                                                                        
                                                                                <tr className="siteBillCss">
                                                                                    {/* employee name */}
                                                                                    <td colspan="2">{job?.employeeDetail?.firstname+" "+(job?.employeeDetail?.lastname || "")}</td>
                                                                                    
                                                                                    {/* Regular pay hour and bill hour */}
                                                                                    <td> 

                                                                                        {/* {convertToHrsMin(parseFloat(job?.payFirstDateHour)+parseFloat(job?.paySecondDateHour))} <br/> */}
                                                                                        
                                                                                        {
                                                                                            (!job?.isPayFirstDateInBankHoilday && !job?.isPaySecondDateInBankHoilday) ?

                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.payFirstDateHour)+parseFloat(job?.paybillDetail?.paySecondDateHour))
                                                                                            :!job?.isPayFirstDateInBankHoilday?
                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.payFirstDateHour))

                                                                                            :!job?.isPaySecondDateInBankHoilday?
                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.paySecondDateHour))
                                                                                            
                                                                                            :convertToHrsMin(0)

                                                                                        } <br/>
                                                                                        
                                                                                        {

                                                                                            job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?

                                                                                                convertToHrsMin(0)

                                                                                            : job?.isBillFirstDateInBankHoilday?
                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.billSecondDateHour))

                                                                                            : job?.isBillSecondDateInBankHoilday?
                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.billFirstDateHour))

                                                                                            :
                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.billFirstDateHour)+parseFloat(job?.paybillDetail?.billSecondDateHour))


                                                                                    
                                                                                        }
                                                                                        {/* {convertToHrsMin(parseFloat(job?.billFirstDateHour)+parseFloat(job?.billSecondDateHour))}  */}

                                                                                    </td>
                                                                                    
                                                                                    {/* total pay amount and bill amount */}
                                                                                    <td>

                                                                                        
                                                                                        {
                                                                                            (!job?.isPayFirstDateInBankHoilday && !job?.isPaySecondDateInBankHoilday)?
                                                
                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                "£ "+ ( parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)  + parseFloat(job?.paybillDetail?.unpaidBreakAmount) ).toFixed(2)
                                                                                                :
                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)).toFixed(2)


                                                                                            : !job?.isPayFirstDateInBankHoilday?
                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.unpaidBreakAmount)).toFixed(2)
                                                                                                
                                                                                                :
                                                                                                "£ "+ parseFloat(job?.paybillDetail?.firstDatePay).toFixed(2)

                                                                                            : !job?.isPaySecondDateInBankHoilday?
                                                                                            "£ "+ parseFloat(job?.paybillDetail?.secondDatePay).toFixed(2)

                                                                                            : "£ 0.00"

                                                                                        }


                                                                                        <br/>

                                                                                        {/* Regular bill amount */}

                                                                                        {
                                                                                            job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                                                                                            "£ 0.00"
                                                                                            :

                                                                                            job?.isBillFirstDateInBankHoilday?
                                                                                            "£ " +(parseFloat(job?.paybillDetail?.secondDateBill))?.toFixed(2)

                                                                                            :

                                                                                            job?.isBillSecondDateInBankHoilday?
                                                                                                job?.paybillDetail?.billFirstDateHour>0?
                                                                                                "£ "+ ( parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.unBillBreakAmount) ).toFixed(2)
                                                                                                :
                                                                                                "£ " +(parseFloat(job?.paybillDetail?.firstDateBill))?.toFixed(2)

                                                                                            :
                                                                                            job?.paybillDetail?.billFirstDateHour>0?
                                                                                                "£ "+ ( parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.secondDateBill)  + parseFloat(job?.paybillDetail?.unBillBreakAmount) ).toFixed(2)
                                                                                                :
                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.secondDateBill)).toFixed(2)

                                                                                            
                                                                                        }

                                                                                        {/* £ {parseFloat(job?.paybillDetail?.totalBill).toFixed(2)} */}
                                                                                    </td>
                                                                                    

                                                                                    {/* total pay and bill hour in holidays */}
                                                                                    <td>

                                                                                        {
                                                                                            job?.isPayFirstDateInBankHoilday && job?.isPaySecondDateInBankHoilday?
                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.payFirstDateHour)+parseFloat(job?.paybillDetail?.paySecondDateHour))
                                                                                            :
                                                                                            job?.isPayFirstDateInBankHoilday?
                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.payFirstDateHour))
                                                                                            :
                                                                                            job?.isPaySecondDateInBankHoilday?
                                                                                                convertToHrsMin(parseFloat(job?.paybillDetail?.paySecondDateHour))
                                                                                            :
                                                                                                convertToHrsMin(0)

                                                                                        }

                                                                                        <br/>

                                                                                        {
                                                                                            job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                                                                                            convertToHrsMin(parseFloat(job?.paybillDetail?.billFirstDateHour)+parseFloat(job?.paybillDetail?.billSecondDateHour))
                                                                                           
                                                                                            :
                                                                                            job?.isBillFirstDateInBankHoilday?
                                                                                            convertToHrsMin(parseFloat(job?.paybillDetail?.billFirstDateHour))
                                                                                            
                                                                                            : job?.isBillSecondDateInBankHoilday?
                                                                                            convertToHrsMin(parseFloat(job?.paybillDetail?.billSecondDateHour))
                                                                                    
                                                                                            :
                                                                                            "00 hrs 00 m"
                                                                                        }
                                                                                    </td>
                                                                                    
                                                                                    {/* total pay and bill amount in holiday */}
                                                                                    <td>
                                                                                        {

                                                                                            (job?.isPayFirstDateInBankHoilday && job?.isPaySecondDateInBankHoilday)?
                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                "£ "+ ( parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)  + parseFloat(job?.paybillDetail?.unpaidBreakAmount) ).toFixed(2)
                                                                                                :
                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)).toFixed(2)

                                                                                            : job?.isPayFirstDateInBankHoilday?
                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.unpaidBreakAmount)).toFixed(2)
                                                                                                :
                                                                                                "£ "+ parseFloat(job?.paybillDetail?.firstDatePay).toFixed(2)


                                                                                            : job?.isPaySecondDateInBankHoilday?
                                                                                                "£ "+ parseFloat(job?.paybillDetail?.secondDatePay).toFixed(2)

                                                                                                : "£ 0.00"

                                                                                        }
                                                                                         
                                                                                        <br/>
                                                                                        {
                                                                                            job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                                                                                                job?.paybillDetail?.billFirstDateHour>0?
                                                                                                "£ "+ ( parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.secondDateBill)  + parseFloat(job?.paybillDetail?.unBillBreakAmount) ).toFixed(2)
                                                                                                :
                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.secondDateBill)).toFixed(2)
                        
                                                                                            :
                        
                                                                                            job?.isBillFirstDateInBankHoilday?
                        
                                                                                                job?.paybillDetail?.billFirstDateHour>0?
                                                                                                    "£ "+ (parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.unBillBreakAmount)).toFixed(2)
                                                                                                    :
                                                                                                    "£ "+ parseFloat(job?.paybillDetail?.firstDateBill).toFixed(2)
                        
                        
                                                                                            :
                        
                                                                                            job?.isBillSecondDateInBankHoilday?
                                                                                                "£ " +(parseFloat(job?.paybillDetail?.secondDateBill))?.toFixed(2)
                                                                                            
                                                                                            :
                                                                                            "£ 0.00"

                                                                                        }
                                                                                    </td>
                                                                                    
                                                                                    {/* total pay and bill overtime hour */}
                                                                                    <td>
                                                                                                           
                                                                                        {

                                                                                            job?.paybillDetail?.payOverTimeHour && job?.paybillDetail?.payOverTimeHour>0?
                                                                                            convertToHrsMin(job?.paybillDetail?.payOverTimeHour)
                                                                                            :
                                                                                            convertToHrsMin(0)

                                                                                        }

                                                                                        <br/>
                                                                                        
                                                                                        {
                                                                                            job?.paybillDetail?.billOverTimeHour && job?.paybillDetail?.billOverTimeHour>0?
                                                                                                convertToHrsMin(job?.paybillDetail?.billOverTimeHour)
                                                                                            :convertToHrsMin(0)  
                                                                                        }

                                                                                    </td>
                                                                                    
                                                                                    {/* total pay and bill overtime amout */}

                                                                                    <td>
                                                                                        {
                                                                                            job?.paybillDetail?.payOverTimeHour && job?.paybillDetail?.payOverTimeHour>0?
                                                                                                job?.paybillDetail?.payFirstDateHour==0?
                                                                                                "£ "+(parseFloat(job?.paybillDetail?.overTimePay) + parseFloat(job?.paybillDetail?.unpaidBreakAmount)).toFixed(2)
                                                                                                :
                                                                                                "£ "+parseFloat(job?.paybillDetail?.overTimePay).toFixed(2)

                                                                                            : "£ 0.00"
                                                                                        }

                                                                                        <br/>
                                                                                        {
                                                                                            job?.paybillDetail?.billOverTimeHour && job?.paybillDetail?.billOverTimeHour>0?
                                                                                                job?.paybillDetail?.billFirstDateHour==0?
                                                                                                "£ "+(parseFloat(job?.paybillDetail?.overTimeBill) + parseFloat(job?.paybillDetail?.unBillBreakAmount)).toFixed(2)
                                                                                            :
                                                                                                "£ "+parseFloat(job?.paybillDetail?.overTimeBill).toFixed(2)
                        
                                                                                            : "£ 0.00"
                                                                                        }

                                                                                    </td>

                                                                                    {/* unpaid unbilled and unpaid break */}
                                                                                    <td>
                                                                                        {
                                                                                            !Number.isNaN(parseFloat(job?.unPaidBreakDuration))?
                                                                                            <>
                                                                                                -  { convertToHrsMin(parseFloat(job?.unPaidBreakDuration)/60) } <br/>
                                                                                                
                                                                                                - £ {parseFloat(job?.paybillDetail?.unpaidBreakAmount).toFixed(2)}

                                                                                            
                                                                                            </>
                                                                                            :
                                                                                            convertToHrsMin(0)
                                                                                        }
                                                                                        <hr/>
                                                                                        {
                                                                                            !Number.isNaN(parseFloat(job?.unBillBreakDuration))?
                                                                                            <>
                                                                                                -  { convertToHrsMin(parseFloat(job?.unBillBreakDuration)/60) } <br/>
                                                                                            
                                                                                                - £ {parseFloat(job?.paybillDetail?.unBillBreakAmount).toFixed(2)}
                        
                                                                                            
                                                                                            </>
                                                                                            :
                                                                                            convertToHrsMin(0)
                                                                                        }
                                                                                    </td>

                                                                                    {/* total pay and bill premium hour */}
                                                                                    <td>
                                                                                        £ {(job?.paybillDetail?.payBillPremium?.length>0 ? (job?.paybillDetail?.payBillPremium[0][0]?.payPremium?.totalPremiumPay)?.toFixed(2) : 0.00) || "0.00"}
                                                                                        <br/>
                                                                                        £ {(job?.paybillDetail?.payBillPremium?.length>0 ? (job?.paybillDetail?.payBillPremium[0][1]?.payBillPremium?.totalPremiumBill)?.toFixed(2) : 0.00) || "0.00"}
                                                                                    </td>


                                                                                    {/* total pay and bill hour */}
                                                                                    
                                                                                    <td>£ {parseFloat(job?.paybillDetail?.totalPay)?.toFixed(2)} <br/> £ {parseFloat(job?.paybillDetail?.totalBill)?.toFixed(2)} </td>
                                                                                    
                                                                                    <td> £ {parseFloat(job?.paybillDetail?.profit)?.toFixed(2)} <br/> % {parseFloat(job?.paybillDetail?.profitPercent)?.toFixed(2)}</td>
                                                                                </tr>

                                                                            )
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>

                                                        {
                                                        
                                                        filters?.header?.groupbySite &&
                                                        <>
                                                            <tr style={{height: "0px"}}>
                                                                <td colspan="2"></td><td colspan="10" style={{borderBottom: "2px solid black"}}></td>
                                                            </tr>
                                                            

                                                            {/* site total */}
                                                            <tr>

                                                                <td colspan="12">
                                                                    <table className="payBillSiteCardTable">
                                                                        <thead>
                                                                            <tr className="titleCss">
                                                                                <th colspan="2">Site Totals:</th>
                                                                                <th>Regular Pay/ Bill Hours</th>
                                                                                <th>Regular Pay/ Bill</th>
                                                                                <th>Bank Holiday Pay/ Bill Hours</th>
                                                                                <th>Bank Holiday Pay/ Bill</th>
                                                                                <th>(Additional / Overtime) Pay/ Bill Hours</th>
                                                                                <th>(Additional / Overtime) Pay/ Bill</th>
                                                                                <th>Unpaid / Unbilled Break</th>
                                                                                <th>Premium Pay/ Bill</th>
                                                                                <th>Total Pay/ Bill</th>
                                                                                <th>Profit/ %Profit</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <tr className="row1">
                                                                                <td colspan="2"></td>
                                                                                {/* regular pay bill hour */}
                                                                                <td>
                                                                                    { convertToHrsMin(parseFloat(siteTotal?.regularPayHour)) } 
                                                                                    <br/> 
                                                                                    {convertToHrsMin(parseFloat(siteTotal?.regularBillHour))}
                                                                                </td>

                                                                                {/* regular pay bill amount */}
                                                                                <td>
                                                                                    £ {parseFloat(siteTotal?.regularPay)?.toFixed(2)}
                                                                                    <br/>
                                                                                    £ {parseFloat(siteTotal?.regularBill)?.toFixed(2)}
                                                                                </td>

                                                                                {/* Bank holiday pay bill hour */}

                                                                                <td>

                                                                                    {convertToHrsMin(parseFloat(siteTotal?.holidayPayHour) || 0)}
                                                                                    <br/>
                                                                                    {convertToHrsMin(parseFloat(siteTotal?.holidayBillHour) || 0)}

                                                                                </td>

                                                                                {/* Bank holiday pay bill amount */}
                                                                                <td>
                                                                                    £ {parseFloat(siteTotal?.holidayPay).toFixed(2)}
                                                                                    <br/>
                                                                                    £ {parseFloat(siteTotal?.holidayBill).toFixed(2)}
                                                                                </td>

                                                                                {/* additional overtime pay bill hours */}
                                                                                <td>

                                                                                    {convertToHrsMin(parseFloat(siteTotal?.overtimePayHour) || 0)}
                                                                                    <br/>
                                                                                    {convertToHrsMin(parseFloat(siteTotal?.overtimeBillHour) || 0)}

                                   
                                                                                </td>

                                                                                {/* additional overtime pay bill amount */}
                                                                                <td>
                                                                                    
                                                                                    £ {parseFloat(siteTotal?.overtimePay).toFixed(2)}
                                                                                    <br/>
                                                                                    £ {parseFloat(siteTotal?.overtimeBill).toFixed(2)}

                                                                                </td>
                                                                                
                                                                                {/* total unpaid and unbilled break */}
                                                                                <td>
                                                                                    {siteTotal?.unPaidBreak>0? "- " +convertToHrsMin(siteTotal?.unPaidBreak) : convertToHrsMin(0)} <br/>
                                                                                    {siteTotal?.unPaidBreakAmount>0? "- £ "+parseFloat(siteTotal?.unPaidBreakAmount).toFixed(2): "£ 0.00"}

                                                                                    <hr/> 
                                                                                    {siteTotal?.unBillBreak>0? "- "+convertToHrsMin(siteTotal?.unBillBreak) : convertToHrsMin(0)}<br/>
                                                                                    {siteTotal?.unPaidBreakAmount>0? "- £ "+parseFloat(siteTotal?.unPaidBreakAmount).toFixed(2): "£ 0.00"}

                                                                                </td>

                                                                                {/* total pay and bill premium */}
                                                                                <td>£ {parseFloat(siteTotal?.payPremium)?.toFixed(2)} <br/> £ {parseFloat(siteTotal?.billPremium)?.toFixed(2)}</td>

                                                                                {/* total pay and bill */}
                                                                                <td>£ {parseFloat(siteTotal?.totalPay)?.toFixed(2)} <br/> £ {parseFloat(siteTotal?.totalBill)?.toFixed(2)}</td>
                                                                               
                                                                                {/* pay and bill profit */}
                                                                                <td> £ {(parseFloat(siteTotal?.totalBill)?.toFixed(2) - parseFloat(siteTotal?.totalPay)?.toFixed(2)).toFixed(2)} <br/> 
                                                                                % {(((parseFloat(siteTotal?.totalBill)?.toFixed(2) - parseFloat(siteTotal?.totalPay)?.toFixed(2))/parseFloat(siteTotal?.totalPay)?.toFixed(2))*100).toFixed(2)} 
                                                                                </td>
                                                                                
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </>

                                                        }

                                                    </>

                                                    )
                                                })}


                                                {
                                                    filters?.header?.groupbySitegroup &&
                                                    <>
                                                        <tr style={{height: "0px"}}>
                                                            <td colspan="2"></td><td colspan="10" style={{borderBottom: "2px solid black"}}></td>
                                                        </tr>
                                                        

                                                        {/* sitegroup total */}
                                                        <tr>
                                                            <td colspan="12">
                                                                <table className="payBillSiteCardTable">
                                                                    <thead>
                                                                        <tr className="titleCss">
                                                                            <th colspan="2">Site Group Totals:</th>
                                                                            <th>Regular Pay/ Bill Hours</th>
                                                                            <th>Regular Pay/ Bill</th>
                                                                            <th>Bank Holiday Pay/ Bill Hours</th>
                                                                            <th>Bank Holiday Pay/ Bill</th>
                                                                            <th>(Additional / Overtime) Pay/ Bill Hours</th>
                                                                            <th>(Additional / Overtime) Pay/ Bill</th>
                                                                            <th>Unpaid / Unbilled Break</th>
                                                                            <th>Premium Pay/ Bill</th>
                                                                            <th>Total Pay/ Bill</th>
                                                                            <th>Profit/ %Profit</th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                            <tr className="row1">
                                                                                <td colspan="2"></td>
                                                                                {/* regular pay bill hour */}
                                                                                <td>
                                                                                    { convertToHrsMin(parseFloat(sitegroupTotal?.regularPayHour)) } 
                                                                                    <br/> 
                                                                                    {convertToHrsMin(parseFloat(sitegroupTotal?.regularBillHour))}
                                                                                </td>

                                                                                {/* regular pay bill amount */}
                                                                                <td>
                                                                                    £ {parseFloat(sitegroupTotal?.regularPay)?.toFixed(2)}
                                                                                    <br/>
                                                                                    £ {parseFloat(sitegroupTotal?.regularBill)?.toFixed(2)}
                                                                                </td>

                                                                                {/* Bank holiday pay bill hour */}

                                                                                <td>

                                                                                    {convertToHrsMin(parseFloat(sitegroupTotal?.holidayPayHour) || 0)}
                                                                                    <br/>
                                                                                    {convertToHrsMin(parseFloat(sitegroupTotal?.holidayBillHour) || 0)}

                                                                                </td>

                                                                                {/* Bank holiday pay bill amount */}
                                                                                <td>
                                                                                    £ {parseFloat(sitegroupTotal?.holidayPay).toFixed(2)}
                                                                                    <br/>
                                                                                    £ {parseFloat(sitegroupTotal?.holidayBill).toFixed(2)}
                                                                                </td>

                                                                                {/* additional overtime pay bill hours */}
                                                                                <td>

                                                                                    {convertToHrsMin(parseFloat(sitegroupTotal?.overtimePayHour) || 0)}
                                                                                    <br/>
                                                                                    {convertToHrsMin(parseFloat(sitegroupTotal?.overtimeBillHour) || 0)}

                                   
                                                                                </td>

                                                                                {/* additional overtime pay bill amount */}
                                                                                <td>
                                                                                    
                                                                                    £ {parseFloat(sitegroupTotal?.overtimePay).toFixed(2)}
                                                                                    <br/>
                                                                                    £ {parseFloat(sitegroupTotal?.overtimeBill).toFixed(2)}

                                                                                </td>
                                                                                
                                                                                {/* total unpaid and unbilled break */}
                                                                                <td>
                                                                                    {sitegroupTotal?.unPaidBreak>0? "- "+convertToHrsMin(sitegroupTotal?.unPaidBreak): convertToHrsMin(0)} <br/>
                                                                                    {sitegroupTotal?.unPaidBreakAmount>0? "- £ "+parseFloat(sitegroupTotal?.unPaidBreakAmount).toFixed(2): "£ 0.00"}

                                                                                    <hr/> 
                                                                                    {sitegroupTotal?.unBillBreak>0? "- "+convertToHrsMin(sitegroupTotal?.unBillBreak): convertToHrsMin(0)} <br/>
                                                                                    {sitegroupTotal?.unPaidBreakAmount>0? "- £ "+parseFloat(sitegroupTotal?.unPaidBreakAmount).toFixed(2): "£ 0.00"}

                                                                                </td>

                                                                                {/* total pay and bill premium */}
                                                                                <td>£ {parseFloat(sitegroupTotal?.payPremium)?.toFixed(2)} <br/> £ {parseFloat(sitegroupTotal?.billPremium)?.toFixed(2)}</td>

                                                                                {/* total pay and bill */}
                                                                                <td>£ {parseFloat(sitegroupTotal?.totalPay)?.toFixed(2)} <br/> £ {parseFloat(sitegroupTotal?.totalBill)?.toFixed(2)}</td>
                                                                               
                                                                                {/* pay and bill profit */}
                                                                                <td> £ {(parseFloat(sitegroupTotal?.totalBill)?.toFixed(2) - parseFloat(sitegroupTotal?.totalPay)?.toFixed(2)).toFixed(2)} <br/> 
                                                                                % {(((parseFloat(sitegroupTotal?.totalBill)?.toFixed(2) - parseFloat(sitegroupTotal?.totalPay)?.toFixed(2))/parseFloat(sitegroupTotal?.totalPay)?.toFixed(2))*100).toFixed(2)} 
                                                                                </td>
                                                                                
                                                                            </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </>
                                                }


                                            </>

                                        )
                                    })
                                }


                                {
                                    filters?.header?.groupbyCustomer &&
                                    <>
                                        {/* customer total border */}
                                        <tr style={{height: "0px"}}>
                                            <td colspan="2"></td><td colspan="10" style={{borderBottom: "2px solid black"}}></td>
                                        </tr>
                                        

                                        {/* Customer total */}
                                        <tr>
                                            <td colspan="12">
                                                <table className="payBillSiteCardTable">
                                                    <thead>
                                                        <tr className="titleCss">
                                                            <th colspan="2">Customer Totals:</th>
                                                            <th>Regular Pay/ Bill Hours</th>
                                                            <th>Regular Pay/ Bill</th>
                                                            <th>Bank Holiday Pay/ Bill Hours</th>
                                                            <th>Bank Holiday Pay/ Bill</th>
                                                            <th>(Additional / Overtime) Pay/ Bill Hours</th>
                                                            <th>(Additional / Overtime) Pay/ Bill</th>
                                                            <th>Unpaid / Unbilled Break</th>
                                                            <th>Premium Pay/ Bill</th>
                                                            <th>Total Pay/ Bill</th>
                                                            <th>Profit/ %Profit</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr className="row1">
                                                            <td colspan="2"></td>
                                                            {/* regular pay bill hour */}
                                                            <td>
                                                                { convertToHrsMin(parseFloat(customerTotal?.regularPayHour)) } 
                                                                <br/> 
                                                                {convertToHrsMin(parseFloat(customerTotal?.regularBillHour))}
                                                            </td>

                                                            {/* regular pay bill amount */}
                                                            <td>
                                                                £ {parseFloat(customerTotal?.regularPay)?.toFixed(2)}
                                                                <br/>
                                                                £ {parseFloat(customerTotal?.regularBill)?.toFixed(2)}
                                                            </td>

                                                            {/* Bank holiday pay bill hour */}

                                                            <td>

                                                                {convertToHrsMin(parseFloat(customerTotal?.holidayPayHour) || 0)}
                                                                <br/>
                                                                {convertToHrsMin(parseFloat(customerTotal?.holidayBillHour) || 0)}

                                                            </td>

                                                            {/* Bank holiday pay bill amount */}
                                                            <td>
                                                                £ {parseFloat(customerTotal?.holidayPay).toFixed(2)}
                                                                <br/>
                                                                £ {parseFloat(customerTotal?.holidayBill).toFixed(2)}
                                                            </td>

                                                            {/* additional overtime pay bill hours */}
                                                            <td>

                                                                {convertToHrsMin(parseFloat(customerTotal?.overtimePayHour) || 0)}
                                                                <br/>
                                                                {convertToHrsMin(parseFloat(customerTotal?.overtimeBillHour) || 0)}

                
                                                            </td>

                                                            {/* additional overtime pay bill amount */}
                                                            <td>
                                                                
                                                                £ {parseFloat(customerTotal?.overtimePay).toFixed(2)}
                                                                <br/>
                                                                £ {parseFloat(customerTotal?.overtimeBill).toFixed(2)}

                                                            </td>
                                                            
                                                            {/* total unpaid and unbilled break */}
                                                            <td>
                                                                {customerTotal?.unPaidBreak>0? "- "+convertToHrsMin(customerTotal?.unPaidBreak): convertToHrsMin(0)} <br/>
                                                                {customerTotal?.unPaidBreakAmount>0? "- £ "+parseFloat(customerTotal?.unPaidBreakAmount).toFixed(2): "£ 0.00"}
                                                                <hr/> 
                                                                {customerTotal?.unBillBreak>0? "- "+convertToHrsMin(customerTotal?.unBillBreak): convertToHrsMin(0)} <br/>
                                                                {customerTotal?.unPaidBreakAmount>0? "- £ "+parseFloat(customerTotal?.unPaidBreakAmount).toFixed(2): "£ 0.00"}

                                                            </td>

                                                            {/* total pay and bill premium */}
                                                            <td>£ {parseFloat(customerTotal?.payPremium)?.toFixed(2)} <br/> £ {parseFloat(customerTotal?.billPremium)?.toFixed(2)}</td>

                                                            {/* total pay and bill */}
                                                            <td>£ {parseFloat(customerTotal?.totalPay)?.toFixed(2)} <br/> £ {parseFloat(customerTotal?.totalBill)?.toFixed(2)}</td>
                                                            
                                                            {/* pay and bill profit */}
                                                            <td> £ {(parseFloat(customerTotal?.totalBill)?.toFixed(2) - parseFloat(customerTotal?.totalPay)?.toFixed(2)).toFixed(2)} <br/> 
                                                            % {(((parseFloat(customerTotal?.totalBill)?.toFixed(2) - parseFloat(customerTotal?.totalPay)?.toFixed(2))/parseFloat(customerTotal?.totalPay)?.toFixed(2))*100).toFixed(2)} 
                                                            </td>
                                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </>
                                }


                            </>
                        )
                    })
                   }


                
                </tbody>
            </table>

        </div>
    );

};