import { gql } from "@apollo/client";

export const getDocumentDetailByObjectandEntityId = gql`
query($isArchived: Boolean!, $entityId: String!, $objectType: String!){
  getDocumentDetailByObjectType(isArchived: $isArchived, entityId: $entityId, objectType: $objectType) {
    response
    message
  }
}
`;

export const getFileUploadURL = gql`
query($filename: String!){
  getFileURL(filename: $filename) {
    response
    message
  }
}
`;

export const getSingleDocumentDetail = gql`
query($id: String!){
  getSingleDocumentDetail(_id: $id) {
    response
    message
  }
}
`;

// this will return the base folder if exist base folder that you will pass
// f.e if i want to check specific site folder exist then i pass siteId
// if this exist then it retun empty array of folders
export const getFoldersFilesQuery = gql`

query($foldername: String!){
  getDirectories(foldername: $foldername) {
    message
    response
  }
}

`;