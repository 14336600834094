import { gql } from "@apollo/client";

export const deleteAgencyCustomViewFieldMutation = gql`
mutation($userId: String!, $propertyId: String!){
  deleteAgencyDetailViewField(userId: $userId, propertyId: $propertyId) {
    message
    response
  }
}
`;

export const newAgencyCustomViewFieldMutation = gql `
mutation($input: AgencyDetailViewInput!){
  newAgencyDetailViewField(input: $input) {
    message
    response
  }
}
`;

export const updateAgencyCustomViewFieldMutation = gql `
mutation($input: UpdateAgencyDetailViewFieldInput!){
  updateAgencyDetailViewField(input: $input) {
    message
    response
  }
}
`;