export const normalUser = {
    Branch: "Branch",
    Employee: "Employee",
    Site: "Site",
    Customer: "Customer",
    SiteGroup: "SiteGroup",
}

// it current in use for staticField value lookups
export const objectType = {
    Branch: "Branch",
    Employee: "Employee",
    SiteGroup: "SiteGroup",
    Site: "Site",
    Customer: "Customer",
    Agency: "Agency",
    Timeline: "Timeline",
    Setting: "Setting",
    UserRoles: "UserRoles",
    UserTeam: "UserTeams",
    Properties: "Properties",
};

export const adminObjectType= [
    "Setting",
    "UserRoles",
    "UserTeams",
    "Properties"
];