import { gql } from "@apollo/client";

export const getScheduleReportByJobIdQuery = gql`
query($jobId: String!){
  getScheduleReportByJobId(jobId: $jobId) {
    response
    message
  }
}
`;


export const getScheduleReportBySiteandTemplateIdQuery = gql `
query($templateId: String!, $siteId: String!){
  getScheduleReportBySiteandTemplateId(templateId: $templateId, siteId: $siteId) {
    message
    response
  }
}
`;