export const fixDecimal = (num, decimalPlaces) => {
    if(num>0){

        const str = num.toString();
        const index = str.indexOf(".");
        if (index === -1) return str+".00"; // No decimal point, return as is
        return str.substring(0, index + decimalPlaces + 1); // Keep only required decimals

    }else{
        return "0.00"
    }
}