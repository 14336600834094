import { Checkbox, Collapse, Input } from "antd";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client"
import { GROUPLIST } from "@src/util/query/group.query"
import { objectType } from "@src/util/types/object.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deleteBranchCustomViewFieldMutation, newBranchCustomViewFieldMutation } from "../../util/mutation/branchCustomViewField.mutation";

export const BranchCustomLeftPane = ({editableProp, branchDetailViewRefetch, getBranchViewDataLoading})=>{


    const {data:branchGrpFields, loading } = useQuery(GROUPLIST,{
        variables: {
            objectType: objectType?.Branch
        }
    });

    const [items, setItems] = useState([]);

    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);
  
    const [newBranchCustomField, {loading: newBranchCustomFieldLoading, error}] = useMutation(newBranchCustomViewFieldMutation);
    const [deleteBranchDetailView, {loading: deleteSiteDetailViewLoading}] = useMutation(deleteBranchCustomViewFieldMutation);



    
    useEffect(()=>{
        if(branchGrpFields?.groupList?.length>0 && !getBranchViewDataLoading && !loading){
            setItems(branchGrpFields?.groupList?.map((list)=>{
                return(
                    {
                        key: list?.key,
                        label: <span>
                        {list?.name} 
                        </span>,
                        children: list?.propertyList?.map((property)=>(
                            <div className='field-presentation'>
                                <Checkbox 
                                    checked={editableProp?.find((siteDetail)=>siteDetail?.propertyId==property?._id)? true: false}
                                    onChange={async (e)=>{
                                        if(e.target.checked){
                                            await newBranchCustomField({
                                                variables:{
                                                    input:{
                                                        propertyId: property?._id,
                                                    }
                                                }
                                            }); await branchDetailViewRefetch();
                                            
                                        }else{
                                            await deleteBranchDetailView({
                                                variables:{
                                                    userId: userDetail?._id,
                                                    propertyId: property?._id,
                                                    
                                                }
                                            }); await branchDetailViewRefetch();
                                        }
                                    }}
                                />

                                <div style={{width: '100%'}}>
                                    
                                    <div className='allpropList-propHead'>
                                        {property?.label} 
                                    </div>
                                    <div className='field-prop-value'>
                                        <span>
                                            {"--"}
                                        </span>
                                    </div>
                                    
                                </div>
                            </div>
                        ))

                    }
                );
            }))
        }
    },[branchGrpFields, loading,  getBranchViewDataLoading, editableProp]);

    const handelSearch = (keyword)=>{
        const resultedFields = branchGrpFields?.groupList?.map((props)=>{
            const filteredProperties = props?.propertyList.filter((prop)=> prop.label.toLowerCase().includes(keyword.toLowerCase()) && prop?.isArchive!=true );
            return {
                ...props,
                propertyList: filteredProperties
            }
        });
        const nonEmptyField = resultedFields?.filter((field)=>field?.propertyList && field?.propertyList?.length>0)
        setItems(nonEmptyField?.map((list)=>{
            return(
                {
                    key: list?.key,
                    label: <span>
                            {list?.name} 
                        </span>,
                    children: list?.propertyList?.map((property)=>(
                        <div className='field-presentation'>
                            
                            
                                <Checkbox 
                                    checked={editableProp?.find((siteDetail)=>siteDetail?.propertyId==property?._id)}
                                    onChange={async (e)=>{
                                        if(e.target.checked){
                                            await newBranchCustomField({
                                                variables:{
                                                    input:{
                                                        propertyId: property?._id,
                                                    }
                                                }
                                            }); await branchDetailViewRefetch();
                                            
                                        }else{
                                            await deleteBranchDetailView({
                                                variables:{
                                                    userId: userDetail?._id,
                                                    propertyId: property?._id,
                                                    
                                                }
                                            }); await branchDetailViewRefetch();
                                        }
                                    }}
                                />
                            <div style={{width: '100%'}}>
                                
                                <div className='allpropList-propHead'>
                                    {property?.label} 
                                </div>
                                <div className='field-prop-value'>
                                    <span>
                                        {"--"}
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                    ))

                }
            );
        }))

    }

    return(
        <div className="hr-section" style={{maxHeight:'83vh', overflow:'auto'}}>
            <div className="hr-info">
                <div className="hr-info-title">
                    Customise your view
                </div>
                <div className="text">Manage your view by select the data fields below</div>
            </div>
            <Input type="search" 
                style={{width:'100%'}} 
                className='generic-input-control' 
                placeholder="Search Fields"
                onChange={(e)=>handelSearch(e.target.value)}
                autoComplete="off"
                suffix={
                <FontAwesomeIcon style={{color:'#0091ae'}} icon={faSearch}/> }
            />
            {
               
                    <Collapse defaultActiveKey={branchGrpFields?.groupList[0]?.key} items={items} />
            }
        </div>
    )

}