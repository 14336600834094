import axios from 'axios';
import { Form, Input, Modal, Select } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import Spinner from "@src/components/spinner";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSiteTaskByIdQuery } from "@src/util/query/siteTask.query";
import { newScheduleTaskMutation } from "@src/util/mutation/scheduleTask.mutation";
import { updateScheduleCheckCallMutation } from "@src/util/mutation/scheduleChecks.mutation";
import { genericFileUploadSignatureMutation } from '@src/util/mutation/genericFileUpload.mutation';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useDispatch } from "react-redux";
import dayjs from 'dayjs';



export const SiteDutyTaskModal = ( {modalDetail, close}, refetch=()=>{} )=>{
    const {modal, siteTaskId, call} = modalDetail;
    
    const {data: siteDutyTaskDetail, loading:siteDutyTaskDetailLoading, refetch: siteDutyTaskRefetch} = useQuery(getSiteTaskByIdQuery,{
        variables:{
            siteTaskId
        },
        skip: siteTaskId?.length==0,
        fetchPolicy:'network-only'
    });

    const [siteTaskDetail, setSiteTaskDetail] = useState(null);

    useEffect(()=>{
        if(siteDutyTaskDetail?.getSiteTaskById?.response){
            setSiteTaskDetail(siteDutyTaskDetail?.getSiteTaskById?.response);
        }else{
            setSiteTaskDetail(null);
        }
    }, [siteDutyTaskDetail?.getSiteTaskById?.response]);
    
    const [fileInputs, setFileInputs] = useState([{ id: Date.now(), file: null }]);

    const handleFileChange = (event, id) => {
        const file = event.target.files[0];
        setFileInputs((prevFileInputs) => [
          ...prevFileInputs.map((input) =>
            input.id === id ? { ...input, file } : input
          ),
          { id: Date.now(), file: null },
        ]);
      };
    
    const handleRemove = (idToRemove) => {
        setFileInputs((prevFileInputs) =>
            prevFileInputs.filter((input) => input.id !== idToRemove)
        );
    };

    const [updateScheduleCheckCall, {loading: updateScheduleCheckCallLoading}] = useMutation(updateScheduleCheckCallMutation)

    const [newScheduleTask, {loading: newScheduleTaskLoading}] = useMutation(newScheduleTaskMutation);

    const [fileLoading, setFileLoading] = useState(false);

    const [response, setResponse] = useState(null);
    const [comment, setComment] = useState(null);
    const [genericFileUploadSignature, {loading: genericFileUploadSignatureLoading}] = useMutation(genericFileUploadSignatureMutation);
    const dispatch = useDispatch();

    const handelDutyTaskSubmit = async ()=>{
        try{

            const filesName = fileInputs.filter((f)=>f?.file?.size)?.map((file)=>file?.file?.name);
            
            if (filesName?.length>0){
                setFileLoading(true);
                const fileUploadUrlsResponse = await genericFileUploadSignature({
                    variables:{
                        input:{
                            files : filesName
                        }
                    }
                });
                const filesToUpload = fileInputs.filter((f)=>f?.file?.size);
                const fileUploadUrls = fileUploadUrlsResponse?.data?.genericFileUploadSignature?.response;
                await Promise.all(filesToUpload?.map(async(file)=>{
                    await axios.put(fileUploadUrls[file?.file?.name], file?.file,{
                        headers: {
                            'Content-Type': file?.file?.type ,
                        },
                    });
                    return true;
                }));

            }

            await newScheduleTask({
                variables:{
                    input:{
                        siteId: call?.siteId,
                        taskId: siteTaskId,
                        scheduleId: call?.scheduleId,
                        response,
                        comment,
                        attachment: filesName,
                    }
                }
            })

            await updateScheduleCheckCall({
                variables:{
                    input: {
                        _id: call?._id,
                        fields: {
                            pending: false,
                            expired: true,
                            late: false,
                            actualDate: dayjs(),
                            callType: call?.callType,
                            scheduleId: call?.scheduleId,
                            reason: null,
                        }
                    }
                }
            });

            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Task was submitted successfully"
            }));

            close();

            await refetch();
        
        }catch(err){
            dispatch(setNotification(({
                error: true,
                notificationState: true,
                message: "Error encountered while submitting schedule task",
            })));
        }
    }
    
    return(
        <Modal
            open={modal}
            width={700}
            footer={
                <div className='drawer-footer' style={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                    <button disabled={fileLoading || newScheduleTaskLoading || updateScheduleCheckCallLoading || genericFileUploadSignatureLoading} className={fileLoading || newScheduleTaskLoading || updateScheduleCheckCallLoading || genericFileUploadSignatureLoading?'disabled-btn drawer-filled-btn':'drawer-filled-btn'} onClick={handelDutyTaskSubmit}>
                        {fileLoading || newScheduleTaskLoading || updateScheduleCheckCallLoading || genericFileUploadSignatureLoading?
                            <Spinner />
                            :
                            "Submit"
                        }
                    </button>
                    <button disabled={fileLoading || newScheduleTaskLoading || updateScheduleCheckCallLoading || genericFileUploadSignatureLoading} className={fileLoading || newScheduleTaskLoading || updateScheduleCheckCallLoading || genericFileUploadSignatureLoading?'disabled-btn dim-btn':'dim-btn'} onClick={close}>Cancel</button>
                    
                </div>
            }
            closable={false}
        >

            <>
                {/* {contextHolder} */}
                <div className='modal-header-title'>
                    <span>Task Detail</span>
                    <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
                </div>

                {
                    siteDutyTaskDetailLoading?

                        <div style={{display:'flex', justifyContent:'center', alignContent:'center'}}>
                            <Spinner/>
                        </div>

                    :

                        <Form style={{padding:'20px'}}>
                            
                            <div style={{textTransform:'capitalize', fontSize:'16px', fontWeight:'500', color: 'red'}}>{siteTaskDetail?.title}</div>

                            {siteTaskDetail?.response &&
                                <Form.Item>
                                    {/* <label>Any Response?</label> */}
                                    <Select
                                        value={response}
                                        className="custom-select"
                                        placeholder={"Yes / No?"}
                                        onChange={(e)=>setResponse(e)}
                                        suffixIcon={<div className="dropdowncaret"></div>}
                                    >
                                        <Option value="Yes">Yes</Option>
                                        <Option value="No">No</Option>
                                    </Select>
                                </Form.Item>
                            }

                            {siteTaskDetail?.comment &&
                                <Form.Item>
                                    {/* <label>Any comments?</label> */}
                                    <TextArea
                                        value={comment}
                                        placeholder="Any comments?"
                                        className="generic-input-control"
                                        onChange={(e)=>setComment(e.target.value)}
                                    />
                                </Form.Item>
                            }

                            {
                            // siteTaskDetail?.attachment &&
                                fileInputs.map((input) => (
                                <div
                                    key={input?.id}
                                    style={{display:'flex', alignItems:'center', gap:'10px', flexDirection:'row'}}
                                >
                                    

                                    <Form.Item style={{flexGrow: 1}}>
                                        {/* <label>Any attachments?</label> */}
                                        <Input 
                                            type="file" 
                                            className="generic-input-control" 
                                            accept="image/*" 
                                            capture="environment"
                                            onChange={(e) => handleFileChange(e, input.id)}

                                        />
                                        
                                    </Form.Item>

                                    {
                                        fileInputs?.length>1 &&
                                        <FontAwesomeIcon 
                                            icon={faClose} 
                                            style={{color:'#7c98b6', cursor:'pointer', fontSize: '20px', width:'20px'}}
                                            onClick={() => handleRemove(input.id)}
                                        />
                                    }


                                </div>

                                ))
                            }

                        </Form>
                }
            </>

        </Modal>
    )
}