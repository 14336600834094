import { Select, Table, Tabs } from "antd";
import { objectType } from "@src/util/types/object.types";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TabPane from "antd/es/tabs/TabPane";
import { ModuleDocumentsHeader } from "./moduleDocuments.header";
import { CreateDocumentCheckListDrawer } from "./moduleDocuments.drawer";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { getDocumentConfigQuery } from "@src/util/query/documentConfig.query";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { updateDocumentConfigMutation } from "@src/util/mutation/documentConfig.mutation";
import { useDispatch } from "react-redux";

export const ModuleDocuments = ()=>{
    // Use URLSearchParams to parse the query string
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const myParam = query.get('field'); // Replace 'myParam' with your parameter name
    const [activeTab, setActiveTab] = useState("1");

    const [objectTypelocal, setObjectType] = useState(myParam ? myParam[0].toUpperCase()+myParam.slice(1).toLowerCase()  : "Branch");
    const [openDocumentCheckList, setOpenDocumentCheckList] = useState(false);

    const [getDocumentConfig, {data: documentConfig, loading: documentConfigLoading, refetch: documentConfigRefetch}] = useLazyQuery(getDocumentConfigQuery,{
        skip: !objectTypelocal,
        fetchPolicy: 'cache-and-network'
    });

    const refetchNonArchivedRecord = async ()=>{
        getDocumentConfig({
            variables:{
                objectType: objectTypelocal,
                isArchived: false 
            }
        });
    }

    const refetchArchivedRecord = async ()=>{
        getDocumentConfig({
            variables:{
                objectType: objectTypelocal,
                isArchived: true 
            }
        });
    }

    // handel when page loads
    // handel when the object type change for non archived

    useEffect(()=>{
        if(activeTab=="1"){
            refetchNonArchivedRecord();
        }
    },[objectTypelocal, activeTab]);

    // =================== Non-archived terminated

    // Archived Start

    // handel when the object type change for archived

    useEffect(()=>{
        if(activeTab=="2"){
            refetchArchivedRecord();
        }
    },[objectTypelocal, activeTab]);

    // Archived Terminated

    useEffect(()=>{
        if(documentConfig?.getDocumentConfigByObject?.response?.length>0){
            setDataSource(documentConfig?.getDocumentConfigByObject?.response?.map((config)=>({
               ...config,
                key: config?._id,
                createdBy: config?.createdDetail?.firstname+" "+config?.createdDetail?.lastname,
                expiryDateRender: config?.expiryDate? "True": "False",
                sendRequestRender: config?.sendRequest? "True": "False"
            
            })))
        }else{
            setDataSource([]);
        }
    },[documentConfig?.getDocumentConfigByObject?.response]);

    const [dataSource, setDataSource] = useState([]);
    const [hoveredRow, setHoveredRow] = useState(null);

    const rowClassName = (record) => {
      return record.key === hoveredRow ? 'hovered-row' : '';
    };
    
    
    const handleRowMouseEnter = (record) => {
      setHoveredRow(record.key);
      sessionStorage.setItem('hoverItem', record.key);
    };
  
  
    const handleRowMouseLeave = () => {
      setHoveredRow(null);
      // setMoreoption(false);
      sessionStorage.removeItem('hoverItem');
  
    };

    // update doc config
    const [updateDocumentConfig, {loading: updateDocumentConfigLoading}] = useMutation(updateDocumentConfigMutation)

    const dispatch = useDispatch();
    const handelDocumentConfigArchive = async (record, isArchived)=>{
        try{

            await updateDocumentConfig({
                variables:{
                    input : {
                        _id: record?.key,
                        isArchived
                    }
                }
            });
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Archived successfully"
            }));
            if(activeTab=="1"){

                await refetchNonArchivedRecord();
            }else{
                await refetchArchivedRecord();
            }

        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }))
        }
    };

    const [configToBeEdit, setConfigToBeEdit] = useState({});

    const columns = [
        {id:1, title: 'Name', dataIndex: 'name',

            render:(_, record)=>{
                const showActions = hoveredRow == record?.key;
                return(
                    <div style={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
                        <span>{record?.name}</span>
                        {activeTab=="1"?
                        <div>
                            <button className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link" onClick={()=>{setOpenDocumentCheckList(true); setConfigToBeEdit(record);}}>
                                Edit
                            </button> &emsp;
                            <button className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link" onClick={()=>handelDocumentConfigArchive(record, true)}>
                                Archive
                            </button>
                        </div>
                        :
                        
                            <button className={"sm-btn"} style={showActions?{visibility: 'visible'}:{visibility: 'hidden'}} type="link" onClick={()=>handelDocumentConfigArchive(record, false)}>
                                Restore
                            </button>
                        }
                    </div>
                );
            }

        },
        {id:2, title: 'Description', dataIndex: 'description'},
        {id:3, title: 'Expiry date (if any)', dataIndex: 'expiryDateRender'},
        {id:4, title: 'Can make request', dataIndex: 'sendRequestRender'},
        {id:5, title: 'Created by', dataIndex: 'createdBy'},
    ];

    return(
        <div className="setting-body">
            <div className="setting-body-inner">
                <div className='setting-body-inner'>
                    <div className="setting-body-title">
                        <div className='setting-body-inner-title'>
                            Document CheckList
                        </div>

                        {/* <div className='btn-group'>
                            <button className='btn-transparent'>
                                <FontAwesomeIcon icon={faLock}/> &nbsp; <span className='text-decore'>Data Quality</span>
                            </button>
                            <Button className='setting-filled-btn'>
                                Export all data fields
                            </Button>
                        </div> */}

                    </div>
                    <div className="text">
                        Document checklist are used to collect and store files about your records in WorkForce City. For example, a right to work, identity documents, licenses or any other additional documents.
                    </div>
                    {/* object selection box */}
                    <div className="object-selection-box">
                        <div className="objects">

                            <div className='left-selection-box'>
                                <div className='object-item'>
                                    Selected module:
                                </div>
                                <div className="object-item">
                                    <Select
                                        className='custom-select'
                                        style={{width:'250px'}}
                                        suffixIcon={<span className="dropdowncaret"></span>}
                                        defaultValue={myParam ? myParam[0].toUpperCase()+myParam.slice(1) : "Branch"}
                                        value={objectTypelocal}
                                        onChange={(e)=>setObjectType(e)}
                                        // disabled
                                    >
                                        {
                                            Object.keys(objectType).slice(0,6)?.map((object)=>(

                                                <Select.Option value={objectType[object]}>{objectType[object]} document checklist</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className="right-selection-box"style={{pointer:'not-allowed'}}>
                                <div className='object-item object-text text-decore' >Go to {objectTypelocal} settings</div>
                            </div>
                        </div>
                    </div>


                    {/* propertie views */}
                    <div className="propertyTab"></div>
                    <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onChange={(e)=>setActiveTab(e)} className="grid-tabs">
                        <TabPane tab={`Document Checklist`} key="1">
                            <ModuleDocumentsHeader dataSource={documentConfig?.getDocumentConfigByObject?.response} setDataSource={setDataSource} openDocumentCheckList={()=> setOpenDocumentCheckList(true)}/>
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                className='moveGroupTable curvedTable'
                                locale={{emptyText: 'No Document Checklist found'}}
                                
                                onRow={(record) => ({
                                    onMouseEnter: () => handleRowMouseEnter(record),
                                    onMouseLeave: () => handleRowMouseLeave(),
                                })}
                                rowClassName={rowClassName}
                            />
                        </TabPane>

                        <TabPane tab={`Archived Document Checklist`} key="2">
                            <ModuleDocumentsHeader dataSource={documentConfig?.getDocumentConfigByObject?.response} setDataSource={setDataSource} openDocumentCheckList={()=> setOpenDocumentCheckList(true)}/>
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                className='moveGroupTable curvedTable'
                                locale={{emptyText: 'No Document Checklist found'}}
                                
                                onRow={(record) => ({
                                    onMouseEnter: () => handleRowMouseEnter(record),
                                    onMouseLeave: () => handleRowMouseLeave(),
                                })}
                                rowClassName={rowClassName}
                            />
                        </TabPane>
                    </Tabs>
                    
                    {/* create document checklist drawer */}
                    <CreateDocumentCheckListDrawer
                        visible={openDocumentCheckList}
                        close={()=>setOpenDocumentCheckList(false)}
                        objectType={objectTypelocal}
                        refetch={refetchNonArchivedRecord}
                        configToBeEdit={configToBeEdit}
                    />

                </div>
            </div>
        </div>
    )
};