import { UserOutlined } from "@ant-design/icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Modal } from "antd";
import dayjs from "dayjs";

export const PostSeenModal = ({visible, close, seenList})=>{
    return(
        <Modal
            open={visible}
            width={650}
            footer={null}
            closable={false}
        >


            <div className='modal-header-title post-modal'>
                <span> {"Post Viewed By"} </span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div className="modal-body">
                {
                    seenList?.map((seen)=>{
                        return(
                        <div className="popoverdataitem mb16" style={{display:'flex', gap:'20px', alignItems:'center'}}>
                            <div className='post-avatar'> 
                                {seen?.employeeDetail[0]?.metadata?.imageURL?
                                <Avatar
                                    size={50}
                                    src={seen?.employeeDetail[0]?.metadata?.imageURL}
                                />
                                :
                                <Avatar 
                                size={50}
                                >
                                    <UserOutlined/>
                                </Avatar>}
                            </div>

                            <div style={{marginTop:'5px'}}>
                                {seen?.employeeDetail[0]?.firstname} {seen?.employeeDetail[0]?.lastname || ""}
                                <div className="text">
                                    view at  <span style={{color:'gray'}}>{dayjs(seen?.seenAt)?.format("DD/MM/YYYY HH:mm")}</span>
                                </div>
                            </div>
                        </div>
                        )
                    })
                }
            </div>


        </Modal>
    );
}