export const timelineAdvanceFilterObject = [
    {
        _id:'TimeLine',
        properties:[

            {
                fieldType:"branch",
                label:"Branch"
            },

            {
                fieldType:"customer",
                label:"Customer"
            },

            {
                fieldType:"selectDropdown",
                label: "Call Type",
                options:[
                    {id:0, key:"Clock In", value:"Clock In"},
                    {id:1, key:"Clock Out", value:"Clock Out"},
                    {id:5, key:"Check call", value:"Check call"},
                    {id:2, key:"Report", value:'Report'},
                    {id:3, key:"Site Task", value:"Task"},
                    {id:4, key:"BookOn Picture 1", value:'BookOn Picture 1'},
                    {id:4, key:"BookOn Picture 2", value:'BookOn Picture 2'},
                    {id:4, key:"BookOff Signature", value:'BookOff Signature'},
                ]

            },

            // {
            //     fieldType:"selectDropdown",
            //     label: "Duty Status",
            //     options:[
            //         {id:0, key:"Open", value:"0"},
            //         {id:1, key:"UnConfirm", value:"2"},
            //         {id:5, key:"Confirm", value:"1"}
            //     ]

            // },

            // {
            //     fieldType:"employee",
            //     label:"Employee"
            // },
            
            // {
            //     fieldType:"selectDropdown",
            //     label: "Late",
            //     options:[
            //         {id:0, key:'Yes', value:"Yes"},
            //         {id:1, key:'No', value:"No"}
            //     ]

            // },

            {
                fieldType:"selectDropdown",
                label: "Is Duty cancel",
                options:[
                    {id:0, key:'Yes', value:"Yes"},
                    {id:1, key:'No', value:"No"}
                ]

            },

            {
                fieldType:"date",
                label: "Required At",

            },

            {
                fieldType:"site",
                label:"Site"
            },

            // {
            //     fieldType:"selectDropdown",
            //     label: "Status",
            //     options:[
            //         {id:0, key:'Pending', value:"Pending"},
            //         {id:1, key:'Completed', value:"Completed"}
            //     ]

            // },
            
        ]
    }
];