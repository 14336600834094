import dayjs from "dayjs";
import { Table } from "antd";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPayBillByScheduleIdQuery } from "@src/util/query/paybill.query";
import { getScheduledJobById } from "@src/util/query/schedule.query";
import { fixDecimal } from "@src/util/convertDecimalToTime/fixDecimal";


export const EditDutyPayBillTab = ({scheduleId})=>{


    const {data: paybillData, loading: paybillLoading, refetch: refetchPayBill} = useQuery(getPayBillByScheduleIdQuery,{
        variables:{
            scheduleId,
        },
        fetchPolicy:'network-only'
    });

    useEffect(()=>{
        refetchPayBill();
    },[]);

    const columns = [
        {title: 'Type', dataIndex:'type', 
            // render: (text, row, index) => mergeRows(text, row, index),
        },
        {title: 'Start', dataIndex:'start'},
        {title: 'Finish', dataIndex:'finish'},
        {title: 'Hour Type', dataIndex:'hourType'},
        {title: 'Premium', dataIndex:'premium'},
        {title: 'Hours', dataIndex:'hour'},
        {title: 'Rate', dataIndex:'rate'},
        {title: 'Rate Applied', dataIndex:'rateapply'},
        {title: 'Break', dataIndex:'break'},
        {title: 'Ext Value', dataIndex:'ext'},
    ];


    // Function to split dates
    function splitDates(startDate, endDate) {
        if (startDate.isSame(endDate, 'day')) {
        // If the dates are on the same day, return only the original dates
        return [{ start: startDate.format(), end: endDate.format() }];
        } else {
        // Split into 4 parts if the dates are on different days
        const endOfFirstDay = startDate.endOf('day');
        const startOfSecondDay = endDate.startOf('day');
    
        return [
            { start: startDate.format(), end: endOfFirstDay.format() },       // Original first date to end of that day
            { start: startOfSecondDay.format(), end: endDate.format() }        // Start of second day to original second date
        ];
        }
    }

    const [dataSource, setDataSource] = useState();

    const HolidayColumns = [

        "65ee50966f6d12d9e20b21fe", // Bank Holiday Pay
        "65ff5f56cdef94de7cabc3e7" // Bank Holiday Bill
    ];

    const convertToHrsMin = (decimalHours) => {
        const hours = Math.floor(decimalHours); // Extract hours
        const minutes = Math.round((decimalHours - hours) * 60); // Convert decimal to minutes
    
        // Format hours and minutes with leading zero
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
    
        return `${formattedHours} hrs ${formattedMinutes} m`;
    }

    useEffect(()=>{
        if(paybillData?.getPayBill?.response){
            const paybill = paybillData?.getPayBill?.response;
            const paybillDetail = paybill?.paybillDetail
            const payDetail = paybill?.paybillDetail?.payDetail;
            const billDetail = paybill?.paybillDetail?.billDetail;


            let payPremiumOnly = paybill?.payBillPremium.flatMap(subArray => 
                subArray.filter(item => item.hasOwnProperty('payPremium'))
            );

            let billPremiumOnly = paybill?.payBillPremium.flatMap(subArray => 
                subArray.filter(item => item.hasOwnProperty('billPremium'))
            );

            console.log(paybill, "payDetailSafras");
            
            const payDates = splitDates(dayjs(payDetail?.payStartDateTime), dayjs(payDetail?.payEndDateTime));
            let pay = [
                {
                    type: <div style={{fontWeight:800}}>Pay</div>,
                    start: dayjs(payDates[0]?.start).local().format("DD/MM/YYYY HH:mm"),
                    finish: dayjs(payDates[0]?.end).local().format("DD/MM/YYYY HH:mm"),
                    hourType: <span>
                        {payDetail?.name}
                        <small>{(paybillDetail?.isPayFirstDateInBankHoilday==true &&  !HolidayColumns.includes(payDetail?.payColumn)) &&  "( In BH )"}</small>
                    </span>  ,
                    hour: convertToHrsMin(fixDecimal(paybill?.payFirstDateHour, 2)),
                    rate: "£ " +parseFloat(paybillDetail?.payRate?.firstDatePayRate).toFixed(2),
                    rateapply: paybillDetail?.payRateDescription,
                    ext: "£ " +parseFloat(paybill?.firstDatePay).toFixed(2),
                    break: !isNaN(parseFloat(paybillDetail?.unPaidBreakDuration)) ?
                        <span>

                        {parseFloat(paybill?.payFirstDateHour)>0? "- "+convertToHrsMin(parseFloat(paybillDetail?.unPaidBreakDuration)/60)  :"" }
                        <br/>
                        {parseFloat(paybill?.payFirstDateHour)>0? "- £ "+ parseFloat(paybill?.unpaidBreakAmount).toFixed(2) :"" }

                    </span> : ""
                },

            ];
            if(payDates && payDates?.length>0){
                if(payDates[1]){
                    pay.push({
                        type: 'Pay',
                        start: dayjs(payDates[1]?.start).local().format("DD/MM/YYYY HH:mm"),
                        finish: dayjs(payDates[1]?.end).local().format("DD/MM/YYYY HH:mm"),
                        hourType:  <span>
                            {payDetail?.name}
                            <small>{(paybillDetail?.isPaySecondDateInBankHoilday==true &&  !HolidayColumns.includes(payDetail?.payColumn)) &&  "( In BH )"}</small>
                        </span>,
                        hour: convertToHrsMin(fixDecimal(paybill?.paySecondDateHour, 2)),
                        rate: "£ " +parseFloat(paybillDetail?.payRate?.secondDatePayRate).toFixed(2),
                        rateapply: paybillDetail?.payRateDescription,
                        ext: "£ " +parseFloat(paybill?.secondDatePay).toFixed(2)
                    });
                }
            }

            if(paybillDetail?.payOverTimeHour && paybillDetail?.payOverTimeHour>0){
                pay.push(
                    {
                        type: <div style={{fontWeight:800}}>Additional / Overtime Pay</div>,
                        
                        hour: convertToHrsMin(fixDecimal(paybill?.payOverTimeHour, 2)),
                        rate: "£ " +parseFloat(paybill?.overTimePayRate).toFixed(2),

                        ext: "£ " +parseFloat(paybill?.overTimePay, 2).toFixed(2),
                        break:!isNaN(parseFloat(paybillDetail?.unPaidBreakDuration)) ? <span>

                            {parseFloat(paybill?.payFirstDateHour)==0? "- "+convertToHrsMin(parseFloat(paybillDetail?.unPaidBreakDuration)/60)  :"" }
                                <br/>
                            {parseFloat(paybill?.payFirstDateHour)==0? "- £ "+ parseFloat(paybill?.unpaidBreakAmount).toFixed(2) :"" }

                        </span> : ""
                    }
                );
            }

            if(payPremiumOnly && payPremiumOnly?.length>0){
                payPremiumOnly?.map((pp)=>{
                    const {payPremium: premia} = pp;
                    pay.push({
                        type: "",
                        start: "",
                        finish: "",
                        hourType: "",
                        premium: premia?.premiumName,
                        hour: (premia?.totalPremiumHour),
                        rate: "£ " +premia?.premiumRate,
                        ext: "£ "+(premia?.totalPremiumPay) || premia?.rejected
                    });
                })
            }


            
            pay.push({
                type: <div style={{padding:'20px 0', fontSize:'14px', color:'#dc3545', fontWeight:'800'}}>Total Pay</div>,
                start: "",
                finish: "",
                hourType: "",
                hour: <div style={{padding:'20px 0', fontSize:'14px', color:'#dc3545', fontWeight:'800'}}>{convertToHrsMin(fixDecimal(paybillDetail?.totalPayHour, 2))}</div>,
                rate: "",
                ext: <div style={{padding:'20px 0', fontSize:'14px', color:'#dc3545 ', fontWeight:'800'}}>£ {parseFloat(paybill?.totalPay).toFixed(2)} </div>
            });

            const billDates = splitDates(dayjs(billDetail?.billStartDateTime), dayjs(billDetail?.billEndDateTime));
            let bill = [
                {
                    type: <div style={{fontWeight:800}}>Bill</div>,
                    start: dayjs(billDates[0]?.start).local().format("DD/MM/YYYY HH:mm"),
                    finish: dayjs(billDates[0]?.end).local().format("DD/MM/YYYY HH:mm"),
                    hourType: <span>
                        {billDetail?.name}
                        <small>{(paybillDetail?.isBillFirstDateInBankHoilday==true &&  !HolidayColumns.includes(payDetail?.billColumn)) &&  "( In BH )"}</small>
                    </span>,
                    hour: convertToHrsMin(fixDecimal(paybill?.billFirstDateHour, 2)),
                    rate: "£ " +parseFloat(paybillDetail?.billRate?.firstDateBillRate).toFixed(2),
                    rateapply: paybillDetail?.billRateDescription,
                    ext: "£ " + parseFloat(paybill?.firstDateBill).toFixed(2),
                    break:!isNaN(parseFloat(paybillDetail?.unPaidBreakDuration)) ?
                      <span>

                        {parseFloat(paybill?.billFirstDateHour)>0? "- "+convertToHrsMin(parseFloat(paybillDetail?.unBillBreakDuration)/60)  :"" }
                        <br/>
                        {parseFloat(paybill?.billFirstDateHour)>0? "- £ "+ parseFloat(paybill?.unBillBreakAmount).toFixed(2) :"" }

                    </span> : ""
                },

            ];


            if(billDates && billDates?.length>0){
                if(billDates[1]){
                    bill.push({
                        type: 'Bill',
                        start: dayjs(billDates[1]?.start).local().format("DD/MM/YYYY HH:mm"),
                        finish: dayjs(billDates[1]?.end).local().format("DD/MM/YYYY HH:mm"),
                        hourType:  <span>
                            {billDetail?.name}
                            <small>{(paybillDetail?.isBillSecondDateInBankHoilday==true &&  !HolidayColumns.includes(payDetail?.billColumn)) &&  "( In BH )"}</small>
                        </span>,
                        hour: convertToHrsMin(fixDecimal(paybill?.billSecondDateHour,2)),
                        rate: "£ " +parseFloat(paybillDetail?.billRate?.secondDateBillRate).toFixed(2),
                        rateapply: paybillDetail?.billRateDescription,
                        ext: "£ " +parseFloat(paybill?.secondDateBill).toFixed(2),

                    });
                }
            }


            if(paybillDetail?.billOverTimeHour && paybillDetail?.billOverTimeHour>0){
                bill.push(
                    {
                        type: <div style={{fontWeight:800}}>Additional / Overtime Bill</div>,
                        hour: convertToHrsMin(fixDecimal(paybill?.billOverTimeHour, 2)),
                        rate: "£ " +fixDecimal(paybillDetail?.overTimeBillRate,2),
                        ext: "£ " +fixDecimal(paybill?.overTimeBill, 2),
                        break:!isNaN(parseFloat(paybillDetail?.unPaidBreakDuration)) ? <span>

                            {parseFloat(paybill?.billFirstDateHour)==0? "- "+convertToHrsMin(parseFloat(paybillDetail?.unBillBreakDuration)/60)  :"" }
                                <br/>
                            {parseFloat(paybill?.billFirstDateHour)==0? "- £ "+ parseFloat(paybill?.unBillBreakAmount).toFixed(2) :"" }

                        </span> : ""
                    }
                );
            }

            if(billPremiumOnly && billPremiumOnly?.length>0){
                billPremiumOnly?.map((pp)=>{
                    const {billPremium: premia} = pp;
                    bill.push({
                        type: "",
                        start: "",
                        finish: "",
                        hourType: "",
                        premium: premia?.premiumName,
                        hour: (premia?.totalPremiumHour),
                        rate: "£ "+premia?.premiumRate,
                        ext: "£ "+ (premia?.totalPremiumBill) || premia?.rejected
                    });
                })
            }

            bill.push({
                type: <div style={{padding:'20px 0', fontSize:'14px', color:'#28a745', fontWeight:'800'}}>Total Bill</div>,
                start: "",
                finish: "",
                hourType: "",
                hour: <div style={{padding:'20px 0', fontSize:'14px', color:'#28a745', fontWeight:'800'}}>{ convertToHrsMin(fixDecimal(paybillDetail?.totalBillHour, 2)) }</div>,
                rate: "",
                ext: <div style={{padding:'20px 0', fontSize:'14px', color:'#28a745', fontWeight:'800'}}>£ {parseFloat(paybill?.totalBill).toFixed(2)} </div>,
            })

            setDataSource([...pay, ...bill])
        }
    }, [paybillData]);

    return(
          
        <div className="box-shadow border-radius" style={{overflow:'auto', height:'75vh'}}>

            <div style={{padding:'16px'}}>
                    <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between'}}>
                        Pay & Bill
                        <button className="drawer-outlined-btn" style={{padding:'5px 10px'}}> <FontAwesomeIcon icon={faFilePdf}/> Export Pdf</button>
                    </div>
            </div>

            <Table
                className="curvedTable"
                style={{fontWeight:'400'}}
                columns={columns}
                dataSource={dataSource}
                locale={{emptyText:'Pay/Bill not calculated yet'}}
                loading={paybillLoading}
                pagination={false}
            />


        </div>
    );
}