import './tablegrid.css';
import React from 'react';
import {DataTable} from '../table';

export const TableGrid=({
    data, 
    setDynamicColumn, 
    dynamicColumn, title,
    loading, viewRefetch, view, objectData,detailpage,handelBulkUpdateSave, noHeader=false,
    handelArchive, clearSelection=false, detailbtn="Preview", detailUrl="", selectionAllowed=true
})=>{


    return (
        
            <div className='tableView'>
                <DataTable
                 data={data}  
                 header={true}
                 setDynamicColumn={setDynamicColumn}
                 dynamicColumn={dynamicColumn}
                 loading={loading}
                 viewRefetch={viewRefetch}
                 view={view}
                 objectData={objectData}
                 detailpage={detailpage}
                 handelBulkUpdateSave={handelBulkUpdateSave}
                 noHeader={noHeader}
                 handelArchive={handelArchive}
                 clearSelection={clearSelection}
                 title={title}
                 detailbtn={detailbtn}
                 detailUrl={detailUrl}
                 selectionAllowed={selectionAllowed}
                />
                
            </div>

            
    )
}