import { createSlice } from '@reduxjs/toolkit';

const reportFilterReducer = createSlice({
  name: 'reportFilterReducer',
  initialState: {
    reportFilters : {}
  },
  reducers: {

    setReportFilters: (state, action) => {
       return {
        ...state,
        reportFilters: action.payload,
       }     
    },

    resetReportFilters: (state, action) => {
       return {
        ...state,
        reportFilters: {}
       }     
    }

  },
});


export const { setReportFilters, resetReportFilters } = reportFilterReducer.actions;
export default reportFilterReducer.reducer;