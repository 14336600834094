import { Modal } from "antd";
import Spinner from "@src/components/spinner";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Confirmation = ({visible, close, message, error=false, submit, loading, btnText1="Confirm", btnText2="Cancel"})=>{
    return(
        <Modal
            visible={visible}
            width={600}
            footer={
                <div 
                    className="drawer-footer"
                    style={{justifyContent:'right', backgroundColor:'none', padding:'15px 16px'}}
                >

                    <button disabled={loading} style={{minWidth:'20%'}}  className={loading?'disabled-btn drawer-filled-btn ' :'drawer-filled-btn'} onClick={loading? null :submit}>
                       {loading? <Spinner/> : btnText1} 
                    </button>

                    <button disabled={loading}  style={{minWidth:'20%'}} className={loading? 'disabled-btn drawer-outlined-btn' : 'drawer-outlined-btn'} onClick={close}>
                        {btnText2}
                    </button>

                </div>
            }
            closable={false}
        >
            <div className={error? 'modal-header-title' : 'modal-header-title'} style={{height:'2.5em'}}>
                <span style={{letterSpacing:'0.2px'}}> Confirmation </span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div className='modal-body modal-body-padding'>
                <div className="text" style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', minHeight:'5vh'}}>{message}</div>
            </div>

        </Modal>
    )
}