// check window on click
// show alert not be undo
// show alert if late
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import DraggableTab from "@src/components/dragableTab";
import { GridHeader } from "@src/components/tablegrid/header";
import { getScheduleCheckCallsByDateQuery } from "@src/util/query/timeline.query";
import { Avatar, Button, DatePicker, Input, Layout, Popover, Progress, Table } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import { CaretDownFilled, UserOutlined } from "@ant-design/icons";
import "./timeline.css";
import { useSelector } from "react-redux";
import { updateScheduleCheckCallMutation } from "@src/util/mutation/scheduleChecks.mutation";
import isBetween from 'dayjs/plugin/isBetween';
import { TimeLineCheckModal } from "./timelineCheck.modal";
import { ClockIn, ClockOut , CheckCall, Report, SiteTask } from "@src/util/reason/reason";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEye, faPenClip, faSearch } from "@fortawesome/free-solid-svg-icons";
import { TimeLineReportModal } from "./report.modal";
import { TimeLineReport } from "./timeLineReport";
import { getTimelineViewQuery } from "../../util/query/timelineView.query";
import { newTimelineViewMutation, updateTimelineViewMutation } from "../../util/mutation/timelineView.mutation";
import { GridFilter } from "@src/components/tablegrid/gridFilter/gridFilter";
import { AdvanceFilter } from "@src/components/advanceFilter/advanceFilter";
import { timelineAdvanceFilterObject } from "./timelineAdvanceFilter";
import { SiteDutyTaskModal } from "./siteDutyTask.modal";
import { EditColumn } from "@src/components/table/editColumn/editColumn.modal";
import { objectType } from "@src/util/types/object.types";
import { TableGrid } from "@src/components/tablegrid";
import { setEditGridColumn } from "@src/middleware/redux/reducers/properties.reducer";


dayjs.extend(isBetween);

export const TimelinePage = ()=>{

    const currentDate = useMemo(() => dayjs(), []);

    const {quickFilter, advanceFilter} = useSelector(state=>state.quickFilterReducer);


    const {data: generalTimeLine, loading: generalTimeLineLoading, refetch} = useQuery(getScheduleCheckCallsByDateQuery,{
        variables:{
            input: {
                filters: quickFilter && Object.values(quickFilter)?.length>0 && advanceFilter?.length>0 ? 
                {quickFilter, advanceFilter: [...advanceFilter]} :
                quickFilter && Object.values(quickFilter)?.length>0 ? {quickFilter} : 
                advanceFilter?.length>0 ? {advanceFilter: [...advanceFilter]} : null
            }
        },
        fetchPolicy:'cache-and-network'
    });



    const [dataSource, setDataSource] = useState([]);
    const navigate = useNavigate();

    const addPrePostWindow = (callType, date, callDetail, report=false)=>{
        if(callDetail?.allowCallsPostPreWindow){

            if(callType==ClockIn){
                return dayjs(date).subtract(callDetail?.onPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.onPostTimeWindow, 'minutes').format("HH:mm");
            }
            else if(callType==ClockOut){
                return dayjs(date).subtract(callDetail?.offPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.offPostTimeWindow, 'minutes').format("HH:mm");
            }else if(callType=="Signature Required At Clock Out"){
                // btnClass="timeline-outlined-btn-required";
            }
            else if(callType==CheckCall){
                return dayjs(date).add(callDetail?.chkPreTime, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.chkPostTime, 'minutes').format("HH:mm")

            }else if(report){
                return dayjs(date).add(callDetail?.offPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.offPostTimeWindow, 'minutes').format("HH:mm");

            }
            else{
                return dayjs(date).add(callDetail?.onPreTimeWindow, 'minutes').format("HH:mm") + " - " +dayjs(date).add(callDetail?.onPostTimeWindow, 'minutes').format("HH:mm");

            }

        }
    }


    

    const [updateScheduleCheckCall, {loading: updateScheduleCheckCallLoading}] = useMutation(updateScheduleCheckCallMutation)

    const [timeLineCheckVisible, setTimeLineCheckVisible] = useState(false);
    const [timeLineCheckData, setTimeLineCheckData] = useState({});


    const handelUpdatePreAction = async (call)=>{
        const currentDateTime = dayjs();
        const date = call?.requiredAt;
        const callDetail = call?.sitecheckcallsDetaill;

        if(call?.callType==ClockIn){
           const preTime = dayjs(date).subtract(callDetail?.onPreTimeWindow, 'minutes');
           const postTime = dayjs(date).add(callDetail?.onPostTimeWindow, 'minutes');
           const isCurrentInRange = currentDateTime.isBetween(preTime, postTime, null, '[]');
           if(currentDateTime.isBefore(preTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "You can not clock in too early", reason: false, currentDate, late:false, actionAllowed: false, call})
           }
           else if(isCurrentInRange){
                await handelUpdate(call, currentDate, "");
           }
           else if(currentDateTime.isAfter(postTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "Clock in action is outside from allowed window", reason: true, currentDate, late:true, actionAllowed: true, call})
        
           }
        }
        else if(call?.callType==ClockOut){
            
           const preTime = dayjs(date).subtract(callDetail?.offPreTimeWindow, 'minutes');
           const postTime = dayjs(date).add(callDetail?.offPostTimeWindow, 'minutes');
           const isCurrentInRange = currentDateTime.isBetween(preTime.local(), postTime, null, '[]');
           if(currentDateTime.isBefore(preTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "Clock out action is too early", reason: false, currentDate, late:false, actionAllowed: false, call})
           
           }
           else if(isCurrentInRange){
            await handelUpdate(call, currentDate, "");
           }
           else if(currentDateTime.isAfter(postTime)){
            setTimeLineCheckVisible(true);
            setTimeLineCheckData({message: "Clock out action is outside from allowed window", reason: true, currentDate, late:true, actionAllowed: true, call})
           
           }

        }else if(call?.callType==CheckCall){
            const preTime = dayjs(date).add(callDetail?.chkPreTime, 'minutes');
            const postTime = dayjs(date).add(callDetail?.chkPostTime, 'minutes');
            const isCurrentInRange = currentDateTime.isBetween(preTime, postTime, null, '[]');
            if(currentDateTime.isBefore(preTime)){
                setTimeLineCheckVisible(true);
                setTimeLineCheckData({message: "Check call action is too early", reason: false, currentDate, late:true, actionAllowed: true, call})

            }
            else if(isCurrentInRange){
                await handelUpdate(call, currentDate, "");

            }else if(currentDateTime.isAfter(postTime)){
                setTimeLineCheckVisible(true);
                setTimeLineCheckData({message: "Check call action is outside from allowed window", reason: true, currentDate, late:true, actionAllowed: true, call})
               
            }
        }

        else{
            console.log("done");
        }
    };

    const dispatch = useDispatch();

    const handelUpdate = async(call, date, reason)=>{
        try{
            await updateScheduleCheckCall({
                variables:{
                    input: {
                        _id: call?._id,
                        fields: {
                            pending: false,
                            expired: true,
                            late: call?.late,
                            actualDate: dayjs(),
                            callType: call?.callType,
                            scheduleId: call?.scheduleId,
                            reason,
                        }
                    }
                }
            });
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Action was successful"
            }));
            setTimeLineCheckVisible(false);
            setTimeLineCheckData({});
            await refetch();
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }
    };

    const [search, setSearch] = useState("");

    const [timeLineReportVisible, setTimeLineReportVisible] = useState(false);
    const [reportData, setReportData] = useState({});

    const [record, setRecord] = useState(0);

    const [siteDutyTaskModal, setSiteDutyTaskModal] = useState(false);
    
    const filterData = (records) => {
        if(records?.length>0){
        
            const scheduleIdSet = new Set();
            return records?.filter((record) => {
                if (
                    record.callType === "Clock In" &&
                    record.pending &&
                    !scheduleIdSet.has(record.scheduleId)
                ) {
                    scheduleIdSet.add(record.scheduleId);
                    return true;
                }
                return !scheduleIdSet.has(record.scheduleId);
            });

        }
    };

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const updateTime = () => {
          setCurrentTime(new Date());
        };
    
        // Calculate the delay until the next minute starts
        const calculateDelayToNextMinute = () => {
          const now = new Date();
          return 60000 - now.getSeconds() * 1000 - now.getMilliseconds();
        };
    
        // Schedule the first update when the next minute starts
        const initialDelay = calculateDelayToNextMinute();
        const timeoutId = setTimeout(() => {
          updateTime();
    
          // Set up a regular interval to update every minute
          const intervalId = setInterval(updateTime, 60000);
    
          // Clear the timeout when the component unmounts
          return () => clearInterval(intervalId);
        }, initialDelay);
    
        // Clear the initial timeout on component unmount
        return () => clearTimeout(timeoutId);
      }, []);

    useEffect(()=>{
        const timeLineToRender = filterData(generalTimeLine?.getScheduleCheckCallsByDate?.response)
        // sessionStorage.getItem("selectedViewId") === "6745d04b69b8da8c7ef873f9"?
        // generalTimeLine?.getScheduleCheckCallsByDate?.response;

        if(timeLineToRender && !generalTimeLineLoading){
            
            setRecord(timeLineToRender?.length);

            setDataSource(timeLineToRender?.filter((call)=> 
             (
                call?.employeeDetail?.firstname.toLowerCase().includes(search.toLowerCase()) ||
                call?.siteDetails?.sitename.toLowerCase().includes(search.toLowerCase()) ||
                call?.customerDetail?.customername.toLowerCase().includes(search.toLowerCase()) ||
                call?.agencyDetail?.agencyname.toLowerCase().includes(search.toLowerCase()) ||
                call?.siteDetails?.postcode.toLowerCase().includes(search.toLowerCase())
             )
             
            )
            ?.map((call)=>{
                const prePostWindow = addPrePostWindow(call?.callType, call?.requiredAt, call?.sitecheckcallsDetaill);
                const preWindow = dayjs((prePostWindow?.split('-')[0])?.trim(), "HH:mm");
                const postWindow = dayjs((prePostWindow?.split('-')[1])?.trim(), "HH:mm");
                
                let btnClass =
                dayjs(currentTime)?.isBefore(preWindow)?
                "timeline-prewindow-btn"
                : dayjs(currentTime)?.isAfter(preWindow) && dayjs(currentTime)?.isSame(dayjs(call?.requiredAt)) && dayjs(currentTime)?.isBefore(postWindow)?
                "timeline-normal-btn"
                :
                dayjs(currentTime)?.isBefore(postWindow) && dayjs(currentTime)?.isAfter(dayjs(call?.requiredAt))?
                "timeline-postwindow-btn"
                :
                dayjs(currentTime)?.isAfter(postWindow)?
                "timeline-btn-timeOut"
                : "timeline-prewindow-btn"

                

                return({
                    id: call?._id,
                    calltype: 
                    call?.callType==Report?
                    <button className={call?.scheduleDetail?.isReportSubmitted? "timeline-outlined-btn-pic picbtn" : "timeline-outlined-btn-pic"} onClick={async ()=>{ setTimeLineReportVisible(true); setReportData(call); }}> Report </button>
                    :
                    <button disabled={!call?.pending} className={btnClass} onClick={()=>{
                        call?.callType==SiteTask?
                        setSiteDutyTaskModal({modal: true, siteTaskId: call?.taskId, call})
                        :
                        handelUpdatePreAction(call)
                    }}>{call?.callType}</button>,
                    operationaltime: 
                    
                    <div style={{fontSize:'13px', display:'flex', flexDirection:'row', gap:'5px'}}>
                        <div style={{width:'8px', height:'40px', marginTop:'5px'}} className={call?.scheduleDetail?.status=="1"? dayjs(currentTime)?.isAfter(postWindow)? "dutyConfirmedPublishedTimeout" : "dutyConfirmedPublished" : "dutyunConfirmedPublished"}></div>
                        <span>
                            <div>{dayjs(call?.requiredAt).format("DD/MM/YYYY")} <b style={{fontSize:'15px'}}>{dayjs(call?.requiredAt).format("HH:mm")}</b> </div>
                            <span>{addPrePostWindow(call?.callType, call?.requiredAt, call?.sitecheckcallsDetaill)}</span>
                        </span>
                    </div>,

                    status: <Progress type="dashboard" percent={Math.ceil((call?.fulfilledCalls/call?.totalCalls)*100)} width={50} />,
                    employeename: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/employee-detail/"+call?.employeeDetail?._id)}> <Avatar size={30} src={call?.employeeDetail?.metadata?.imageURL || <UserOutlined/>} style={{background:'lightgrey'}}/> {call?.employeeDetail?.firstname+" "+(call?.employeeDetail?.lastname||"")}</div>,
                    sitename: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/site-detail/"+call?.siteDetails?._id)}> {call?.siteDetails?.sitename} </div>,
                    sitegroup: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/sitegroup-detail/"+call?.sitegroupDetail?._id)}> {call?.sitegroupDetail?.sitegroupname} </div>,
                    sitepostcode: <div className="link" onClick={()=>window.open("https://www.google.com/maps/search/?api=1&query="+call?.siteDetails?.metadata?.address+" "+call?.siteDetails?.postcode)}>{call?.siteDetails?.postcode}</div>,
                    customername: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/customer-detail/"+call?.customerDetail?._id)}>{call?.customerDetail?.customername}</div>,
                    agencyname: <div className="link" style={{fontWeight:'normal'}} onClick={()=>navigate("/user/agency-detail/"+call?.agencyDetail?._id)}>{call?.agencyDetail?.agencyname}</div>,
                })
            }));
        }

    },[currentTime, generalTimeLine?.getScheduleCheckCallsByDate?.response, quickFilter , generalTimeLineLoading, search]);

    



    
    const popoverRef = useRef(null);

    useEffect(() => {
        // Function to handle clicks outside the box
        const handleClickOutside = (event) => {
          if(event.target.name==="popoverSearch" || event.target.name==="popoverCheckboxes"){ return; }
          if (popoverRef.current && !popoverRef.current.contains(event.target)) {
            
          }
        };
    
        // Attach the event listener when the component mounts
        document.addEventListener('click', handleClickOutside);
    
        // Clean up the event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    


    const {data: timelineViewData, loading: timelineViewLoading, refetch: viewRefetch} = useQuery(getTimelineViewQuery,{
        fetchPolicy: 'cache-and-network'
    });
    const [createTimelineView, {loading: createTimelineViewLoading}] = useMutation(newTimelineViewMutation);
    const [updateTimelineView, {loading: updateTimelineViewLoading}] = useMutation(updateTimelineViewMutation)
    const [filterModal, setFilterModal] = useState(false);

    // const [editGridColumn, setEditGridColumn] = useState(false);
    const {editGridColumn} = useSelector(state => state.propertyReducer);

    const [dynamicColumn, setDynamicColumn] = useState([]);

    return(
        
        <div className="tablegrid">

            <GridHeader 
                title={"Timeline"} 
                to={"/branch/editform"}
                record={record} 
                from={"/user/branch"}      
                createAction={()=>{}} 
                actionBtnHide={true}
            />

            {/* <div className="hr" style={{margin:'40px 50px', width:'auto'}}></div> */}

            <DraggableTab  
                viewList = {timelineViewData?.timelineView?.response}
                loading = {timelineViewLoading}
                refetch = {viewRefetch}
                updateView = {updateTimelineView}
                createView = {createTimelineView}
                createViewLoading = {false}
                addNewViewHide={false}
                objectType={objectType?.Timeline}
            />

            <GridFilter
                openAdvanceFilter={()=>setFilterModal(true)}
                updateView={updateTimelineView}
                viewList = {timelineViewData?.timelineView?.response}
                refetch= {async()=>{
                  await viewRefetch();
                  await refetch();
                }}
                firstFilter="Start Date"
                secondFilter="End Date"
            />

            <AdvanceFilter 
              firstFiltername="Start date"
              secondFiltername="End date"
              visible= {filterModal} 
              onClose= {()=>setFilterModal(false)}
              groupProperty= {timelineAdvanceFilterObject || []}
            />

       
            <TableGrid
                title={"Timeline"}
                data={dataSource}
                refetch={refetch}
                setDynamicColumn={setDynamicColumn}
                dynamicColumn={dynamicColumn}
                viewRefetch={viewRefetch}
                view={timelineViewData?.timelineView?.response?.find((e)=>e._id==sessionStorage.getItem("selectedViewId"))?.viewFields || []}
                loading={timelineViewLoading || generalTimeLineLoading}
                objectData={timelineAdvanceFilterObject[0]?.properties?.map((prop)=>({isReadOnly:false, isMandatory: true, propertyDetail:{...prop, groupName: 'Timeline', groupId:"1", isArchive: false}}))}
                detailpage={""}
                handelBulkUpdateSave={()=>{}}
                handelArchive={()=>{}}
                clearSelection={()=>{}}
                selectionAllowed={false}
            />
            
            {/* <Layout className='bg-white'>

                <Content className="site-layout timelineTableWrapper" style={{ padding: '0 42px' }}>
                        
                        <Table
                            dataSource={dataSource}
                            columns={columns}
                            className="curvedTable" 
                            title={  
                                () => {
                                    return(
                                        <div className='grid-table-search-input'>
                                        
                                            <div className='table-footer' id="selection-options">
                                                <Input type='search' value={search} 
                                                    onChange={(e)=>setSearch(e.target.value)} 
                                                    style={{background: 'white', width:'250px', height:'33px', borderRadius:'15px'}} 
                                                    className='generic-input-control' placeholder='Search ...'  
                                                    suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                                    allowClear
                                                />
                                            </div>

                                            <div className="small-btn">
                                                <button className={'sm-btn'}>Export</button> &emsp;
                                                <button className={'sm-btn'} onClick={()=>{setEditGridColumn(true)}}>Edit columns</button>
                                            </div>

                                        </div>
                                    );
                                }
                            }     
                            loading={generalTimeLineLoading}                     
                        />

                </Content>
            </Layout> */}

            {timeLineCheckVisible &&
                <TimeLineCheckModal
                    visible={timeLineCheckVisible}
                    close={()=>setTimeLineCheckVisible(false)}
                    timeLineCheckData={timeLineCheckData}
                    handelUpdate = {handelUpdate}
                />
            }

            {
                timeLineReportVisible &&
                <TimeLineReport 
                    reportModalVisible={timeLineReportVisible}
                    reportModalClose={()=>setTimeLineReportVisible(false)}
                    reportStandardData={reportData}
                    refetch={refetch}
                />
            }


            {
                siteDutyTaskModal?.modal &&
                <SiteDutyTaskModal
                    modalDetail={siteDutyTaskModal}
                    close={()=>setSiteDutyTaskModal(false)}
                />
            }


            {
                editGridColumn ?
                    <EditColumn 
                        objectType={objectType.Timeline} 
                        visible={editGridColumn} 
                        onClose={()=>dispatch(setEditGridColumn(false))}
                        properties = {timelineAdvanceFilterObject[0]?.properties?.map((prop)=>({isReadOnly:false, isMandatory: true, propertyDetail:{...prop, groupName: 'Timeline', groupId:"1", isArchive: false}}))}
                        propertiesRefetch = {()=>{}}
                        loading = {timelineViewLoading}
                        disable = {timelineViewLoading}
                        refetchView = {viewRefetch}
                        view = {timelineViewData?.timelineView?.response?.find((e)=>e._id==sessionStorage.getItem("selectedViewId"))?.viewFields}
                        updateRenderedView = {updateTimelineView}
                    />
                :null
            }


        </div>

    )
}