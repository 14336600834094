import { gql } from "@apollo/client";

export const getPayBillReportMutation = gql`
mutation($filters: JSON!){
  getPayBillReport(filters: $filters) {
    message
    response
  }
}
`;


export const getEmployeePayHourReportMutation = gql`
mutation($filters: JSON!){
  getEmployeePayHourReport(filters: $filters) {
    message
    response
  }
}
`;

export const upsertSystemReportMutation = gql`
mutation($input: SystemReportInput!){
  upsertSystemReport(input: $input) {
    message
    response
  }
}
`;