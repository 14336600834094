import { CaretDownFilled } from "@ant-design/icons";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";

export const CreatePoll = ({setIsPollChangedDisabled, setPollConfiguration, savePoll, close, setSavePoll, pollConfiguration})=>{
    const [pollDuration, setPollDuration] = useState("3d");
    const [pollQuestion, setPollQuestion] = useState("");
    const [options, setOptions] = useState([
        {id:0, label: 'Option 1', value:''},
        {id:1, label: 'Option 2', value:''},
    ]);



    const AddAnOption = ()=>{
        setOptions([
            ...options,
            {
                id: options?.length,
                label: 'Option '+(options?.length+1),
                value: ''
            }
        ])
    };

    const removePollField = (id)=>{

        setOptions([
            ...options?.filter((option)=>option?.id!=id)
        ])
    };

    const setOptionValue = (value, id)=>{
        setOptions([
            ...options?.map((option)=>{
                if(option?.id==id){
                    return {
                        ...option,
                        value,
                    }
                }else{
                    return option;
                }
            })
        ])
    };

    useEffect(()=>{
        if(pollQuestion?.length>5 && options?.slice(0,2)?.every((option)=>option?.value?.length>2)){
            setIsPollChangedDisabled(false);
            
        }else{
            setIsPollChangedDisabled(true);
        }
    },[options, pollQuestion]);

    useEffect(()=>{
        if(savePoll==true){
            setPollConfiguration({
                pollQuestion,
                options,
                pollDuration
            });
            close();
        }
    },[savePoll]);

    useEffect(()=>{
        if(pollConfiguration?.hasOwnProperty('pollQuestion')){
            setPollQuestion(pollConfiguration?.pollQuestion);
            setPollDuration(pollConfiguration?.pollDuration);
            setOptions(pollConfiguration?.options);
            
        }
    },[pollConfiguration]);

    return(
        <Form>
            <Form.Item>
                <label>Your Question <sup>*</sup></label>
                <TextArea
                    className="generic-input-control"
                    placeholder="E.g., How do you commute to work?"
                    onChange={(e)=>setPollQuestion(e?.target?.value)}
                    value={pollQuestion}
                />
            </Form.Item>

            {
                options?.map((option, index)=>(

                    <Form.Item>
                        <label className="row-flex" style={{justifyContent:'space-between'}}>
                            <span>
                                {option?.label} {option?.id<2?<sup>*</sup>:null}
                            </span>
                            {
                                option?.id>1?
                                    <span className="remove-poll-field" onClick={()=>removePollField(option?.id)}>Remove</span>
                                :null
                            }
                        </label>
                        <Input
                            className="generic-input-control"
                            placeholder={option?.id>0? "E.g., Other (please comment)"  :"E.g., Public Transportation?"}
                            value={option?.value}
                            onChange={(e)=>setOptionValue(e?.target?.value, option?.id)}
                        />
                    </Form.Item>

                ))
            }

            {/* add more options */}
            {options?.length>4? null :
                <button className="timeline-normal-btn" style={{width:'150px'}} onClick={()=>AddAnOption()}> <FontAwesomeIcon icon={faPlus}/> Add option </button>
            }

            <hr className="mt16 mb16"/>

            {/* poll duration */}
            <Form.Item>
                <label>Poll duration</label>
                <Select
                    className="custom-select"
                    placeholder="Poll duration"
                    suffixIcon={<CaretDownFilled />}
                    value={pollDuration}
                    onChange={(e)=>setPollDuration(e)}
                >
                    
                    <Option value={"1d"} selected>1 day</Option>
                    <Option value={"3d"}>3 days</Option>
                    <Option value={"1w"}>1 week</Option>
                    <Option value={"2w"}>2 weeks</Option>

                </Select>
            </Form.Item>
            

        </Form>
    );
}