import { gql } from "@apollo/client";

export const newScheduleMutation = gql `
mutation($input: ScheduleInput!){
    newSchedule(input: $input) {
      response
      message
    }
}
`;

export const UpdateScheduleByField = gql `
mutation($input: updateScheduleByField!){
  updateScheduleByField(input: $input) {
    response
  }
}
`;

export const updateScheduleMutation = gql`
mutation($input: ScheduleUpdateInput!){
  updateSchedule(input: $input) {
    response
    message
  }
}
`;

export const deleteScheduleByScheduleIdMutation = gql`
mutation($scheduleId: String!){
  deleteScheduleByScheduleId(scheduleId: $scheduleId) {
    message
    response
  }
}
`;


export const scheduleUpdateBulkByField = gql`
mutation($bulkScheduleUpdateInput: bulkScheduleUpdateInput!){
  updateBulkScheduleByField(bulkScheduleUpdateInput: $bulkScheduleUpdateInput) {
    message
    response
  }
}
`;


export const scheduleBulkDeleteDutiesMutation = gql`
mutation($scheduleIds: JSON!){
  deleteScheduleByScheduleIds(scheduleIds: $scheduleIds) {
    message
    response
  }
}
`;