import "./calendar.css";
import dayjs from "dayjs";
import { Calendar, Col, Modal, Popover, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { HolidayDrawer } from "./holiday.drawer";
import { useMutation, useQuery } from "@apollo/client";
import { getHolidayByEntityId } from "@src/util/query/holiday.query";
import { useParams } from "react-router-dom";
import { faClose, faMapLocationDot, faMapMarked, faMapPin, faMarker, faPersonBooth, faPersonHarassing, faSitemap, faStreetView } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteHolidayMutation } from "@src/util/mutation/holiday.mutation";
import { useDispatch } from "react-redux";
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { getEmployeeScheduleByIdQuery } from "../../../../util/query/employee.query";
import { getSystemHolidayQuery } from "@src/util/query/systemHoliday.query";



export const CalendarTab = ()=>{

  const [bankHoliday, setBankHoliday] = useState([]);

  const {data: holidayData, loading: holidayLoading} = useQuery(getSystemHolidayQuery);

  useEffect(()=>{
    if(holidayData?.getSystemHoliday && !holidayLoading){
      setBankHoliday(holidayData?.getSystemHoliday?.holiday);
    }
  },[holidayData, holidayLoading]);

  const param = useParams();
  
  // reterive employee holidays pre and post
  const {data: employeeHoliday, loading: employeeHolidayLoading, refetch: refetchHolidays} = useQuery(getHolidayByEntityId,{
    variables:{
        entityId: param?.id
    },
    fetchPolicy:'network-only'
  });

  const [editEmpLeave, setEditEmpLeave] = useState(false);
  const [editEmpLeaveData, setEditEmpLeaveData] = useState(null);
  
  //get employee scheduled duties
  const {data: employeeSchedule, loading: employeeScheduleLoading, refetch: employeeScheduleRefetch} = useQuery(getEmployeeScheduleByIdQuery,{
    variables:{
      employeeId: param?.id
    },
    fetchPolicy:'network-only'
  });


  const cellRender = (date)=>{

    const isBankHoliday = bankHoliday?.find((bh)=>(dayjs(bh?.date).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY")));
    const isEmpHoliday = employeeHoliday?.getHolidaysByEntityId?.response?.find((eh)=>dayjs(eh?.holidayDate).format("DD/MM/YYYY")==dayjs(date).format("DD/MM/YYYY"));
    const isScheduleExist = employeeSchedule?.getEmployeeSchedule?.response[0]?.schedules?.find((schedule)=>schedule?.date==dayjs(date).format("DD/MM/YYYY"));
    

    if(isBankHoliday && isEmpHoliday && isScheduleExist){
      return (
        <>
        {/* Bank Holiday */}
          <div style={{display:'flex', padding:'6px', background:'#f5f8fa', alignItems:'center', gap:5}}>
            <div className="status-circle status-done" style={{padding:'6px 6px', height:0, border:0}}></div>
            <span  style={{fontSize:'0.6875rem'}}>{isBankHoliday?.title}</span>
          </div>

        {/* Bank Holiday Terminated */}
        
          
        {/* employee holiday */}
          <div style={{display:'flex', padding:'6px', background:'#f5f8fa', alignItems:'center', gap:5, marginTop:8}} onClick={()=>{setEditEmpLeave(true); setEditEmpLeaveData(isEmpHoliday);}}>
            <div className={
              isEmpHoliday?.holidayType=="BH"? "status-circle status-done" : isEmpHoliday?.holidayType=="AH"? "status-circle annual-status"
              : isEmpHoliday?.holidayType=="SL"? "status-circle sick-status" : isEmpHoliday?.holidayType=="OL"? "status-circle other-status" : null
            } 
                style={{padding:'6px 6px', height:0, border:0}}></div>
            <Popover content={isEmpHoliday?.holidayComment}>
              <span style={{fontWeight:'bold'}}>{isEmpHoliday?.holidayType}</span> -
              {isEmpHoliday?.holidayComment && <span style={{fontSize:'0.6875rem'}}> {isEmpHoliday?.holidayComment?.length>15? isEmpHoliday?.holidayComment?.slice(0, 16)+"..." : isEmpHoliday?.holidayComment} </span>}
            </Popover>
          </div>
        {/* employee holiday terminated */}

          <div style={{display:'flex', padding:'6px', background:'#f5f8fa', alignItems:'center', gap:5, marginTop:8}} onClick={()=>{setEditEmpLeave(true); setEditEmpLeaveData(isEmpHoliday);}}>
            
            <FontAwesomeIcon icon={faMapLocationDot} /> 
            <div>
              {dayjs(isScheduleExist?.fistDate).format("HH:mm")}
            </div>
            
          </div>

        </>
      )
    }else if(isBankHoliday){
      return(
        <div style={{display:'flex', padding:'6px', background:'#f5f8fa', alignItems:'center', gap:5}}>
          <div className="status-circle status-done" style={{padding:'6px 6px', height:0, border:0}}></div>
          <span  style={{fontSize:'0.6875rem'}}>{isBankHoliday?.title}</span>
        </div>
      )

    }else if(isEmpHoliday){
      return(
        <div style={{display:'flex', padding:'6px', background:'#f5f8fa', alignItems:'center', gap:5}} onClick={()=>{setEditEmpLeave(true); setEditEmpLeaveData(isEmpHoliday);}}>
          <div className={
            isEmpHoliday?.holidayType=="BH"? "status-circle status-done" : isEmpHoliday?.holidayType=="AL"? "status-circle annual-status"
            : isEmpHoliday?.holidayType=="SL"? "status-circle sick-status" : isEmpHoliday?.holidayType=="OL"? "status-circle other-status" : null
          
          }
          style={{padding:'6px 6px', height:0, border:0}}></div>
          <Popover content={isEmpHoliday?.holidayComment}>
              <span style={{fontWeight:'bold'}}>{isEmpHoliday?.holidayType}</span> -
              {isEmpHoliday?.holidayComment && <span style={{fontSize:'0.6875rem'}}> {isEmpHoliday?.holidayComment?.length>15? isEmpHoliday?.holidayComment?.slice(0, 16)+"..." : isEmpHoliday?.holidayComment} </span>}
          </Popover>
        </div>
      )

    }else if(isScheduleExist){
      return(
        
        <div className="calendarDutyDetail"  style={{fontSize:'0.6875rem'}}>
            
          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            
            <span  style={{fontSize:'0.6875rem'}}>{dayjs(isScheduleExist?.firstDate).format("HH:mm")} - {isScheduleExist?.secondDate? dayjs(isScheduleExist?.secondDate).format("HH:mm") : isScheduleExist?.shiftEndTime}</span> 
            <div>
              <div style={{fontSize:'0.6875rem'}}>Hr {(+isScheduleExist?.firstDateHours + +isScheduleExist?.secondDateHours).toFixed(2)}</div>
              {/* <div>Br {(isScheduleExist?.unPaidBreakDuration==":"? "0" :  isScheduleExist?.unPaidBreakDuration)}</div> */}
            </div>

          </div>

        <div>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'5px'}}>
              <FontAwesomeIcon icon={faStreetView} /> 
              {isScheduleExist?.site?.sitename}
            </div>

            <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'5px', marginTop:'8px'}}>
              <FontAwesomeIcon icon={faPersonBooth} /> 
              {isScheduleExist?.siteArea?.areaname}
            </div>

        </div>
        
        </div>

      );
    }



  }


  const [holidayDrawerVisible, setHolidayDrawerVisible] = useState(false);


  return (

      <>
        <div className='hr-section emp-calendar-section' style={{marginLeft:'0'}}>
            
            <div style={{paddingRight:'16px'}}>
                <div className='hr-info-title' style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                    <div>Calendar</div>
                </div>
            </div>

            <Row gutter={32} style={{marginTop:'-20px'}}>

              <Col span={24} className="empCalendar">
                
                <button className="drawer-filled-btn" onClick={()=>setHolidayDrawerVisible(true)} style={{position:'absolute', marginTop:'5px', right:18}}>Add Holidays</button>
                <Calendar cellRender={cellRender}  style={employeeHolidayLoading?{opacity:'0.2'} :{height:'100%', overflow:'hidden'}} />

              </Col>

            </Row>

        </div>

        <div className='hr-action-footer smallScreen-action-footer'>
          
          <div className="row-flex" style={{width:'100%', justifyContent:'space-between'}}>

            <div className="row-flex" >
              <div className="status-circle status-done" style={{padding:'6px 3px', width:'6px', border:0}}></div>
              <div>Bank Holidays</div>  
            </div>

            <div className="row-flex" >
              <div className="status-circle annual-status" style={{padding:'6px 3px', width:'6px', height:0, border:0}}></div>
              <div>Annual Holidays</div>
            </div>

            <div className="row-flex" >
              <div className="status-circle sick-status" style={{padding:'6px 3px', width:'6px', border:0, background:'#F2545B'}}></div>
              <div>Sick Leaves</div>  
            </div>

            <div className="row-flex" >
              <div className="status-circle other-status" style={{padding:'6px 3px', width:'6px', height:0, border:0, background:'gray'}}></div>
              <div>Other Leave</div>
            </div>

          </div>
          
        </div>
        {
          holidayDrawerVisible &&
          <HolidayDrawer
            visible={holidayDrawerVisible}
            close={()=>{setHolidayDrawerVisible(false); setEditEmpLeaveData(null)}}
            refetchHolidays = {refetchHolidays}
            editEmpLeaveData={editEmpLeaveData}
          />
        }

        <Confirmation 
          visible={editEmpLeave}
          close={()=>setEditEmpLeave(false)}
          editHoliday={()=>{ setHolidayDrawerVisible(true); setEditEmpLeave(false); }}
          data={editEmpLeaveData}
          refetch={refetchHolidays}
        />

      </>
    );
}


export const Confirmation = ({visible, close, editHoliday, data, refetch})=>{
  const [deleteHoliday, {loading: deleteHolidayLoading}] = useMutation(deleteHolidayMutation);
  const dispatch = useDispatch();
  const handelDelete = async ()=>{
    const res = confirm("Do you want to delete this holiday?");
    if(res){
      await deleteHoliday({
        variables:{
          deleteHolidayId: data?._id
        }
      });
      
      dispatch(setNotification({
        error: false,
        notificationState: true,
        message: "Holiday was deleted successfully"
      }));
      close();
      await refetch();

    }else{
      null;
    }
  };

  return(
      <Modal
          visible={visible}
          width={600}
          footer={
              <div 
                  className="drawer-footer"
              >

                  <button disabled={false}  className={false?'disabled-btn drawer-filled-btn ' :'drawer-filled-btn'} onClick={editHoliday}>
                    Edit
                  </button>
                  
                  <button disabled={false}  className={false?'disabled-btn drawer-outlined-btn ' :'drawer-outlined-btn'} onClick={handelDelete}>
                    Delete
                  </button>

              </div>
          }
          closable={false}
      >
          <div className={true? 'modal-header-title archive-modal-header' : 'modal-header-title'}>
              <span style={{letterSpacing:'0.2px'}}> Perform Action </span>
              <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
          </div>

          <div className='modal-body modal-body-padding'>
              <div className="text" style={{textAlign:'center', margin:'0'}}>Do you want to perform any action?</div>
          </div>

      </Modal>
  )
}

