import { gql } from "@apollo/client";

export const ScheduleQuery = gql`
query($endDate: String!, $startDate: String!, $siteId: String!){
  getScheduleBySiteId(endDate: $endDate, startDate: $startDate, siteId: $siteId) {
    message
    response
  }
}
`;


export const ScheduleTrainedEmployee = gql `
query($allowedSkilledForSpecificArea: [String!]!, $numberOfDuties: Float!, $shiftStartTime: DateTime!, $siteArea: String!, $siteId: String!){
  getTrainedEmployeeOnSite(allowedSkilledForSpecificArea: $allowedSkilledForSpecificArea, numberOfDuties: $numberOfDuties, shiftStartTime: $shiftStartTime, siteArea: $siteArea, siteId: $siteId) {    response
    message
  }
}
`;


export const getScheduledJobById = gql`
query($jobId: String!){
  getScheduledJobById(jobId: $jobId) {
    response
    message
  }
}
`;


export const getScheduleBySiteIdAndEmployeeIdQuery = gql`
query($endDate: String!, $startDate: String!, $employeeId: String!, $siteId: String!){
  getScheduleBySiteIdAndEmployeeId(endDate: $endDate, startDate: $startDate, employeeId: $employeeId, siteId: $siteId) {
    message
    response
  }
}
`;