import React,{useEffect, useState} from 'react';
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsH, faCalendarAlt, faChevronDown, faChevronRight, faClockFour, faClose, faEllipsisV, faMaximize, faSearch, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, DatePicker, Divider, Input, Popover, Select } from 'antd';
import { setTaskToggle } from '@src/middleware/redux/reducers/task.reducer';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { objectType } from "@src/util/types/object.types";
import { NewNoteMutation } from '@src/util/mutation/note.mutation';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';
import "./task.model.css";
import dayjs from 'dayjs';
import { CaretDownFilled } from '@ant-design/icons';
import { GetEmployeeRecord } from "@src/util/query/employee.query";
import { getSitesQuery } from "@src/util/query/site.query";
import { newTaskMutation } from "@src/util/mutation/task.mutation";




export const TaskModel = ({refetch})=>{
    const [position, setPosition] = useState({ x: 306, y: 212 });

    const handleDrag = (e, ui) => {
      const { x, y } = ui;
      setPosition({ x, y });
    };
    const [collapse, setCollapse] = useState(false);
   
   
   

    const [width, setWidth] = useState(false);
    const dispatch = useDispatch();



    const param = useParams();
    

    const  {Option} = Select;

        
    const [time, setTime] = useState([]);

    const generateTime = ()=>{
        let generatedTime = [];
        for(let hType=0; hType<2; hType++){
            for(let h=1; h<13; h++){
                for(let m=0; m<46; m=m+15){
                    if(hType==0){

                        if(m==0){
                            generatedTime.push(h+":00 AM")
                        }else{
                            
                            generatedTime.push(h+":"+m+" AM")
                        }

                    }else{
                        
                        if(m==0){
                            generatedTime.push(h+":00 PM");
                        }else{
                            generatedTime.push(h+":"+m+" PM");                        
                        }
                        

                    }
                }
            }
        };

        setTime(generatedTime);
    };

    useEffect(()=>{
        generateTime();
    },[]);


    const [title, setTitle] = useState("");
    const [dueDate, setDueDate] = useState(dayjs(new Date()));
    const [dueTime, setDueTime] = useState('1:00 AM');
    const [reminder, setReminder] = useState("duetime")
    const [notes, setNote] = useState("");
    const [reminderRepeat, setReminderRepeat] = useState(false);
    const [type, setType] = useState("To-do");
    const [priority, setPriority] = useState("None");
    const [queue, setQueue] = useState("None");

    
    const {data: employeeData, loading: employeeDataLoading, refetch: refetchEmployeeData} = useQuery(GetEmployeeRecord,{fetchPolicy: 'cache-and-network',
    variables: {
        input: {
            filters: null
        }
        }
    });


    const [assignee, setAssignee] = useState([]);
    useEffect(()=>{
        if(employeeData?.getEmployee?.response){
            setAssignee([...employeeData?.getEmployee?.response])
        }
    },[employeeData?.getEmployee?.response]);

    const [assignedTo, setAssignedTo] = useState("");
    // this state prop will be use to display the selected assignee user for specific task
    const [selectedAssignee, setSelectedAssignee] = useState({});
    useEffect(()=>{
        if(assignedTo && employeeData?.getEmployee?.response?.length>0) {
            setSelectedAssignee(employeeData?.getEmployee?.response?.find((emp)=>emp?._id==assignedTo));
        }else{
            setSelectedAssignee({});
        }
    }, [assignedTo, employeeData?.getEmployee?.response]);

    const [assigneeSearch, setAssigneeSearch] = useState("");

    // association
    const [activeAssociatedModule, setActiveAssociateModule] = useState("employee");
    const [employeeAssociation, setEmployeeAssociation] = useState([]);
    const [siteAssociation, setSiteAssociation] = useState([]);

    // get site list
    const {data: siteData, loading: siteLoading, refetch:refetchSites} = useQuery(getSitesQuery,{fetchPolicy: 'cache-and-network',
      variables: {
          input: {
              filters: null
          }
      }
    });

    const totalAssociation = Number(siteAssociation?.length)+Number(employeeAssociation?.length);

    const [isReady, setReady] = useState(false);

    useEffect(()=>{
        if(title?.length>0 && type?.length>0 && (employeeAssociation?.length>0 || siteAssociation?.length>0)){
            setReady(true);
        }else{
            setReady(false);
        }
    }, [title, type, assignedTo, employeeAssociation, siteAssociation]);

    const [newTask, {loading: newTaskLoading}] = useMutation(newTaskMutation);
    
    const handelTaskSubmit = async () =>{
        try{
            let task = {
                title,
                type,
                priority,
                employeeAssociation,
                siteAssociation,
                assignedTo,
                dueDate, dueTime,
                reminder, notes
            };
            await newTask({
                variables: {
                    input: task
                }
            });
           
            dispatch(setTaskToggle(false));
    
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Task was created successfully",
            }));

            await refetch();


            
        }
        catch(err){
            dispatch(setTaskToggle(false));

            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));

        }
    }

    return(
        <Draggable 
            onDrag={handleDrag}
            handle='.notes-header'
            defaultPosition={{ x: position.x, y: position.y }}
        >
            <div  className="notes-main" 
            style={{
                position:'fixed', 
                top: collapse? 420 : width? 0  :position.y,
                left: width? 0 :position.x, 
                zIndex:99999,
                overflow:"hidden",
                height:collapse?50 : 500,
                background: 'white',
                width: width? '1050px' : '750px',
            }}>
                <div className={width? "notes-header width-max" :"notes-header width-min"}>
                    <span style={{display:'flex', alignItems:'center'}}>
                       {collapse? <FontAwesomeIcon className='svg-icon' icon={faChevronRight} style={{    width: '14px', fontSize:'12px'  }}  onClick={()=>setCollapse(false)} /> : 
                       <FontAwesomeIcon  style={{    width: '14px', fontSize:'12px'  }}  className='svg-icon'  icon={faChevronDown} onClick={()=>setCollapse(true)} />} 
                       Task
                    </span>
                    <span className='dragable-icon-svg' style={width?{paddingTop:'12px'}:{}}>
                        <FontAwesomeIcon icon={faEllipsisV} />
                        <FontAwesomeIcon icon={faEllipsisV} />
                        <FontAwesomeIcon icon={faEllipsisV} />
                        <FontAwesomeIcon icon={faEllipsisV} />
                        <FontAwesomeIcon icon={faEllipsisV} />
                        <FontAwesomeIcon icon={faEllipsisV} />
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </span>
                    <span>
                        <FontAwesomeIcon icon={faArrowsH} onClick={()=>setWidth(!width)} className='svg-icon dblarrow'/>
                        <FontAwesomeIcon onClick={()=>dispatch(setTaskToggle(false))}  className='svg-icon' icon={faClose}/>
                    </span>
                </div>



                <div className="notes-body task-model">
                    
                    <Input 
                        placeholder='Enter your task'
                        className='task-input'
                        value={title}
                        onChange={(e)=>setTitle(e.target.value)}
                    />


                    {/* due dates block */}

                    <div style={{padding: '16px 0.6875rem', display: 'flex', alignItems: 'center', gap:'20px'}}>

                        {/* dueDate */}
                        <div style={{display: 'flex', gap:'12px', flexDirection: 'column'}}>
                            <span className='top-label'>Activity date</span>
                            <div
                                style={{
                                    display:'flex',
                                    alignItems: 'center',
                                    gap:'20px'
                                }}
                            >
                                <DatePicker 
                                    className="generic-input-control"
                                    style={{width: '150px', margin:0, height:'30px'}}
                                    suffixIcon={<FontAwesomeIcon className="dueDateIcon" style={{color: 'rgba(0, 0, 0, 0.25)' }} icon={faCalendarAlt} />}
                                    format={'DD/MM/YYYY'}
                                    disabledDate={(current)=>current && current < dayjs().startOf('day')}
                                    value={dueDate}
                                    onChange={(e, dateString)=>setDueDate(e)}
                                />

                                <Select
                                    style={{width:'150px'}}
                                    className="custom-select"
                                    suffixIcon={<FontAwesomeIcon className="dueDateIcon"  icon={faClockFour} />}
                                    value={dueTime}
                                    onChange={(e)=>setDueTime(e)}
                                >

                                    {time?.map((t)=>(
                                        <Option value={t} > {t} </Option>
                                    ))}

                                </Select>
                            </div>
                        </div>

                        {/* reminder detail */}
                        <div style={{display: 'flex', gap:'12px', flexDirection: 'column'}}>
                            <span className='top-label'>Send reminder</span>
                            <Select
                                style={{width:'200px'}}
                                className="custom-select"
                                suffixIcon={<FontAwesomeIcon className="dueDateIcon"  icon={faStopwatch} />}
                                value={reminder}
                                onChange={(e)=>setReminder(e)}

                            >
                                <Option value="0">No reminder</Option>
                                <Option value="duetime">At task due time</Option>
                                <Option value="30m">30 mintues before</Option>
                                <Option value="1h">1 hour before</Option>
                                <Option value="1d">1 day before</Option>
                                {/* <Option value="customDate">Custom date</Option> */}
                            </Select>
                            
                        </div>

                        <Checkbox 
                        style={{marginTop:'3%'}}
                        value={reminderRepeat}
                        onChange={(e)=>setReminderRepeat(e.target.checked)}
                        >Set to repeat</Checkbox>

                    </div>

                    {/* task type section */}

                    <div className="dueDateBox top-border" style={{padding: '16px 0.6875rem', display: 'flex', alignItems: 'center', gap:'20px'}}>
                        
                    {/* <div className="dueDateBox top-border" style={{marginTop:'32px', gap:'50px', paddingBottom: '8px', marginBottom: '16px'}}> */}

                        <div className="date-section">
                            <span className="duetext">Type</span>
                            <div className="due-fields">
                                <Popover
                                    trigger={"click"}
                                    overlayClassName='notePopover'
                                    placement="bottom"
                                    content={
                                        <div className="popover-data">
                                            <div 
                                            onClick={(e)=>setType(e.target.innerText)}
                                            className={type.toLowerCase()=="call" ? "popoverdataitem popoverdataitem-active" : "popoverdataitem"}>
                                                Call
                                            </div>

                                            <div 
                                            
                                            onClick={(e)=>setType(e.target.innerText)}
                                            className={type.toLowerCase()=="email" ? "popoverdataitem popoverdataitem-active" : "popoverdataitem"}>
                                                Email
                                            </div>

                                            <div
                                            onClick={(e)=>setType(e.target.innerText)}
                                            className={type.toLowerCase()=="to-do" ? "popoverdataitem popoverdataitem-active" :"popoverdataitem"}>
                                                To-do
                                            </div>
                                        </div>
                                    }
                                >
                                <div className="grid-text-btn" > {type}  <CaretDownFilled style={{color:'#0091ae'}} /> </div> 
                                </Popover>
                            </div>
                        </div>

                        <div className="date-section">
                            <span className="duetext">Priority</span>
                            <div className="due-fields">
                                <Popover
                                    trigger={"click"}
                                    placement="bottom"
                                    overlayClassName='notePopover'
                                    content={
                                        <div className="popover-data">
                                            <div
                                            
                                            onClick={(e)=>setPriority(e.target.innerText)}
                                            className={priority?.toLowerCase()==="none"? "popoverdataitem popoverdataitem-active" :"popoverdataitem"}>
                                                None
                                            </div>

                                            <div 
                                            onClick={(e)=>setPriority(e.target.innerText)}
                                            className={priority?.toLowerCase()==="low"? "popoverdataitem popoverdataitem-active" :"popoverdataitem"} style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                            <div className="low-status"></div>  Low
                                            </div>

                                            <div
                                            onClick={(e)=>setPriority(e.target.innerText)}
                                            className={priority?.toLowerCase()==="medium"? "popoverdataitem popoverdataitem-active" :"popoverdataitem"} 
                                            style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                            <div className="medium-status"></div>  Medium
                                            </div>

                                            <div 
                                            onClick={(e)=>setPriority(e.target.innerText)}
                                            className={priority?.toLowerCase()==="high"? "popoverdataitem popoverdataitem-active" :"popoverdataitem"} style={{display:'flex', gap:'5px', alignItems:'center'}}>
                                            <div className="high-status"></div> High
                                            </div>

                                        </div>
                                    }
                                >
                                <div className="grid-text-btn" > {priority[0]?.toUpperCase()+priority.slice(1)} <CaretDownFilled style={{color:'#0091ae'}} /> </div> 
                                </Popover>
                            </div>

                        </div>


                        <div className="date-section">
                            <span className="duetext">Queue</span>
                            <div className="due-fields">
                                <Popover
                                    trigger={"click"}
                                    overlayClassName='notePopover'
                                    placement="bottom"
                                    content={
                                        <div className="popover-data">
                                            <div className="popoverdataitem">
                                                None
                                            </div>
                                        </div>
                                    }
                                >
                                <div className="grid-text-btn" > None <CaretDownFilled style={{color:'#0091ae'}} /> </div> 
                                </Popover>
                            </div>

                        </div>

                        <div className="date-section">
                            <span className="duetext">Assigned To</span>
                            <div className="due-fields">
                            <Popover
                                trigger={"click"}
                                overlayClassName='settingCustomPopover tableGridPopover'
                                content={
                                    true?
                                    <div>
                                        <div className="popover-search" >
                                            <Input type="search" 
                                                id="inputSearch"
                                                name='popoverSearch'
                                                style={{ width: '-webkit-fill-available', backgroundColor: 'white'  }} 
                                                className='generic-input-control' 
                                                placeholder="Search..."
                                                autoFocus={true}
                                                autoComplete="off"
                                                value={assigneeSearch}
                                                onChange={(e)=> {
                                                    setAssignee(employeeData?.getEmployee?.response?.filter((date)=> (date.firstname)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))
                                                    setAssigneeSearch(e.target.value);
                                                }}
                                                suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                            />
                                        </div>

                                        <div className="popover-data "  >
                                            <div 
                                                    className={assignedTo==""? "popoverdataitem-active popoverdataitem": "popoverdataitem"} 
                                                    onClick={()=>{setAssignedTo(""); setSelectedAssignee({})}}
                                                >
                                                    No owner
                                            </div>
                                            
                                            {assignee && assignee?.map((datalist)=>(

                                                <div 
                                                    className={datalist?._id==assignedTo? "popoverdataitem-active popoverdataitem": "popoverdataitem"} 
                                                    onClick={()=>setAssignedTo(datalist?._id)}
                                                >

                                                    {datalist.firstname+ " "+ datalist?.lastname}
                                                    
                                                    <div 
                                                        className="text" 
                                                        style={{color: '#516f90',
                                                        fontWeight: '200'}}
                                                    >
                                                        {datalist?.metadata?.email}
                                                    </div>

                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                    :
                                    null
                                }
                                placement='bottom'
                            >
                                <div  className={'grid-text-btn'}>
                                    {selectedAssignee?.hasOwnProperty('firstname') ? selectedAssignee.firstname+" "+selectedAssignee?.lastname: 'No owner'} <CaretDownFilled style={{color:'#0091ae'}} />
                                </div>
                            </Popover>
                            </div>

                        </div>


                    {/* </div> */}
                    </div>
                    
                    
                    {/* note block */}

                    <ReactQuill 
                        theme="snow" 
                        placeholder='Notes ...' 
                        value={notes} 
                        onChange={(e)=>setNote(e)} 
                    />
            

                    <div className='note-association'>
                    <Popover
                            placement="bottom"
                            overlayClassName='associationCustomPopover'
                            trigger={"click"}
                            content={
                                <div className="association">
                                    <div className="association-sidebar">
                                        
                                        <div className="popoverdataitem">
                                            Selected {totalAssociation}
                                        </div>
                                        
                                        <Divider style={{marginTop:'0px', marginBottom:'10px'}}/>

                                        <div className={activeAssociatedModule=="employee"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>setActiveAssociateModule("employee")}>
                                            Employee
                                        </div>
                                        <div className={activeAssociatedModule=="site"? "popoverdataitem activeTab" :"popoverdataitem"} onClick={()=>setActiveAssociateModule("site")}>
                                            Site
                                        </div>                                    
                                    </div>

                                    <div className="association-body">
                                        <Input type="search"
                                            id="inputSearch"
                                            name='popoverSearch'
                                            style={{ width: '-webkit-fill-available', margin:'8px 8px 16px 8px' }} 
                                            className='generic-input-control' 
                                            placeholder={"Search "+activeAssociatedModule}
                                            autoFocus={true}
                                            autoComplete="off"
                                            onChange={(e)=> {
                                                // setLocalGroup(groupList?.filter((group)=> (group.name)?.toLowerCase()?.includes(e.target.value?.toLowerCase())))
                                                // setGroupSearch(e.target.value);
                                            }}
                                            suffix={<FontAwesomeIcon style={{color:'#0091ae'}}  icon={faSearch}/>}
                                        />
                                        <div className="popover-data" style={{overflowY:'scroll', height:'170px', }}>
                                            
                                            {
                                                activeAssociatedModule=="employee"?
                                                employeeData?.getEmployee?.response?.map((employee)=>(
                                                    <div className="popoverdataitem">
                                                        <Checkbox onChange={(e)=>{
                                                            if(e.target.checked){
                                                                setEmployeeAssociation([...employeeAssociation, employee?._id])
                                                            }else{
                                                                setEmployeeAssociation(employeeAssociation?.filter((id)=>id!=employee?._id))
                                                            }
                                                        }}
                                                        checked={employeeAssociation?.find((id)=>id==employee?._id)}
                                                        > 
                                                            {employee?.firstname +" "+ employee?.lastname} 
                                                        </Checkbox>
                                                    </div>
                                                ))
                                                :
                                                siteData?.sites?.map((site)=>(
                                                    <div className="popoverdataitem">
                                                        <Checkbox  onChange={(e)=>{
                                                            if(e.target.checked){
                                                                setSiteAssociation([...siteAssociation, site?._id])
                                                            }else{
                                                                setSiteAssociation(siteAssociation?.filter((id)=>id!=site?._id))
                                                            }
                                                        }}
                                                        checked={siteAssociation?.find((id)=>id==site?._id)}
                                                        > {site?.sitename} </Checkbox>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>


                                </div>
                            }


                        >
                            
                            <span className="grid-text-btn">
                               Associated with {totalAssociation} {totalAssociation?.length==1 ?" record" : "records" }
                            </span>
                        </Popover>
                    </div>

                </div>

                <div className="note-footer">
                    <button onClick={handelTaskSubmit} disabled={!isReady || newTaskLoading? true : false} className={!isReady || newTaskLoading? 'disabled-btn middle-note-btn' :'middle-note-btn'}>
                        Create Task
                    </button>
                   
                </div>

            </div>
      </Draggable>
    )
}