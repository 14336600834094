import "./systemReport.css";
import React, { useEffect, useState } from 'react';
import { Collapse, Table } from 'antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar} from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQuery } from '@apollo/client';
import { getSystemReportQueryByCategory } from '@src/util/query/systemReport.query';
import { SystemReportFilterModal } from './systemReportFilter.modal';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { resetReportFilters } from "@src/middleware/redux/reducers/reportFilters.reducer";
import { upsertSystemReportMutation } from "../../util/mutation/systemReport.mutation";

const SystemReport = () => {

  const [reportFilterModal, setReportFilterModal] = useState({});
  const navigate = useNavigate();

  const {data, loading: systemCategorisedReportLoading, refetch: refetchGetSystemReportQueryByCategory} = useQuery(getSystemReportQueryByCategory);

  const [systemCategorisedReport, setSystemCategorisedReport] = useState(null);
  useEffect(()=>{
    if(!systemCategorisedReportLoading){
        setSystemCategorisedReport(data?.getSystemReportCategoryWithReports?.response);
    }
  },[data, systemCategorisedReportLoading]);


  const columns = [
    {
        title:'Name',
        dataIndex:'name',
        align: 'center',
        width:'33%',
        ellipsis: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        align: 'center',
        width:'33%',    
        ellipsis: {
            showTitle: true, // Hide default tooltip
        },
        render: (_,record) => (
            <span title={record?.description} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", display: "block", maxWidth: '100%', cursor:'text' }}>
                {record?.description}
            </span>
        )

    },
    {
        title: 'Last Run',
        dataIndex: 'lastrun',
        align: 'center',
        width:'33%',
    },
  ];

  const [dataSource, setDataSource] = useState([]);

  const [activeReportCat, setActiveReportCat] = useState("67aa325d7a560fd07cd68c9f");
  useEffect(()=>{
    if(activeReportCat){
        navigate("/user/sysreport/"+activeReportCat)
    }
  },[activeReportCat]);

  const [categorisedReport, setCategorisedReport] = useState({});


  // This report filters saved in filter modal to keep track the filter saved in this filter modal.

  const reportFilters = useSelector(state=>state.reportFilterReducer.reportFilters);

  useEffect(()=>{
    if(reportFilters && Object.keys(reportFilters)?.length>0){
        const reportFilter = reportFilters?.reportFilters;
        setReportFilterModal({ modal: true, reportDetail: reportFilter?.reportDetail, reportFilter: reportFilter?.filters })
    }
  },[reportFilters]);

  const dispatch = useDispatch();
  const [upsertSystemReport, {loading: upsertSystemReportLoading}] = useMutation(upsertSystemReportMutation);


  const handelFavourit = async (_id, isFavourite)=>{
    await upsertSystemReport({
        variables:{
            input:{
                _id,
                isFavourite
            }
        }
    });
    await refetchGetSystemReportQueryByCategory();
  }

  useEffect(()=>{
    if(activeReportCat && !systemCategorisedReportLoading && systemCategorisedReport?.length>0){
        console.log(systemCategorisedReport, "systemCategorisedReport");
        let reportCatDetail = {};
        if(activeReportCat=="favourite"){
            let data = [];
            reportCatDetail = systemCategorisedReport?.map((category)=>{
                category?.categorisedReport?.map((report)=>{
                    if(report?.isFavourite){
                        data?.push(report);
                    }
                })
            })
            reportCatDetail['categorisedReport'] = data;
        }else{
            reportCatDetail = systemCategorisedReport?.find((category)=>category?._id==activeReportCat)
        }
        
        
        setCategorisedReport(reportCatDetail);
        setDataSource(
            reportCatDetail?.categorisedReport?.map((report)=>{
                return(
                    {
                        name: 
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                            <div className="link" style={{width:'100%', textAlign:'left', padding:'15px'}} 
                                onClick={()=>{
                                    dispatch(resetReportFilters());
                                    setReportFilterModal({ modal: true, reportDetail: report });
                                }}
                            >
                                {report?.name} 
                            </div>
                            <span>
                            {

                                report?.isFavourite?
                                <StarFilled style={{fontSize:'20px', cursor:'pointer'}} onClick={()=>handelFavourit(report?._id, false)}/>
                                :
                                <StarOutlined style={{fontSize:'20px', cursor:'pointer'}} onClick={()=>handelFavourit(report?._id, true)}/>

                            }
                            </span>
                        </div>,

                        description: report?.description || "--",

                        lastrun: "--"
                    }
                )
            })
        );

    }else{
        setCategorisedReport({});
    }
  },[activeReportCat, systemCategorisedReportLoading, systemCategorisedReport]);



  return (
    <div className='systemReportWrapper'>

        <div style={{display:'flex'}}>

            <div className='setting-sidebar' style={{height:'110vh'}}>
                    <div className='setting-sidebar-body'>
                        <div className="setting-sidebar-inner">
                            <div className="setting-heading">
                                <div className='setting-header-title'>
                                     Reports
                                </div>
                                
                            </div>

                            <nav className='setting-custom-nav' style={{paddingBottom:'32px'}}>
                                <Collapse  defaultActiveKey={['1', '2', '3', '4']}>
                                    
                                    <div 
                                        className={activeReportCat=="favourite"? "setting-sidebar-nav popoverdataitem" : 'setting-sidebar-nav popoverdataitem'} 
                                        onClick={()=>setActiveReportCat("favourite")}
                                        style={
                                            activeReportCat=="favourite" ? 
                                            {
                                                background:'#e5f5f8', display:'flex', justifyContent:'space-between'
                                            } : 
                                            {display:'flex', justifyContent:'space-between'}
                                        }
                                    >
                                        <span>
                                            Favourite Reports  
                                        </span>
                                        <span>
                                            <FontAwesomeIcon icon={faStar}/> 
                                        </span>
                                    </div>

                                    <div className='divider mb16' />

                                    
                                    {
                                        systemCategorisedReport?.map((category)=>{
                                            return(

                                                <div 
                                                    className={activeReportCat==category?._id? "setting-sidebar-nav popoverdataitem" : 'setting-sidebar-nav popoverdataitem'} 
                                                    onClick={()=>setActiveReportCat(category?._id)}
                                                    style={activeReportCat==category?._id ? {background:'#e5f5f8'} : {}}
                                                >
                                                        {category?.name}
                                                </div>

                                            )
                                        })
                                    }


                                    <div className='divider mb16' />

                                    
                                    <div className='setting-sidebar-nav popoverdataitem'>Custom reports</div>
                                    
                            
                                    
                                </Collapse>
                            </nav>

                        </div>
                    </div>
            </div>

            <div className='hr-section setting-body' style={{margin:'32px'}}>
            
                <div style={{paddingRight:'16px'}}>
                    <div className='hr-info-title'>
                        <div>{categorisedReport?.name || "Favourite Reports"}</div>
                        

                    </div>
                </div>


                <Table
                    className="history-table curvedTable mt16"
                    columns={columns}
                    loading={upsertSystemReportLoading || systemCategorisedReportLoading}
                    dataSource={dataSource}
                    locale={{emptyText: 'No report found'}}
                />
        
         
                            
            </div>
        </div>


        {
            reportFilterModal?.modal?
            <SystemReportFilterModal 

                visible={reportFilterModal?.modal}
                reportDetail={reportFilterModal?.reportDetail}
                reportFilter={reportFilterModal?.reportFilter}
                close={()=>{setReportFilterModal(null); dispatch(resetReportFilters());}}
                
            />
            : null
        }

    </div>

      
  )
}

export default SystemReport
