import './leftsidebar.css';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildingFlag, faEllipsisV, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { Popover, Skeleton } from 'antd';
import {faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { PhoneOutlined,  FormOutlined, MessageOutlined, UserAddOutlined } from '@ant-design/icons';
import { GET_BRANCHES,} from '@src/util/query/branch.query';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';import { getBranchDetailViewFieldQuery } from '@src/util/query/branchDetailViewField.query';
import { useSelector } from 'react-redux';
import { EditableAvatar } from '@src/components/avatar/editableAvatar';
import { updateEmployeeMutation } from '@src/util/mutation/employee.mutation';
import { updateBranchMutation } from '@src/util/mutation/branch.mutation';
import { GenerateFields } from '@src/util/generateFields/generateFields';
import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useDispatch } from 'react-redux';


export const DetailPageLeftSideBar = (
    {showDetail=true, refetch, saveUpdate, setSaveUpdate, setEdit, edit,
        setIsFieldChanged, singleBranchData, loading, handelInputChange})=>{
   
    const param = useParams();

    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);


    const [isAction, setAction] = useState(false);
    const containerRef = useRef(null);

    const userDetail = useSelector(state=>state?.userAuthReducer?.authenticatedUserDetail);
    
    const {data:branchDetailViewField, loading: getBranchDetailViewDataLoading, refetch: branchDetailViewRefetch} = useQuery(getBranchDetailViewFieldQuery,{
        variables:{
            userId: userDetail?._id
        },
        fetchPolicy: 'network-only'
    });


    const [branchSchema, setBranchSchema] = useState([]);
    useEffect(()=>{
        if(branchDetailViewField?.getBranchDetailViewField?.response){
            setBranchSchema(branchDetailViewField?.getBranchDetailViewField?.response?.map((object)=>({
                label: object?.propertyDetail?.label,
                name: object?.propertyDetail?.label?.toLowerCase().replace(/\s/g,""),
                fieldType: object?.propertyDetail?.fieldType,
                property: object,
            })));
        }           
    },[branchDetailViewField?.getBranchDetailViewField?.response]);



    
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setAction(false);
        }
      };
  
      document.addEventListener('click', handleOutsideClick);
  
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);

    const navigate = useNavigate();

    

    const { data: branchData, } = useQuery(GET_BRANCHES ,{
        fetchPolicy: 'cache-and-network',
        variables: {
            input: {
                filters: null
            }
        }
    });

    const [readonlyProp, setReadOnlyProp] = useState([]);

    useEffect(()=>{
        let readOnly = [];
        for (const key in authenticatedUserDetail?.permission?.Branch) {
            if (authenticatedUserDetail?.permission?.Branch.hasOwnProperty(key) && authenticatedUserDetail?.permission?.Branch[key].hasOwnProperty('edit')) {
                if(authenticatedUserDetail?.permission?.Branch[key]?.edit==0){

                    readOnly.push(key);
                    
                }
                
            }
        };
        setReadOnlyProp([...readOnly]);
       // console.log(Object.values(authenticatedUserDetail?.permission?.Employee), "authenticatedUserDetail");
    }, [authenticatedUserDetail]);
    // console.log(authenticatedUserDetail?.permission?.Employee, "authenticatedUserDetail", employeeObject);


    // branch multi select
        
  const inputRef = useRef(null);
  const [localGroup, setLocalGroup] = useState(branchData?.branches||[]);
  const [groupInput, setGroupInput] = useState();
  const [groupPopover, setGroupPopover] = useState(false);

  useEffect(()=>{
    if(branchData?.branches?.length>0){
        setLocalGroup(branchData?.branches);
    }
  },[branchData?.branches]);

  
  const [parentWidth, setParentWidth] = useState(null);
  const parentRef = useRef(null);

  const [tags, setTags] = useState([]);

  useEffect(() => {

    const updateParentWidth = () => {
      if (parentRef.current) {
        const width = parentRef.current.offsetWidth;
        setParentWidth(width);
      }
    };

    // Call the update function on initial mount and window resize
    updateParentWidth();
    window.addEventListener('resize', updateParentWidth);
    inputRef?.current?.focus();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };

  }, [groupPopover]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.group-wrapper')) {
        setGroupPopover(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

 
  useEffect(()=>{
    if(groupInput && !tags?.find((tag)=>tag?.name==groupInput?.name) && groupInput?.id!="dumy"){
        setTags([...tags, groupInput]);
        handelInputChange({name: "branch", value: [...tags, groupInput]});
    }
  }, [groupInput]);



  const [imageUrl, setImageUrl] = useState(null);
  const [updateEmployee, {loading: uploadLoading}]  = useMutation(updateEmployeeMutation);



    const handleUploadChange = ({ file }) => {
      if (file) {
          // Get this URL from response in real scenario
          const imageUrl = URL.createObjectURL(file.originFileObj);
          setImageUrl(imageUrl);
        

          var reader = new window.FileReader();
            reader.readAsDataURL(file.originFileObj); // Pass the file object here, not the URL
            reader.onloadend = async function () {
                const base64data = reader.result;
                await updateEmployee({
                    variables:{
                        input:{
                            _id: param?.id,
                            properties: [{
                                metadata: 1,
                                name:'imageURL',
                                value: base64data
                            }],

                        }
                    }
                });
            }

      }
    };

    
    const [field, setField] = useState([]);

    const handelDataValue = ({name, value})=>{
        if(name){
            if(value){
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value}])
                }
            }else{
                // setField(field?.filter(f=>f.name!==name));
                
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value:''
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value:''}])
                }
            }
        }
    }

    
    useEffect(()=>{
        if(Object.values(singleBranchData)?.length>0 && branchSchema?.length>0){
            const localFeed = branchSchema?.map((schema)=>{
                const {name} = schema;
                return {name, value: singleBranchData[name] || singleBranchData?.metadata[name]}
            });

            setField([...localFeed]);
        }
    },[branchSchema, singleBranchData]);

    useEffect(()=>{
        
        if(Object.values(singleBranchData)?.length>0 && branchSchema?.length>0 && edit){
            const localFeed = branchSchema?.map((schema, index)=>{

                return {name: branchSchema?.name, value: singleBranchData[schema?.name]}
            });

             const isEqual = localFeed.every((local)=>field.find((f)=> {
                if(f?.name==local?.name && ( Array.isArray(f?.value) || Array.isArray(local?.value) )){

                    if(f?.value?.length!=local?.value?.length){
                        return false;
                    }else{
                        return f?.value?.every((value, index)=> value==local?.value[index])
                    }

                }
                else if(f.name==local.name && f.value?.toLowerCase()==local.value?.toLowerCase()){
                    return true;
                }else{
                    return false;
                }
            }));

            setIsFieldChanged(!isEqual);
        }

    },[field]);

    const [updatebranch, {loading: updateBranchLoading}] = useMutation(updateBranchMutation);
    const dispatch = useDispatch();
    const handelUpdateBranch = async()=>{
        try{
            
            let schemaFields = [];

            field?.map((field)=>{
                if(field.name==="branchname" || field.name==="postcode" ){
                    schemaFields.push(field);
                }
                else{
                    schemaFields.push({...field, metadata:1})
                }
            });


            await updatebranch({
                variables:{
                    input:{
                        _id: param?.id,
                        properties: schemaFields
                    }
                }
            });
            setEdit(false);
            setSaveUpdate(false);
            await refetch();
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Branch  was updated successfully"
            }));
        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: err.message
            }));
        }   
    }

    useEffect(()=>{
        if(saveUpdate){
            handelUpdateBranch();
        }
    },[saveUpdate]);

    
    useEffect(()=>{
        setIsFieldChanged(false);
        setEdit(false);
    },[loading, updateBranchLoading, getBranchDetailViewDataLoading, singleBranchData]);

    return(
        <div className='sidebar-wrapper' >
            <div className='leftsidebar'>

                <div className='side-intro'>
                    {!loading || singleBranchData?
                    <>
                        
                        
                        <div className='text-head' style={{width:'100%', textAlign:'center'}}>
                            <div className='text-title' style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%', textAlign:'center'}}>

                                <span span style={{width:'100%'}}>
                                    {singleBranchData?.branchname}
                                </span>
                                <Popover
                                    overlayClassName='saveView'
                                    placement='bottom'
                                    content={
                                        <div className='popover-data'>

                                            <div className="popoverdataitem" onClick={()=>setEdit(!edit)}>
                                               {edit? "Cancel Edit" : "Edit"}
                                            </div>

                                            <div className="popoverdataitem" onClick={()=>navigate("/user/branch-detail/"+param?.id+"/edit")}>
                                                Customise View
                                            </div>
                                        </div>
                                    }
                                >
                                    <FontAwesomeIcon style={{cursor:'pointer'}} icon={faEllipsisV}/>
                                </Popover>
                            </div>

                            <div className='text-subtitle'>

                                <div style={{fontSize:'1em', marginBottom:'22px', marginTop:'10px'}}>
                                    <FontAwesomeIcon icon={faLocationDot}/> &nbsp; {singleBranchData?.postcode?.toUpperCase()} 
                                </div>   
                            
                                <div className="activity-btn-grp" style={{justifyContent:'center'}}>
                                    
                                    <Popover
                                        content={"Make a phone call"}
                                    >
                                        <span>
                                            <button>
                                                <PhoneOutlined />
                                            </button>
                                            <span className='tiny-text'>Call</span>
                                        </span>
                                    </Popover>

                                    
                                    <Popover
                                        content={"Start conversation"}
                                    >
                                        <span>
                                            <button>
                                                {/* <FontAwesomeIcon icon={faComment} /> */}
                                                <MessageOutlined/>
                                            </button>
                                            <span className='tiny-text'>Chat</span>
                                        </span>
                                    </Popover>


                                    <Popover
                                        content={"Create a note"}
                                    >
                                        <span>

                                            <button>
                                                <FormOutlined icon={faPenToSquare} />
                                            </button>
                                            <span className='tiny-text'>Note</span>
                                        </span>
                                    </Popover>


                                    
                                    <Popover content={"Follow this"} >
                                        <span>
                                            <button>
                                                {/* <FontAwesomeIcon icon={faUserPlus} /> */}
                                                <UserAddOutlined />
                                            </button>
                                            <span className='tiny-text'>Follow</span>
                                        </span>
                                    </Popover>
                                </div>

                            </div>
                        </div>
                    </>
                    : 
                    <div className='skeleton-custom'>

                    <Skeleton.Avatar active size={69} />
                    <Skeleton className='text-head' active/>
                    </div>
                    }
                </div>

                

                
            </div>
            
            <div className="btm-border"></div>
            
            {showDetail?
                    branchSchema?.map((schema)=>{

                            return(
                                    edit?
                                    <GenerateFields
                                        label = {schema?.label}
                                        name = {schema?.name}
                                        fieldType= {schema?.fieldType}
                                        handelDataValue = {handelDataValue}
                                        value={ field?.find((f)=>schema?.name==f.name)?.value }
                                        property = {schema?.property}
                                    />
                                    :
                                    <div className='fieldView'>
                                        <div>{schema?.label}</div>
                                        <div style={{textAlign:'right', textWrap:'pretty', paddingLeft:'1px'}}>
                                            {
                                            schema?.fieldType=="multiCheckbox"?
                                                singleBranchData[schema?.name?.toLowerCase().replace(/\s/g,"")]?.join(", ")
                                            : 
                                                singleBranchData?.hasOwnProperty(schema?.label?.replaceAll(" ","")?.toLowerCase())  || singleBranchData['metadata']?.hasOwnProperty(schema?.label?.replaceAll(" ","")?.toLowerCase())? 
                                                singleBranchData[schema?.label?.replaceAll(" ","")?.toLowerCase()] || singleBranchData['metadata'][schema?.label?.replaceAll(" ","")?.toLowerCase()] : ""
                                            }
                                        </div>
                                    </div>
            
                            )
                        
                    }):
                    null
                }

               
        </div>
    );
}