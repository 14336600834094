import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { getEmployeePayHourReportMutation } from "@src/util/mutation/systemReport.mutation";
import Spinner from "@src/components/spinner";
import { useSelector } from "react-redux";
import { saveAs } from 'file-saver';
import Papa from "papaparse";
import { CaretDownFilled } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


// to do holiday bill and hour
// if first date is in holiday then first date hour and pay and bill will goes to holiday section and regular would be zero
// vice versa for second date as well
// Bank Holiday 65ff5efdcdef94de7cabc3cb

export const SystemReportEmployeePaySummaryView = () => {
    
    const location = useLocation();
    const {filters = {}, filterContent = '', reportDetail = {}} = location?.state || {};
    
    
    const [reportData, setReportData] = useState([]);
    const navigate = useNavigate();

    const [getEmployeePayHourReport, {loading: getPayBillReportLoading}] = useMutation(getEmployeePayHourReportMutation);

    const handelPayBillReport = async ()=>{
        try{
            if(filters?.conditions){

                const {data:{getEmployeePayHourReport: {response}}} = await getEmployeePayHourReport({
                    variables:{
                        filters: {conditions: filters.conditions[0]!=null? filters.conditions: [], date: {from: dayjs(filters?.dates?.from, "DD/MM/YYYY"), to: dayjs(filters?.dates?.to, "DD/MM/YYYY")}}
                    }
                });

                if(response?.length==0){
                    alert("There is no pay/bill calculated in the selected dates");
                    navigate(-1);
                }else{
                    setReportData([...response]);
                }

            }
        }catch(err){
            console.log(err);
        }
    };

    useEffect(()=>{
        if(filters){

            handelPayBillReport();

        }
    },[filters]);

    const exportToCSV = ()=>{

        const groupedData = groupByMultipleKeys(reportData, ["assignedEmployee"]);
        let csvData = [];

        Object.keys(groupedData)?.map((group)=>{
            groupedData[group]?.map((job)=>{
                
                const response = {
                    
                    "Branch": job?.branchDetail?.branchname,
                    
                    "Site": job?.siteDetail?.sitename,

                    "Employee": job?.employeeDetail?.firstname+" "+(job?.employeeDetail?.lastname || ""),

                    "Hour Type": job?.hourTypeDetail?.name,

                    "Day": dayjs(job?.date, "DD/MM/YYYY").format("dddd"),

                    "Start": dayjs(job?.firstDate)?.format("DD/MM/YYYY HH:mm"),

                    "Finish": dayjs(job?.shiftEndDateTime)?.format("DD/MM/YYYY HH:mm"),

                    "Rate": `£ ${job?.paybillDetail?.firstDatePay}`,

                    "Hour / Shifts": (parseFloat(job?.payFirstDateHour) + parseFloat(job?.paySecondDateHour)).toFixed(2),

                    "Total": `£ ${parseFloat(job?.paybillDetail?.totalPay).toFixed(2)}`
                };

                csvData.push(response);

            });
        });

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const name = reportDetail?.name+" - "+dayjs().format("DD/MM/YYYY");
        saveAs(blob, `${name}.csv`);

    };

    const groupByMultipleKeys = (data, keys) => {
        if (!keys.length) return data; // If no keys are provided, return original data
      
        return data.reduce((acc, item) => {
          let currentLevel = acc;
      
          // Iterate through keys and build nested structure
          keys.forEach((key, index) => {

            let keyValue = "Unknown";

            if(key=="branchId"){
                keyValue = item[key]+"_"+item['branchDetail']['branchname'].replaceAll(" ", "-")+"_branch";
            }
            else if(key=="siteId"){
                keyValue = item[key]+"_"+item['siteDetail']['sitename'].replaceAll(" ", "-")+"_site";
            }
            else if(key=="assignedEmployee"){
                keyValue = item[key]+"_"+(item['employeeDetail']['firstname']+" "+(item['employeeDetail']['lastname'] || "")).replaceAll(" ", "-")+"_employee";
            }
      
            if (!currentLevel[keyValue]) {
              currentLevel[keyValue] = index === keys.length - 1 ? [] : {}; // Last level stores array, others are objects
            }
      
            // Move to the next nested level
            currentLevel = currentLevel[keyValue];
          });
      
          // Push item to the last nested level
          currentLevel.push(item);
          return acc;
        }, {});
    };

    const [groupedData, setGroupedData] = useState([]);

    const groupAndSum = (data, keys) => {
        if (!keys.length) return data; // If no keys are provided, return original data
    
        return data.reduce((acc, item) => {
            let currentLevel = acc;
    
            // Build dynamic keys for grouping
            keys.forEach((key, index) => {
                let keyValue = "Unknown";
    
                if (key === "branchId") {
                    keyValue = `${item[key]}_${item.branchDetail.branchname.replaceAll(" ", "-")}_branch`;
                } else if (key === "siteId") {
                    keyValue = `${item[key]}_${item.siteDetail.sitename.replaceAll(" ", "-")}_site`;
                } else if (key === "assignedEmployee") {
                    keyValue = `${item[key]}_${((item.employeeDetail?.firstname) +" "+(item.employeeDetail?.lastname || "")).replaceAll(" ", "-")}_employee`;
                    
                }
    
                // If last level, store an array
                if (!currentLevel[keyValue]) {
                    currentLevel[keyValue] = index === keys.length - 1 ? {  sums: {} } : {};
                }
    
                // Move to the next nested level
                currentLevel = currentLevel[keyValue];
            });
    
    
            // Define properties to sum
            const sumProperties = [
                "totalPay", "totalBill",
                "payFirstDateHour", "paySecondDateHour",
                "billFirstDateHour", "billSecondDateHour",
                "payPremium", "billPremium", "isPayFirstDateInBankHoilday", 
                "isPaySecondDateInBankHoilday", "isBillFirstDateInBankHoilday", 
                "isBillSecondDateInBankHoilday"
            ];
    
            // Initialize sums object if not present
            if (Object.keys(currentLevel.sums)?.length==0) {
                currentLevel.sums = {};
                sumProperties.map(prop => /Hoilday/i.test(prop?.toLowerCase()) ? currentLevel.sums[prop] = false : currentLevel.sums[prop] = 0);
               

            }
            
            // Sum up required fields
            sumProperties.forEach(prop => { 

                const value = 
                prop=="payPremium"?
                    item?.paybillDetail?.payBillPremium[0] && item?.paybillDetail?.payBillPremium[0]?.length>0?
                        parseFloat( item?.paybillDetail?.payBillPremium[0][0]?.payPremium?.totalPremiumPay ) || 0.00
                    :
                    0.00
                :
                prop=="billPremium"?
                    item?.paybillDetail?.payBillPremium[0] && item?.paybillDetail?.payBillPremium[0]?.length>0?
                        parseFloat( item?.paybillDetail?.payBillPremium[0][0]?.billPremium?.totalPremiumBill ) || 0.00
                    :
                    0.00
                :
                /Hoilday/i.test(prop?.toLowerCase()) ?
                    prop=="isPayFirstDateInBankHoilday"?
                        parseFloat(item['payFirstDateHour'])
                    :
                    prop=="isPaySecondDateInBankHoilday"?
                        parseFloat(item['paySecondDateHour'])
                    :
                    prop=="isBillFirstDateInBankHoilday"?
                        parseFloat(item['isBillFirstDateInBankHoilday'])
                    :
                    prop=="isBillSecondDateInBankHoilday"?
                        parseFloat(item['isBillSecondDateInBankHoilday'])

                    : 0.00
                :
                parseFloat(item.paybillDetail[prop]) || parseFloat(item[prop]) || 0.00; // Convert to number safely
                currentLevel.sums[prop] += value;
            });

    
            return acc;
        }, {});
    };
    
    const [totalBranch, setTotalBranch] = useState({});
    const [totalSite, setTotalSite] = useState({});
    const [totalEmployee, setTotalEmployee] = useState({});

    useEffect(()=>{
        if(reportData?.length>0 && !getPayBillReportLoading){
            // ["customerId", "siteGroupId", "siteId"]
            const groupedData = groupByMultipleKeys(reportData, ["branchId", "siteId", 'assignedEmployee']);
            setGroupedData(groupedData);
            setTotalBranch( groupAndSum(reportData, ["branchId"]) );
            setTotalSite( groupAndSum(reportData, ["siteId"]) );
            setTotalEmployee( groupAndSum(reportData, ["assignedEmployee"]) );

        }
    },[reportData, getPayBillReportLoading]);

    

    const convertToHrsMin = (decimalHours) => {
        const hours = Math.floor(decimalHours); // Extract hours
        const minutes = Math.round((decimalHours - hours) * 60); // Convert decimal to minutes
    
        // Format hours and minutes with leading zero
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
    
        return `${formattedHours} hrs ${formattedMinutes} m`;
    }
    


    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);

    const exportToPdf = ()=>{

        window.print();
        
        

    };


    useEffect(()=>{
        document.title = reportDetail?.name+" "+dayjs().format("DD/MM/YYYY");

        return ()=>{
            document.title = "WFC";
        }
    },[]);


    const menu = (
        <Menu>
          <Menu.Item key="1" onClick={exportToCSV}>Export CSV</Menu.Item>
          <Menu.Item key="2" onClick={exportToPdf} >Print Report</Menu.Item>
        </Menu>
    );


    return (
        <div style={{padding: "15px", zoom: "1.15"}} id="pdfContent">

            <div className="reportsHeaderCss">

                <div style={{display: "flex", justifyContent: "space-between"}}>
                   <span style={{textTransform:'capitalize', fontWeight: "bold", fontSize: "20px"}}>{reportDetail?.name}</span> 
                   <div id="pdfexportBtn">
                        
                        <Dropdown overlay={menu}>
                            <Button style={{fontSize:'12px'}}>
                                Exports <CaretDownFilled />
                            </Button>
                        </Dropdown>

                        
                        


                        <div className='close-link' style={{marginRight:'-20px'}} onClick={()=>navigate(-1)}>
                            <span >Close Report</span> <FontAwesomeIcon className='icon' icon={faTimes}/>
                        </div>

                    </div>
                   
                </div>




                <div className="row-flex" style={{gap: "30px", marginBottom: "6px", marginTop:'12px'}}>
                    <div style={{fontWeight: "bold"}}> Report ID : {reportDetail?._id}</div>
                    <div style={{fontWeight: "bold"}}> Run Date : &nbsp;
                        <span className="text-muted" style={{fontWeight: "normal"}}>
                            {dayjs().format("DD/MM/YYYY HH:mm")} by {authenticatedUserDetail?.employeeDetail[0]?.firstname +" "+(authenticatedUserDetail?.employeeDetail[0]?.lastname || "")}
                        </span>
                    </div>
                </div>

                <div className="text-muted pt20">
                    <span>Date Range Report using dates between </span>
                    <span style={{padding: "0px 2px"}}>
                        {dayjs(filters?.dates?.from, "DD/MM/YYYY").format("ddd DD MMM YYYY HH:mm")}
                    </span>
                    and
                    <span style={{padding: "0px 2px"}}>
                        {dayjs(filters?.dates?.to, "DD/MM/YYYY").format("ddd DD MMM YYYY HH:mm")}
                    </span>
                </div>

                <div style={{display: "flex", width: "100%", marginTop: "6px"}}>
                    <div style={{width: "50px"}}>Filters :</div>
                    <div style={{width: "calc(100% - 110px)", paddingLeft: "10px", textAlign:'left', fontStyle:'oblique'}}
                        dangerouslySetInnerHTML={{__html: filterContent}}
                    >  
                      
                    </div>
                </div>

            </div>

            <div className="mt32"></div>


            <table id="payBillPDF" className="payBillMainTable">
                <tbody>
                    <tr className="payBillHeaderCss">
                        <th colspan="4">
                            Hour Type
                        </th>
                        <th colspan="2">Site Name</th>
                        <th colspan="2">Day</th>
                        <th colspan="2">Start</th>
                        <th colspan="2">Finish</th>
                        <th colspan="2">Regular Rate</th>
                        <th colspan="2">Bank Holiday Rate</th>
                        <th colspan="2">Overtime Rate</th>
                        <th colspan="2">Break Duration / Rate</th>

                        <th colspan="2">Hours / Shifts</th>
                        <th colspan="2">Total</th>

                    </tr>
                    
                   {
                    getPayBillReportLoading?
                        <tr>
                            <td colSpan={22} style={{textAlign: 'center'}}><Spinner /></td>
                        </tr>
                    :
                    Object.keys(groupedData)?.map((key)=>{

                        const branchTotal = totalBranch[key]?.sums;
                        return(
                            <>
                                {
                                    filters?.header?.groupbyBranch &&
                                    <tr className="customerCss">
                                        <td colspan="1">Branch: </td>
                                        <td colspan="3">{key?.split("_")[1]?.replaceAll("-", " ")}</td>
                                    </tr>
                                }
                                {
                                    // Site rendering
                                    Object.keys(groupedData[key])?.map((secKey)=>{
                                        const siteTotal = totalSite[secKey]?.sums;
                                        return(
                                            <>
                                            {
                                                filters?.header?.groupbySite &&
                                                <tr className="siteGroupCss">
                                                    <td colspan="1">Site:  </td>
                                                    <td colspan="3">{secKey?.split("_")[1]?.replaceAll("-", " ")}</td>
                                                </tr>
                                            }


                                                {/* employee rendering */}
                                                { Object.keys(groupedData[key][secKey])?.map((tkey)=>{
                                                    const employeeTotal = totalEmployee[tkey]?.sums;
                                                    return(
                                                        <>
                                                            {
                                                                filters?.header?.groupbyEmployee &&

                                                                <tr className="siteCss">
                                                                    <td colspan="1">Employee: </td>
                                                                    <td colspan="3">{tkey?.split("_")[1]?.replaceAll("-", " ")}</td>
                                                                </tr>
                                                            }



                                                            <tr>
                                                                <td colspan="24">
                                                                    <table className="payBillSitePayTable">
                                                                        <tbody>
                                                                        {
                                                                            groupedData[key][secKey][tkey]?.map((job)=>{
                                                                                
                                                                                return(
                                                                            
                                                                                    <tr className="siteBillCss">
                                                                                        {/* hour name */}
                                                                                        <td colspan="2">{job?.hourTypeDetail?.name}</td>
                                                                                        <td colspan="">{job?.siteDetail?.sitename}</td>
                                                                                        
                                                                                        {/* day */}
                                                                                        <td  colspan=""> 

                                                                                            {dayjs(job?.date, "DD/MM/YYYY").format("dddd")}

                                                                                        </td>
                                                                                        
                                                                                        {/* shift start time */}
                                                                                        <td>
                                                                                            {dayjs(job?.firstDate)?.format("DD/MM/YYYY HH:mm")}
                                                                                        </td>
                                                                                        
                                                                                        {/* shift end time */}
                                                                                        <td>
                                                                                            {dayjs(job?.shiftEndDateTime)?.format("DD/MM/YYYY HH:mm")}
                                                                                
                                                                                        </td>
                                                                                        
                                                                                        {/* regular rate */}
                                                                                        <td>
                                                                                            {
                                                                                                (!job?.isPayFirstDateInBankHoilday && !job?.isPaySecondDateInBankHoilday)?
                                                
                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                "£ "+ ( parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)  + parseFloat(job?.paybillDetail?.unpaidBreakAmount) ).toFixed(2)
                                                                                                :
                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)).toFixed(2)


                                                                                                : !job?.isPayFirstDateInBankHoilday?
                                                                                                    job?.paybillDetail?.payFirstDateHour>0?
                                                                                                    "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.unpaidBreakAmount)).toFixed(2)
                                                                                                    
                                                                                                    :
                                                                                                    "£ "+ parseFloat(job?.paybillDetail?.firstDatePay).toFixed(2)

                                                                                                : !job?.isPaySecondDateInBankHoilday?
                                                                                                "£ "+ parseFloat(job?.paybillDetail?.secondDatePay).toFixed(2)

                                                                                                : "£ 0.00"
                                                                                                
                                                                                            }
                                                                                        </td>

                                                                                        {/* Bank holiday rate */}

                                                                                        <td>
                                                                                            {

                                                                                                (job?.isPayFirstDateInBankHoilday && job?.isPaySecondDateInBankHoilday)?
                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                "£ "+ ( parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)  + parseFloat(job?.paybillDetail?.unpaidBreakAmount) ).toFixed(2)
                                                                                                :
                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.secondDatePay)).toFixed(2)

                                                                                                : job?.isPayFirstDateInBankHoilday?
                                                                                                job?.paybillDetail?.payFirstDateHour>0?
                                                                                                "£ "+ (parseFloat(job?.paybillDetail?.firstDatePay) + parseFloat(job?.paybillDetail?.unpaidBreakAmount)).toFixed(2)
                                                                                                :
                                                                                                "£ "+ parseFloat(job?.paybillDetail?.firstDatePay).toFixed(2)


                                                                                                : job?.isPaySecondDateInBankHoilday?
                                                                                                "£ "+ parseFloat(job?.paybillDetail?.secondDatePay).toFixed(2)

                                                                                                : "£ 0.00"

                                                                                            }
                                                                                        </td>
                                                                                        
                                                                                        {/* overtime rate */}

                                                                                        <td>
                                                                                            { 

                                                                                                job?.paybillDetail?.payOverTimeHour && job?.paybillDetail?.payOverTimeHour>0?
                                                                                                job?.paybillDetail?.payFirstDateHour==0?
                                                                                                "£ "+(parseFloat(job?.paybillDetail?.overTimePay) + parseFloat(job?.paybillDetail?.unpaidBreakAmount)).toFixed(2)
                                                                                                :
                                                                                                "£ "+parseFloat(job?.paybillDetail?.overTimePay).toFixed(2)

                                                                                                : "£ 0.00"
                                                                                        
                                                                                            }
                                                                                        </td>
                                                                                        
                                                                                        <td>
                                                                                            {
                                                                                                !Number.isNaN(parseFloat(job?.unPaidBreakDuration))?
                                                                                                <>
                                                                                                    -  { convertToHrsMin(parseFloat(job?.unPaidBreakDuration)/60) } <br/>
                                                                                                    
                                                                                                    - £ {parseFloat(job?.paybillDetail?.unpaidBreakAmount).toFixed(2)}

                                                                                                
                                                                                                </>
                                                                                                :
                                                                                                convertToHrsMin(0)
                                                                                            }
                                                                                        </td>

                                                                                        {/* total hour */}
                                                                                        <td> {(parseFloat(job?.payFirstDateHour) + parseFloat(job?.paySecondDateHour)).toFixed(2)} </td>
                                                                                        
                                                                                        {/* total pay */}

                                                                                        <td>£ {parseFloat(job?.paybillDetail?.totalPay).toFixed(2)}</td>

                                                                                    </tr>

                                                                                )
                                                                            })
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>

                                                            {
                                            
                                                                filters?.header?.groupbyEmployee &&
                                                                <>
                                                                    <tr style={{height: "0px"}}>
                                                                        <td colspan="2"></td><td colspan="22" style={{borderBottom: "2px solid black"}}></td>
                                                                    </tr>
                                                                    

                                                                    {/* Employee total */}
                                                                    <tr>

                                                                        <td colspan="24">
                                                                            <table className="payBillSiteCardTable">
                                                                                <thead>
                                                                                    <tr className="titleCss">
                                                                                        <th colSpan={18}></th>
                                                                                        <th colSpan={2}>Employee Totals:</th>
                                                                                        <th colSpan={2}>Total Hours / Shifts</th>
                                                                                        <th colSpan={2}>Total </th>

                                                                                    </tr>
                                                                                </thead>

                                                                                <tbody>
                                                                                    <tr className="row1">
                                                                                        <td colSpan={20}></td>
                                                                                        <td colSpan={2}>
                                                                                            {convertToHrsMin(parseFloat(employeeTotal?.payFirstDateHour) + parseFloat(employeeTotal?.paySecondDateHour))} 
                                                                                        </td>

                                                                                        <td colSpan={2}>
                                                                                            £ {parseFloat(employeeTotal?.totalPay)?.toFixed(2)}
                                                                                        </td>


                                                                                        
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </>

                                                            }

                                                        </>
                                                    )}
                                                )}


                                                {
                                                    filters?.header?.groupbySite &&
                                                    <>
                                                        <tr style={{height: "0px"}}>
                                                            <td colspan="2"></td><td colspan="22" style={{borderBottom: "2px solid black"}}></td>
                                                        </tr>
                                                        

                                                        {/* Site total */}
                                                        <tr>

                                                            <td colspan="24">
                                                                <table className="payBillSiteCardTable">
                                                                    <thead>
                                                                        <tr className="titleCss">
                                                                            <th colSpan={18}></th>
                                                                            <th colSpan={2}>Site Totals:</th>
                                                                            <th colSpan={2}>Total Hours / Shifts</th>
                                                                            <th colSpan={2}>Total </th>

                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr className="row1">
                                                                            <td colSpan={20}></td>
                                                                            <td colSpan={2}>
                                                                                {convertToHrsMin(parseFloat(siteTotal?.payFirstDateHour) + parseFloat(siteTotal?.paySecondDateHour))} 
                                                                            </td>

                                                                            <td colSpan={2}>
                                                                                £ {parseFloat(siteTotal?.totalPay)?.toFixed(2)}
                                                                            </td>


                                                                            
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </>
                                                }


                                            </>

                                        )
                                    })
                                }


                                {
                                    filters?.header?.groupbyBranch &&
                                    <>
                                        <tr style={{height: "0px"}}>
                                            <td colspan="2"></td><td colspan="22" style={{borderBottom: "2px solid black"}}></td>
                                        </tr>
                                        

                                        {/* branch total */}
                                        <tr>

                                            <td colspan="24">
                                                <table className="payBillSiteCardTable">
                                                    <thead>
                                                        <tr className="titleCss">
                                                            <th colSpan={18}></th>
                                                            <th colSpan={2}>Branch Totals:</th>
                                                            <th colSpan={2}>Total Hours / Shifts</th>
                                                            <th colSpan={2}>Total </th>

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr className="row1">
                                                            <td colSpan={20}></td>
                                                            <td colSpan={2}>
                                                                {convertToHrsMin(parseFloat(branchTotal?.payFirstDateHour) + parseFloat(branchTotal?.paySecondDateHour))} 
                                                            </td>

                                                            <td colSpan={2}>
                                                                £ {parseFloat(branchTotal?.totalPay)?.toFixed(2)}
                                                            </td>


                                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </>
                                }


                            </>
                        )
                    })
                   }


                
                </tbody>
            </table>

        </div>
    );

};