import { setNotification } from '@src/middleware/redux/reducers/notification.reducer';
import { useMutation, useQuery } from "@apollo/client";
import { getReportTemplateById } from "@src/util/query/reportTemplate.query";
import { useEffect, useState } from "react";
import { GenerateNormalFields } from "@src/util/generateFields/generateNormalField";
import { lookupTypes } from '@src/util/types/lookup.types';
import { CreateFormLookUpFields } from '@src/util/generateFields/createFormlookupFields';
import Spinner from "@src/components/spinner";
import { useDispatch } from "react-redux";
import { createScheduleReportMutation } from '@src/util/mutation/scheduleReport.mutation';
import { getScheduleReportByJobIdQuery } from '@src/util/query/scheduleReport.query';
import { updateScheduleReportMutation } from '@src/util/mutation/scheduleReport.mutation';
import { Alert, Form, message, Modal, notification, Table, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { UpdateScheduleByField } from '@src/util/mutation/schedule.mutation';
import { genericFileUploadSignatureMutation } from '@src/util/mutation/genericFileUpload.mutation';
import axios from 'axios';
import { FileFormatTypes } from '@src/util/fileFormatTypes';
import TabPane from 'antd/es/tabs/TabPane';
import { Confirmation } from '../../components/confirmation/confirmation.modal';
import { newReportReminderMutation } from '@src/util/mutation/reportReminder.mutation';
import dayjs from 'dayjs';
import { getReportReminderByScheduleIdQuery } from '@src/util/query/reportReminder.query';
import { submitReportByEmailMutation } from '@src/util/mutation/scheduleReport.mutation';
import { getScheduleReportBySiteandTemplateIdQuery } from '@src/util/query/scheduleReport.query';
import { useNavigate } from 'react-router-dom';
import { dutyReportToTemplateMapping } from '../../util/mobileDutyReportToTemplateMapping/mobileDutyReportToTemplateMapping';



export const TimeLineReport = ({reportStandardData, refetch, reportModalVisible, reportModalClose})=>{
    console.log(reportStandardData, "reportStandardData");
    const [editData, setEditData] = useState({});
    
    useEffect(()=>{
        if(reportStandardData){
            let reportEditData ={}
            reportEditData['jobId'] = reportStandardData?.scheduleId;
            // site name and siteId
            reportEditData['sitename'] = reportStandardData?.siteDetail[0]?.sitename;
            reportEditData ['_id'] = reportStandardData?.siteId; 

            // selected area
            reportEditData['selectedarea'] = {_id: reportStandardData?.scheduleDetail[0]?.siteAreaId};
            reportEditData['customerDetail'] = reportStandardData?.customerDetail[0];
            setEditData(reportEditData)
        }
    },[reportStandardData]);

    const templateId = reportStandardData?.scheduleDetail[0]?.reportTemplateId;


    const {data, loading} = useQuery(getReportTemplateById,{
        variables:{
            templateId
        },
        fetchPolicy:'network-only',
        skip: !templateId
    });

    const {data: reportData, loading: reportDataLoading, refetch: reportDataRefetch} = useQuery(getScheduleReportByJobIdQuery,{
        variables:{
            jobId: editData?.jobId
        },
        skip: !editData?.jobId,
        fetchPolicy:'network-only'
    });

    const {data: lastReportData, loading:  lastReportDataLoading,} = useQuery(getScheduleReportBySiteandTemplateIdQuery,{
        variables:{

            siteId: reportStandardData?.siteId,
            templateId: templateId,

        },
        skip: !templateId || !reportStandardData?.siteId,
        fetchPolicy:'network-only'
    });

    
    const [reportFields, setReportFields] = useState([]);
    const [field, setField] = useState([]);

    useEffect(()=>{
        if(!loading && !lastReportDataLoading){
            if(
                (reportData?.getScheduleReportByJobId?.response && Object.keys(reportData?.getScheduleReportByJobId?.response)?.length>0) ||
                (lastReportData?.getScheduleReportBySiteandTemplateId?.response && Object.keys(lastReportData?.getScheduleReportBySiteandTemplateId?.response)?.length>0)
            ){
                const reportFormData = reportData?.getScheduleReportByJobId?.response?.reportDetail;
                if(reportFormData){
                    setField([...Object.keys(reportFormData)?.map((report)=>{
                        return({
                            name: report,
                            value: reportFormData[report],
                            type: reportFormData[report]?.includes(".")? 
                            FileFormatTypes.includes(reportFormData[report]?.split(".")[1])? 
                            FileFormatTypes?.find((format)=>format==reportFormData[report]?.split(".")[1])
                            : null : null,

                        })
                    })]);    
                }
                // if there is not this job data extract the previouse report if any
                
                else if(lastReportData?.getScheduleReportBySiteandTemplateId?.response?.hasOwnProperty("reportDetail")){
                
                    const reportFormData = lastReportData?.getScheduleReportBySiteandTemplateId?.response?.reportDetail;

                    setField([...Object.keys(reportFormData)?.map((report)=>{
                        return({
                            name: report,
                            value: reportFormData[report],
                            type: null

                        })
                    })]);    
                }
                
            }else{
                setField([...field, {name:'sitename', value:editData?.sitename}, {name:'siteid', value:editData?._id}]);
            }
        }
    },[reportData?.getScheduleReportByJobId?.response, editData, loading, lastReportDataLoading]);


    useEffect(()=>{
        if(data?.getReportTemplateById?.response?.template?.rawReportFields){
            setReportFields([...data?.getReportTemplateById?.response?.template?.rawReportFields]);
        }
    },[data?.getReportTemplateById?.response?.template?.rawReportFields]);

    
    
    const handelDataValue = ({name, value})=>{
        if(name){
            if(value){
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value}])
                }
            }else{
                const isExist = field?.find((f)=>f.name==name);
                if(isExist){
                    setField(field?.map((f)=>{
                        if(f.name==name){
                            return {
                                ...f,
                                value:''
                            }
                        }else{
                            return f;
                        }
                    }))
                }else{
                    setField([...field, {name, value:''}])
                }
            }
        }
    };


    const [groupInput, setGroupInput] = useState();
    
    const dispatch = useDispatch();
    const [newScheduleReport, {loading: newScheduleReportLoading}] = useMutation(createScheduleReportMutation)
    const [updateScheduleReport, {loading: updateScheduleReportLoading}] = useMutation(updateScheduleReportMutation)

    const [confirmationModal, setConfirmationModal] = useState(false);

    const [updateScheduleByFieldMutation, {loading: updateScheduleLoading}] = useMutation(UpdateScheduleByField)

    const [genericFileUploadSignature, {loading: genericFileUploadSignatureLoading}] = useMutation(genericFileUploadSignatureMutation);
    const [fileLoading, setFileLoading] = useState(false);

    const handelSubmit = async (isSubmitted=false)=>{
        try{
            setConfirmationModal(false);
            const filesName = field.filter((f)=>f?.value?.size)?.map((file)=>file?.value?.name);

            if (filesName?.length>0){

                setFileLoading(true);
                


                await field.filter((f)=>f?.value?.size)?.map(async(file)=>{
                    
                    const fileUploadUrlsResponse = await genericFileUploadSignature({
                        variables:{
                            input:{
                                fileName : "dutyreport/"+file?.value?.name,
                                objectType: "dutyreport"
                            }
                        }
                    });
    
                    const fileUploadUrls = fileUploadUrlsResponse?.data?.genericFileUploadSignatureUrl?.response?.url;
                    
                    await axios.put(fileUploadUrls, file?.value,{
                        headers: {
                            'Content-Type': file?.value?.type ,
                        },
                    });


                })

            }


            let fieldData= {};
            field?.map((f)=>{fieldData[f.name]=f?.value?.name? "dutyreport/"+f?.value?.name: f.value});
            setFileLoading(false);

            if(reportData?.getScheduleReportByJobId?.response?._id){
                await updateScheduleReport({
                    variables:{
                        input:{
                            _id: reportData?.getScheduleReportByJobId?.response?._id,
                            reportDetail: fieldData,
                            isSubmitted,
                        }
                    }
                });
                dispatch(
                    setNotification({
                        error: false,
                        notificationState: true,
                        message: "Report was updated successfully"
                    })
                );
            }else{

                await newScheduleReport({
                    variables:{
                        input: {
                            jobId: editData?.jobId,
                            areaId: editData?.selectedarea?._id,
                            siteId: editData?._id,
                            templateId: templateId,
                            customerId: editData?.customerDetail?._id,
                            reportDetail: fieldData,
                            isSubmitted
                        }
                    }
                });
                dispatch(
                    setNotification({
                        error: false,
                        notificationState: true,
                        message: "Report was saved successfully"
                    })
                );
            }
            await reportDataRefetch();

            // update report submitted report in schedule 
            if(isSubmitted){
                await updateScheduleByFieldMutation({
                    variables:{
                        input:{
                            _id: editData?.jobId,
                            field: "isReportSubmitted",
                            value: true
                        }
                    }
                });
                await refetch();
            }

        }catch(err){
            dispatch(
                setNotification({
                    error: true,
                    notificationState: true,
                    message: err.message
                })
            );
        }
    };


    const [activeTab, setActiveTab] = useState("1");

    // report reminder segment
    const [reminderModal, setReminderModal] = useState(false);

    const [newReportReminder, {loading:newReportReminderLoading}] = useMutation(newReportReminderMutation);

    const {data: reportReminderData, loading: reportReminderDataLoading, refetch: reportReminderRefetch} = useQuery(getReportReminderByScheduleIdQuery,{
        variables:{
            scheduleId: reportStandardData?.scheduleId
        },
        fetchPolicy:'network-only'
    });

    // reminder data
    const columns = [
        {id:0, title:'Customer', dataIndex:'customer'},
        {id:0, title:'Agency', dataIndex:'agency'},
        {id:0, title:'Email', dataIndex:'email'},
        {id:0, title:'Sent at', dataIndex:'sentat'},
        {id:0, title:'Sent By', dataIndex:'sentby'},
    ];


    const [reportReminderDataSource, setReportReminderDataSource] = useState([]);

    useEffect(()=>{
        setReportReminderDataSource(reportReminderData?.getReportReminderByScheduleId?.response?.map((reminder)=>({
            email: reminder?.email || "--",
            customer: reminder?.customerDetail?.customername || "--",
            agency: reminder?.agencyDetail?.suppliername || "--",
            sentat: dayjs(reminder?.sentAt).format("DD/MM/YYYY HH:mm"),
            sentby: reminder?.sentByDetail?.firstname+" "+ (reminder?.sentByDetail?.lastname || "")

        })));
    }, [reportReminderData?.getReportReminderByScheduleId?.response]);

    const handelReportReminder = async ()=>{
        try{

            if(reportStandardData?.agencyDetail?.metadata?.email?.length>5 || reportStandardData?.customerDetail?.metadata?.email?.length>5){
                const reportReminderInput = {
                    scheduleId: reportStandardData?.scheduleId,
                    assignedEmployeeId: reportStandardData?.employeeDetail?._id,
                    customerId: reportStandardData?.customerDetail?._id || null,
                    agencyId: reportStandardData?.agencyDetail?._id || null,
                    email: reportStandardData?.agencyDetail?.metadata?.email || reportStandardData?.customerDetail?.metadata?.email,
                    sentAt: dayjs(),
                }
    
                await newReportReminder({
                    variables:{
                        input: reportReminderInput
                    }
                });
                
                setReminderModal(false);
    
                dispatch(setNotification({
                    error: false,
                    notificationState: true,
                    message: 'Report reminder sent successfully'
                }));
    
                await reportReminderRefetch();
            }else{
                dispatch(setNotification({
                    error: true,
                    notificationState: true,
                    message: "Please add email first for agency or customer"
                }));
            }


        }catch(err){
            dispatch(setNotification({
                error: true,
                notificationState: true,
                message:"An error encountered while sending the report reminder"
            }));
        }
    }

    // set report send by email from btn
    const [submitReportByEmail, {loading: submitReportByEmailLoading}] = useMutation(submitReportByEmailMutation);

    const handelSubmitReportByEmail = async(scheduleId)=>{
        try{

            await submitReportByEmail({
                variables:{
                    scheduleId
                }
            });
            
            dispatch(setNotification({
                error: false,
                notificationState: true,
                message: "Report submission marked as submitted by email"
            }));
            await refetch();
            await reportDataRefetch();

        }catch(err){

            dispatch(setNotification({
                error: true,
                notificationState: true,
                message: "An error was encountered while marking submission"
            }));

        }
    }

    const navigate = useNavigate();
    // isSubmittedByEmail
    // isSubmitted
    useEffect(()=>{
        if(reportData?.getScheduleReportByJobId?.response?.isSubmitted && !reportData?.getScheduleReportByJobId?.response?.isSubmittedByEmail){
            if(dutyReportToTemplateMapping['wardSecurity']?.templateId==templateId){
                navigate(dutyReportToTemplateMapping[wardSecurity]?.route+scheduleId);
            }
        }
    },[reportData?.getScheduleReportByJobId?.response]);

    return(
        <Modal
            open={reportModalVisible}
            width={'100%'}
            height="100vh"
            className='createUserModal editDutyModalWrapper'
            footer = {
                <div 
                    className="drawer-footer border-radius-bottom"
                    style={
                        loading? 
                        {display:'flex', alignItems:'right', justifyContent:'space-between', margin:'-10px', marginTop:'30px', opacity:0.1}
                        :{display:'flex', alignItems:'right', justifyContent:'space-between', margin:'-10px', marginTop:'30px'}
                    }

                >

                    <div>
                        <button disabled={newScheduleReportLoading || updateScheduleReportLoading}  className={newScheduleReportLoading || updateScheduleReportLoading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={reportModalClose}>
                            Close
                        </button>
                        &emsp;
                        {activeTab==1 && !reportData?.getScheduleReportByJobId?.response?.isSubmitted?
                        <button  className={newScheduleReportLoading || updateScheduleReportLoading || submitReportByEmailLoading? 'disabled-btn drawer-filled-btn':'drawer-filled-btn'} disabled={newScheduleReportLoading || updateScheduleReportLoading || submitReportByEmailLoading}  
                            onClick={()=>{const result = confirm("Did you already recevied report on email?"); result? handelSubmitReportByEmail(reportStandardData?.scheduleId) : false}}
                        > 
                            {submitReportByEmailLoading? <Spinner/> : "Report recevied by email? "}
            
                        </button>
                        :null}

                    </div>

                    <div>
                        {activeTab==1 && !reportData?.getScheduleReportByJobId?.response?.isSubmitted?
                            <>
                                <button disabled={newScheduleReportLoading || updateScheduleReportLoading || submitReportByEmailLoading}  className={newScheduleReportLoading || updateScheduleReportLoading || submitReportByEmailLoading? 'disabled-btn drawer-filled-btn':'drawer-filled-btn'} onClick={()=>handelSubmit(false)}>
                                    {newScheduleReportLoading || updateScheduleReportLoading || submitReportByEmailLoading? <Spinner/> : "Save"}
                                </button>
                                &emsp;
                                <button disabled={newScheduleReportLoading || updateScheduleReportLoading || submitReportByEmailLoading}  className={newScheduleReportLoading || updateScheduleReportLoading || submitReportByEmailLoading? 'disabled-btn drawer-outlined-btn':'drawer-outlined-btn'} onClick={()=>{setConfirmationModal(true)}}>
                                    Save & Submit
                                </button>
                            </>
                            :
                            null
                        }

                    </div>


                    
                </div>
            }

            closable={false}
            // reportModalClose
        >

            <div className='modal-header-title'>
                <span>{data?.getReportTemplateById?.response?.template?.templatename.toUpperCase()}</span>
                <span onClick={reportModalClose}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div style={{padding:'16px 32px'}} className="setting-body-inner grid-tabs">
                <Tabs defaultActiveKey={"1"} activeKey={activeTab} onChange={(e)=>setActiveTab(e)}>
                    
                    <TabPane tab={"Manual Report"} key={"1"}>
                        <div style={reportData?.getScheduleReportByJobId?.response?.isSubmitted? 
                              {overflow:'auto', maxHeight:'75vh'}
                            : {overflow:'auto', maxHeight:'74.2vh'}
                        } 
                            className="hide-scrollbar border-radius">
                        
                            {/* show alert if report submitted by email */}
                            {
                                reportData?.getScheduleReportByJobId?.response?.isSubmittedByEmail?
                                <Alert
                                    showIcon
                                    type='success'
                                    message={"Report was submitted by email"}
                                    style={{margin:'0 10px'}}
                                />
                                :null
                            }

                            {
                            loading || reportDataLoading || submitReportByEmailLoading?
                                <div style={{margin:'auto', display:'table'}}>
                                    <Spinner/>
                                </div>
                            
                            :
                            genericFileUploadSignatureLoading || fileLoading || updateScheduleReportLoading || newScheduleReportLoading?
                                <div style={{display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center'}}>
                                    <div>
                                        <Spinner/>
                                    </div>
                                    <div className="text">
                                        {
                                            genericFileUploadSignatureLoading || fileLoading? "Files are uploading" :
                                            (updateScheduleReportLoading || newScheduleReportLoading) && "Please wait we are saving details"
                                        }
                                    </div>
                                </div>

                            :
                                reportFields.map((section, index) => {
                                    const sectionKey = Object?.keys(section)[0];
                                    const sectionItems = section[sectionKey] || [];
                                    const groupName = sectionItems[0]?.groupName || "";
                                    return(
                                        sectionItems?.length>0?
                                        
                                        <div style={{marginBottom:'0%', margin:'15px 8px'}} className="box-shadow border-radius">
                                            
                                            <div className='hr-info-title' style={{marginBottom:'16px'}}>
                                                {groupName}
                                            </div>
                                            
                                            <div className='report-section'>
                                                {
                                                    sectionItems.map((prop, itemIndex) => {
                                                        const label = prop?.label;
                                                        const name = prop?.label.toLowerCase().replace(/\s/g,"");
                                                        const fieldType = prop?.fieldType;
                                                        const newprop = prop;                                    
                                                        const {value, type} = field?.find((f)=>f.name==name) || {value: "", type: null};
                                                        

                                                        if(reportData?.getScheduleReportByJobId?.response?.isSubmitted){
                                                            
                                                            return(
                                                                <Form.Item style={{width:'49%'}} >
                                                                    <label style={{fontSize:'16px'}}>{label}</label>
                                                                    {type && FileFormatTypes?.includes(type) ?
                                                                    <div className='reportImgWrapper'>
                                                                        <img 
                                                                        src={reportData?.getScheduleReportByJobId?.response?.filesURL[name]}  
                                                                        onClick={()=>window.open(reportData?.getScheduleReportByJobId?.response?.filesURL[name],"_blank")} 
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className="text" style={{fontStyle:'italic', padding:'10px 5px', background:'rgb(245, 248, 250)'}}>{value?.length>0? value : "---"}</div>
                                                                    }
                                                                </Form.Item>
                                                            )
                                                        }else{
                                                            return(
                                                                lookupTypes.includes(fieldType)?
                                                                
                                                                <div  style={{width:'49%'}}>
                                                                    <CreateFormLookUpFields
                                                                        employee={false}
                                                                        setGroupInput={setGroupInput}
                                                                        tags={[]}
                                                                        setTags={()=>{}}
                                                                        property={{propertyDetail:prop}}
                                                                        value={value}
                                                                        name={name}
                                                                        handelDataValue={handelDataValue}
                                                                        checkMandatoryField={()=>{}}
                                                                        setBtn={()=>{}}
                                                                        disabled={true}
                                                                    />
                                                                </div>

                                                                :
                                                                <div  style={{width:'49%'}} >

                                                                    
                                                                    {
                                                                        type && FileFormatTypes?.includes(type) ?
                                                                        <div style={{marginTop:'16px'}}>
                                                                            <label>{label}</label>
                                                                            <div style={{display:'flex', gap:'16px'}}>
                                                                                <div className='prev-btn' onClick={()=>window.open(reportData?.getScheduleReportByJobId?.response?.filesURL[name],"_blank")}> View </div> 
                                                                                OR
                                                                                <div className='prev-btn' onClick={()=>setField(field?.filter((prop)=>prop?.name!==name))}> Replace </div>
                                                                            </div>
                                                                        </div>

                                                                        :
                                                                        
                                                                        <GenerateNormalFields
                                                                            label={label}
                                                                            name={name}
                                                                            fieldType={fieldType}
                                                                            handelDataValue={handelDataValue}
                                                                            property={newprop}
                                                                            value={value}
                                                                            employee={false}
                                                                            isReporting={true}
                                                                            disabled={true}
                                                                        />

                                                                    }

                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>

                                        </div>
                                        :
                                        null
                                    )
                                })
                            
                            } 
                            
                            

                            <ConfirmationModal
                                visible={confirmationModal}
                                close={()=>setConfirmationModal(false)}
                                handelSubmit={handelSubmit}
                            />
                        </div>
                    </TabPane>

                    <TabPane tab={"Send Reminder"} key={"2"}>

                        {activeTab==2 && !reportData?.getScheduleReportByJobId?.response?.isSubmitted?
                            <div style={{display:'flex', flexDirection:'right', justifyContent:'end', alignItems:'end', marginBottom:'16px'}}>
                                <button className='drawer-filled-btn' onClick={()=>{setReminderModal(true); refetch();}}>Send Reminder</button>
                            </div>
                        : null}

                        <Table
                            className='curvedTable'
                            columns={columns}
                            dataSource={reportReminderDataSource}
                            locale={{emptyText:'no reminder sent yet'}}
                            loading={reportReminderDataLoading}
                            style={{marginTop:'16px'}}
                        />
                    </TabPane>

                </Tabs>

                {/* confirmation modal to send reminder to customer or Agency */}
                <Confirmation
                    visible={reminderModal}
                    close={()=>{refetch(); setReminderModal(false);}}
                    message={"Do you want to send reminder?"}
                    error={false}
                    submit={handelReportReminder}
                    loading={newReportReminderLoading}
                />

            </div>

        </Modal>
    );
};

const ConfirmationModal = ({visible, close, handelSubmit})=>{
    return(
        <Modal    
            visible={visible}
            width={600}
            footer={
                <div 
                    className="drawer-footer"
                >

                    <button className={'drawer-filled-btn'} onClick={()=>{handelSubmit(true)}}>
                        Confirm
                    </button>

                    <button disabled={false}  className={'drawer-outlined-btn'} onClick={close}>
                        Cancel
                    </button>

                </div>
            }
            closable={false}
        >
            <div className='modal-header-title'>
                <span style={{letterSpacing:'0.2px'}}> Confirmation </span>
                <span  onClick={close}><FontAwesomeIcon className='close' icon={faClose}/></span>
            </div>

            <div className='modal-body modal-body-padding'>
                <div className="text" style={{textAlign:'center'}}>This action can not be undo. Are you sure to perform this action?</div>
            </div>

        </Modal>
    )
}