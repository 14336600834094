import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { getPayBillReportMutation } from "@src/util/mutation/systemReport.mutation";
import Spinner from "@src/components/spinner";
import { useSelector } from "react-redux";
import { saveAs } from 'file-saver';
import Papa from "papaparse";
import { holidayHour, regularHour, overTimeHour } from "@src/util/reportVariables";
import { Button, Dropdown, Menu } from "antd";
import { CaretDownFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";


// to do holiday bill and hour
// if first date is in holiday then first date hour and pay and bill will goes to holiday section and regular would be zero
// vice versa for second date as well
// Bank Holiday 65ff5efdcdef94de7cabc3cb

export const SystemReportBillDetailedView = () => {
    
    const location = useLocation();
    const {filters = {}, filterContent = '', reportDetail = {}} = location?.state || {};
    const navigate = useNavigate();

    
    
    const [reportData, setReportData] = useState([]);

    const [getPayBillReport, {loading: getPayBillReportLoading}] = useMutation(getPayBillReportMutation);

    const handelPayBillReport = async ()=>{
        try{
            if(filters?.conditions){

                const {data:{getPayBillReport: {response}}} = await getPayBillReport({
                    variables:{
                        filters: {conditions: filters.conditions[0]!=null? filters.conditions: [], date: {from: dayjs(filters?.dates?.from, "DD/MM/YYYY"), to: dayjs(filters?.dates?.to, "DD/MM/YYYY")}}
                    }
                });

                if(response?.length==0){
                    alert("There is no pay/bill calculated in the selected dates");
                    navigate(-1);
                }else{

                    setReportData(response);
                }

            }
        }catch(err){
            console.log(err);
        }
    };

    useEffect(()=>{
        if(filters){

            handelPayBillReport();

        }
    },[filters]);

    const exportToCSV = ()=>{

        const groupedData = groupByMultipleKeys(reportData, ["siteId"]);
        let csvData = [];

        Object.keys(groupedData)?.map((group)=>{
            groupedData[group]?.map((job)=>{
                
                const response = {
                    "Employee": job?.employeeDetail?.firstname+" "+(job?.employeeDetail?.lastname || ""),
                    
                    "Bill Start": dayjs(job?.paybillDetail?.paybillDetail?.billDetail?.billStartDateTime)?.format("DD/MM/YYYY HH:mm"),
                    
                    "Bill End": `£ ${parseFloat(job?.paybillDetail?.totalBill).toFixed(2)}`,

                    "Regular Hours" : job?.billHourTypeId==regularHour?
                        job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?

                        "00 hrs 00 m"

                        : job?.isBillFirstDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.paybillDetail?.paybillDetail?.billFirstDateHour))

                        : job?.isBillSecondDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.paybillDetail?.paybillDetail?.billSecondDateHour))
                        
                        :
                        convertToHrsMin(parseFloat(job?.paybillDetail?.paybillDetail?.billFirstDateHour)+parseFloat(job?.paybillDetail?.paybillDetail?.billSecondDateHour))
                    :
                    "00 hrs 00 m",

                    "Regular Bill": job?.billHourTypeId==regularHour?

                            job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                            "£ 0.00"
                            :

                            job?.isBillFirstDateInBankHoilday?
                            "£ " +(parseFloat(job?.paybillDetail?.secondDateBill))?.toFixed(2)
                            
                            :
                            
                            job?.isBillSecondDateInBankHoilday?
                            "£ " +(parseFloat(job?.paybillDetail?.firstDateBill))?.toFixed(2)

                        :
                            "£ " +(parseFloat(job?.paybillDetail?.totalBill).toFixed(2))
                        :
                        "£ 0.00",



                    "Holiday Bill Hour": 
                    job?.billHourTypeId==holidayHour?
                        job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.paybillDetail?.paybillDetail?.billFirstDateHour)+parseFloat(job?.paybillDetail?.paybillDetail?.billSecondDateHour))
                        
                        :
                        job?.isBillFirstDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.paybillDetail?.paybillDetail?.billFirstDateHour))
                        
                        : job?.isBillSecondDateInBankHoilday?
                        convertToHrsMin(parseFloat(job?.paybillDetail?.paybillDetail?.billSecondDateHour))
                
                        :
                        "00 hrs 00 m"
                    :
                    "00 hrs 00 m"
                    ,


                    "Holiday Bill": 
                        job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                        "£ " +(parseFloat(job?.paybillDetail?.totalBill)?.toFixed(2))
                        :
                        job?.isBillFirstDateInBankHoilday?
                            "£ " +(parseFloat(job?.paybillDetail?.firstDateBill))?.toFixed(2)
                            :
                        job?.isBillSecondDateInBankHoilday?
                            "£ " +(parseFloat(job?.paybillDetail?.secondDateBill))?.toFixed(2)
                        :
                        job?.billHourTypeId==holidayHour?
                        "£ " +(parseFloat(job?.paybillDetail?.totalBill)?.toFixed(2))

                        :
                        "£ 0.00"
                    ,

                    "Additional Bill Hour": "00 hrs 00 m",

                    "OverTime Bill": "£ 0.00",

                    "Premium Bill": `£ ${(job?.paybillDetail?.payBillPremium?.length>0 ? (job?.paybillDetail?.payBillPremium[0][1]?.payBillPremium?.totalPremiumBill)?.toFixed(2) : 0.00) || "0.00"}`,
                    
                    "Total Bill": `£ ${parseFloat(job?.paybillDetail?.totalBill)?.toFixed(2)}`,
                    
                };

                csvData.push(response);

            });
        });

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const name = reportDetail?.name+" - "+dayjs().format("DD/MM/YYYY");
        saveAs(blob, `${name}.csv`);

    };


    const exportToPdf = ()=>{

        window.print();

    };

    const groupByMultipleKeys = (data, keys) => {
        if (!keys.length) return data; // If no keys are provided, return original data
      
        return data.reduce((acc, item) => {
          let currentLevel = acc;
      
          // Iterate through keys and build nested structure
          keys.forEach((key, index) => {

            let keyValue = "Unknown";
            
            if(key=="siteId"){
                keyValue = item[key]+"_"+item['siteDetail']['sitename'].replaceAll(" ", "-")+"_site";
            }
      
            if (!currentLevel[keyValue]) {
              currentLevel[keyValue] = index === keys.length - 1 ? [] : {}; // Last level stores array, others are objects
            }
      
            // Move to the next nested level
            currentLevel = currentLevel[keyValue];
          });
      
          // Push item to the last nested level
          currentLevel.push(item);
          return acc;
        }, {});
    };

    const [groupedData, setGroupedData] = useState([]);

    const groupAndSum = (data, keys) => {
        if (!keys.length) return data; // If no keys are provided, return original data
    
        return data.reduce((acc, item) => {
            let currentLevel = acc;
    
            // Build dynamic keys for grouping
            keys.forEach((key, index) => {
                let keyValue = "Unknown";
    
                if (key === "siteId") {
                    keyValue = `${item[key]}_${item.siteDetail.sitename.replaceAll(" ", "-")}_site`;
                }
    
                // If last level, store an array
                if (!currentLevel[keyValue]) {
                    currentLevel[keyValue] = index === keys.length - 1 ? {  sums: {} } : {};
                }
    
                // Move to the next nested level
                currentLevel = currentLevel[keyValue];
            });
    
    
            // Define properties to sum
            const sumProperties = [
                "regularBillHour", "regularBill", "holidayBillHour",
                "holidayBill", "overtimeBillHour", "overtimeBill", 
                "premiumBill", "totalBill", "unBillBreak", "unBillBreakAmount", "totalHours"
            ];
    
            // Initialize sums object if not present
            if (Object.keys(currentLevel.sums)?.length==0) {
                currentLevel.sums = {};
                sumProperties.map(prop => currentLevel.sums[prop] = 0);
               

            }
            
            // Sum up required fields
            sumProperties.forEach(prop => { 

                let value = 0.00;

                if(prop=="regularBillHour"){

                    value = 
                    item?.isBillFirstDateInBankHoilday && item?.isBillSecondDateInBankHoilday?
                        0.00

                        : item?.isBillFirstDateInBankHoilday?
                        (parseFloat(item?.paybillDetail?.billSecondDateHour))

                        : item?.isBillSecondDateInBankHoilday?
                        (parseFloat(item?.paybillDetail?.billFirstDateHour))
                        
                        :
                        (parseFloat(item?.paybillDetail?.billFirstDateHour)+parseFloat(item?.paybillDetail?.billSecondDateHour))
                    
                }
                if(prop=="regularBill"){
                    
                    value = 

                    item?.isBillFirstDateInBankHoilday && item?.isBillSecondDateInBankHoilday?
                        0
                        :

                    item?.isBillFirstDateInBankHoilday?
                       (parseFloat(item?.paybillDetail?.secondDateBill))
                    
                    :
                    
                    item?.isBillSecondDateInBankHoilday?
                        item?.paybillDetail?.billFirstDateHour>0?
                        ( parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.unBillBreakAmount) )
                        :
                        (parseFloat(item?.paybillDetail?.firstDateBill))

                    :
                    item?.paybillDetail?.billFirstDateHour>0?
                        ( parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.secondDateBill)  + parseFloat(item?.paybillDetail?.unBillBreakAmount) )
                        :
                        (parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.secondDateBill))


                        
                

                }
                if(prop=="holidayBillHour"){

                    value = 
                        item?.isBillFirstDateInBankHoilday && item?.isBillSecondDateInBankHoilday?
                            (parseFloat(item?.paybillDetail?.billFirstDateHour)+parseFloat(item?.paybillDetail?.billSecondDateHour))
                        
                        :
                        item?.isBillFirstDateInBankHoilday?
                            (parseFloat(item?.paybillDetail?.billFirstDateHour))
                        
                        : item?.isBillSecondDateInBankHoilday?
                            (parseFloat(item?.paybillDetail?.billSecondDateHour))
                
                        :
                        0

                }
                if(prop=="holidayBill"){

                    value = item?.isBillFirstDateInBankHoilday && item?.isBillSecondDateInBankHoilday?
                        item?.paybillDetail?.billFirstDateHour>0?
                        ( parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.secondDateBill)  + parseFloat(item?.paybillDetail?.unBillBreakAmount) )
                        :
                        (parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.secondDateBill))

                    :

                    item?.isBillFirstDateInBankHoilday?

                        item?.paybillDetail?.billFirstDateHour>0?
                            (parseFloat(item?.paybillDetail?.firstDateBill) + parseFloat(item?.paybillDetail?.unBillBreakAmount))
                            :
                            parseFloat(item?.paybillDetail?.firstDateBill)


                    :

                    item?.isBillSecondDateInBankHoilday?
                        (parseFloat(item?.paybillDetail?.secondDateBill))
                    
                    :
                    0.00

                }
                if(prop=="overtimeBillHour"){

                    value =  item?.paybillDetail?.billOverTimeHour && item?.paybillDetail?.billOverTimeHour>0?
                        parseFloat(item?.paybillDetail?.billOverTimeHour)
                    :0
                    
                }
                if(prop=="overtimeBill"){

                    value = item?.paybillDetail?.billOverTimeHour && item?.paybillDetail?.billOverTimeHour>0?
                    item?.paybillDetail?.billFirstDateHour==0?
                        (parseFloat(item?.paybillDetail?.overTimeBill) + parseFloat(item?.paybillDetail?.unBillBreakAmount))
                    :
                        parseFloat(item?.paybillDetail?.overTimeBill)
                    : 0.00


                }
                if(prop=="premiumBill"){
                    value = (item?.paybillDetail?.payBillPremium?.length>0 ? (item?.paybillDetail?.payBillPremium[0][1]?.payBillPremium?.totalPremiumBill) : 0.00) || 0.00
                }
                if(prop=="totalBill"){
                    value = parseFloat(item?.paybillDetail?.totalBill)
                }

                if(prop=="unBillBreak"){
                    value = !Number.isNaN(parseFloat(item?.unBillBreakDuration))?
                        parseFloat(item?.unBillBreakDuration)/60
                    :
                    0
                }

                if(prop=="unBillBreakAmount"){
                    value = !Number.isNaN(parseFloat(item?.unBillBreakDuration))?
                        parseFloat(item?.paybillDetail?.unBillBreakAmount)
                    :
                    0
                }

                if(prop=="totalHours"){
                    value =  !Number.isNaN(parseFloat(item?.unBillBreakDuration))?
                    ((parseFloat(item?.paybillDetail?.billFirstDateHour)+parseFloat(item?.paybillDetail?.billSecondDateHour)+parseFloat(item?.paybillDetail?.billOverTimeHour))-(parseFloat(item?.unBillBreakDuration)/60))
                    
                    :
                    (parseFloat(item?.paybillDetail?.billFirstDateHour)+parseFloat(item?.paybillDetail?.billSecondDateHour)+parseFloat(item?.paybillDetail?.billOverTimeHour))
                    
                }


                currentLevel.sums[prop] += value;
            });

    
            return acc;
        }, {});
    };
    
    const [totalSite, setTotalSite] = useState({});

    console.log(totalSite,"totalSite")
    useEffect(()=>{
        if(reportData){
            // ["customerId", "siteGroupId", "siteId"]
            const groupedData = groupByMultipleKeys(reportData, ["siteId"]);
            console.log(groupedData, "groupedData");
            setGroupedData(groupedData);
            setTotalSite( groupAndSum(reportData, ["siteId"]) );

        }
    },[reportData]);

    

    const convertToHrsMin = (decimalHours) => {
        const hours = Math.floor(decimalHours); // Extract hours
        const minutes = Math.round((decimalHours - hours) * 60); // Convert decimal to minutes
    
        // Format hours and minutes with leading zero
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
    
        return `${formattedHours} hrs ${formattedMinutes} m`;
    }
    


    const {authenticatedUserDetail} = useSelector(state=>state.userAuthReducer);


    useEffect(()=>{
        document.title = reportDetail?.name+" "+dayjs().format("DD/MM/YYYY");

        return ()=>{
            document.title = "WFC";
        }
    },[]);
    
    const menu = (
        <Menu>
          <Menu.Item key="1" onClick={exportToCSV}>Export CSV</Menu.Item>
          <Menu.Item key="2" onClick={exportToPdf} >Print Report</Menu.Item>
        </Menu>
    );


    return (
        <div style={{padding: "15px", zoom: "1.15"}} id="pdfContent">

            <div className="reportsHeaderCss" >

                <div style={{display: "flex", justifyContent: "space-between"}}>
                   <span style={{fontWeight: "bold", fontSize: "20px", textTransform:'capitalize'}}>{reportDetail?.name}</span>
                   <div id="pdfexportBtn">

                        <Dropdown overlay={menu}>
                            <Button style={{fontSize:'12px'}}>
                                Exports <CaretDownFilled />
                            </Button>
                        </Dropdown>

                        
                        


                        <div className='close-link' style={{marginRight:'-20px'}} onClick={()=>navigate(-1)}>
                            <span >Close Report</span> <FontAwesomeIcon className='icon' icon={faTimes}/>
                        </div>

                    </div>
                   
                </div>

                <div className="row-flex" style={{gap: "30px", marginBottom: "6px"}}>
                    <div style={{fontWeight: "bold"}}> Report ID : {reportDetail?._id}</div>
                    <div style={{fontWeight: "bold"}}> Run Date : &nbsp;
                        <span className="text-muted" style={{fontWeight: "normal"}}>
                            {dayjs().format("DD/MM/YYYY HH:mm")} by {authenticatedUserDetail?.employeeDetail[0]?.firstname +" "+(authenticatedUserDetail?.employeeDetail[0]?.lastname || "")}
                        </span>
                    </div>
                </div>

                <div className="text-muted pt20">
                    <span>Date Range Report using dates between </span>
                    <span style={{padding: "0px 2px"}}>
                        {dayjs(filters?.dates?.from, "DD/MM/YYYY").format("ddd DD MMM YYYY HH:mm")}
                    </span>
                    and
                    <span style={{padding: "0px 2px"}}>
                        {dayjs(filters?.dates?.to, "DD/MM/YYYY").format("ddd DD MMM YYYY HH:mm")}
                    </span>
                </div>

                <div style={{display: "flex", width: "100%", marginTop: "6px"}}>
                    <div style={{width: "50px"}}>Filters :</div>
                    <div style={{width: "calc(100% - 110px)", paddingLeft: "10px", textAlign:'left', fontStyle:'oblique'}}
                        dangerouslySetInnerHTML={{__html: filterContent}}
                    >  
                      
                    </div>
                </div>

            </div>

            <div className="mt32"></div>


            <table id="payBillPDF" className="payBillMainTable">
                <tbody>
                    <tr className="payBillHeaderCss">
                        <th colspan="4">
                            Employee
                        </th>
                        <th colspan="2">Bill Start</th>
                        <th colspan="2">Bill End</th>
                        <th colspan="2">Regular Hours</th>
                        <th colspan="2">Regular Bill</th>
                        <th colspan="2">Bank Holiday Hours</th>
                        <th colspan="2">Bank Holiday Bill</th>
                        <th colspan="2">Additional Hours</th>
                        <th colspan="2">Additional Bill</th>

                        <th colspan="2">Break Hours</th>
                        <th colspan="2">Break Bill</th>

                        <th colspan="2">Premium  Bill</th>

                        <th colspan="2">Total Hours</th>

                        <th colspan="2">Total Bill</th>
                    </tr>
                    
                   {
                    getPayBillReportLoading?
                        <tr>
                            <td colSpan={30} style={{textAlign: 'center'}}><Spinner /></td>
                        </tr>
                    :
                    Object.keys(groupedData)?.map((key)=>{

                        const siteTotal = totalSite[key]?.sums;

                        return(
                            <>
                                {
                                    filters?.header?.groupbySite &&
                                    <tr className="customerCss">
                                        <td colspan="1">Site:</td>
                                        <td colspan="15">{key?.split("_")[1]?.replaceAll("-", " ")}</td>
                                    </tr>
                                }
                                
                                {/* first site record */}
                                <tr>
                                    <td colspan="30">
                                        <table className="payBillSitePayTable">
                                            <tbody>
                                            {
                                                groupedData[key]?.map((job)=>{
                                                    
                                                    return(
                                                
                                                        <tr className="siteBillCss">
                                                            {/* employee name */}
                                                            <td colspan="4">{job?.employeeDetail?.firstname+" "+(job?.employeeDetail?.lastname || "")}</td>
                                                            
                                                            {/* bill start  */}
                                                            <td  colspan="2"> 

                                                                {dayjs(job?.paybillDetail?.paybillDetail?.billDetail?.billStartDateTime)?.format("DD/MM/YYYY HH:mm")} 

                                                            </td >
                                                            
                                                            {/* bill end */}
                                                            <td  colspan="2">
                                                                {dayjs(job?.paybillDetail?.paybillDetail?.billDetail?.billEndDateTime)?.format("DD/MM/YYYY HH:mm")} 
                                                                
                                                            </td>
                                                            
                                                            {/* regular bill hour */}
                                                            <td colspan="2">
                                                                {
                                                                        job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?

                                                                        convertToHrsMin(0)

                                                                        : job?.isBillFirstDateInBankHoilday?
                                                                        convertToHrsMin(parseFloat(job?.paybillDetail?.billSecondDateHour))

                                                                        : job?.isBillSecondDateInBankHoilday?
                                                                        convertToHrsMin(parseFloat(job?.paybillDetail?.billFirstDateHour))
                                                                        
                                                                        :
                                                                        convertToHrsMin(parseFloat(job?.paybillDetail?.billFirstDateHour)+parseFloat(job?.paybillDetail?.billSecondDateHour))
                                                                   

                                                                }
                                                            
                                                            </td>

                                                            {/* regular bill amount */}
                                                            <td colspan="2">
                                                            {
                                                                    
                                                                // if job hour is regular but the duty hours comes under bank holiday
                                                                // it will set 0 for regular hours if both dates are in bank holiday
                                                                // if the first date is in bank holiday then second date bill would be regular and vice verse
                                                                    

                                                                        job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                                                                        "£ 0.00"
                                                                        :

                                                                        job?.isBillFirstDateInBankHoilday?
                                                                        "£ " +(parseFloat(job?.paybillDetail?.secondDateBill))?.toFixed(2)
                                                                        
                                                                        :
                                                                        
                                                                        job?.isBillSecondDateInBankHoilday?
                                                                            job?.paybillDetail?.billFirstDateHour>0?
                                                                            "£ "+ ( parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.unBillBreakAmount) ).toFixed(2)
                                                                            :
                                                                            "£ " +(parseFloat(job?.paybillDetail?.firstDateBill))?.toFixed(2)

                                                                        :
                                                                        job?.paybillDetail?.billFirstDateHour>0?
                                                                            "£ "+ ( parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.secondDateBill)  + parseFloat(job?.paybillDetail?.unBillBreakAmount) ).toFixed(2)
                                                                            :
                                                                            "£ "+ (parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.secondDateBill)).toFixed(2)

                                                                }
                                                            
                                                            </td>


                                                            {/* holiday bill hour */}
                                                            <td colspan="2">
                                                                {
                                                                        job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                                                                        convertToHrsMin(parseFloat(job?.paybillDetail?.billFirstDateHour)+parseFloat(job?.paybillDetail?.billSecondDateHour))
                                                                       
                                                                        :
                                                                        job?.isBillFirstDateInBankHoilday?
                                                                        convertToHrsMin(parseFloat(job?.paybillDetail?.billFirstDateHour))
                                                                        
                                                                        : job?.isBillSecondDateInBankHoilday?
                                                                        convertToHrsMin(parseFloat(job?.paybillDetail?.billSecondDateHour))
                                                                
                                                                        :
                                                                        "00 hrs 00 m"

                                                                }
                                                            
                                                            </td>

                                                            
                                                            {/* total bill amount in holiday */}
                                                            <td colspan="2">
                                                                {
                                                                    job?.isBillFirstDateInBankHoilday && job?.isBillSecondDateInBankHoilday?
                                                                        job?.paybillDetail?.billFirstDateHour>0?
                                                                        "£ "+ ( parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.secondDateBill)  + parseFloat(job?.paybillDetail?.unBillBreakAmount) ).toFixed(2)
                                                                        :
                                                                        "£ "+ (parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.secondDateBill)).toFixed(2)

                                                                    :

                                                                    job?.isBillFirstDateInBankHoilday?

                                                                        job?.paybillDetail?.billFirstDateHour>0?
                                                                            "£ "+ (parseFloat(job?.paybillDetail?.firstDateBill) + parseFloat(job?.paybillDetail?.unBillBreakAmount)).toFixed(2)
                                                                            :
                                                                            "£ "+ parseFloat(job?.paybillDetail?.firstDateBill).toFixed(2)


                                                                    :

                                                                    job?.isBillSecondDateInBankHoilday?
                                                                        "£ " +(parseFloat(job?.paybillDetail?.secondDateBill))?.toFixed(2)
                                                                    
                                                                    :
                                                                     "£ 0.00"

                                                                }


                                                            </td>
                                                            
                                                            {/* total bill additional / overtime hour */}
                                                            <td colspan="2">
                                                                {
                                                                    job?.paybillDetail?.billOverTimeHour && job?.paybillDetail?.billOverTimeHour>0?
                                                                        convertToHrsMin(job?.paybillDetail?.billOverTimeHour)
                                                                    :convertToHrsMin(0)                        
                                                                }
                                                            </td>
                                                            
                                                            {/* total pay and bill additional / overtime amout */}
                                                            <td colspan="2">
                                                                {
                                                                    job?.paybillDetail?.billOverTimeHour && job?.paybillDetail?.billOverTimeHour>0?
                                                                    job?.paybillDetail?.billFirstDateHour==0?
                                                                    "£ "+(parseFloat(job?.paybillDetail?.overTimeBill) + parseFloat(job?.paybillDetail?.unBillBreakAmount)).toFixed(2)
                                                                    :
                                                                    "£ "+parseFloat(job?.paybillDetail?.overTimeBill).toFixed(2)

                                                                    : "£ 0.00"

                                                                }
                                                            </td>

                                                            {/* unbilled break hour */}
                                                            <td colspan="2">
                                                                {
                                                                    !Number.isNaN(parseFloat(job?.unBillBreakDuration))?
                                                                    <>
                                                                        -  { convertToHrsMin(parseFloat(job?.unBillBreakDuration)/60) } <br/>
                                                                    
                                                                       
                                                                    
                                                                    </>
                                                                    :
                                                                    convertToHrsMin(0)
                                                                }
                                                            </td>

                                                            {/* unbilled break amount */}
                                                            <td colspan="2">
                                                                {
                                                                    !Number.isNaN(parseFloat(job?.unBillBreakDuration))?
                                                                    <>
                                                                        
                                                                        - £ {parseFloat(job?.paybillDetail?.unBillBreakAmount).toFixed(2)}

                                                                    
                                                                    </>
                                                                    :
                                                                    "£ 0.00"
                                                                }
                                                            </td>



                                                            {/* total pay and bill premium hour */}
                                                            <td colspan="2">
                                                                £ {(job?.paybillDetail?.payBillPremium?.length>0 ? (job?.paybillDetail?.payBillPremium[0][1]?.payBillPremium?.totalPremiumBill)?.toFixed(2) : 0.00) || "0.00"}
                                                            </td>

                                                            {/* total hours */}
                                                            <td colspan="2">
                                                                {
                                                                    !Number.isNaN(parseFloat(job?.unBillBreakDuration))?
                                                                    convertToHrsMin((parseFloat(job?.paybillDetail?.billFirstDateHour)+parseFloat(job?.paybillDetail?.billSecondDateHour)+parseFloat(job?.paybillDetail?.billOverTimeHour))-(parseFloat(job?.unBillBreakDuration)/60))
                                                                    
                                                                    :
                                                                    convertToHrsMin(parseFloat(job?.paybillDetail?.billFirstDateHour)+parseFloat(job?.paybillDetail?.billSecondDateHour)+parseFloat(job?.paybillDetail?.billOverTimeHour))
                                                                    
                                                                }
                                                            </td>

                                                            {/* total pay and bill hour */}
                                                            
                                                            <td colspan="2">£ {parseFloat(job?.paybillDetail?.totalBill)?.toFixed(2)} </td>
                                                            
                                                        </tr>

                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>


                                {
                                    filters?.header?.groupbySite &&
                                    <>
                                        {/* site total border */}
                                        <tr style={{height: "0px"}}>
                                            <td colspan="4"></td><td colspan="26" style={{borderBottom: "2px solid black"}}></td>
                                        </tr>
                                        

                                        {/* Site total */}
                                        <tr>
                                            <td colspan="30">
                                                <table className="payBillSiteCardTable">
                                                    <thead>
                                                        <tr className="titleCss">
                                                            <th colspan="6"></th>
                                                            <th  colspan="2">Site Totals:</th>
                                                            <th colspan="2">Regular Hours</th>
                                                            <th colspan="2">Regular Bill</th>
                                                            <th colspan="2">Bank Holiday Hours</th>
                                                            <th colspan="2">Bank Holiday Bill</th>
                                                            <th colspan="2">Additional Hours</th>
                                                            <th colspan="2">Additional Bill</th>
                                                            <th colspan="2">Break Hours</th>
                                                            <th colspan="2">Break Bill</th>
                                                            <th colspan="2">Premium  Bill</th>
                                                            <th colspan="2">Total Hours</th>
                                                            <th colspan="2">Total Bill</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr className="row1">
                                                            <td colSpan={8}></td>
                                                            <td colSpan={2} >
                                                                {convertToHrsMin(parseFloat(siteTotal?.regularBillHour))} 
                                                                
                                                            </td>

                                                            <td colSpan={2}>
                                                                £ {parseFloat(siteTotal?.regularBill)?.toFixed(2)}
                                                            </td>

                                                            <td colSpan={2}>
                                                                {convertToHrsMin((siteTotal?.holidayBillHour))}
                                                            </td>

                                                            <td colSpan={2}>£ {parseFloat(siteTotal?.holidayBill)?.toFixed(2)} </td>

                                                            <td colSpan={2}>{convertToHrsMin(siteTotal?.overtimeBillHour)}</td>

                                                            <td colSpan={2}>£ {parseFloat(siteTotal?.overtimeBill)?.toFixed(2)}</td>
                                                            
                                                            <td colSpan={2}> 
                                                                {parseFloat(siteTotal?.unBillBreak)>0 ? "- "+ convertToHrsMin(parseFloat(siteTotal?.unBillBreak || 0)) : convertToHrsMin(0)} 
                                                                
                                                            </td>
                                                            
                                                            <td colSpan={2}> 
                                                                {siteTotal?.unBillBreakAmount>0? "- £ "+parseFloat(siteTotal?.unBillBreakAmount).toFixed(2): "£ 0.00"}
                                                            </td>
                                                            


                                                            <td colSpan={2}>£ {parseFloat(siteTotal?.premiumBill)?.toFixed(2)}</td>

                                                            <td colSpan={2}>{convertToHrsMin(parseFloat(siteTotal?.totalHours))}</td>
                                                            <td colSpan={2}>£ {parseFloat(siteTotal?.totalBill)?.toFixed(2)}</td>
                                                            
                                                           
                                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </>
                                }


                            </>
                        )
                    })
                   }


                
                </tbody>
            </table>

        </div>
    );

};